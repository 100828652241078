import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { getSlug, clearQueryParams } from 'utils';
import { useLocation } from '@reach/router';
import { FormContainer } from '../FormComponents';

const NewUserEmailVerification = ({
  colors,
  setShowNewUserEmailVerificationModal,
  newUserEmailVerified,
  event
}) => {
  const { user } = useContext(FirebaseContext);
  const { theme } = useContext(LocalContext);
  const [userSetInDatabase, setUserSetInDatabase] = useState(false);
  const { origin, pathname } = useLocation();

  const handleButtonClick = () => {
    if (user) {
      if (pathname === '/' && event && user?.emailVerified && user?.eventsUserCanAccess.includes(event.node.frontmatter.eid)) {
        navigate(`/events/${event.node.frontmatter.slug}/livestream/`);
      }
      clearQueryParams(`${origin}${pathname}`);
      setShowNewUserEmailVerificationModal(false);
    }
  };

  useEffect(() => {
    if (newUserEmailVerified && user?.emailVerified) {
      setUserSetInDatabase(true);
    }
  }, [newUserEmailVerified, user]);

  return (
    <FormContainer theme={theme} colors={colors}>
      <Status
        newUserEmailVerified={newUserEmailVerified}
        dangerouslySetInnerHTML={{
          __html:
          userSetInDatabase
              ? `Thanks, ${user.firstName}!<br><br>Your email address is now verified.${
                  !user.emailAddressIsWhitelisted && !user.eventsUserCanAccess.includes(event?.node.frontmatter.eid)
                    ? ' Our moderators will review your registration application and respond as soon as possible.<br><br>Keep an eye on that inbox!'
                    : ''
                }<br><br>`
              : 'Verifying Email'
        }}
      />
      {userSetInDatabase && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => handleButtonClick()}
            type="button"
            width={event ? '14rem' : '9.25rem'}
            style={{ alignSelf: 'center', marginTop: '0.5rem' }}>
            Close
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

const Status = styled.h3`
  align-items: center;
  display: flex;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;

  ${({ newUserEmailVerified }) =>
    !newUserEmailVerified &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.25rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 #fff;
        }
      }
    `}
`;

export default NewUserEmailVerification;
