import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FirebaseContext, LocalContext } from 'context';
import { Markup } from 'interweave';
import { AnimatePresence, motion } from 'framer-motion';
import { fullGridContainer, breakpoints } from 'styles';

export default (props) => {
  const {
    colors,
    eventName,
    eventDates,
    eventBanner,
    eventBannerPromotion,
    eventSubtitle,
    eventTitle,
    eventBannerVideo
  } = props;
  const promoImage = getImage(eventBannerPromotion);
  const { headerRef, selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [streamUrl, setStreamUrl] = useState(null);
  const headerImage = getImage(eventBanner);

  useEffect(() => {
    let unsubscribeFromEventUpdates;

    if (firebase && selectedEvent) {
      unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
        eid: selectedEvent.eid,
        onSnapshot: (snapshot) => {
          if (snapshot.exists) {
            const { streamUrl: _streamUrl } = snapshot.data();

            if (_streamUrl !== streamUrl) {
              setStreamUrl(_streamUrl);
            }
          } else if (!snapshot.exists) {
            /* CONOR TODO: Is there a better way of doing this? Can we automatically add the event doc somehow? Hmmmm */
            console.error(
              "You need to add a doc for this event in the 'Events' collection in Firebase."
            );
          }
        }
      });
      return () => {
        if (unsubscribeFromEventUpdates) {
          unsubscribeFromEventUpdates();
        }
      };
    }
  }, [firebase, selectedEvent]);

  return (
    <Header
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Background>
        {eventBannerVideo !== '' && (
          <BackgroundVideo autoPlay muted loop id="bgvid">
            <source src={eventBannerVideo} type="video/mp4" />
          </BackgroundVideo>
        )}
        <BackgroundImage image={headerImage} alt="Background" />
      </Background>
      <HeaderText>
        <DateAndTime>{eventDates}</DateAndTime>
        <Markup content={eventTitle} noWrap />
        {eventSubtitle && (
          <EventSubtitle>
            <Markup content={eventSubtitle} noWrap />
          </EventSubtitle>
        )}
      </HeaderText>
      <Container id="livestream" colors={colors}>
        <AnimatePresence>
          {streamUrl && (
            <LiveStream initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <div style={{ padding: '56.25% 0 0 0', height: '100%', position: 'relative' }}>
                <iframe
                  title={eventName}
                  src={`${streamUrl}&pip=1&autoplay=1&fullscreen=1&controls=1`}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                />
              </div>
            </LiveStream>
          )}
        </AnimatePresence>
      </Container>
      {eventBannerPromotion && <BannerPromotion image={promoImage} alt="Promotion" />}
    </Header>
  );
};

const HeaderText = styled(motion.div)`
  align-items: center;
  color: #fff;
  display: grid;
  line-height: 3em;
  position: relative;
  max-width: calc(1200px + 1rem);
  padding: 0 1rem;
  margin: 0 auto 2.5rem;
  z-index: 1;

  h1 {
    font-size: 3rem;
    margin-top: 0.425em;
  }
`;

const DateAndTime = styled.h3`
  font-size: 1.5rem;
`;

const EventSubtitle = styled.p`
  font-size: 1rem;
  margin-left: 0.188em;
  margin-top: 1.25em;
`;

const LiveStream = styled(motion.div)`
  grid-column: 1/7;
  height: auto;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.lg}) {
    grid-column: 2/12;
    height: 100%;
    margin: 0px;
  }
`;

const Container = styled(motion.div)`
  ${fullGridContainer}
  color: #00004E;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  grid-gap: 0;
  padding: 0;
  @media (min-width: ${breakpoints.lg}) {
    grid-gap: 0;
  }
`;

const BannerPromotion = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  z-index: 1;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const BackgroundVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Header = styled.header`
  height: auto;
  width: 100%;
  position: relative;
  padding-top: 4rem;
  @media (min-width: ${breakpoints.lg}) {
    min-height: 100vh;
    padding: 1.25rem 1.25rem 4rem;
    padding-top: 10rem;
  }
`;
