import { useStaticQuery, graphql } from 'gatsby';

const useEventData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(events)/" }
          frontmatter: { startTime: { ne: null } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              eid
              colors {
                primary
                secondary
                tertiary
              }
              name
              start
              end
              slug
              location
              type
              hidden
              dates
              description
              startTime
              endTime
              thumb {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark;
};

export default useEventData;
