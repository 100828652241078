import * as React from 'react';

function SvgLocoIcon(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={13} cy={13} r={13} fill="#E79E6D" />
      <path
        d="M18 10.92c0 4.08-5.054 9.524-5.054 9.524S8 15 8 10.92C8 8.302 10.258 6 13.054 6 15.85 6 18 8.198 18 10.92z"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13a2 2 0 100-4 2 2 0 000 4z"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgLocoIcon;
