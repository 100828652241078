import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Markup } from 'interweave';
import { useEventData, useWindowSize } from 'hooks';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { LeftArrow, RightArrow } from 'assets/svgs';

export default ({ colors }) => {
  const { edges: events } = useEventData();
  const [playCarousel, setPlayCarousel] = useState(true);
  const pastEvents = events
    .filter(({ node }) => node.frontmatter.type === 'past' && !node.frontmatter.hidden)
    .sort((a, b) => b.node.frontmatter.id - a.node.frontmatter.id);
  const { windowWidth } = useWindowSize();
  return (
    <Wrapper>
      <CarouselProvider
        naturalSlideWidth={374}
        naturalSlideHeight={240}
        totalSlides={4}
        visibleSlides={windowWidth < 768 ? 1 : windowWidth < 1200 ? 2 : 3}
        isIntrinsicHeight
        isPlaying={playCarousel}
        interval={6000}
        infinite>
        <CarouselWrapper
          onMouseOver={() => setPlayCarousel(false)}
          onMouseOut={() => setPlayCarousel(true)}>
          <StyledButtonBack>
            <StyledCustomLeftArrow colors={colors}>
              <LeftArrow />
            </StyledCustomLeftArrow>
          </StyledButtonBack>
          <StlyedSlider>
            {pastEvents.map((event, i) => {
              const eventImage = getImage(event.node.frontmatter.thumb);
              return (
                <StyledSlide key={event.node.frontmatter.name} index={i}>
                  <Event key={event.node.frontmatter.id} colors={colors}>
                    <div>
                      {eventImage && <GatsbyImage image={eventImage} alt={event.node.frontmatter.name} />}
                      <Details colors={colors}>
                        <h3>{event.node.frontmatter.name}</h3>
                        <h4>
                          <Markup content={event.node.frontmatter.dates} />
                        </h4>
                      </Details>
                      <Link to={event.node.frontmatter.slug}>Visit Event</Link>
                    </div>
                  </Event>
                </StyledSlide>
              );
            })}
          </StlyedSlider>
          <StyledButtonNext>
            <StyledCustomRightArrow colors={colors}>
              <RightArrow />
            </StyledCustomRightArrow>
          </StyledButtonNext>
        </CarouselWrapper>
      </CarouselProvider>
    </Wrapper>
  );
};

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5em 0;
  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${({ colors }) => colors.secondary} !important;
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    color: #3c3c3c !important;
    margin-bottom: 0.5em;
  }
`;

const Event = styled.div`
  background: transparent;
  padding: 0;
  width: 100%;
  height: 100%;
  div {
    display: flex;
    flex-direction: column;
    margin: 0 0.3175rem;
    height: 100%;
    a {
      margin: auto 0;
      padding: 0.75rem 1.5rem;
      border-radius: 3rem;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      background-color: ${({ colors }) => colors.tertiary};
      color: white;
      transition: 0.3s;
      width: 150px;
      &:hover {
        background-color: ${({ colors }) => colors.secondary};
      }
    }
  }
`;

const StyledCustomRightArrow = styled.div`
  background-color: ${({ colors }) => colors.primary};
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    margin-left: -5px;
  }
  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s;
    background-color: ${({ colors }) => colors.primary};
  }
  &:not(:hover) {
    transform: scale(1);
    transition: transform 0.2s;
    background-color: ${({ colors }) => colors.primary};
  }
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    svg {
    height: 15px;
    width: 15px;
    margin-left: -3.75px;
  }
  @media (min-width: 2000px) {
    right: 15rem !important;
  }
  @media (min-width: 2200px) {
    right: 25rem !important;
  }
`;

const StyledCustomLeftArrow = styled.div`
  background-color: ${({ colors }) => colors.primary};
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 20px;
    width: 20px;
    margin-right: -5px;
  }
  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s;
    background-color: ${({ colors }) => colors.primary};
  }
  &:not(:hover) {
    transform: scale(1);
    transition: transform 0.2s;
    background-color: ${({ colors }) => colors.primary};
  }
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    svg {
      height: 15px;
      width: 15px;
      margin-right: -3.75px;
    }
  }
  @media (min-width: 2000px) {
    left: 15rem !important;
  }
  @media (min-width: 2200px) {
    left: 25rem !important;
  }
`;

const Wrapper = styled.section`
  padding: 2rem 0;
  > h3 {
    color: #3c3c3c;
    font-size: 1.35rem;
    margin: 0 auto 2.05em;
    padding: 0 2em;
    text-align: center;
  }
  > p {
    color: #3c3c3c;
    font-size: 1rem;
    line-height: 1.4em;
    margin: 0 auto 2.55em;
    max-width: 32.813em;
    padding: 0 1.25em;
    text-align: center;
  }
  @media screen and (min-width: 768px) {
    > h3 {
      font-size: 1.5rem;
    }
    > p {
      font-size: 1.25rem;
      max-width: 58.15em;
    }
  }
`;

const CarouselWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  max-width: 90rem;
  width: 100%;
`;

const StlyedSlider = styled(Slider)`
  grid-column: 2/12;
  grid-row: 1;
  width: 100%;
  @media screen and (min-width: 74rem) {
    left: 0.188rem;
    position: relative;
  }
`;

const StyledSlide = styled(Slide)`
  width: 100%;
  p {
    color: ${({ color }) => color};
    font-size: 1.25rem;
    font-weight: 700;
    &:first-of-type {
      margin-top: 1.1em;
    }
    &:last-of-type {
      color: #3c3c3c;
      font-weight: 400;
    }
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  background-color: transparent;
  cursor: pointer;
  grid-column: 1/2;
  grid-row: 1;
  justify-self: start;
  @media screen and (min-width: 1440px) {
    position: relative;
  }
`;

const StyledButtonNext = styled(ButtonNext)`
  background-color: transparent;
  cursor: pointer;
  grid-column: 12/13;
  grid-row: 1;
  @media screen and (min-width: 520px) {
    grid-column: 12/13;
  }
  @media screen and (min-width: 696px) {
    margin-left: auto;
  }
  @media screen and (min-width: 1200px) {
    position: relative;
  }
`;
