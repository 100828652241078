import * as React from 'react';

function SvgDefaultGroupAvatar(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.094 12.986a3.327 3.327 0 0 0 1.302-2.63C16.396 8.51 14.868 7 13 7c-1.868 0-3.396 1.51-3.396 3.357 0 1.063.51 2.014 1.302 2.629C8.642 13.826 7 16.007 7 18.524c0 3.301 12 3.301 12 0 0-2.517-1.585-4.699-3.906-5.538Z"
        fill="#FFFFFF"
      />
      <path
        d="M9.745 10.703c.66-.483 1.085-1.23 1.085-2.066C10.83 7.187 9.557 6 8 6 6.443 6 5.17 7.187 5.17 8.637c0 .836.424 1.583 1.085 2.066C4.368 11.363 3 13.077 3 15.055c0 2.593 10 2.593 10 0 0-1.978-1.32-3.692-3.255-4.352ZM19.745 10.703c.66-.483 1.085-1.23 1.085-2.066C20.83 7.187 19.557 6 18 6c-1.557 0-2.83 1.187-2.83 2.637 0 .836.424 1.583 1.085 2.066-1.887.66-3.255 2.374-3.255 4.352 0 2.593 10 2.593 10 0 0-1.978-1.32-3.692-3.255-4.352Z"
        fill="#FFFFFF"
        fillOpacity={0.5}
      />
      <circle cx={13} cy={13} r={12.5} stroke="#FFFFFF" />
    </svg>
  );
}
export default SvgDefaultGroupAvatar;