import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';
import { Modal, EventKioskPopup } from 'components';

export default ({ kiosk, colors }) => {
  const { user } = useContext(FirebaseContext);
  return (
    <Kiosk>
      {kiosk.map((item) => {
        const thumbnail = getImage(item.thumb);
        return (
          <StickyNote key={item.id}>
            <StickyImg image={thumbnail} alt={item.name} />
            <Details colors={colors}>
              <h3>{item.name}</h3>
              <p>{item.excerpt}</p>

              <Modal
                trigger={
                  <ReadMore colors={colors} type="button">
                    Read More
                  </ReadMore>
                }
                modalContent={<EventKioskPopup colors={colors} stickyNote={item} />}
              />
            </Details>
          </StickyNote>
        );
      })}
    </Kiosk>
  );
};

const ReadMore = styled.button`
  width: 10rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  background: ${({ colors }) => colors.tertiary};
  border-radius: 50px;
  text-transform: uppercase;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

const StickyImg = styled(GatsbyImage)`
  width: 100%;
  height: 250px;
  object-fit: cover !important;
  object-position: bottom right !important;
`;

const Details = styled.div`
  width: 100%;
  height: auto;
  font-size: 1.25rem;
  padding: 1em 1.5em;
  h3 {
    color: ${({ colors }) => colors.secondary};
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.5em;
  }
  p {
    color: #3c3c3c;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: left;
    width: 100% !important;
    text-align: left !important;
    margin-bottom: 2em !important;
  }
`;

const StickyNote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  text-align: left;
  width: 100%;
`;

const Kiosk = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  padding: 1.25rem;
  background: white;
  img {
    max-width: 100%;
    object-position: bottom right !important;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }
`;
