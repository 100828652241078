import React from 'react';
import { SEO } from 'components';

const Home = () => {

  return (
    <>
      <SEO pageSpecificTitle="Home" />
    </>
  );
};

export default Home;
