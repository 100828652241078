const app = import('firebase/app');
const auth = import('firebase/auth');
const realtimeDatabase = import('firebase/database');
const firestoreDatabase = import('firebase/firestore');
const functions = import('firebase/functions');
const storage = import('firebase/storage');

const loadFirebaseDependencies = Promise.all([
  app,
  auth,
  realtimeDatabase,
  firestoreDatabase,
  functions,
  storage
])
  .then((values) => values[0].default)
  .catch(console.error);

export default loadFirebaseDependencies;
