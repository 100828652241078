import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { clearQueryParams } from 'utils';
import ModalContent from '../ModalContent';
import ModalTrigger from '../ModalTrigger';

export default ({
  colors,
  disableCloseOnClickOutside,
  hideCloseButton,
  modalContent,
  openWithoutTrigger,
  redirectIfNotAuthorised,
  customOutsideClose,
  setShowTangent90ConsentModal,
  style,
  transparentCloseButtonBackground,
  trigger
}) => {
  const [isShown, setIsShown] = useState(openWithoutTrigger);
  const TriggerButton = useRef(null);
  const closeButton = useRef(null);
  const modal = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsShown(openWithoutTrigger);
  }, [openWithoutTrigger]);

  useEffect(() => {
    if (isShown === false) document.querySelector('html').classList.remove('scroll-lock');
  }, [isShown]);

  const showModal = () => {
    setIsShown(true);
    document.querySelector('html').classList.add('scroll-lock');
  };

  const closeModal = () => {
    setIsShown(false);
    document.querySelector('html').classList.remove('scroll-lock');
    if (setShowTangent90ConsentModal) {
      setShowTangent90ConsentModal(false);
    }

    if (openWithoutTrigger && !setShowTangent90ConsentModal) {
      clearQueryParams();
    }
    if (redirectIfNotAuthorised && pathname !== '/') {
      navigate('/');
    }
    if (customOutsideClose) {
      customOutsideClose();
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Escape' && !disableCloseOnClickOutside) {
      closeModal();
    }
  };

  const onClickOutside = (e) => {
    if ((modal && modal.current.contains(e.target)) || disableCloseOnClickOutside) return;
    closeModal();
  };

  return (
    <>
      <ModalTrigger showModal={showModal} buttonRef={TriggerButton} trigger={trigger} />
      {isShown ? (
        <ModalContent
          buttonRef={closeButton}
          closeModal={closeModal}
          content={modalContent}
          hideCloseButton={hideCloseButton}
          transparentCloseButtonBackground={transparentCloseButtonBackground}
          modalRef={modal}
          onClickOutside={onClickOutside}
          onKeyDown={onKeyDown}
          openWithoutTrigger={openWithoutTrigger}
          colors={colors}
          style={style}
        />
      ) : (
        <></>
      )}
    </>
  );
};
