import React from 'react';
import { EventHost } from 'components';
import styled from 'styled-components';

export default ({ eventHosts, colors, eid }) => {
  console.log(eventHosts);
  return (
    <Hosts>
      {eventHosts.map((host) => (
        <EventHost
          key={host.hostOrder}
          data={host}
          cropped
          popupType="speaker"
          carouselData={eventHosts}
          colors={colors}
          eid={eid}
        />
      ))}
    </Hosts>
  );
};

const Hosts = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr;
  justify-content: center;
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 1.25rem;
  position: relative;
  width: 100%;
  @media (min-width: 1600px) {
    max-width: 1400px;
  }
`;
