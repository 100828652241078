import * as React from 'react';

function SvgBannerSvg1(props) {
  return (
    <svg viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M200 400c110.457 0 200-89.543 200-200S310.457 0 200 0 0 89.543 0 200s89.543 200 200 200z"
        fill="#36D1B7"
      />
      <path
        d="M200 296.203c-1.013 0-2.025 0-2.532-.506-82.025-24.811-82.531-128.102-80.506-158.988.506-5.063 4.557-9.114 10.127-9.114h5.569c31.393 0 53.671-15.19 62.279-21.772 1.519-1.519 3.544-2.025 6.076-2.025 2.025 0 4.05.506 6.076 2.025 8.101 6.582 30.886 21.772 61.772 21.772H274.937c5.063 0 9.62 4.051 10.126 9.114 2.026 30.886 1.519 134.177-80.506 158.988-2.532.506-3.544.506-4.557.506zm-73.418-160.506c-.506 0-1.012 0-1.519.506-.506.506-.506 1.013-.506 1.519-1.519 37.975 2.532 128.101 75.443 150.38h1.013c75.949-22.785 75.949-121.013 74.43-150.38 0-1.013-1.012-2.025-2.025-2.025h-5.063c-33.418 0-57.722-16.203-66.836-23.292-.506-.506-1.013-.506-1.013-.506-.506 0-1.012 0-1.012.506-9.114 7.089-33.418 23.292-66.836 23.292h-6.076z"
        fill="#fff"
      />
      <path
        d="M187.848 245.064c-2.025 0-4.05-2.025-4.05-4.051V216.71c0-1.013-1.013-2.026-2.026-2.026h-24.303c-2.026 0-4.051-2.025-4.051-4.05V186.33c0-2.026 2.025-4.051 4.051-4.051h24.303c1.013 0 2.026-1.012 2.026-2.025V155.95c0-2.025 2.025-4.051 4.05-4.051h24.81c2.026 0 4.051 2.026 4.051 4.051v24.304c0 1.013 1.013 2.025 2.025 2.025h24.304c2.026 0 4.051 2.025 4.051 4.051v24.304c0 2.025-2.025 4.05-4.051 4.05h-24.304c-1.012 0-2.025 1.013-2.025 2.026v24.303c0 2.026-2.025 4.051-4.051 4.051h-24.81zm-24.303-55.19c-1.013 0-2.026 1.013-2.026 2.025v12.152c0 1.013 1.013 2.026 2.026 2.026h24.303c2.026 0 4.051 2.025 4.051 4.05v24.81c0 1.013 1.013 2.026 2.025 2.026h12.152c1.013 0 2.026-1.013 2.026-2.026v-24.303c0-2.026 2.025-4.051 4.05-4.051h24.304c1.013 0 2.025-1.013 2.025-2.025v-12.152c0-1.013-1.012-2.026-2.025-2.026h-23.798c-2.025 0-4.05-2.025-4.05-4.05v-24.304c0-1.013-1.013-2.025-2.025-2.025h-12.152c-1.013 0-2.026 1.012-2.026 2.025v24.304c0 2.025-2.025 4.05-4.05 4.05h-24.81v-.506z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgBannerSvg1;
