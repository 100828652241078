import * as React from 'react';

function SvgBackground(props) {
  return (
    <svg viewBox="0 0 849 900" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={0.4} strokeMiterlimit={10}>
        <path
          opacity={0.66}
          d="M602.954 1450.02c-107.5-30.6-323 73.9-409.5 4.4-83.5-67.1-80-274.3-98.6-397.8-16-106.3-74.4-195.4-87.6-301.7-13.6-110.1-5-220.5 25.6-327.9 30.5-107.5 81.3-205.9 150.8-292.3 67.1-83.5 130.6-257.7 145.1-364.2 11.1-81.2 37.8-244.5 144.1-257.7 110.1-13.6 388.7 52.5 496.1 83 107.396 30.5 292.696 342.3 379.196 411.8 83.5 67.1 152.3 148.7 204.6 242.6 52.3 93.9 85.5 195.4 98.7 301.7 13.6 110.1 5.1 220.5-25.5 327.9-30.6 107.5-81.3 205.8-150.8 292.3-67.1 83.5-148.7 152.3-242.6 204.6-93.9 52.3-195.4 85.5-301.696 98.7-110.1 13.7-220.5 5.2-327.9-25.4z"
          stroke="#000"
          strokeWidth={0.75}
        />
        <path
          opacity={0.644}
          d="M332.355-217.08c11.8-80.3 39.1-240.5 144.1-253.9 109.6-14.2 383.7 50 490.8 80.2 107.295 30.2 290.395 335.6 376.695 405.4 82.6 66.6 150.6 147.6 202.2 240.6 51.6 93.1 84.3 193.6 97.2 298.9 13.3 109.1 4.6 218.3-25.8 324.7-30.4 106.3-80.8 203.6-149.8 289.2-66.5 82.5-147.4 150.5-240.3 202.1-93 51.6-193.4 84.4-298.595 97.3-109 13.4-218.1 4.8-324.4-25.5-106.6-30.4-318.6 70.6-404.3 1.4-82.5-66.6-79.9-270-98.6-391.9-16.1-104.8-73.4-192.8-86.4-297.6-13.5-108.7-5.1-217.5 24.9-323.5s79.9-203.1 148.2-288.6c66.2-82.7 128.9-253.8 144.1-358.8z"
          stroke="#020202"
          strokeWidth={0.744}
        />
        <path
          opacity={0.629}
          d="M335.954-204.58c12.4-79.4 40.4-236.2 144.1-250.1 109-14.6 378.8 47.6 485.6 77.4 107.096 29.8 288.096 329 374.196 398.9 81.7 66.1 148.9 146.4 199.8 238.5 51 92.2 83.2 191.9 95.7 296.1 13 108 4.2 216.2-26.1 321.4-30.3 105.1-80.3 201.4-148.8 286.1-66 81.5-146.1 148.7-238 199.6-92 51-191.4 83.2-295.496 95.9-107.8 13.1-215.8 4.6-320.9-25.4-105.7-30.2-314.2 67.3-399.2-1.6-81.6-66-79.8-265.7-98.6-385.9-16.1-103.2-72.3-190.2-85.1-293.5-13.4-107.3-5.2-214.7 24.2-319.2 29.4-104.6 78.4-200.4 145.7-284.8 65-81.9 126.8-250.1 142.9-353.4z"
          stroke="#040404"
          strokeWidth={0.738}
        />
        <path
          opacity={0.613}
          d="M339.454-192.18c13-78.5 41.7-232.1 144.1-246.3 108.5-15 373.9 45.1 480.3 74.5 106.996 29.5 285.896 322.3 371.696 392.5 80.8 65.6 147.2 145.2 197.4 236.5 50.3 91.4 82 190.1 94.2 293.3 12.7 106.9 3.8 214-26.4 318.1-30.1 104-79.8 199.2-147.8 282.9-65.4 80.6-144.8 146.8-235.7 197.1-91.1 50.3-189.5 82.1-292.396 94.5-106.6 12.9-213.5 4.3-317.4-25.4-104.9-30.1-309.8 64.1-394.1-4.6-80.6-65.5-79.7-261.4-98.6-380-16.2-101.7-71.3-187.5-83.9-289.4-13.1-105.6-5.1-211.5 23.8-314.6 28.9-103.1 77-197.7 143.1-281.1 64-81.1 124.9-246.2 141.7-348z"
          stroke="#060607"
          strokeWidth={0.732}
        />
        <path
          opacity={0.597}
          d="M343.055-179.68c13.7-77.6 43-228 144.1-242.5 108-15.5 369 42.7 475 71.7 106.795 29.1 283.595 315.7 369.095 386 79.9 65.1 145.4 144.1 195 234.4 49.6 90.5 80.8 188.3 92.8 290.5 12.4 105.8 3.4 211.8-26.6 314.9-30 102.8-79.3 197-146.8 279.8-64.9 79.6-143.4 145-233.4 194.6-90.1 49.6-187.5 80.9-289.295 93.1-105.5 12.6-211.1 4.1-313.9-25.4-104-29.9-305.4 60.8-389-7.6-79.6-64.9-79.6-257.2-98.7-374.1-16.2-100.1-70.2-184.9-82.7-285.3-12.9-104.1-5.1-208.5 23.2-310.2 28.3-101.7 75.6-195 140.5-277.4 63-80.2 123-242.4 140.7-342.5z"
          stroke="#080809"
          strokeWidth={0.726}
        />
        <path
          opacity={0.581}
          d="M346.654-167.28c14.3-76.7 44.3-223.8 144.1-238.7 107.4-16 364.1 40.2 469.7 68.8 106.696 28.8 281.296 309 366.596 379.5 79 64.6 143.7 142.9 192.6 232.4 49 89.7 79.7 186.6 91.3 287.7 12 104.8 3 209.7-26.9 311.6-29.8 101.7-78.9 194.9-145.8 276.7-64.3 78.6-142.1 143.2-231.1 192-89.2 48.9-185.5 79.8-286.095 91.8-104.3 12.4-208.801 3.9-310.401-25.4-103.2-29.7-301 57.5-383.9-10.6-78.7-64.4-79.5-252.9-98.7-368.1-16.3-98.6-69.2-182.3-81.5-281.2-12.8-102.6-5.1-205.5 22.7-305.8 27.8-100.2 74.2-192.3 137.9-273.6 61.9-79.4 121-238.5 139.5-337.1z"
          stroke="#0A0B0B"
          strokeWidth={0.72}
        />
        <path
          opacity={0.566}
          d="M350.254-154.88c15-75.8 45.7-219.7 144.1-234.9 106.9-16.5 359.2 37.8 464.4 66 106.496 28.4 278.996 302.3 364.096 373.1 78.1 64.2 142 141.7 190.2 230.3 48.3 88.9 78.5 184.8 89.8 284.9 11.7 103.7 2.6 207.5-27.2 308.3-29.7 100.5-78.4 192.7-144.8 273.6-63.7 77.6-140.8 141.4-228.8 189.5-88.3 48.3-183.6 78.7-282.996 90.4-103.1 12.1-206.5 3.6-306.9-25.3-102.3-29.6-296.6 54.2-378.7-13.6-77.7-63.8-79.5-248.6-98.7-362.2-16.3-97.1-68.1-179.7-80.2-277.1-12.6-101.1-5.1-202.5 22.1-301.3 27.2-98.8 72.7-189.6 135.3-269.9 60.7-78.7 119.1-234.8 138.3-331.8z"
          stroke="#0D0D0D"
          strokeWidth={0.714}
        />
        <path
          opacity={0.55}
          d="M353.754-142.38c15.7-74.9 47-215.6 144.1-231.1 106.3-17 354.2 35.3 459.1 63.2 106.296 28.1 276.796 295.7 361.596 366.6 77.2 63.7 140.3 140.6 187.8 228.3 47.6 88 77.3 183 88.3 282.1 11.4 102.6 2.2 205.4-27.5 305.1-29.5 99.4-77.9 190.5-143.8 270.4-63.2 76.7-139.4 139.5-226.5 187-87.3 47.6-181.6 77.5-279.896 89-101.9 11.9-204.1 3.4-303.4-25.3-101.5-29.4-292.2 50.9-373.6-16.6-76.8-63.3-79.4-244.3-98.8-356.2-16.4-95.5-67.1-177.1-79-273-12.4-99.6-5.2-199.5 21.5-296.9 26.7-97.3 71.3-186.9 132.7-266.1 60-78 117.5-231.1 137.4-326.5z"
          stroke="#0F0F0F"
          strokeWidth={0.708}
        />
        <path
          opacity={0.534}
          d="M357.354-129.98c16.4-74 48.3-211.5 144.1-227.3 105.8-17.5 349.3 32.9 453.9 60.3 106.196 27.7 274.496 289 359.096 360.2 76.3 63.2 138.6 139.4 185.4 226.2 47 87.2 76.2 181.3 86.9 279.3 11.1 101.5 1.8 203.2-27.8 301.8-29.4 98.2-77.4 188.3-142.9 267.3-62.6 75.7-138.1 137.7-224.2 184.5-86.4 46.9-179.6 76.4-276.796 87.6-100.8 11.6-201.8 3.2-299.9-25.3-100.6-29.2-287.8 47.6-368.5-19.6-75.8-62.7-79.3-240-98.8-350.3-16.4-94-66-174.5-77.8-269-12.2-98.1-5.2-196.5 21-292.5 26.1-95.9 69.9-184.2 130.1-262.4 58.9-76.9 115.5-227 136.2-320.8z"
          stroke="#111"
          strokeWidth={0.702}
        />
        <path
          opacity={0.519}
          d="M360.954-117.48c17-73.1 49.601-207.401 144.101-223.501 105.3-18 344.399 30.4 448.599 57.5 105.996 27.3 272.196 282.3 356.596 353.7 75.4 62.7 136.8 138.2 182.9 224.2 46.3 86.4 75 179.5 85.4 276.4 10.8 100.5 1.4 201.101-28.1 298.601-29.2 97-76.9 186.1-141.9 264.2-62 74.7-136.8 135.9-221.9 182-85.5 46.2-177.7 75.2-273.696 86.2-99.6 11.4-199.499 2.9-296.399-25.2-99.7-29.1-283.401 44.3-363.401-22.6-74.9-62.2-79.2-235.7-98.8-344.3-16.4-92.501-64.9-171.801-76.5-264.901-12.1-96.6-5.2-193.499 20.4-287.999 25.6-94.4 68.6-181.3 127.501-258.7 58.099-76.2 113.699-223.5 135.199-315.6z"
          stroke="#131314"
          strokeWidth={0.696}
        />
        <path
          opacity={0.503}
          d="M364.555-105.08c17.7-72.2 50.9-203.2 144.1-219.7 104.7-18.5 339.499 28 443.299 54.7 105.796 27 269.996 275.7 354.096 347.2 74.5 62.2 135.1 137.1 180.5 222.1 45.6 85.5 73.8 177.8 83.9 273.6 10.5 99.4 1 198.9-28.3 295.3-29.1 95.9-76.4 183.9-140.9 261.1-61.5 73.8-135.5 134.1-219.6 179.4-84.5 45.6-175.7 74.1-270.595 84.8-98.4 11.2-197.101 2.7-292.901-25.2-98.9-28.9-278.999 41.1-358.299-25.6-73.9-61.6-79.1-231.5-98.9-338.4-16.5-90.9-63.9-169.2-75.3-260.8-11.9-95.1-5.2-190.6 19.8-283.6 25-93 67.2-178.6 124.9-254.9 57.2-75.2 111.9-219.4 134.2-310z"
          stroke="#151516"
          strokeWidth={0.691}
        />
        <path
          opacity={0.487}
          d="M368.054-92.68c18.4-71.3 52.2-199.1 144.1-215.9 104.2-19 334.6 25.5 438 51.8 105.696 26.6 267.696 269 351.596 340.8 73.6 61.7 133.4 135.9 178.1 220.1 44.9 84.7 72.6 176 82.4 270.8 10.1 98.3.5 196.8-28.6 292-28.9 94.7-75.9 181.7-139.9 257.9-60.9 72.8-134.1 132.2-217.2 176.9-83.6 44.9-173.7 72.9-267.496 83.5-97.3 10.9-194.8 2.5-289.4-25.2-98-28.7-274.6 37.8-353.2-28.5-73-61.1-79.1-227.2-98.9-332.4-16.5-89.4-62.8-166.6-74.1-256.7-11.7-93.6-5.2-187.6 19.3-279.2 24.5-91.5 65.8-175.9 122.3-251.2 56.1-74.4 110.1-215.7 133-304.7z"
          stroke="#171718"
          strokeWidth={0.684}
        />
        <path
          opacity={0.471}
          d="M371.654-80.18c19.1-70.4 53.5-195.1 144.1-212.1 103.6-19.5 329.6 23.1 432.7 49 105.496 26.3 265.396 262.4 349.096 334.3 72.7 61.2 131.7 134.7 175.7 218 44.3 83.9 71.5 174.2 81 268 9.8 97.2.1 194.599-28.9 288.799-28.8 93.6-75.4 179.501-138.9 254.801-60.4 71.8-132.8 130.4-214.9 174.4-82.7 44.2-171.796 71.8-264.396 82.1-96.1 10.7-192.5 2.2-285.9-25.1-97.2-28.6-270.1 34.3-348-31.5-71.9-60.7-79-222.9-98.9-326.5-16.6-87.8-61.8-164-72.9-252.6-11.5-92.1-5.2-184.601 18.7-274.701 23.9-90.1 64.4-173.2 119.7-247.5 55-73.8 108.1-212 131.8-299.4z"
          stroke="#19191A"
          strokeWidth={0.679}
        />
        <path
          opacity={0.456}
          d="M375.254-67.78c19.8-69.5 54.8-191 144.2-208.3 103.1-20 324.7 20.6 427.4 46.1 105.396 25.9 263.096 255.7 346.596 327.9 71.8 60.7 130 133.6 173.3 216 43.6 83 70.3 172.5 79.5 265.2 9.5 96.2-.3 192.5-29.2 285.5-28.6 92.4-74.9 177.3-137.9 251.7-59.8 70.9-131.5 128.6-212.6 171.9-81.7 43.6-169.796 70.7-261.296 80.7-94.9 10.4-190.1 2-282.4-25.1-96.3-28.4-265.7 31-342.9-34.5-70.9-60.1-78.9-218.6-98.9-320.5-16.6-86.3-60.7-161.4-71.6-248.5-11.4-90.6-5.3-181.6 18.1-270.3 23.4-88.6 62.9-170.5 117.1-243.7 53.9-73 106.2-208.3 130.6-294.1z"
          stroke="#1B1C1C"
          strokeWidth={0.673}
        />
        <path
          opacity={0.44}
          d="M378.854-55.28c20.5-68.6 56.1-186.9 144.2-204.5 102.6-20.5 319.8 18.2 422.2 43.3 105.196 25.6 260.896 249 344.096 321.4 70.8 60.2 128.2 132.4 170.9 213.9 42.9 82.2 69.1 170.7 78 262.4 9.2 95.1-.7 190.3-29.5 282.2-28.5 91.2-74.4 175.1-136.9 248.6-59.2 69.9-130.1 126.8-210.3 169.4-80.8 42.9-167.796 69.5-258.196 79.3-93.7 10.2-187.8 1.7-278.9-25.1-95.4-28.3-261.3 27.7-337.8-37.5-69.9-59.6-78.8-214.3-99-314.6-16.7-84.8-59.7-158.8-70.4-244.4-11.2-89.1-5.3-178.6 17.6-265.9 22.8-87.2 61.5-167.8 114.6-240 52.8-72.1 104.2-204.3 129.4-288.5z"
          stroke="#1D1E1E"
          strokeWidth={0.667}
        />
        <path
          opacity={0.424}
          d="M382.354-42.88c21.2-67.7 57.4-182.8 144.2-200.7 102-21.1 314.9 15.7 416.9 40.5 104.996 25.2 258.596 242.4 341.596 314.9 69.9 59.7 126.5 131.2 168.5 211.9 42.3 81.4 68 169 76.5 259.6 8.9 94-1.1 188.1-29.8 279-28.4 90.1-73.9 172.9-135.9 245.4-58.7 68.9-128.8 124.9-208 166.8-79.9 42.2-165.896 68.4-255.096 77.9-92.6 9.9-185.5 1.5-275.4-25.1-94.6-28.1-256.9 24.4-332.7-40.5-69-59.1-78.9-210-99-308.6-16.9-83.2-58.6-156.1-69.2-240.3-11-87.6-5.3-175.6 17-261.4 22.3-85.7 60.1-165 112-236.3 51.9-71.2 102.6-200.5 128.4-283.1z"
          stroke="#1F2021"
          strokeWidth={0.661}
        />
        <path
          opacity={0.409}
          d="M385.954-30.48c21.9-66.8 58.7-178.7 144.2-196.9 101.5-21.6 310 13.3 411.6 37.6 104.896 24.8 256.296 235.7 339.096 308.5 69 59.2 124.8 130.1 166.1 209.8 41.6 80.5 66.8 167.2 75.1 256.8 8.6 92.9-1.5 186-30 275.7-28.2 88.9-73.5 170.7-134.9 242.3-58.1 67.9-127.5 123.1-205.7 164.3-78.9 41.5-163.896 67.2-251.996 76.6-91.4 9.7-183.1 1.3-271.9-25-93.7-27.9-252.4 21.1-327.6-43.5-68-58.5-78.9-205.7-99-302.7-17-81.7-57.5-153.5-67.9-236.2-10.8-86.1-5.3-172.6 16.4-257 21.5-84.2 58.5-162.3 109.2-232.5 50.9-70.5 100.8-196.8 127.3-277.8z"
          stroke="#222223"
          strokeWidth={0.655}
        />
        <path
          opacity={0.393}
          d="M389.554-17.98c22.6-65.9 60-174.7 144.2-193.1 101-22.1 305.1 10.8 406.3 34.8 104.696 24.5 254.096 229 336.596 302 68.1 58.7 123.1 128.9 163.7 207.8 40.9 79.7 65.6 165.4 73.6 254 8.2 91.9-1.9 183.8-30.3 272.4-28.1 87.8-73 168.5-133.9 239.2-57.5 67-126.2 121.3-203.4 161.8-78 40.9-161.896 66.1-248.896 75.2-90.2 9.4-180.8 1-268.4-25-92.9-27.8-248 17.8-322.5-46.5-67.1-58-78.8-201.4-99.1-296.7-17-80.1-56.5-150.9-66.7-232.1-10.7-84.6-5.3-169.6 15.8-252.6 21.1-82.8 57.3-159.6 106.8-228.8 49.9-69.7 99-193 126.2-272.4z"
          stroke="#242425"
          strokeWidth={0.649}
        />
        <path
          opacity={0.377}
          d="M393.154-5.58c23.3-65.1 61.3-170.6 144.2-189.3 100.4-22.7 300.1 8.4 401 32 104.496 24.1 251.796 222.4 334.096 295.6 67.2 58.2 121.4 127.7 161.2 205.7 40.2 78.9 64.5 163.7 72.1 251.2 7.9 90.8-2.3 181.7-30.6 269.2-27.9 86.6-72.5 166.4-132.9 236.1-57 66-124.8 119.5-201.1 159.3-77.1 40.2-159.996 64.9-245.796 73.8-89.1 9.2-178.4.8-264.9-25-92-27.6-243.6 14.5-317.3-49.5-66.1-57.4-78.7-197.1-99.1-290.8-17.1-78.6-55.4-148.3-65.5-228.1-10.5-83.1-5.3-166.6 15.3-248.1 20.6-81.3 55.9-156.9 104.2-225 48.8-69 97.2-189.3 125.1-267.1z"
          stroke="#262627"
          strokeWidth={0.643}
        />
        <path
          opacity={0.361}
          d="M396.654 6.92c24.1-64.2 62.6-166.5 144.2-185.5 99.9-23.2 295.201 5.9 395.701 29.1 104.395 23.8 249.495 215.7 331.595 289.1 66.3 57.8 119.6 126.6 158.8 203.7 39.6 78 63.3 161.9 70.6 248.4 7.6 89.7-2.7 179.5-30.9 265.9-27.8 85.5-72 164.2-131.9 232.9-56.4 65-123.5 117.6-198.8 156.8-76.1 39.5-157.996 63.8-242.696 72.4-87.9 8.9-176.1.6-261.4-24.9-91.1-27.4-239.2 11.2-312.2-52.5-65.1-56.9-78.7-192.9-99.1-284.8-17.1-77-54.4-145.7-64.3-224-10.3-81.6-5.3-163.6 14.7-243.7 20-79.9 54.4-154.2 101.6-221.3 48-68.1 95.6-185.4 124.1-261.6z"
          stroke="#282829"
          strokeWidth={0.637}
        />
        <path
          opacity={0.346}
          d="M400.254 19.32c24.8-63.3 63.9-162.5 144.2-181.7 99.3-23.8 290.3 3.5 390.5 26.3 104.196 23.4 247.296 209 329.096 282.6 65.4 57.3 117.9 125.4 156.4 201.6 38.9 77.2 62.1 160.1 69.2 245.6 7.3 88.6-3.1 177.4-31.2 262.7-27.6 84.3-71.5 162-130.9 229.8-55.9 64.1-122.2 115.8-196.5 154.2-75.2 38.8-155.996 62.7-239.596 71-86.7 8.7-173.799.3-257.899-24.9-90.3-27.3-234.801 8-307.101-55.5-64.2-56.4-78.6-188.6-99.1-278.9-17.2-75.5-53.3-143.1-63-219.9-10.1-80.1-5.4-160.6 14.1-239.3 19.5-78.4 53-151.5 99-217.6 46.8-67.1 93.6-181.4 122.8-256z"
          stroke="#2A2A2B"
          strokeWidth={0.631}
        />
        <path
          opacity={0.33}
          d="M403.854 31.72c25.6-62.4 65.3-158.5 144.2-177.9 98.8-24.4 285.4 1 385.2 23.4 104.096 23.1 244.996 202.4 326.596 276.2 64.5 56.8 116.2 124.2 154 199.6 38.2 76.3 60.9 158.4 67.7 242.8 7 87.6-3.5 175.2-31.5 259.4-27.5 83.1-71 159.8-129.9 226.7-55.3 63.1-120.9 114-194.2 151.7-74.3 38.2-154.096 61.5-236.496 69.6-85.6 8.4-171.4.1-254.4-24.9-89.4-27.1-230.4 4.7-302-58.5-63.2-55.8-78.5-184.3-99.2-272.9-17.2-74-52.3-140.4-61.8-215.8-9.9-78.6-5.4-157.7 13.6-234.8 18.9-77 51.6-148.8 96.4-213.8 45.8-66.4 91.9-177.7 121.8-250.8z"
          stroke="#2C2D2E"
          strokeWidth={0.625}
        />
        <path
          opacity={0.314}
          d="M407.454 44.22c26.3-61.5 66.6-154.4 144.2-174.1 98.3-24.9 280.5-1.4 379.9 20.6 103.896 22.7 242.696 195.7 324.096 269.7 63.6 56.3 114.5 123.1 151.6 197.5 37.6 75.5 59.8 156.6 66.2 240 6.7 86.5-4 173.1-31.7 256.1-27.3 82-70.5 157.6-128.9 223.6-54.7 62.1-119.5 112.1-191.9 149.2-73.3 37.5-152.096 60.4-233.396 68.3-84.4 8.2-169.1-.1-250.9-24.9-88.6-26.9-226 1.4-296.9-61.5-62.3-55.3-78.4-180-99.2-267-17.3-72.4-51.2-137.8-60.6-211.7-9.8-77.1-5.4-154.7 13-230.4 18.4-75.5 50.2-146.1 93.8-210.1 44.8-65.6 90.1-173.9 120.7-245.3z"
          stroke="#2E2F30"
          strokeWidth={0.619}
        />
        <path
          opacity={0.299}
          d="M410.954 56.62c27.1-60.6 67.9-150.4 144.2-170.3 97.7-25.5 275.5-3.9 374.6 17.8 103.696 22.3 240.396 189.1 321.596 263.3 62.7 55.8 112.8 121.9 149.2 195.5 36.9 74.7 58.6 154.9 64.7 237.2 6.3 85.4-4.4 170.9-32 252.9-27.2 80.8-70 155.4-127.9 220.4-54.2 61.1-118.2 110.3-189.6 146.7-72.4 36.8-150.096 59.2-230.296 66.9-83.2 7.9-166.8-.4-247.4-24.8-87.7-26.8-221.6-1.9-291.8-64.5-61.3-54.7-78.4-175.7-99.2-261-17.3-70.9-50.1-135.2-59.3-207.6-9.6-75.6-5.4-151.7 12.4-226 17.8-74.1 48.7-143.4 91.2-206.4 43.9-64.9 88.4-170.2 119.6-240.1z"
          stroke="#303132"
          strokeWidth={0.613}
        />
        <path
          opacity={0.283}
          d="M414.554 69.12c27.8-59.7 69.2-146.4 144.2-166.5 97.2-26.1 270.6-6.3 369.3 14.9 103.596 22 238.196 182.4 319.096 256.8 61.8 55.3 111 120.7 146.8 193.4 36.2 73.8 57.4 153.1 63.3 234.4 6 84.3-4.8 168.8-32.3 249.6-27 79.7-69.5 153.2-126.9 217.3-53.6 60.2-116.9 108.5-187.3 144.2-71.5 36.1-148.196 58.1-227.196 65.5-82 7.7-164.4-.6-243.9-24.8-86.8-26.6-217.2-5.2-286.6-67.5-60.3-54.2-78.3-171.4-99.3-255.1-17.4-69.4-49.1-132.6-58.1-203.5-9.4-74.1-5.4-148.7 11.9-221.5 17.3-72.6 47.3-140.7 88.6-202.6 42.7-64.1 86.6-166.4 118.4-234.6z"
          stroke="#323334"
          strokeWidth={0.607}
        />
        <path
          opacity={0.267}
          d="M418.154 81.52c28.6-58.8 70.4-142.4 144.2-162.7 96.6-26.7 265.7-8.8 364 12.1 103.396 21.6 235.896 175.7 316.496 250.3 60.9 54.8 109.3 119.6 144.4 191.4 35.5 73 56.3 151.3 61.8 231.6 5.7 83.3-5.2 166.6-32.6 246.3-26.9 78.5-69 151-125.9 214.2-53 59.2-115.5 106.7-185 141.6-70.5 35.5-146.196 56.9-224.096 64.1-80.9 7.4-162.1-.9-240.4-24.8-86-26.5-212.9-8.5-281.5-70.5-59.4-53.6-78.2-167.2-99.3-249.1-17.4-67.8-48-130-56.9-199.4-9.2-72.6-5.4-145.7 11.3-217.1 16.7-71.2 45.9-138 86-198.9 41.9-63.1 85.1-162.4 117.5-229.1z"
          stroke="#343536"
          strokeWidth={0.601}
        />
        <path
          opacity={0.251}
          d="M421.755 94.02c29.4-57.9 71.7-138.3 144.2-158.9 96.1-27.3 260.8-11.2 358.8 9.3 103.195 21.3 233.595 169.1 313.995 243.9 60 54.3 107.6 118.4 142 189.3 34.9 72.2 55.1 149.6 60.3 228.8 5.4 82.2-5.6 164.4-32.9 243.1-26.7 77.3-68.6 148.8-124.9 211.1-52.5 58.2-114.2 104.8-182.6 139.1-69.6 34.8-144.195 55.8-220.995 62.7-79.7 7.2-159.8-1.1-236.9-24.7-85.1-26.3-208.5-11.8-276.4-73.5-58.4-53.1-78.1-162.9-99.3-243.2-17.5-66.3-47-127.4-55.6-195.3-9.1-71.1-5.5-142.7 10.7-212.7 16.2-69.7 44.5-135.3 83.5-195.2 40.5-62.5 83-158.8 116.1-223.8z"
          stroke="#363738"
          strokeWidth={0.595}
        />
        <path
          opacity={0.236}
          d="M425.255 106.42c30.2-57 72.999-134.3 144.199-155.1 95.5-27.9 255.9-13.7 353.5 6.4 103.096 20.9 231.396 162.4 311.496 237.4 59.1 53.8 105.9 117.2 139.6 187.3 34.2 71.3 53.9 147.8 58.8 226 5.1 81.1-6 162.3-33.2 239.8-26.6 76.2-68.1 146.6-123.9 208-51.9 57.3-112.9 103-180.3 136.6-68.6 34.1-142.295 54.7-217.895 61.3-78.5 6.9-157.401-1.3-233.401-24.7-84.3-26.1-204.099-15.1-271.299-76.5-57.5-52.5-78.001-158.6-99.401-237.2-17.5-64.7-45.9-124.7-54.4-191.2-8.9-69.6-5.5-139.7 10.2-208.2 15.6-68.3 43-132.6 80.9-191.4 39.7-61.8 81.501-155 115.101-218.5z"
          stroke="#39393A"
          strokeWidth={0.589}
        />
        <path
          opacity={0.22}
          d="M428.854 118.82c31-56.1 74.3-130.3 144.2-151.3 95-28.5 251-16.1 348.2 3.6 102.896 20.6 229.096 155.7 308.996 231 58.2 53.3 104.2 116.1 137.1 185.2 33.5 70.5 52.7 146 57.3 223.2 4.8 80-6.4 160.1-33.4 236.5-26.4 75-67.6 144.4-122.9 204.8-51.4 56.3-111.6 101.2-178 134.1-67.7 33.4-140.296 53.5-214.796 60-77.4 6.7-155.1-1.6-229.9-24.7-83.4-26-199.7-18.3-266.2-79.5-56.5-52-78-154.3-99.4-231.3-17.6-63.2-44.9-122.1-53.2-187.2-8.7-68.1-5.5-136.7 9.6-203.8 15.1-66.8 41.6-129.9 78.3-187.7 38.8-60.7 79.9-150.9 114.1-212.9z"
          stroke="#3B3B3D"
          strokeWidth={0.583}
        />
        <path
          opacity={0.204}
          d="M432.454 131.32c31.8-55.2 75.6-126.3 144.2-147.5 94.4-29.2 246-18.5 342.9.7 102.796 20.2 226.796 149.1 306.496 224.5 57.3 52.8 102.4 114.9 134.7 183.2 32.9 69.7 51.6 144.3 55.9 220.4 4.5 78.9-6.8 158-33.7 233.3-26.3 73.9-67.1 142.2-121.9 201.7-50.8 55.3-110.2 99.4-175.7 131.6-66.8 32.8-138.296 52.4-211.696 58.6-76.2 6.4-152.8-1.8-226.4-24.6-82.6-25.8-195.3-21.6-261.1-82.5-55.6-51.4-77.9-150-99.4-225.3-17.6-61.7-43.8-119.5-52-183.1-8.5-66.6-5.5-133.7 9-199.4 14.5-65.4 40.2-127.2 75.7-183.9 37.7-60.2 78.2-147.4 113-207.7z"
          stroke="#3D3D3F"
          strokeWidth={0.577}
        />
        <path
          opacity={0.189}
          d="M436.054 143.72c32.6-54.2 76.9-122.4 144.2-143.7 93.8-29.8 241.1-21 337.6-2.1 102.596 19.8 224.496 142.4 303.996 218 56.4 52.3 100.7 113.7 132.3 181.1 32.2 68.8 50.4 142.5 54.4 217.6 4.1 77.9-7.2 155.8-34 230-26.2 72.7-66.6 140.1-120.9 198.6-50.2 54.4-108.9 97.5-173.4 129-65.8 32.1-136.396 51.2-208.496 57.2-75 6.2-150.4-2-222.9-24.6-81.7-25.6-190.9-24.9-255.9-85.4-54.6-50.8-77.8-145.7-99.4-219.4-17.7-60.1-42.7-116.9-50.7-179-8.4-65.1-5.5-130.7 8.5-194.9 13.9-63.9 38.7-124.5 73.1-180.2 36.4-59.3 76.2-143.4 111.6-202.2z"
          stroke="#3F4041"
          strokeWidth={0.571}
        />
        <path
          opacity={0.173}
          d="M439.554 156.22c33.4-53.3 78.2-118.4 144.2-139.9 93.3-30.4 236.2-23.4 332.3-4.9 102.396 19.5 222.296 135.7 301.496 211.6 55.5 51.9 99 112.6 129.9 179.1 31.5 68 49.2 140.8 52.9 214.8 3.8 76.8-7.6 153.7-34.3 226.8-26 71.5-66.1 137.9-119.9 195.5-49.7 53.4-107.6 95.7-171.1 126.5-64.9 31.4-134.396 50.1-205.396 55.8-73.9 5.9-148.1-2.3-219.4-24.6-80.8-25.5-186.5-28.2-250.8-88.4-53.7-50.3-77.7-141.4-99.5-213.4-17.7-58.6-41.7-114.3-49.5-174.9-8.2-63.6-5.5-127.7 7.9-190.5 13.4-62.5 37.3-121.8 70.5-176.5 35.6-58.7 74.8-139.8 110.7-197z"
          stroke="#414243"
          strokeWidth={0.566}
        />
        <path
          opacity={0.157}
          d="M443.154 168.62c34.3-52.4 79.5-114.4 144.2-136.1 92.7-31.1 231.3-25.9 327.1-7.8 102.296 19.1 219.996 129.1 298.996 205.1 54.6 51.4 97.3 111.4 127.5 177.1 30.9 67.2 48.1 139 51.4 212 3.5 75.7-8.1 151.5-34.6 223.5-25.9 70.4-65.6 135.7-118.9 192.3-49.1 52.4-106.3 93.9-168.8 124-64 30.7-132.396 48.9-202.296 54.4-72.7 5.7-145.8-2.5-215.9-24.6-80-25.3-182.1-31.5-245.7-91.4-52.7-49.7-77.6-137.2-99.5-207.5-17.8-57.1-40.6-111.7-48.3-170.8-8-62.1-5.6-124.8 7.3-186.1 12.8-61 35.9-119.1 67.9-172.7 34.6-57.7 73.1-135.7 109.6-191.4z"
          stroke="#434445"
          strokeWidth={0.559}
        />
        <path
          opacity={0.141}
          d="M446.754 181.02c35.1-51.4 80.7-110.4 144.2-132.3 92.1-31.8 226.4-28.3 321.8-10.6 102.096 18.8 217.696 122.4 296.496 198.7 53.7 50.9 95.6 110.2 125.1 175 30.2 66.3 46.9 137.2 50 209.2 3.2 74.6-8.5 149.4-34.9 220.2-25.7 69.2-65.1 133.5-117.9 189.2-48.5 51.4-104.9 92.1-166.5 121.5-63 30.1-130.496 47.8-199.196 53-71.5 5.4-143.4-2.8-212.4-24.5-79.1-25.1-177.7-34.7-240.6-94.4-51.8-49.2-77.5-132.9-99.5-201.5-17.8-55.5-39.6-109-47-166.7-7.8-60.6-5.6-121.8 6.8-181.6 12.3-59.6 34.4-116.4 65.3-169 33.3-57.1 71.2-132 108.3-186.2z"
          stroke="#454647"
          strokeWidth={0.554}
        />
        <path
          opacity={0.126}
          d="M450.354 193.52c36-50.4 82-106.5 144.2-128.5 91.5-32.5 221.4-30.8 316.5-13.4 101.896 18.4 215.496 115.8 293.996 192.2 52.8 50.4 93.8 109.1 122.7 173 29.5 65.5 45.7 135.5 48.5 206.4 2.9 73.6-8.9 147.2-35.1 217-25.6 68.1-64.6 131.3-116.9 186.1-48 50.5-103.6 90.2-164.2 119-62.096 29.4-128.496 46.7-196.096 51.7-70.3 5.2-141.1-3-208.9-24.5-78.3-25-173.3-38-235.5-97.4-50.8-48.6-77.4-128.6-99.6-195.6-17.8-54-38.5-106.4-45.8-162.6-7.7-59.1-5.6-118.8 6.2-177.2 11.7-58.1 33-113.7 62.7-165.3 32.4-56.4 69.7-128.2 107.3-180.9z"
          stroke="#47484A"
          strokeWidth={0.548}
        />
        <path
          opacity={0.11}
          d="M453.854 205.92c36.8-49.5 83.3-102.5 144.2-124.7 90.9-33.1 216.5-33.2 311.2-16.3 101.796 18.1 213.196 109.1 291.496 185.8 51.9 49.9 92.1 107.9 120.3 170.9 28.8 64.7 44.6 133.7 47 203.6 2.6 72.5-9.3 145.1-35.4 213.7-25.4 66.9-64.1 129.1-115.9 183-47.4 49.5-102.3 88.4-161.9 116.4-61.196 28.7-126.496 45.5-192.996 50.3-69.2 5-138.8-3.2-205.4-24.5-77.4-24.8-169-41.3-230.4-100.4-49.9-48.1-77.3-124.3-99.6-189.7-17.9-52.5-37.5-103.8-44.6-158.5-7.5-57.6-5.6-115.8 5.6-172.8 11.2-56.7 31.5-111 60.1-161.5 31.5-55.4 68.2-124.1 106.3-175.3z"
          stroke="#494A4C"
          strokeWidth={0.542}
        />
        <path
          opacity={0.094}
          d="M457.454 218.42c37.7-48.5 84.6-98.6 144.2-120.9 90.4-33.8 211.6-35.7 305.9-19.1 101.596 17.7 210.896 102.399 288.996 179.299 51 49.4 90.4 106.701 117.9 168.901 28.2 63.8 43.4 131.9 45.5 200.8 2.2 71.4-9.7 142.899-35.7 210.399-25.3 65.8-63.7 126.901-114.9 179.801-46.9 48.5-100.9 86.6-159.6 113.9a537.876 537.876 0 01-189.896 48.9c-68 4.7-136.4-3.5-201.9-24.4-76.5-24.6-164.6-44.5-225.2-103.4-49-47.5-77.2-120-99.6-183.7-17.9-50.9-36.4-101.2-43.4-154.4-7.3-56.1-5.6-112.8 5.1-168.3 10.6-55.2 30.1-108.3 57.5-157.8 30.3-54.8 66.5-120.4 105.1-170z"
          stroke="#4B4C4E"
          strokeWidth={0.536}
        />
        <path
          opacity={0.079}
          d="M461.054 230.82c38.6-47.5 85.8-94.6 144.3-117.1 89.8-34.6 206.7-38.1 300.6-22 101.496 17.3 208.596 95.8 286.496 172.8 50.1 48.9 88.7 105.6 115.4 166.8 27.5 63 42.2 130.2 44.1 198 1.9 70.3-10.1 140.7-36 207.2-25.1 64.6-63.2 124.7-113.9 176.7-46.3 47.6-99.6 84.8-157.3 111.4-59.296 27.4-122.596 43.2-186.796 47.5-66.8 4.5-134.1-3.7-198.4-24.4-75.7-24.5-160.3-47.7-220.1-106.4-48.1-46.8-77.1-115.8-99.6-177.8-17.9-49.4-35.3-98.6-42.1-150.3-7.1-54.6-5.6-109.8 4.5-163.9 10.1-53.8 28.6-105.6 55-154.1 29.1-53.7 64.6-116.2 103.8-164.4z"
          stroke="#4E4E50"
          strokeWidth={0.53}
        />
        <path
          opacity={0.063}
          d="M464.555 243.22c39.5-46.5 87.1-90.7 144.3-113.3 89.1-35.3 201.8-40.6 295.4-24.8 101.295 17 206.395 89.1 283.995 166.4 49.2 48.4 87 104.4 113 164.8 26.8 62.2 41 128.4 42.6 195.2 1.6 69.3-10.5 138.6-36.3 203.9-25 63.4-62.7 122.5-112.9 173.6-45.7 46.6-98.3 82.9-155 108.9-58.395 26.7-120.595 42.1-183.695 46.1-65.7 4.2-131.8-3.9-194.9-24.4-74.8-24.3-155.9-51-215-109.4-47.1-46.3-76.9-111.5-99.7-171.8-17.9-47.9-34.3-96-40.9-146.3-7-53.1-5.6-106.8 3.9-159.5 9.5-52.3 27.2-102.9 52.4-150.3 28.2-53 63.2-112.4 102.8-159.1z"
          stroke="#505152"
          strokeWidth={0.524}
        />
        <path
          opacity={0.047}
          d="M468.154 255.72c40.4-45.5 88.3-86.8 144.3-109.5 88.5-36 196.901-43 290.101-27.6 101.095 16.6 204.095 82.4 281.495 159.9 48.3 47.9 85.2 103.2 110.6 162.7 26.2 61.3 39.9 126.7 41.1 192.4 1.3 68.2-10.9 136.4-36.6 200.6-24.8 62.3-62.2 120.3-111.9 170.5-45.2 45.6-97 81.1-152.7 106.4-57.395 26-118.596 40.9-180.596 44.8-64.5 4-129.399-4.2-191.399-24.3-74-24.2-151.501-54.3-209.901-112.4-46.2-45.8-76.8-107.3-99.7-165.9-17.9-46.4-33.2-93.3-39.7-142.2-6.8-51.6-5.7-103.8 3.4-155 9-50.9 25.7-100.2 49.8-146.6 27.1-52.4 61.6-108.6 101.7-153.8z"
          stroke="#525354"
          strokeWidth={0.518}
        />
        <path
          opacity={0.031}
          d="M471.754 268.12c41.3-44.4 89.6-82.9 144.3-105.7 87.9-36.8 191.9-45.5 284.8-30.5 100.996 16.3 201.796 75.8 278.996 153.5 47.4 47.4 83.5 102.1 108.2 160.7 25.5 60.5 38.7 124.9 39.6 189.6 1 67.1-11.3 134.3-36.8 197.4-24.7 61.1-61.7 118.1-110.9 167.3-44.6 44.6-95.6 79.3-150.4 103.8-56.496 25.3-116.696 39.8-177.496 43.4-63.3 3.7-127.1-4.4-187.9-24.3-73.1-24-147.1-57.6-204.8-115.4-45.2-45.2-76.7-103-99.7-159.9-18-44.8-32.2-90.7-38.4-138.1-6.6-50.1-5.7-100.8 2.8-150.6 8.4-49.4 24.2-97.6 47.2-142.9 25.9-51.5 59.8-104.5 100.5-148.3z"
          stroke="#545557"
          strokeWidth={0.512}
        />
        <path
          opacity={0.016}
          d="M475.355 280.62c42.2-43.4 90.8-78.9 144.3-102 87.3-37.6 187-47.9 279.5-33.3 100.8 15.9 199.595 69.1 276.495 147 46.5 46.9 81.8 100.9 105.8 158.6 24.8 59.6 37.5 123.1 38.2 186.8.7 66-11.7 132.1-37.1 194.1-24.5 60-61.2 115.9-109.9 164.2-44 43.7-94.3 77.5-148 101.3-55.595 24.7-114.695 38.7-174.395 42-62.1 3.5-124.8-4.6-184.4-24.3-72.2-23.8-142.7-60.9-199.7-118.4-44.3-44.7-76.7-98.7-99.8-154-18-43.3-31.1-88.1-37.2-134-6.4-48.6-5.7-97.8 2.2-146.2 7.9-48 22.7-94.9 44.6-139.1 24.8-50.6 58.2-100.4 99.4-142.7z"
          stroke="#565759"
          strokeWidth={0.506}
        />
      </g>
    </svg>
  );
}

export default SvgBackground;
