import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Button, ErrorMessage, LoadingSpinner } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import PasswordMask from 'react-password-mask';
import { clearQueryParams } from 'utils';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { FormContainer, FormInputLabel } from '../FormComponents';

const EnterNewPassword = ({ userEmail, actionCode, setShowEnterNewPasswordModal, colors }) => {
  const [formValues, setFormValues] = useState({
    newPassword: '',
    retypedNewPassword: ''
  });
  const [settingNewPassword, setSettingNewPassword] = useState(false);
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState(false);
  const [loggingUserIn, setLoggingUserIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);

  const handleInputChange = (e) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    const { name, value } = e.target;
    setFormValues((currentValues) => ({
      ...currentValues,
      [name]: value
    }));
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const { newPassword, retypedNewPassword } = formValues;

    if (newPassword !== retypedNewPassword) {
      setErrorMessage('The password fields do not match.<br />Please double check and try again.');
    } else {
      setSettingNewPassword(true);
      firebase
        .confirmPasswordReset(actionCode, newPassword)
        .then(() => setNewPasswordConfirmed(true))
        .catch((error) => {
          console.error(error);
          setErrorMessage(
            'Error occurred during confirmation.<br />The code might have expired or the password is too weak.'
          );
        })
        .finally(() => setSettingNewPassword(false));
    }
  };
  return (
    <StyledFormContainer
      style={{ alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}
      onSubmit={handleSaveChanges}
      theme={theme}
      colors={colors}>
      <ModalTitle
        userEmail={userEmail}
        dangerouslySetInnerHTML={{
          __html: !userEmail
            ? 'Loading'
            : userEmail && !newPasswordConfirmed
            ? `Please set a new password for the account registered to ${userEmail}<br /><br />`
            : 'Your password has been updated. Click below to be automatically logged in.<br /><br />'
        }}
      />
      {userEmail && !newPasswordConfirmed ? (
        <>
          <FormInputLabel hide htmlFor="newPassword">
            Enter New Password
          </FormInputLabel>
          <PasswordMask
            id="newPassword"
            minLength={6}
            name="newPassword"
            onChange={handleInputChange}
            placeholder="Enter New Password"
            required
            type="password"
            value={formValues.newPassword}
            inputClassName="password-mask-input"
            buttonClassName={
              formValues.newPassword ? 'password-mask-button' : 'password-mask-button-hide'
            }
            inputStyles={{
              marginBottom: '1.5rem'
            }}
            buttonStyles={{
              background: '#0095FF'
            }}
          />
          <FormInputLabel hide htmlFor="retypedNewPassword">
            Retype Your New Password
          </FormInputLabel>
          <PasswordMask
            id="retypedNewPassword"
            minLength={6}
            name="retypedNewPassword"
            onChange={handleInputChange}
            placeholder="Retype Your New Password"
            required
            type="password"
            value={formValues.retypedNewPassword}
            inputClassName="password-mask-input"
            buttonClassName={
              formValues.retypedNewPassword ? 'password-mask-button' : 'password-mask-button-hide'
            }
            inputStyles={{
              marginBottom: '1.063rem'
            }}
            buttonStyles={{
              background: '#0095FF'
            }}
          />
          <ErrorMessage
            errorMessage={errorMessage}
            style={{
              color: '#0095FF'
            }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <Button
            style={{ alignSelf: 'center', marginTop: '1.063rem' }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            width="15rem">
            {settingNewPassword ? (
              <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
            ) : (
              'Save New Password'
            )}
          </Button>
        </>
      ) : newPasswordConfirmed ? (
        <Button
          whileTap={{ scale: 0.9 }}
          type="button"
          width="9.375rem"
          style={{ margin: '0 auto' }}
          onClick={() => {
            setLoggingUserIn(true);
            firebase
              .login(userEmail, formValues.newPassword)
              .then(() => {
                clearQueryParams();
                setShowEnterNewPasswordModal(false);
              })
              .catch((error) => {
                console.error(error);
                setErrorMessage(error.message);
              })
              .finally(() => setLoggingUserIn(false));
          }}>
          {loggingUserIn ? <LoadingSpinner style={{ color: '#fff', width: '2rem' }} /> : 'Continue'}
        </Button>
      ) : null}
    </StyledFormContainer>
  );
};

const ModalTitle = styled.h3`
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.813rem;
  /* text-transform: capitalize; */

  ${({ userEmail }) =>
    !userEmail &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: -0.125rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 #fff;
        }
      }
    `}
`;

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};

  button {
    background: ${({ colors }) => colors.secondary};
  }

  max-width: 28.125rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  ${({ style }) => style};

  @media screen and (min-width: 1150px) {
    padding: 2.125rem 1.25rem;
  }
`;

export default EnterNewPassword;
