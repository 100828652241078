import React, { useContext, useState } from 'react';
import { Button } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { clearQueryParams } from 'utils';
import styled, { css } from 'styled-components';
import { FormContainer } from '../FormComponents';

const RecoverEmail = ({ colors, emailRecovered, setShowRecoverEmailModal, userEmail }) => {
  const { theme } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
  return (
    <FormContainer theme={theme}>
      <Status
        emailRecovered={emailRecovered}
        dangerouslySetInnerHTML={{
          __html: !emailRecovered
            ? 'Loading'
            : emailRecovered && !passwordResetEmailSent
            ? `Account successfully reverted to:<br />${userEmail}<br /><br />As an extra security measure, would you like to also reset your password?<br /><br />`
            : emailRecovered && passwordResetEmailSent
            ? 'We&apos;ve sent you a<br>password reset email.<br><br>Please check your inbox.<br><br>'
            : null
        }}
      />
      {emailRecovered && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '19.688rem',
            margin: '0.7rem auto 0'
          }}>
          {passwordResetEmailSent ? (
            <Button
              onClick={() => {
                clearQueryParams();
                setShowRecoverEmailModal(false);
                firebase.logout(user);
              }}
              type="button"
              width="9.25rem"
              style={{
                margin: '0 auto'
              }}>
              Close
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  firebase
                    .sendPasswordResetEmail({
                      email: userEmail,
                      actionCodeSettings: {
                        handleCodeInApp: true,
                        url: window.location.href
                      },
                      colors
                    })
                    .then(() => {
                      setPasswordResetEmailSent(true);
                    });
                }}
                type="button"
                width="9.25rem">
                Yes
              </Button>
              <Button
                onClick={() => {
                  clearQueryParams();
                  setShowRecoverEmailModal(false);
                  firebase.logout(user);
                }}
                type="button"
                width="9.25rem">
                No
              </Button>
            </>
          )}
        </div>
      )}
    </FormContainer>
  );
};

const Status = styled.h3`
  align-items: center;
  display: flex;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;

  ${({ emailRecovered }) =>
    !emailRecovered &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.25rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 #fff;
        }
      }
    `}
`;

export default RecoverEmail;
