import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';

const ErrorMessage = ({ errorMessage, style }) => {
   
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}>
      <AnimatePresence>
        {errorMessage && (
          <motion.div
            style={{
              textAlign: 'center',
              // textTransform: 'capitalize',
              color: '#fee500',
              // position: 'absolute',
              // top: '-1.1rem',
              ...style
            }}
            initial={{
              opacity: 0,
              height: 0
            }}
            animate={{
              opacity: 1,
              height: 'auto',
              transition: {
                ease: 'easeInOut',
                duration: 0.2,
                opacity: {
                  delay: 0.1
                }
              }
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                ease: 'easeInOut',
                duration: 0.2,
                height: {
                  delay: 0.1
                }
              }
            }}
          >
            <Markup content={errorMessage} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ErrorMessage;
