import * as React from 'react';

function SvgBannerSvg3(props) {
  return (
    <svg viewBox="0 0 161 158" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M133.265 99.443l-.036-40.335c-.845-2.11-1.76-4.289-2.886-6.257l.046 52.844a71.11 71.11 0 002.876-6.252zM125.968 112.648l-.06-66.758c-.914-1.195-1.829-2.39-2.884-3.586l.066 73.785c1.053-1.053 1.965-2.247 2.878-3.441zM118.667 120.652l-.073-82.92c-.985-.844-1.899-1.618-2.884-2.462l.078 87.699c1.054-.632 2.037-1.474 2.879-2.317zM111.505 126.268l-.084-94.024c-.914-.633-1.898-1.196-2.883-1.759l.087 97.537c.983-.561 1.896-1.193 2.88-1.754zM104.2 130.196l-.091-102.034c-.984-.422-1.898-.774-2.882-1.197l.093 104.424a14.65 14.65 0 002.88-1.193zM96.964 132.931L96.8 25.485c-.985-.281-1.898-.493-2.882-.775l.097 109.061 2.95-.84zM89.658 134.611l-.099-110.748c-.984-.142-1.968-.283-2.881-.354l.099 111.591c.983-.28 1.897-.349 2.88-.489zM80.945 135.306l1.405.001-.1-112.013h-1.405l-1.405-.002.1 112.013c.49-.07.912-.07 1.404.001zM75.112 134.949l-.029-111.52c-.984.139-1.897.208-2.88.348l.097 110.748c.914.212 1.898.353 2.812.424zM67.873 133.748l-.097-109.061c-.984.28-1.897.49-2.88.77l.095 107.516c.913.212 1.898.493 2.882.775zM60.633 131.423L60.54 26.999c-.983.42-1.897.771-2.88 1.192l.09 102.035c.914.352 1.828.844 2.883 1.197zM53.321 127.903l-.086-97.537c-.984.561-1.967 1.123-2.88 1.754l.084 94.024c.914.633 1.898 1.196 2.882 1.759zM46.08 123.048L46 35.348c-.983.843-1.966 1.545-2.879 2.458l.074 82.92c.914.774 1.828 1.548 2.883 2.322zM38.765 116.155l-.066-73.785c-.983 1.123-1.965 2.387-2.878 3.58l.06 66.759c.914 1.055 1.899 2.18 2.884 3.446zM31.447 105.607L31.4 52.763a71.483 71.483 0 00-2.875 6.252l.035 40.335c.916 2.18 1.831 4.218 2.887 6.257z"
        fill="#36D1B7"
      />
    </svg>
  );
}

export default SvgBannerSvg3;
