import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { QuizmasFunIcon1, QuizmasFunIcon2, QuizmasFunText } from 'assets/svgs';

const QuizmasFun = () => {
  const { bgImg } = useStaticQuery(graphql`
    {
      bgImg: file(relativePath: { eq: "assets/images/event-9/quizmas-fun-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1164)
        }
      }
    }
  `);

  return (
    <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <InnerContainer>
        <BackgroundImage image={bgImg.childImageSharp.gatsbyImageData} alt="Quizmas Fun" />
        <p>Click here for</p>
        <StyledQuizmasFunIcon1 />
        <StyledQuizmasFunText />
        <StyledQuizmasFunIcon2 />
        <PlayButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.975 }}
          href="https://us02web.zoom.us/j/84356588039"
          target="_blank">
          Play
        </PlayButton>
      </InnerContainer>
    </Container>
  );
};

const Container = styled(motion.section)`
  margin-top: 5.125rem;
  width: 100%;
`;

const InnerContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 0.2fr 0.6fr 0.2fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    '..... text .....'
    'icon1 quizmasFun icon2'
    '..... button .....';
  justify-items: center;
  margin: 0 auto;
  max-width: 72.75rem;
  position: relative;

  p {
    align-self: end;
    color: #fff;
    font-size: 1.5rem;
    grid-area: text;
    position: relative;
    top: 0.563rem;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  grid-area: 1 / 1 / 4 / 4;
  z-index: -1;
`;

const PlayButton = styled(motion.a)`
  align-items: center;
  align-self: start;
  background-color: #0095ff;
  border-radius: 3.125rem;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  grid-area: button;
  height: 2.5rem;
  justify-content: center;
  position: relative;
  bottom: 0.688rem;
  width: 7.5rem;
`;

const StyledQuizmasFunIcon1 = styled(QuizmasFunIcon1)`
  grid-area: icon1;
  left: 0.625rem;
  max-width: 8.188rem;
  position: relative;
  width: 100%;
`;

const StyledQuizmasFunIcon2 = styled(QuizmasFunIcon2)`
  grid-area: icon2;
  max-width: 8.438rem;
  position: relative;
  right: 0.625rem;
  width: 100%;
`;

const StyledQuizmasFunText = styled(QuizmasFunText)`
  grid-area: quizmasFun;
  max-width: 40.875rem;
  width: 100%;
`;

export default QuizmasFun;
