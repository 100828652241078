import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from 'context';
import styled from 'styled-components';
import { hexToRGB } from 'utils';

function LivestreamAndInteractions({ eid, colors }) {
  const [eventPresentParticipants, setEventPresentParticipants] = useState(0);
  const [eventComments, setEventComments] = useState(0);
  const [eventQuestions, setEventQuestions] = useState(0);
  const [eventAnsweredQuestions, setEventAnsweredQuestions] = useState(0);
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    let unsubscribeFromEventComments;
    let unsubscribeFromEventQuestions;
    let unsubscribeFromEventParticipantsOnline;
    if (firebase) {
      unsubscribeFromEventParticipantsOnline =
        firebase.subscribeToEventParticipantsOnline({
          eid,
          onSnapshot: (snapshot) => {
            if (!snapshot.empty) {
              setEventPresentParticipants(snapshot.size);
            } else {
              setEventPresentParticipants(0);
            }
          }
        });
      unsubscribeFromEventComments = firebase.subscribeToEventComments({
        eid,
        onSnapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventComments(snapshot.size);
          } else {
            setEventComments(0);
          }
        }
      });
      unsubscribeFromEventQuestions = firebase.subscribeModeratorToAllSubmittedQuestions({
        eid,
        onSnapshot: (snapshot) => {
          if (!snapshot.empty) {
            let answeredQuestions = 0;
            snapshot.forEach((doc) => {
              const question = doc.data();
              if (Object.hasOwn(question, 'answer')) {
                answeredQuestions += 1;
              }
            });
            setEventQuestions(snapshot.size);
            setEventAnsweredQuestions(answeredQuestions);
          } else {
            setEventQuestions(0);
            setEventAnsweredQuestions(0);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventParticipantsOnline) {
        unsubscribeFromEventParticipantsOnline();
      }
      if (unsubscribeFromEventComments) {
        unsubscribeFromEventComments();
      }
      if (unsubscribeFromEventQuestions) {
        unsubscribeFromEventQuestions();
      }
    };
  }, [firebase]);

  return (
    <Container>
      <Title>Livestream & Interactions</Title>
      <Bar colors={colors}>
        <Analytic>
          <span>{eventPresentParticipants}</span>
          <p>Number of currently active participants</p>
        </Analytic>
      </Bar>
      <Bar colors={colors}>
        <Analytic>
          <span>{eventComments}</span>
          <p>Total number of comments</p>
        </Analytic>
        <Analytic>
          <span>{eventQuestions}</span>
          <p>Total number of questions</p>
        </Analytic>
        <Analytic>
          <span>{eventAnsweredQuestions}</span>
          <p>Total number of answered questions</p>
        </Analytic>
      </Bar>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h5`
  color: #004D9A;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 0.875rem;
  margin: 5.5em auto 1.667em;
  text-transform: uppercase;
`;

const Bar = styled.div`
  background: ${({ colors }) => hexToRGB({ color: '#007fc0', alpha: 0.1 })};
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 1rem;
  padding: 1.5rem 1.25rem;
  row-gap: 1.75rem;
  color: #004d9a;
  span {
    color: #004d9a;
  }
  @media only screen and (min-width: 800px) {
    &:nth-of-type(2) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

const Analytic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  span {
    font-size: 3rem !important;
    font-weight: 700;
    line-height: 3rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 200px;
    text-align: center;
  }
`;

export default LivestreamAndInteractions;