import * as React from 'react';

function SvgBannerSvg7(props) {
  return (
    <svg viewBox="0 0 239 239" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M154.411 203.458c46.485-19.397 68.444-72.805 49.047-119.29-19.397-46.485-72.805-68.444-119.29-49.047-46.485 19.397-68.444 72.805-49.047 119.29 19.397 46.485 72.805 68.444 119.29 49.047z"
        fill="#EAF5F9"
      />
    </svg>
  );
}

export default SvgBannerSvg7;
