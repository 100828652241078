import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'components';
import { PinComment } from 'components/Events/Livestream/Interaction/Chat';
import { LocalContext, FirebaseContext } from 'context';
import { Markup } from 'interweave';
import { hexToRGB } from 'utils';
import { format } from 'date-fns';
import { Star } from 'assets/animated-svgs';

const QAndA = ({ eid, questionCurrentlyBeingAnsweredLive }) => {
  const { selectedEvent, theme } = useContext(LocalContext);
  const { colors } = selectedEvent;
  const { firebase, user } = useContext(FirebaseContext);
  const [textArea, setTextArea] = useState('');
  const [submitAnonymousQuestion, setSubmitAnonymousQuestion] = useState(false);
  const [submittedQuestions, setSubmittedQuestions] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const isModerator = user?.isModerator?.includes(eid);

  useEffect(() => {
    let unsubscribeModeratorFromAllSubmittedQuestions;
    let unsubscribeUserFromTheirSubmittedQuestions;

    if (firebase && user) {
      if (isModerator) {
        unsubscribeModeratorFromAllSubmittedQuestions =
          firebase.subscribeModeratorToAllSubmittedQuestions({
            eid,
            onSnapshot: (snapshot) => {
              if (!snapshot.empty) {
                const _submittedQuestions = [];

                snapshot.forEach((doc) => {
                  _submittedQuestions.push({
                    qid: doc.id,
                    ...doc.data()
                  });
                });

                setSubmittedQuestions(_submittedQuestions);
              }
            }
          });
      } else {
        unsubscribeUserFromTheirSubmittedQuestions =
          firebase.subscribeUserToTheirSubmittedQuestions({
            eid,
            uid: firebase.auth.currentUser.uid,
            onSnapshot: (snapshot) => {
              if (!snapshot.empty) {
                const _submittedQuestions = [];

                snapshot.forEach((doc) => {
                  _submittedQuestions.push({
                    qid: doc.id,
                    ...doc.data()
                  });
                });

                setSubmittedQuestions(_submittedQuestions);
              }
            }
          });
      }
      return () => {
        if (unsubscribeModeratorFromAllSubmittedQuestions) {
          unsubscribeModeratorFromAllSubmittedQuestions();
        }
        if (unsubscribeUserFromTheirSubmittedQuestions) {
          unsubscribeUserFromTheirSubmittedQuestions();
        }
        setTextArea('');
        setSubmitAnonymousQuestion(false);
        setSubmittedQuestions(null);
        setSelectedQuestion(null);
      };
    }
  }, [firebase, user, isModerator, eid]);

  const handleStarQuestion = ({ uid, qid, isStarred }) => {
    if (isStarred) {
      firebase.unstarQuestion({ uid, qid });
    } else {
      firebase.starQuestion({ uid, qid });
    }
  };

  const handleTextAreaChange = (e) => {
    e.persist();
    if (e.target.value !== ' ') {
      setTextArea(e.target.value);
    }
  };

  const handleSubmitNewQuestion = (e) => {
    e.preventDefault();
    // TODO: Shoudln't this be && ?
    if (textArea !== '' || textArea !== ' ') {
      firebase
        .submitNewQuestion({
          uid: user.uid,
          eid,
          text: textArea,
          name: submitAnonymousQuestion ? '' : user.fullName
        })
        .then(() => {
          setTextArea('');
          setSubmitAnonymousQuestion(false);
        });
    }
  };

  const handleAnswerThisQuestionLive = () => {
    firebase
      .answerThisQuestionLive({
        eid,
        text: selectedQuestion.text
      })
      .then(() => {
        // setTextArea('');
        // setSelectedQuestion(null);
      });
  };

  const handleSendAnswer = () => {
    // TODO: Shoudln't this be && ?
    if (textArea !== '' || textArea !== ' ') {
      firebase
        .submitAnswer({
          text: textArea,
          eid,
          qid: selectedQuestion.qid,
          uid: selectedQuestion.uid
        })
        .then(() => {
          setTextArea('');
          setSelectedQuestion(null);
        });
    }
  };

  return (
    <Wrapper colors={colors} isModerator={isModerator}>
      {isModerator ? (
        <p>Moderator&apos;s Control Panel</p>
      ) : (
        <p>
          Here you can privately submit questions,
          <br />
          which will be discussed during the livestream.
        </p>
      )}
      <div>
        <SubmittedQuestions submittedQuestions={submittedQuestions} colors={colors}>
          <AnimatePresence initial={false}>
            {submittedQuestions?.map((submittedQuestion, i, arr) => (
              <SubmittedQuestion
                layout
                colors={colors}
                isStarred={submittedQuestion.starred.status}
                isModerator={isModerator}
                isSelected={selectedQuestion?.qid === submittedQuestion.qid}
                onClick={() => {
                  if (isModerator) {
                    if (selectedQuestion?.qid === submittedQuestion.qid) {
                      setSelectedQuestion(null);
                    } else {
                      setSelectedQuestion(submittedQuestion);
                    }
                  }
                }}
                key={submittedQuestion.qid}
                initial={{
                  opacity: 0,
                  height: 0
                }}
                animate={{
                  opacity: 1,
                  height: 'auto',
                  transition: {
                    type: 'tween',
                    ease: 'easeInOut',
                    height: {
                      duration: 0.4
                    },
                    opacity: {
                      duration: 0.65,
                      delay: 0.1
                    }
                  }
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  transition: {
                    type: 'tween',
                    ease: 'easeInOut',
                    height: {
                      duration: 0.4,
                      delay: 0.55
                    },
                    opacity: {
                      duration: 0.65
                    }
                  }
                }}>
                {isModerator && (
                  <SelectQuestionCheckbox
                    checked={selectedQuestion?.qid === submittedQuestion.qid}
                    id="selectQuestionCheckbox"
                    name="selectQuestionCheckbox"
                    colors={colors}
                    type="checkbox"
                    readOnly
                  />
                )}
                <p>
                  <span>
                    {isModerator && submittedQuestion.name
                      ? <Markup content={`${submittedQuestion.name}:`} noWrap />
                      : isModerator && !submittedQuestion.name
                      ? 'Anonymous User'
                      : `My Question${arr.length > 1 ? ` (${arr.length - i}):` : ':'}`}
                  </span>
                  {submittedQuestion.timestamp && (
                    <span>{format(submittedQuestion.timestamp.toDate(), 'HH:mmaaa')}</span>
                  )}
                </p>
                <p><Markup content={submittedQuestion.text} noWrap /></p>
                {isModerator && (
                  <StarQuestion
                    isStarred={submittedQuestion.starred.status}
                    whileTap={{ scale: 0.9 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStarQuestion({
                        uid: submittedQuestion.uid,
                        qid: submittedQuestion.qid,
                        isStarred: submittedQuestion.starred.status
                      });
                    }}>
                    <Star
                      stroke={submittedQuestion.starred.status ? colors.secondary : '#000'}
                      fill={submittedQuestion.starred.status ? colors.secondary : 'transparent'}
                    />
                    {submittedQuestion.starred.status ? 'Unprioritise' : 'Prioritise'}
                  </StarQuestion>
                )}
                <AnimatePresence>
                  {submittedQuestion.answer && (
                    <ModeratorsAnswer
                      colors={colors}
                      isModerator={isModerator}
                      isSelected={selectedQuestion?.qid === submittedQuestion.qid}
                      initial={{
                        opacity: 0,
                        height: 0
                      }}
                      animate={{
                        opacity: 1,
                        height: 'auto',
                        transition: {
                          type: 'tween',
                          ease: 'easeInOut',
                          height: {
                            duration: 0.4
                          },
                          opacity: {
                            duration: 0.65,
                            delay: 0.1
                          }
                        }
                      }}
                      exit={{
                        opacity: 0,
                        height: 0,
                        transition: {
                          type: 'tween',
                          ease: 'easeInOut',
                          height: {
                            duration: 0.4,
                            delay: 0.55
                          },
                          opacity: {
                            duration: 0.65
                          }
                        }
                      }}>
                      <p>
                        <span>Moderator&apos;s Answer:</span>
                        {submittedQuestion.answer.timestamp && (
                          <span>
                            {format(submittedQuestion.answer.timestamp.toDate(), 'HH:mmaaa')}
                          </span>
                        )}
                      </p>
                      <p><Markup content={submittedQuestion.answer.text} noWrap /></p>
                    </ModeratorsAnswer>
                  )}
                </AnimatePresence>
              </SubmittedQuestion>
            ))}
          </AnimatePresence>
        </SubmittedQuestions>
        {isModerator ? (
          <AnswerQuestion colors={colors}>
            <TextArea
              $theme={theme}
              id="answer"
              name="answer"
              colors={colors}
              value={textArea}
              disabled={!selectedQuestion}
              onChange={handleTextAreaChange}
              placeholder="Select a question then type your answer here to send an answer privately to the user, or click 'Answer Live' to answer it publically during the livestream."
            />
            <div>
              <Button
                type="button"
                disabled={!selectedQuestion}
                style={{
                  backgroundColor:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '#ff7aa1'
                      : '#fff',
                  border:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '1px solid #ff7aa1'
                      : `1px solid ${colors.secondary}`,
                  color:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '#fff'
                      : colors.secondary,
                  marginRight: '10px'
                }}
                onClick={() => {
                  if (questionCurrentlyBeingAnsweredLive === selectedQuestion?.text) {
                    firebase.stopShowingAnswerLiveOverlay({ eid });
                    setSelectedQuestion(null);
                  } else {
                    handleAnswerThisQuestionLive();
                  }
                }}
                whileTap={{ scale: textArea && selectedQuestion ? 0.9 : 1 }}
                whileHover={{
                  border:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '1px solid #ff7aa1'
                      : selectedQuestion
                      ? `1px solid ${colors.secondary}`
                      : `1px solid ${colors.secondary}`,
                  backgroundColor:
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                      ? '#ff7aa1'
                      : selectedQuestion
                      ? colors.secondary
                      : '#fff',
                  color: selectedQuestion ? '#fff' : colors.secondary,
                  cursor: !selectedQuestion ? 'default' : 'pointer'
                }}>
                {questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                  ? 'Stop Showing'
                  : 'Answer Live'}
              </Button>
              <Button
                type="button"
                style={{
                  marginLeft: '10px'
                }}
                onClick={handleSendAnswer}
                disabled={!textArea || !selectedQuestion}
                whileTap={{ scale: textArea && selectedQuestion ? 0.9 : 1 }}
                whileHover={{
                  backgroundColor:
                    textArea && selectedQuestion ? colors.secondary : colors.secondary,
                  cursor: !textArea || !selectedQuestion ? 'default' : 'pointer'
                }}>
                Send Answer
              </Button>
            </div>
          </AnswerQuestion>
        ) : (
          <SubmitNewQuestion onSubmit={handleSubmitNewQuestion} colors={colors}>
            <TextArea
              $theme={theme}
              id="question"
              name="question"
              colors={colors}
              value={textArea}
              onChange={handleTextAreaChange}
              placeholder="Type your question..."
            />
            <div>
              <label htmlFor="anonymousCheckbox" style={{ color: '#3C3C3C' }}>
                <AnonymousCheckbox
                  checked={submitAnonymousQuestion}
                  id="anonymousCheckbox"
                  name="anonymousCheckbox"
                  onChange={(e) => setSubmitAnonymousQuestion(e.target.checked)}
                  colors={colors}
                  type="checkbox"
                />
                Submit Anonymously
              </label>
              <Button
                type="submit"
                disabled={!textArea}
                whileTap={{ scale: textArea ? 0.9 : 1 }}
                whileHover={{
                  backgroundColor: textArea ? colors.tertiary : colors.tertiary
                }}>
                Submit Question
              </Button>
            </div>
          </SubmitNewQuestion>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 0.75rem;

  @media screen and (min-width: 410px) {
    padding: 1.275rem 1.0125rem;
  }

  @media screen and (min-width: 1150px) and (max-width: 1365px) {
    padding: 0.75rem;
  }

  @media screen and (min-width: 1366px) {
    padding: 1.275rem 1.0125rem;
  }

  > p {
    color: ${({ colors }) => colors.secondary};
    font-size: 0.875rem;
    font-style: ${({ isModerator }) => (isModerator ? 'normal' : 'italic')};
    font-weight: ${({ isModerator }) => (isModerator ? 600 : 300)};
    line-height: 1.25em;
    margin: 0.5em 0 1em;
    text-align: center;
    ${({ isModerator }) => isModerator && `transform: scale3d(1.125, 1.125, 1.125)`}

    @media screen and (min-width: 410px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      font-size: 0.875rem;
      margin: 0.5em 0 1em;
    }

    @media screen and (min-width: 1366px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.35rem);
    justify-content: space-between;

    @media screen and (min-width: 410px) {
      height: calc(100% - 1.85rem);
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      height: calc(100% - 2.5rem);
    }

    @media screen and (min-width: 1366px) {
      height: calc(100% - 2.05rem);
    }
  }
`;

const SubmittedQuestions = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ submittedQuestions }) =>
    submittedQuestions &&
    submittedQuestions.length > 0 &&
    css`
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.31rem grey;
        border-radius: 0.625rem;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ colors }) => colors.secondary};
        border-radius: 0.625rem;
      }
    `}
`;

const SubmittedQuestion = styled(motion.div)`
  background-color: ${({ isSelected, colors }) =>
    isSelected ? hexToRGB({ color: colors.secondary, alpha: 0.2 }) : 'transparent'};
  color: ${({ isSelected, colors }) => (isSelected ? colors.secondary : '#000')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  font-size: 1rem;
  margin-bottom: 0.688em;
  padding: 0.125em 0.25em 0.25em;
  position: relative;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  p {
    padding-left: ${({ isModerator }) => (isModerator ? '1.375rem' : 0)};
  }

  p:first-of-type {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.313em;

    span:first-of-type {
      font-weight: 700;
    }

    span:last-of-type {
      color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
      transition: color 200ms ease-in-out;
      font-size: 0.75em;
    }
  }

  small {
    color: ${({ isStarred, colors }) => (isStarred ? colors.secondary : '#000')};
    display: inline-block;
    font-size: 0.65rem;
    left: 0.95em;
    position: relative;
  }
`;

const ModeratorsAnswer = styled(motion.div)`
  color: ${({ isSelected }) => (isSelected ? '#000' : '#3C3C3C')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  margin-top: 0.625rem;
  padding: 0.125rem 0 0.25rem 1rem;
  transition: color 200ms ease-in-out;
`;

const SelectQuestionCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  height: 1rem;
  margin-top: 2px;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.secondary};
  }

  &:checked {
    border: 1px solid ${({ colors }) => colors.secondary};
  }

  &:checked:before {
    color: ${({ colors }) => colors.secondary};
    content: '✓';
    font-size: 0.8rem;
    height: 0.8rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37%, -60%);
    width: 0.8rem;
  }
`;

const TextArea = styled.textarea`
  border: 0.063em solid ${({ colors }) => colors.secondary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  height: 6.25em;
  margin-top: 0.5em;
  outline-color: ${({ colors }) => colors.secondary};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.625em;
  resize: none;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }

  &::placeholder {
    color: #c4c4c4;
    font-size: 1rem;
  }
`;

const AnswerQuestion = styled.div`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;

    @media screen and (min-width: 1150px) {
      justify-content: space-between;
    }

    button {
      border-radius: 0.125rem;
      max-width: 11.875rem;
    }

    button:first-of-type {
      background-color: #fff;
      border: 1px solid ${({ colors }) => colors.secondary};
      color: ${({ colors }) => colors.secondary};

      @media screen and (max-width: 1149px) {
        margin-right: 30px;
      }
    }

    button:last-of-type {
      background-color: ${({ colors }) => colors.secondary};
    }
  }
`;

const AnonymousCheckbox = styled(SelectQuestionCheckbox)`
  border: 1px solid #3C3C3C;
  height: 1rem;
  margin-right: 0.375rem;
  margin-top: unset;
  position: relative;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.secondary};
    margin-right: 0.375rem;
  }

  &:checked {
    background-color: ${({ colors }) => colors.secondary};
    border: 2px solid ${({ colors }) => colors.secondary};
    margin-right: 0.375rem;
  }

  &:checked:before {
    color: ${({ contrast }) => (contrast ? `#000` : `#fff`)};
    font-size: 0.8rem;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const SubmitNewQuestion = styled.form`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.438rem 0 1.4rem;

    /* TODO: Fix all this horrible margin fiddling! */

    @media screen and (min-width: 1150px) {
      margin: 0.438rem 0 1rem !important;
    }

    @media screen and (min-width: 1354px) {
      margin: 0.438rem 0 1.4rem !important;
    }

    button {
      border-radius: 0.125rem;
      background-color: ${({ colors }) => colors.secondary};
      max-width: 11.875rem;
    }

    label {
      align-items: center;
      color: #c4c4c4;
      display: flex;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 500px) and (max-width: 1149px) {
      /* justify-content: flex-end; */

      label {
        white-space: nowrap;
      }
    }

    @media screen and (min-width: 500px) and (max-width: 1300px) {
      label {
        margin-right: 1em;
      }
    }
  }
`;

const StarQuestion = styled(PinComment)`
  > svg {
    bottom: unset;
  }
`;

export default QAndA;
