import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { Logo, DefaultCommenterAvatar } from 'assets/svgs';
import { Modal, RegistrationForm, LoginModal, UserProfile, NavbarDropdown } from 'components';
// AccessibilityBar
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { fullGridContainer, breakpoints, pfizer } from 'styles';
// import Eye from 'assets/svgs/eye-icon.svg';
import { AnimatePresence, motion } from 'framer-motion';

const Navbar = ({ colors }) => {
  const { theme, mobile, selectedDepartment, selectedEvent } = useContext(LocalContext);
  const { pathname } = useLocation();
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [navOpen, setNavOpen] = useState(false);
  // const [themeToggleOpen, setThemeToggleOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);

  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => setActiveLink(pathname), [pathname]);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const toggleNav = () => {
    // setThemeToggleOpen(false);
    setNavOpen(!navOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  // const toggleTheme = () => {
  //   setNavOpen(false);
  //   setThemeToggleOpen(!themeToggleOpen);
  // };

  const handleLogout = () =>
    firebase
      .logout(user)
      .then(() => {
        document.querySelector('html').classList.remove('scroll-lock');
        if (selectedEvent) {
          navigate(`/events/${selectedEvent.slug}/`);
        } else {
          navigate('/');
        }
      })
      .catch((error) => isMounted && console.error(error.message));

  return (
    <>
      <StyledNavbar
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        scrolled={isScrolled}
        // themeToggle={themeToggleOpen}
        theme={theme}>
        <LogoContainer
          to="/"
          aria-label="Home"
          onClick={(e) => {
            e.preventDefault();
          }}>
          <Logo />
        </LogoContainer>

        {(navOpen || !mobile) && (
          <NavLinks
            initial={mobile ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.75 }}>
            {/* Display link if verified user */}
            {/* {!user?.emailVerified && (
              <></>
            )} */}
            {/* {user && (user.eventsUserCanAccess.length > 0 || user.emailAddressIsWhitelisted) && (
              <NavLink
                to="/events"
                theme={theme}
                className={activeLink === '/events' ? 'active' : null}
                onClick={() => {
                  toggleActiveLink('/events');
                }}>
                <Hub />
                Hub
              </NavLink>
            )} */}
            {/* <AnimatePresence>
              {pathname === '/' && <NavbarDropdown toggleNav={toggleNav} />}
            </AnimatePresence> */}
            {/* <NavLink
              theme={theme}
              to="#gallery"
              className={activeLink === 'gallery' ? 'active ' : null}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(galleryRef);
                toggleActiveLink('gallery');
              }}>
              Gallery
            </NavLink> */}
            {/* <NavLink
              theme={theme}
              to="#faq"
              className={activeLink === 'faq' ? 'active ' : null}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(faqRef);
                toggleActiveLink('faq');
              }}>
              FAQ
            </NavLink>
            <NavLink
              theme={theme}
              to="#contact"
              className={activeLink === 'contact' ? 'active ' : null}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(contactRef);
                toggleActiveLink('contact');
              }}>
              Contact
            </NavLink> */}
            {mobile && !loading && firebase?.auth?.currentUser && user && (
              <LoginButton theme={theme}>
                <div role="button" onClick={() => setShowUserProfileModal(true)} tabIndex="0">
                  <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                    {user.firstName || user.email}
                  </p>
                  {user.avatarUrl ? (
                    <ProfileImage src={user.avatarUrl} alt={user.fullName} theme={theme} />
                  ) : (
                    <DefaultCommenterAvatar />
                  )}
                </div>
                <Modal
                  openWithoutTrigger={showUserProfileModal}
                  customOutsideClose={() => setShowUserProfileModal(false)}
                  modalContent={<UserProfile handleLogout={handleLogout} setShowUserProfileModal={setShowUserProfileModal} />}
                  colors={colors}
                />
              </LoginButton>
            )}
            {mobile && !loading && !firebase?.auth?.currentUser && !user && (
              <>
                <RegisterButton theme={theme}>
                  <Modal
                    trigger={<p>Register</p>}
                    modalContent={<RegistrationForm event={selectedEvent} colors={colors} />}
                    colors={colors}
                  />
                </RegisterButton>

                <LoginButton theme={theme}>
                  <Modal
                    hideCloseButton
                    trigger={
                      <>
                        <p>Login </p>
                        <DefaultCommenterAvatar />
                      </>
                    }
                    modalContent={<LoginModal colors={colors} />}
                    colors={colors}
                  />
                </LoginButton>
              </>
            )}
          </NavLinks>
        )}
        {!mobile && !loading && firebase?.auth?.currentUser && user && (
          <User theme={theme}>
            <div role="button" onClick={() => setShowUserProfileModal(true)} tabIndex="0">
              <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                {user.firstName || user.email}
              </p>
              {user.avatarUrl ? (
                <ProfileImage src={user.avatarUrl} alt={user.fullName} theme={theme} />
              ) : (
                <DefaultCommenterAvatar />
              )}
            </div>
            <Modal
              hideCloseButton
              openWithoutTrigger={showUserProfileModal}
              customOutsideClose={() => setShowUserProfileModal(false)}
              modalContent={
                <UserProfile
                  handleLogout={handleLogout}
                  setShowUserProfileModal={setShowUserProfileModal}
                />
              }
              colors={colors}
            />
          </User>
        )}
        {!mobile && !loading && !firebase?.auth?.currentUser && !user && (
          <User theme={theme}>
            <Modal
              hideCloseButton
              trigger={
                <>
                  {' '}
                  <p style={{ marginRight: '.625rem', fontWeight: '700' }}>Login</p>
                  <DefaultCommenterAvatar />
                </>
              }
              transparentCloseButtonBackground
              modalContent={<LoginModal colors={colors} />}
              colors={colors}
              style={{
                right: '25px',
                top: '24px'
              }}
            />
          </User>
        )}
        {/* <ThemeToggle onClick={toggleTheme} open={themeToggleOpen} theme={theme}>
          {user && (
            <BulletPoint
              top=".5rem"
              right="1rem"
              width="11rem"
              content={`
                      <p>Accessibility Bar</p>`}
            />
          )}
          <Eye />
          {themeToggleOpen && <AccessibilityBar />}
        </ThemeToggle> */}
        <NavToggler onClick={toggleNav} theme={theme} aria-label="Menu">
          <NavBurger theme={theme} navOpen={navOpen}>
            <BurgerBar theme={theme} navOpen={navOpen} />
            <BurgerBar theme={theme} navOpen={navOpen} />
            <BurgerBar theme={theme} navOpen={navOpen} />
          </NavBurger>
        </NavToggler>
      </StyledNavbar>
    </>
  );
};

const ProfileImage = styled.img`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  object-fit: cover;
  background: #c4c4c4;
  border: 0.125rem solid #0095ff;
`;

const User = styled.button`
  outline: 0;
  border: 0;
  grid-area: user;
  grid-column: 10/12;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: transparent;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.textColor};
    font-size: 1rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
  }
  @media (min-width: ${breakpoints.lg}) {
    div {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }
  svg {
    max-width: 1.5rem;
    circle {
      stroke: ${({ theme }) => theme.textColor};
    }
    path {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;

const RegisterButton = styled.div`
  outline: none;
  border: none;
  line-height: 3rem;
  font-size: 1rem;
  padding-top: 0.125rem;
  font-weight: 600;
  width: 100%;
  text-align: center;
  background: white;
  color: #3c3c3c;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 0.5px;
  cursor: pointer;
  @media (min-width: 1150px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`;

const LoginButton = styled.button`
  outline: none;
  border: none;
  line-height: 3rem;
  font-size: 1rem;
  padding-top: 0.125rem;
  font-weight: 600;
  width: 100%;
  max-width: 100%;
  text-align: center;
  background: ${pfizer.secondary};
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 0.5px;
  cursor: pointer;
  div {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 1.25rem;
    margin-left: 0.5rem;
    path {
      fill: white;
    }
    circle {
      stroke: white;
    }
  }
  @media (min-width: 1150px) {
    background: transparent;
    justify-content: flex-end;
    font-size: 0.875rem;
    line-height: 1.5rem;
    svg {
      width: 1.5rem;
    }
  }
`;

const NavLink = styled(Link)`
  line-height: 3rem;
  font-size: 1rem;
  padding-top: 0.125rem;
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  background: white;
  color: #3c3c3c;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 0.5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 0.75rem;
    margin-right: 0.5rem;
    margin-top: -0.25rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 0.875rem;
    line-height: 1.5rem;

    width: auto;
    background: transparent;
    margin-bottom: 0.125rem;
    svg {
      margin-top: 0;
    }
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  background: white;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  grid-area: links;
  grid-gap: 0px;
  height: auto;
  justify-content: center;
  @media (min-width: ${breakpoints.lg}) {
    background: transparent;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    justify-content: flex-start;
    height: 100%;
    margin-top: 0px;
    box-shadow: none;
    width: 400px;
    position: absolute;
    left: 8.125rem;
    /* left: -2.45rem; */
  }
`;

const NavBurger = styled.div`
  width: ${({ navOpen }) => (navOpen === true ? '1rem' : '1.25rem')};
  height: 1rem;
  position: relative;
`;

const BurgerBar = styled.span`
  width: 1.25rem;
  height: 0.185rem;
  background: white;
  position: absolute;
  display: block;
  content: '';
  transform-origin: center center;
  left: 0;
  &:first-child {
    top: ${({ navOpen }) => (navOpen === true ? '50%' : '0')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(45deg)' : 'none')};
  }
  &:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    opacity: ${({ navOpen }) => (navOpen === true ? '0' : '1')};
  }
  &:last-child {
    top: ${({ navOpen }) => (navOpen === true ? '50%' : 'initial')};
    bottom: ${({ navOpen }) => (navOpen === true ? 'initial' : '0')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(-45deg)' : 'none')};
  }
`;

const NavToggler = styled.button`
  display: flex;
  grid-area: toggle;
  width: 4rem;
  height: 4rem;
  justify-self: end;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ theme }) => theme.bg};
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const LogoContainer = styled(Link)`
  grid-area: logo;
  pointer-events: none;
  svg {
    height: 2.5rem;
    width: auto;
    object-fit: cover;
    z-index: 2;
    margin-left: 1.25rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-column: 2/3;

    svg {
      margin-left: unset;
    }
  }
`;

const StyledNavbar = styled(motion.nav)`
  display: grid;
  height: 4rem;
  z-index: 5;
  padding: 0px;
  align-items: center;
  grid-template-areas:
    'logo toggle'
    'links links';
  grid-template-columns: 1fr 60px;
  grid-gap: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  background: white;
  transition-duration: 0.75s;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.25rem;
    height: 5rem;
    box-shadow: none;
    grid-template-areas: 'logo links user';
    background: 'white;';
    box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.15);
  }
`;

export default Navbar;

// const ThemeToggle = styled.div`
//   cursor: pointer;
//   position: relative;
//   width: 3.75rem;
//   height: 3.75rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   grid-area: eye;
//   justify-self: end;
//   background: ${({ open, theme }) => (open ? theme.bgLight : 'transparent')};
//   svg {
//     path {
//       stroke: ${({ theme }) => theme.textColor};
//       :last-child {
//         fill: ${({ theme }) => theme.textColor};
//       }
//     }
//   }
//   @media (min-width: ${breakpoints.lg}) {
//     grid-column: 12/13;
//     justify-self: start;
//     width: 4rem;
//     height: 4rem;
//   }
// `;
