import React, { useContext, useEffect } from 'react';
import { LocalContext } from 'context';
import styled, { css } from 'styled-components';
import { getSlug, clearQueryParams } from 'utils';
import { FormContainer } from '../FormComponents';

const Unsubscribe = () => {
  const { theme } = useContext(LocalContext);

  useEffect(() => {
    setTimeout(() => clearQueryParams(), 1000);
  }, []);

  return (
    <FormContainer theme={theme}>
      <H3>You have been unsubscribed</H3>
    </FormContainer>
  );
};

const H3 = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;
`;

export default Unsubscribe;
