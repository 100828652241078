import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';

const ZoomBanner = ({ colors, zoomUrl }) => {
  return (
    <Wrapper
      colors={colors}>
      <p>Click the button to</p>
      <h3>Join the nominees and winners</h3>
      <a href={zoomUrl} target="_blank" rel="noreferrer">
        <Button style={{ backgroundColor: colors.secondary, width: '180px' }}>Zoom Link</Button>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  animation: fadeIn 200ms ease-in-out forwards;
  align-items: center;
  background-color: ${({ colors }) => colors.primary};
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  margin: 7.5rem auto 2.5rem;
  max-width: 812px;
  padding: 2rem 1rem;
  width: 100%;

  p,
  h3 {
    color: #fff;
    text-align: center;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0.15em 0 0.7em;
  }

  @media screen and (min-width: 812px) {
    border-radius: 8px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default ZoomBanner;
