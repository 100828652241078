import * as React from 'react';

function SvgEvent10Arrow(props) {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 12L0.00480936 -5.93693e-07L12.9952 -1.81303e-06L6.5 12Z" fill="black" />
    </svg>
  );
}

export default SvgEvent10Arrow;
