import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, gridContainer } from 'styles';
import { LocalContext } from 'context';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { LeftArrow, RightArrow, FbIcon, TwitterIcon, LinkedinIcon, EmailIcon } from 'assets/svgs';
import { Markup } from 'interweave';

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 300 : -300,
    opacity: 0
  })
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

export default ({ startPoint, speakers, colors, eid }) => {
  const { mobile, theme } = useContext(LocalContext);
  const [[page, direction], setPage] = useState([startPoint, 0]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const speakerIndex = wrap(0, speakers.length, page);

  const speakerImage = getImage(speakers[speakerIndex].image);

  const speakerHasSocialLinks =
    speakers[speakerIndex].fbLink ||
    speakers[speakerIndex].linkedinLink ||
    speakers[speakerIndex].twLink ||
    speakers[speakerIndex].emailLink;

  return (
    <Backdrop>
      <Container
        key={page}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.5 }}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        dragDirectionLock
        drag="x"
        onDrag={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
          }
        }}>
        <AnimatePresence initial={false} custom={direction}>
          <Slide colors={colors}>
            {speakers.length > 1 && (
              <Prev colors={colors} onClick={() => paginate(-1)}>
                <LeftArrow />
              </Prev>
            )}
            <SpeakerImgContainer>
              <SpeakerImg image={speakerImage} cropped />
            </SpeakerImgContainer>
            <SpeakerDetails>
              <SpeakerName>{speakers[speakerIndex].name}</SpeakerName>
              {speakers[speakerIndex].abbreviations && (
                <SpeakerAbbreviations>
                  <Markup content={speakers[speakerIndex].abbreviations} />
                </SpeakerAbbreviations>
              )}
              <SpeakerTitle colors={colors} eid={eid}>
                <Markup
                  content={speakers[speakerIndex].position}
                  noHtml={
                    speakers[speakerIndex].name !== 'Professor Fidelma Fitzpatrick' &&
                    speakers[speakerIndex].name !== 'Dr. Fintan Kelleher'
                  }
                />
              </SpeakerTitle>
              {speakerHasSocialLinks && (
                <SpeakerSocials onClick={(e) => e.stopPropagation()}>
                  {speakers[speakerIndex].fbLink && (
                    <SpeakerSocial
                      href={speakers[speakerIndex].fbLink}
                      target="_blank"
                      colors={colors}>
                      <FbIcon />
                    </SpeakerSocial>
                  )}
                  {speakers[speakerIndex].linkedinLink && (
                    <SpeakerSocial
                      href={speakers[speakerIndex].linkedinLink}
                      target="_blank"
                      colors={colors}>
                      <LinkedinIcon />
                    </SpeakerSocial>
                  )}
                  {speakers[speakerIndex].twLink && (
                    <SpeakerSocial
                      href={speakers[speakerIndex].twLink}
                      target="_blank"
                      colors={colors}>
                      <TwitterIcon />
                    </SpeakerSocial>
                  )}
                  {speakers[speakerIndex].emailLink && (
                    <SpeakerSocial
                      href={speakers[speakerIndex].emailLink}
                      target="_blank"
                      colors={colors}>
                      <EmailIcon />
                    </SpeakerSocial>
                  )}
                </SpeakerSocials>
              )}
              <SpeakerSummary colors={colors}>
                <Markup content={speakers[speakerIndex].summary} />
              </SpeakerSummary>
            </SpeakerDetails>
            {speakers.length > 1 && (
              <Next colors={colors} onClick={() => paginate(1)}>
                <RightArrow />
              </Next>
            )}
          </Slide>
        </AnimatePresence>
      </Container>
    </Backdrop>
  );
};

const Prev = styled.div`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  border: none;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
  display: none;
  height: 3rem;
  justify-content: center;
  justify-self: end;
  left: -12.45rem;
  opacity: 0.8;
  outline: none;
  padding-left: 0.625rem;
  position: absolute;
  transition: 0.5s;
  width: 3rem;
  z-index: 10;
  svg {
    width: 1.5rem;
    path {
      stroke: rgb(245, 250, 250);
    }
  }
  :hover {
    opacity: 1;
    transform: scale(1.05);
  }
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
  }
`;

const Next = styled.div`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  border: none;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  cursor: pointer;
  display: none;
  height: 3rem;
  justify-content: center;
  justify-self: start;
  opacity: 0.8;
  outline: none;
  padding-right: 0.625rem;
  position: absolute;
  right: -4.3rem;
  transition: 0.5s;
  width: 3rem;
  svg {
    width: 1.5rem;
    path {
      stroke: rgb(245, 250, 250);
    }
  }
  :hover {
    opacity: 1;
    transform: scale(1.05);
  }
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
  }
`;

const SpeakerName = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
`;

const SpeakerTitle = styled.p`
  color: ${({ eid, colors }) => (eid === 'eIj0KJ' ? colors.secondary : colors.tertiary)};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  margin-top: 0.375rem;
  text-align: left;
  span {
    color: ${({ eid, colors }) => (eid === 'eIj0KJ' ? colors.secondary : colors.tertiary)};
  }
`;

const SpeakerAbbreviations = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0.375rem;
  margin-bottom: -0.375rem;
  text-align: left;
`;

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
`;

const SpeakerSocial = styled(motion.a).attrs({
  whileHover: {
    scale: 1.075
  },
  whileTap: {
    scale: 0.925
  }
})`
  margin-right: 0.625rem;
  svg {
    width: 1.5rem;
    circle {
      fill: ${({ colors }) => colors.tertiary};
    }
  }
`;

const SpeakerSummary = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 1.15rem;
  a {
    color: ${({ colors }) => colors.tertiary};
  }
`;

const SpeakerDetails = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  line-height: 1.5rem;
  margin-left: left;
  @media (min-width: ${breakpoints.lg}) {
    grid-column: 5/11;
    margin-left: -100px;
    padding: 2.5rem 3.85rem 2.5rem 1.3rem;
    text-align: justify;
  }

  @media screen and (min-width: 1300px) {
    margin-left: -150px;
  }

  ul {
    left: 1.25em;
    position: relative;
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  border-radius: ${({ cropped }) => (cropped ? '50%' : '0px')};
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: 250px;
  height: auto;
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 250px;
  z-index: 2;

  @media screen and (min-width: 1150px) {
    height: 22.5rem;
    width: 22.5rem;
  }
`;

const SpeakerImgContainer = styled.div`
  grid-column: 1/7;
  justify-self: center;
  margin-bottom: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-top: -50%;
  max-width: 100%;
  position: relative;
  /* width: 300px; */

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    width: 300px;
    margin-bottom: unset;
    max-width: 100%;
    margin-top: 0px;
    grid-column: 1/5;
    margin-left: calc(-50% + 2.5rem);
    margin-right: 50%;
    align-self: flex-start;
  }
`;

const Slide = styled.div`
  ${gridContainer}
  align-items: center;
  background-color: #f3f3f3;
  color: #3c3c3c;
  height: auto;
  margin-bottom: 3rem;
  margin-top: 10rem;
  max-width: 380px;
  padding: 0 1.25rem 2rem;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    padding: unset;
    margin-top: 0px;
    margin-left: 200px;
    margin-right: 5rem;
    max-width: 1200px;
    border: 1px solid transparent;
    border-top-left-radius: 56px;
  }
`;

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-top: 1rem;
  width: 1300px;
  @media (min-width: 768px) {
    max-width: 400px;
  }
  @media (min-width: 1150px) {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 1150px) {
    overflow-x: hidden;
  }
`;
