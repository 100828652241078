import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useIntersection } from 'hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, gridContainer } from 'styles';
import { motion } from 'framer-motion';
import {
  BgMoon1,
  BgMoon2,
  FbIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon,
  InstaIcon,
  WebsiteIcon
} from 'assets/svgs';
import { Markup } from 'interweave';

export default ({ data, colors }) => {
  const speakerImage = getImage(data.image);

  const speakerHasSocialLinks =
    data.websiteLink ||
    data.fbLink ||
    data.linkedinLink ||
    data.twLink ||
    data.igLink ||
    data.emailLink;

  const [ref, observerEntry] = useIntersection({
    threshold: 0.1
  });

  const speakerIsInView = observerEntry?.isIntersecting;

  return (
    <Slide colors={colors}>
      <SpeakerImgContainer ref={ref} speakerIsInView={speakerIsInView}>
        <SpeakerImg image={speakerImage} cropped speakerIsInView={speakerIsInView} />
        <TiltedBorder speakerIsInView={speakerIsInView}>
          <LilacSquare speakerIsInView={speakerIsInView} />
          <WhiteGlow1 speakerIsInView={speakerIsInView} />
          <WhiteGlow2 speakerIsInView={speakerIsInView} />
          <WhiteGlow3 speakerIsInView={speakerIsInView} />
          <WhiteGlow4 speakerIsInView={speakerIsInView} />
        </TiltedBorder>
      </SpeakerImgContainer>
      <SpeakerDetails>
        <SpeakerName>
          <Markup content={data.name} noWrap />
        </SpeakerName>
        {data.position && (
          <SpeakerTitle>
            <Markup content={data.position} />
          </SpeakerTitle>
        )}
        {speakerHasSocialLinks && (
          <SpeakerSocials>
            {data.websiteLink && (
              <SpeakerSocial href={data.websiteLink} target="_blank" colors={colors}>
                <WebsiteIcon />
              </SpeakerSocial>
            )}
            {data.linkedinLink && (
              <SpeakerSocial href={data.linkedinLink} target="_blank" colors={colors}>
                <LinkedinIcon />
              </SpeakerSocial>
            )}
            {data.twLink && (
              <SpeakerSocial href={data.twLink} target="_blank" colors={colors}>
                <TwitterIcon />
              </SpeakerSocial>
            )}
            {data.fbLink && (
              <SpeakerSocial href={data.fbLink} target="_blank" colors={colors}>
                <FbIcon />
              </SpeakerSocial>
            )}
            {data.igLink && (
              <SpeakerSocial href={data.igLink} target="_blank" colors={colors}>
                <InstaIcon />
              </SpeakerSocial>
            )}
            {data.emailLink && (
              <SpeakerSocial href={data.emailLink} target="_blank" colors={colors}>
                <EmailIcon />
              </SpeakerSocial>
            )}
          </SpeakerSocials>
        )}
        <SpeakerSummary colors={colors}>
          <Markup content={data.summary} />
        </SpeakerSummary>
      </SpeakerDetails>
    </Slide>
  );
};

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem 0 0.25rem;
`;

const SpeakerSocial = styled(motion.a).attrs({
  whileHover: {
    scale: 1.075
  },
  whileTap: {
    scale: 0.925
  }
})`
  margin-right: 0.625rem;
  svg {
    width: 1.5rem;
    circle {
      fill: ${({ colors }) => colors.tertiary};
    }
  }
`;

const SpeakerSummary = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-top: 1.15rem !important;
  max-width: 400px !important;
  text-align: center !important;

  a {
    color: #db1778;
    font-weight: bold;
    text-decoration: underline;
  }

  @media (min-width: 1150px) {
    text-align: left !important;
    max-width: 100% !important;
  }
`;

const SpeakerName = styled.h3`
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  margin: 0 auto;
  text-align: center !important;
  @media (min-width: 1150px) {
    text-align: left !important;
    margin-left: 0;
  }
`;

const SpeakerTitle = styled.p`
  color: #3c3c3c !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem !important;
  margin: 1rem 0 0.375rem !important;
  text-align: center !important;
  @media (min-width: 1150px) {
    margin: 0.25rem 0 0.375rem !important;
    text-align: left !important;
  }
`;

const SpeakerDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: center;
  margin-left: 0px;

  @media (min-width: 1150px) {
    padding: 2.5rem 2.5rem 0;
    grid-column: 5/12;
    align-items: flex-start;
  }

  @media (min-width: 1440px) {
    padding: 2.5rem 0 0 2.5rem;
    margin-left: -60px;
  }
`;

const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  svg {
    path {
      fill: ${({ colors }) => colors.tertiary};
      transition: 0.5s;
    }
  }
  :nth-child(1) {
    svg {
      path {
        fill: ${({ colors }) => colors.primary};
      }
    }
  }
  :nth-child(2) {
    svg {
      path {
        fill: ${({ colors }) => colors.secondary};
      }
    }
  }
`;

const Circle1 = styled(BgMoon1)`
  bottom: -15%;
  height: 100%;
  left: -15%;
  position: absolute;
  transition-duration: 1s;
  width: 100%;
`;
const Circle2 = styled(BgMoon2)`
  height: 100%;
  position: absolute;
  right: -15%;
  top: -15%;
  transition-duration: 1s;
  width: 100%;
`;

const speakerAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  height: 250px;
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 250px;
  z-index: 2;
  opacity: 0;

  ${({ speakerIsInView }) =>
    speakerIsInView &&
    css`
      animation: ${speakerAnimation} 1s ease-in-out forwards;
    `};
`;

const redBorderAnimation = keyframes`
  0% {
  border-image: conic-gradient(transparent, transparent, transparent, transparent, transparent, transparent, transparent) 1;
  }
  100% {
  border-image: conic-gradient(#d32c4d, #75192d, #d32c4d, #75192d, #75192d, #d32c4d, #d32c4d) 1;
  }
`;

const SpeakerImgContainer = styled.div`
  border: 8px solid;
  border-width: 4px;
  border-image: conic-gradient(transparent, transparent, transparent, transparent, transparent, transparent, transparent) 1;
  grid-column: 1/7;
  justify-self: center;
  margin: 3rem auto;
  max-width: 100%;
  position: relative;
  width: auto;
  padding: 7px;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    max-width: 100%;
    grid-column: 1/5;
    align-self: flex-start;
  }

    ${({ speakerIsInView }) =>
    speakerIsInView &&
    css`
      animation: ${redBorderAnimation} 1s 0.4s ease-in-out forwards;
    `};
`;

const whiteGlowAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
`

const WhiteGlow = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 12px 12px rgba(255, 255, 255, 1);
  border-radius: 50%;
  width: 5px;
  height: 5px;
  opacity: 0;
  position: absolute;
  z-index: 1;

  ${({ speakerIsInView }) =>
    speakerIsInView &&
    css`
      animation: ${whiteGlowAnimation} 0.4s 1.4s ease-in-out forwards;
    `};
`;

const WhiteGlow1 = styled(WhiteGlow)`
  bottom: -5px;
  left: 20%;
`;

const WhiteGlow2 = styled(WhiteGlow)`
  bottom: 40%;
  right: -5px;
`;

const WhiteGlow3 = styled(WhiteGlow)`
  top: -5px;
  right: 21%;
`;

const WhiteGlow4 = styled(WhiteGlow)`
  top: 36%;
  left: -5px;
`;

const tiltedBorderAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotateZ(0deg);
  }
  50% {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  100% {
    opacity: 1;
    transform: rotateZ(4.28deg);
  }
`;

const TiltedBorder = styled.div`
  width: 122%;
  height: 122%;
  border: 11px solid;
  border-width: 6px;
  border-image: conic-gradient(
      #9829f2,
      #e54eee,
      #9829f2,
      #e54eee,
      #e54eee,
      #9829f2,
      #9829f2,
      #e54eee,
      #9829f2,
      #e54eee,
      #e54eee,
      #9829f2,
      #9829f2
    )
    1;
  position: absolute;
  opacity: 0;
  top: -11%;
  left: -11%;
  padding: 7.5%;
  transform: rotateZ(0deg);

    ${({ speakerIsInView }) =>
    speakerIsInView &&
    css`
      animation: ${tiltedBorderAnimation} 1s 0.8s ease-in-out forwards;
    `};
`;

const LilacSquare = styled.div`
  background-color: rgba(174, 45, 200, 0.3);
  width: 100%;
  height: 100%;
  transform: rotateZ(8.48deg);
`;

const Slide = styled.div`
  ${gridContainer}
  align-items: center;
  color: #3c3c3c;
  height: auto;
  max-width: 100%;
  padding: 1.25rem 1.25rem 0;
  position: relative;
  width: 1200px;
`;
