import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button, LoadingSpinner } from 'components';
import { FirebaseContext } from 'context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const EventPublications = ({ colors, publications, tangent90EventSource }) => {
  const { user } = useContext(FirebaseContext);
  const [checkingIfUserHasGivenTangent90Consent, setCheckingIfUserHasGivenTangent90Consent] =
    useState(false);

  const handlePublicationDownload = async ({ publication }) => {
    try {
      setCheckingIfUserHasGivenTangent90Consent(true);

      const url = `${publication.tangent90Link}?title=${encodeURIComponent(
        user.title
      )}&fullName=${encodeURIComponent(user.fullName)}&to=${encodeURIComponent(
        user.email.toLowerCase()
      )}&workplaceName=${encodeURIComponent(user.workplaceName)}&profession=${encodeURIComponent(
        user.profession
      )}&speciality=${encodeURIComponent(user.speciality)}&source=${encodeURIComponent(
        tangent90EventSource
      )}`;

      window.open(url, '_blank')?.focus();
    } catch (error) {
      console.error(error.message);
    } finally {
      setCheckingIfUserHasGivenTangent90Consent(false);
    }
  };

  return publications.map((publication, i) => (
    <Wrapper colors={colors}>
      <GatsbyImage image={getImage(publication.thumbnail)} alt="Thumbnail" />
      <Content>
        <Title>{publication.title}</Title>
        <Button
          onClick={() => handlePublicationDownload({ publication, i })}
          type="button"
          width="215px"
          colors={colors}
          whileTap={{
            scale: 0.95
          }}
          whileHover={{
            scale: 1.025
          }}
          style={{ background: `${colors.secondary}` }}>
          {checkingIfUserHasGivenTangent90Consent ? (
            <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
          ) : (
            'Download Now'
          )}
        </Button>
      </Content>
    </Wrapper>
  ));
};

const Wrapper = styled.div`
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  color: ${({ colors }) => colors.secondary};
  left: 0;
  line-height: 1.182em;
  max-width: 572px;
  width: 100%;
  margin: 0 auto 2rem;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 1.25rem;
`;

const Title = styled.p`
  font-family: 'noto-sans' !important;
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
  margin: 0 0 1.75rem 0 !important;
  text-align: left !important;
`;

export default EventPublications;
