import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { DefaultGroupAvatar } from 'assets/svgs';

function GroupWatchingSettings({ amount, colors }) {
  const { setShowGroupWatchingDialog } = useContext(LocalContext);
  return (
    <Container
      colors={colors}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      exit={{ y: -100 }}
      transition={{ duration: 1 }}>
      <span>
        <DefaultGroupAvatar />
        {`You are currently watching with ${amount} others`}
      </span>
      <Button type="button" onClick={() => setShowGroupWatchingDialog(true)}  colors={colors}>
        Edit
      </Button>
    </Container>
  );
}

const Container = styled(motion.div)`
  align-items: center;
  background: ${({ colors }) => colors.secondary};
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.14);
  color: white;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-left: -1.25rem;
  margin-top: -1.25rem;
  padding: 0.5rem 1rem 0.6rem 1rem;
  width: calc(100% + 2.5rem);
  span {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    font-weight: 700;
    gap: 0.5rem;
    svg {
      height: 1.75rem;
    }
  }
`;

const Button = styled.button`
  background: ${({ colors }) => colors.secondary};
  color: white;
  cursor: pointer;
  font-weight: 700;
`;

export default GroupWatchingSettings;