import * as React from 'react';

function SvgBannerSvg4(props) {
  return (
    <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M64 128c35.346 0 64-28.654 64-64 0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64z"
        fill="#004D9A"
      />
      <path
        d="M39.858 94.784a3.25 3.25 0 01-3.24-3.24V36.455a3.25 3.25 0 013.24-3.24h27.545a3.25 3.25 0 013.24 3.24v54.927a3.25 3.25 0 01-3.24 3.24H39.858v.162zm0-58.977c-.324 0-.648.324-.648.648v54.927c0 .324.324.648.648.648h27.545c.324 0 .648-.324.648-.648V36.455c0-.162 0-.324-.162-.486-.162-.162-.324-.162-.486-.162H39.858z"
        fill="#fff"
      />
      <path
        d="M46.987 82.31h-2.592V84.9h2.592V82.31zM62.704 82.31h-2.592V84.9h2.592V82.31zM46.987 74.532h-2.592v2.592h2.592v-2.592zM62.704 74.532h-2.592v2.592h2.592v-2.592zM46.987 66.593h-2.592v2.592h2.592v-2.592zM62.704 66.593h-2.592v2.592h2.592v-2.592zM46.987 58.815h-2.592v2.592h2.592v-2.592zM62.704 58.815h-2.592v2.592h2.592v-2.592zM46.987 50.876h-2.592v2.592h2.592v-2.593zM62.704 50.876h-2.592v2.592h2.592v-2.593zM46.987 43.1h-2.592v2.591h2.592V43.1zM62.704 43.1h-2.592v2.591h2.592V43.1zM53.63 86.846c-.648 0-1.296-.648-1.296-1.296V42.45c0-.648.648-1.296 1.296-1.296s1.297.648 1.297 1.296v43.26c0 .649-.648 1.135-1.297 1.135zM83.12 94.785c-4.538 0-8.264-3.727-8.264-8.263v-21.55c0-4.536 3.727-8.263 8.263-8.263 4.537 0 8.263 3.727 8.263 8.263v21.55c0 4.536-3.726 8.263-8.263 8.263zm-4.862-17.66c-.324 0-.648.323-.648.647v8.75a5.463 5.463 0 005.51 5.508 5.463 5.463 0 005.508-5.508V77.61c0-.324-.324-.648-.648-.648h-9.722v.162zm4.861-17.662a5.463 5.463 0 00-5.509 5.51v8.91c0 .325.324.649.648.649h9.884c.324 0 .648-.324.648-.648v-8.912c0-3.078-2.592-5.509-5.67-5.509z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgBannerSvg4;
