import * as React from 'react';

function SvgButterflyWingRight(props) {
  return (
    <svg viewBox="0 0 135 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M134.894 1.164a1.115 1.115 0 00-1.262-.644L37.5 18.968a1.1 1.1 0 00-.675.38L.889 58.488a1.339 1.339 0 00-.213.271.982.982 0 00-.132.417c0 .03-.015.073-.007.102v.081l2.56 62.079v.066c.03.285.17.548.375.724l20.79 19.508.029.029c.014.015.044.037.044.037l.066.044s.059.036.066.043c.007 0 .059.03.066.037 0 0 .051.022.073.029h.022s.06.022.067.022c.073.022.139.029.227.037H25.149s.088-.022.103-.022l.08-.022s.082-.029.096-.037a.408.408 0 00.081-.036l38.856-19.589a1.13 1.13 0 00.617-.68l9.283-28.373a1.118 1.118 0 00-.507-1.324L37.676 71.399 81.36 84.675c.308.117.66.088.932-.066l27.643-13.942a1.07 1.07 0 00.44-.387l18.779-26.677c.125-.153.206-.343.235-.548l5.599-41.313c.029-.205 0-.417-.088-.6l-.007.022zm-2.605 3.716l-4.998 36.887-29.096-1.316 34.101-35.564-.007-.007zm-26.815 65.546L82.073 82.232l-11.86-15.2 35.261 3.394zm-34.05 24.306l-8.005 24.482-16.034-15.968 24.047-8.514h-.007zm-39.92 25.002l-6.971 19.193-17.766-16.67 24.737-2.523zM41.998 20.38l82.204-15.778-56.49 24.65-25.714-8.872zM9.563 58.935l85.47-16.224 12.511 25.66-39.7-3.826-.037.366v-.366l-58.244-5.61zm1.666-2.597L129.177 4.88 95.105 40.42 11.23 56.339zm-5.174-.19l31.937-34.781 26.674 9.202L6.048 56.14l.007.007zm39.84 45.241L30.947 70.163l39.56 22.507-24.612 8.711v.008zM5.622 61.67l21.971 6.679 13.906 29.046L5.622 61.67zm55.287 58.217l-27.042-2.509L6.151 65.364l38.371 38.212.257-.256-.257.263 16.38 16.311.007-.007zm-.983 2.158L27.02 138.627l6.905-19.003 26 2.414v.007zm18.756-40.508l-49.907-15.17s-.074-.023-.088-.03L12.703 61.48l54.428 5.245L78.69 81.537h-.008zm47.479-37.59l-16.562 23.524-12.101-24.819 28.663 1.295zm-94.774 73.541l-26.11 2.663-2.304-55.986 28.414 53.323z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgButterflyWingRight;
