import React from 'react';
import styled from 'styled-components';

const EventAPI = () => {
  return (
    <Container>
      <InnerContainer>
      <a
          href="https://www.medicines.ie/medicines/paxlovid-150-mg-100-mg-film-coated-tablets-35210/spc"
          target="_blank"
          rel="noreferrer"><h3>Click here for Paxlovid▼ Prescribing Information</h3></a>
        <p>Legal category: S1A | Further information is available upon request</p>
        <span>▼ This medicinal product is subject to additional monitoring. This will allow quick identification of new safety information. 
        <br />Healthcare professionals are asked to report any suspected adverse reactions. Refer to section 4.8 of the SmPC for how to report adverse reactions.</span>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  background-color: #d2d2d2;
  color: #3c3c3c;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: start;
  padding: 3rem 1rem;
  @media (min-width: 1150px) {
    padding: 3rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  grid-column: 1 /7;
  width: 100%;
  max-width: 1164px;
  margin: 0 auto;
  h3 {
   font-size: 1.25rem;
  }
  p {
    font-size: 0.875rem;
  }
  span {
    font-size: 0.875rem;
    margin-top: 1.25rem;
  }
  a {
    color: #3c3c3c;
  }
  @media (min-width: 1150px) {
    grid-column: 2 / 12;
  }
`;
export default EventAPI;
