import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import { graphql } from 'gatsby';
import { breakpoints } from 'styles';
import { ProtectedRoute, SEO, NumberBreakdowns, ReferringUrls } from 'components';
import { scrollToAnchor } from 'utils';

function EventAnalytics({ data }) {
  console.log('event', data.event);
  const event = data.event.frontmatter;
  const { eid, colors } = event;
  const { firebase } = useContext(FirebaseContext);
  const [numberOfPeopleRegistered, setNumberOfPeopleRegistered] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [urlCount, setUrlCount] = useState({});
  const [groups, setGroups] = useState(0);
  const [totalGroupMembers, setTotalGroupMembers] = useState(0);

  useEffect(() => {
    let unsubscribeFromEventData;
    console.log(eid);
    if (firebase) {
      unsubscribeFromEventData = firebase.subscribeToEventData({
        eid,
        onSnapshot: (snapshot) => {
          console.log(snapshot);
          if (!snapshot.empty) {
            setNumberOfPeopleRegistered(snapshot.size);

            const { docs } = snapshot;
            console.log(docs);
            const _companiesList = [];
            const _professionsList = [];
            const _urlCount = {};

            docs.forEach((doc) => {
              const { workplaceName, profession, referringUrl } = doc.data();
              _companiesList.push(workplaceName);
              _professionsList.push(profession);
              _urlCount[referringUrl] = _urlCount[referringUrl] + 1 || 1;
            });
            setCompanies(_companiesList);
            setProfessions(_professionsList);
            setUrlCount(_urlCount);

            const totalGroups = docs.filter((doc) => {
              const { groupWatching } = doc.data();
              return Object.hasOwn(groupWatching || {}, eid);
            });

            const totalMembers = totalGroups.reduce((accumulator, currentValue) => {
              const { groupWatching } = currentValue.data();
              return accumulator + groupWatching[eid].length;
            }, 0);

            setGroups(totalGroups.length);
            setTotalGroupMembers(totalMembers);
          } else {
            setNumberOfPeopleRegistered(0);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventData) {
        unsubscribeFromEventData();
      }
    };
  }, [firebase]);

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle="Event Analytics" />
      <MenuTab
        colors={colors}
        initial={{ y: -120 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.75, duration: 0.75 }}>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}>
          Event Analytics
        </motion.span>
        <Anchors>
          <Anchor
            role="button"
            onClick={() => scrollToAnchor('the-numbers', { offset: -120, duration: 800 })}>
            The Numbers
          </Anchor>
          <Anchor
            role="button"
            onClick={() => scrollToAnchor('referring-urls', { offset: -120, duration: 1200 })}>
            Referring URLs
          </Anchor>
        </Anchors>
      </MenuTab>
      <Wrapper>
        <NumberBreakdowns
          eid={eid}
          colors={colors}
          numberOfPeopleRegistered={numberOfPeopleRegistered}
          companies={companies}
          professions={professions}
          groups={groups}
          totalGroupMembers={totalGroupMembers}
        />
        <ReferringUrls urlCount={urlCount} colors={colors} />
      </Wrapper>
    </ProtectedRoute>
  );
}

const MenuTab = styled(motion.div)`
  align-items: center;
  background: #00004e;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  left: 0;
  max-height: 60px;
  min-height: 60px;
  position: absolute;
  top: 5rem;
  width: 100%;
  span {
    color: #ffffff;
    font-family: noto-sans, tahoma, sans-serif;
    font-size: 1.25rem;
    grid-column: 1 / 6;
    line-height: 1.5rem;
    padding: 0 1rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    span {
      grid-column: 2 / 5;
      padding: 0;
    }
  }
`;

const Anchors = styled.div`
  display: none;
  @media only screen and (min-width: 1150px) {
    align-items: center;
    display: flex;
    gap: 3rem;
    grid-column: 6 / 12;
    justify-content: end;
  }
`;

const Anchor = styled.div`
  line-height: 3rem;
  font-size: 1rem;
  padding-top: 0.125rem;
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  color: white;
  font-family: noto-sans, tahoma, sans-serif;
  letter-spacing: 0.5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
  color: white-space;
  transition: 0.5s;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: white;
    width: auto;
    border-bottom: 2px solid transparent;
  }
  &:hover {
    border-bottom: 2px solid white;
  }
  &.active {
    border-bottom: 2px solid transparent;
  }
`;

const Wrapper = styled(motion.div)`
  align-items: center;
  color: #004d9a;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-column: 1 / 7;
  justify-content: center;
  margin: 9rem 0 2rem 0;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    gap: 6rem;
    grid-column: 1 / 13;
    margin: 12.5rem 0 4rem 0;
  }
`;

export const query = graphql`
  query ($eid: String!) {
    event: markdownRemark(frontmatter: { eid: { eq: $eid } }) {
      frontmatter {
        eid
        slug
        name
        colors {
          primary
          secondary
          tertiary
        }
      }
    }
  }
`;

export default EventAnalytics;
