import * as React from 'react';

function SvgBannerSvg6(props) {
  return (
    <svg viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={34.5} cy={34.5} r={34.5} fill="#36D1B7" />
    </svg>
  );
}

export default SvgBannerSvg6;
