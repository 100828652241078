import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Bauble } from 'assets/svgs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const Baubles = () => {
  const { mainBauble } = useStaticQuery(graphql`
    query {
      mainBauble: file(relativePath: { eq: "assets/images/event-9/main-bauble.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            width: 600
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `);

  return (
    <>
      <WhiteBauble
        initial={{ y: -300 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.75, delay: 1 }}>
        <Bauble />
      </WhiteBauble>
      <MainBauble>
        <GatsbyImage image={getImage(mainBauble)} alt="Bauble Image" />
      </MainBauble>
      <AquaBauble
        initial={{ y: -500 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.75, delay: 1.325 }}>
        <Bauble />
      </AquaBauble>
    </>
  );
};

const MainBauble = styled(motion.div)`
  height: auto;
  position: absolute !important;
  right: -3.75%;
  top: 55px;
  width: 40rem;

  @media screen and (min-width: 1300px) {
    right: 1.875%;
  }
`;

const AquaBauble = styled(motion.div)`
  position: absolute;
  right: 20px;
  top: -15px;
  z-index: 1;
  svg {
    width: 9.688rem;
    height: auto;
    path {
      fill: #89d4e3;
    }
  }
`;

const WhiteBauble = styled(motion.div)`
  position: absolute;
  right: 40%;
  top: 35px;
  z-index: 1;
  svg {
    width: 7.063rem;
    height: auto;
    path {
      fill: white;
    }
  }
`;

export default Baubles;
