import * as React from 'react';

function SvgDownArrow(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24.314 11.314L13 22.627 1.686 11.314" stroke="#36A9E1" strokeWidth={4} />
    </svg>
  );
}

export default SvgDownArrow;
