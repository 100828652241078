import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { MutedIcon } from 'assets/svgs';

function UnmuteOverlay({ handleUnmuteOverlayClick, colors, isStreamMuted }) {
  return (
    <AnimatePresence>
      {isStreamMuted && (
        <Container whileTap={{ scale: 0.95 }} onClick={() => handleUnmuteOverlayClick()} colors={colors}>
          <div>
            <StyledMutedIcon /> Click To Unmute
            <Shine />
          </div>
        </Container>
      )}
    </AnimatePresence>
  );
}

const Container = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: ${({ colors }) => colors.secondary};
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  overflow: hidden;
  text-transform: uppercase;
  top: calc(50% - 30px);
  left: calc(50% - 150px);
  width: 300px;
  height: 60px;

  @media screen and (min-width: 386px) {
    top: 10px;
  }

  @media screen and (min-width: 768px) {
    left: calc(50% - 200px);
    width: 400px;
    height: 80px;
  }

  > div {
    align-items: center;
    color: #fff;
    display: flex;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
  }
`;

const StyledMutedIcon = styled(MutedIcon)`
  margin-right: 0.95rem;
  width: 22px;
`;

const Shine = styled.div`
  animation: slide 1.5s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  width: 100%;
  z-index: 1;

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export default UnmuteOverlay;
