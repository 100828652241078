import * as React from 'react';

function SvgEventxLogo(props) {
  return (
    <svg viewBox="0 0 232 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32.404 0h3.061l11.227 27.98L57.96 0h3.062L48.35 30.958h-3.274L32.404 0zM111.456 4.252v26.706h-2.636V0h2.679l19.391 26.365V0h2.636v30.958h-2.594L111.456 4.252zM157.424 2.424h-10.12V0h22.92v2.424h-10.121v28.534h-2.679V2.424zM20.624.043H0v2.424h20.624V.043zM15.351 13.395H0v2.424h14.288l1.063-2.424zM20.624 28.576H0V31h20.624v-2.424zM94.999.043H74.374v2.424H95V.043zM89.725 13.395H74.374v2.424h14.288l1.063-2.424zM94.998 28.576H74.374V31h20.624v-2.424z"
        fill="#000"
      />
      <path
        d="M217.298 12.885l-14.075 18.03h3.317l10.758-13.778 10.844 13.82h3.317l-14.161-18.072z"
        fill="#EB0045"
      />
      <path
        d="M220.317 13.31L230.736 0h-3.402l-8.675 11.141 1.658 2.169zM215.98 11.141L207.262 0h-3.359l10.418 13.31 1.659-2.169z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgEventxLogo;
