const formatName = (name) => {
  const titleCaseName = name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/ +(?= )/g, '');
  const re = /(?:')/;
  if (titleCaseName.includes("'")) {
    const match = titleCaseName.match(re);
    const index = titleCaseName.indexOf(match[0]);
    const str2 =
      titleCaseName.slice(0, index - 1) +
      titleCaseName.charAt(index - 1).toUpperCase() +
      titleCaseName.slice(index, index + 1) +
      titleCaseName.charAt(index + 1).toUpperCase() +
      titleCaseName.slice(index + 2);
    return str2;
  }
  return titleCaseName;
};

export default formatName;
