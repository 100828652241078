import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import { breakpoints, gridContainer } from 'styles';
import { motion, AnimatePresence } from 'framer-motion';
// import { LeftArrow, RightArrow, FbIcon, TwitterIcon, LinkedinIcon, EmailIcon } from 'assets/svgs';
import Interweave from 'interweave';

export default ({ stickyNote, colors }) => {
  const thumbnail = getImage(stickyNote.thumb);
  const { videos } = stickyNote;

  return (
    <Backdrop>
      <Container colors={colors}>
        <BannerImg image={thumbnail} alt={stickyNote.name} />
        <div>
          <Title colors={colors}>{stickyNote.name}</Title>
          <Excerpt colors={colors}>{stickyNote.excerpt}</Excerpt>
          <Content colors={colors}>
            <Interweave content={stickyNote.content} allowElements />
          </Content>
          <Kiosk>
            {videos &&
              videos.map((item) => {
                const videoThumb = getImage(item.thumb);
                return (
                  <StickyNote key={item.id}>
                    <StickyImg image={videoThumb} alt={item.name} />
                    <Details colors={colors}>
                      <h3>{item.title}</h3>
                      <ReadMore colors={colors} href={item.url} target="blank">
                        View Video
                      </ReadMore>
                    </Details>
                  </StickyNote>
                );
              })}
          </Kiosk>
          <p>{stickyNote.footer}</p>
        </div>
      </Container>
    </Backdrop>
  );
};

const ReadMore = styled.a`
  width: 10rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  background: ${({ colors }) => colors.tertiary};
  border-radius: 50px;
  text-transform: uppercase;
  transition: 0.5s;
  margin-top: auto;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

const StickyImg = styled(GatsbyImage)`
  width: 100%;
  height: 250px;
`;

const Details = styled.div`
  width: 100%;
  height: auto;
  font-size: 1.25rem;
  padding: 1em 1.5em;
  h3 {
    color: ${({ colors }) => colors.secondary};
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 1.5em;
  }
`;

const StickyNote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  text-align: left;
  width: 100%;
  max-width: 100%;
`;

const Kiosk = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  margin-bottom: 3rem;
  img {
    max-width: 100%;
    object-position: top left !important;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: 0;
  h3 {
    color: #00004e;
    margin-bottom: 0.75em;
  }
  p {
    margin-bottom: 2.5rem;
    color: #00004e;
    line-height: 1.5em;
  }
  a {
    width: 10rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ colors }) => colors.tertiary};
    border-radius: 50px;
    text-transform: uppercase;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 2.5rem;
    button {
      background: transparent;
      color: white;
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    }
  }
  .video-embed {
    margin: 5rem auto;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

const Excerpt = styled.h4`
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2rem;
  max-width: 100%;
  color: #00004e;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 2rem;
  max-width: 100%;
  margin-bottom: 0.5rem;
  color: #00004e;
  text-align: left;
`;

const BannerImg = styled(GatsbyImage)`
  width: 100%;
  height: 250px;
  @media (min-width: 768px) {
    height: 420px;
  }
`;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1200px;
  max-width: 100%;
  padding-top: 1rem;
  > div {
    background: #ededed;
    padding: 1rem 1.5rem;
    max-width: 100%;
  }
  @media (min-width: 768px) {
    > div {
      padding: 3rem 5rem;
    }
  }
  @media (min-width: 1150px) {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  width: auto;
  height: auto;
  @media (max-width: 1150px) {
    overflow-x: hidden;
  }
`;
