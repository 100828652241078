import * as React from 'react';

function SvgButterflyWingLeft(props) {
  return (
    <svg viewBox="0 0 91 178" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.635 135.359l-13.268-26.64L.129 135.791l26.506-.432z"
        fill="url(#butterfly_wing_left_svg__paint0_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.433 155.211L.13 135.791l14.412 41.422 9.892-22.002z"
        fill="url(#butterfly_wing_left_svg__paint1_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.129 135.791l24.304 19.42 64.475-20.847-62.273.995-26.506.432z"
        fill="url(#butterfly_wing_left_svg__paint2_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.907 134.364l-64.474 20.847 18.918 22.39 45.556-43.237z"
        fill="url(#butterfly_wing_left_svg__paint3_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.351 177.601l-18.918-22.39-9.892 22.002 28.81.388z"
        fill="url(#butterfly_wing_left_svg__paint4_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.634 135.359l37.154-13.803-50.421-12.837 13.267 26.64z"
        fill="url(#butterfly_wing_left_svg__paint5_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.788 121.556l-37.154 13.803 62.273-.995-25.119-12.808z"
        fill="url(#butterfly_wing_left_svg__paint6_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.697 96.014l50.091 25.542 25.12 12.808-52.389-41.576-22.822 3.226z"
        fill="url(#butterfly_wing_left_svg__paint7_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.697 96.014l22.822-3.226L3.446 66.536l10.251 29.478z"
        fill="url(#butterfly_wing_left_svg__paint8_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.446 66.536l33.073 26.252 52.389 41.576L33.29 55.689 3.446 66.535z"
        fill="url(#butterfly_wing_left_svg__paint9_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.446 66.536L33.29 55.688 8.619 33.971 3.446 66.536z"
        fill="url(#butterfly_wing_left_svg__paint10_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.619 33.971L33.29 55.69 33.628.097 8.618 33.97z"
        fill="url(#butterfly_wing_left_svg__paint11_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.628.098l-.338 55.59 55.617 78.676-27.636-67.133L33.628.098z"
        fill="url(#butterfly_wing_left_svg__paint12_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.628.098L61.271 67.23 90.39 80.74 33.628.098z"
        fill="url(#butterfly_wing_left_svg__paint13_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.39 80.741L61.27 67.231l27.636 67.133 1.483-53.623z"
        fill="url(#butterfly_wing_left_svg__paint14_linear_7640_1278)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.635 135.359l-13.268-26.64L.129 135.791l26.506-.432z"
        fill="#00FF57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.433 155.211L.13 135.791l14.412 41.422 9.892-22.002z"
        fill="#0095FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.129 135.791l24.304 19.42 64.475-20.847-62.273.995-26.506.432z"
        fill="#DF00FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.907 134.364l-64.474 20.847 18.918 22.39 45.556-43.237z"
        fill="#0095FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.351 177.601l-18.918-22.39-9.892 22.002 28.81.388z"
        fill="#00FF57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.634 135.359l37.154-13.803-50.421-12.837 13.267 26.64z"
        fill="#DF00FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.788 121.556l-37.154 13.803 62.273-.995-25.119-12.808z"
        fill="#0095FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.697 96.014l50.091 25.542 25.12 12.808-52.389-41.576-22.822 3.226z"
        fill="#DF00FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.697 96.014l22.822-3.226L3.446 66.536l10.251 29.478z"
        fill="#0095FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.446 66.536l33.073 26.252 52.389 41.576L33.29 55.689 3.446 66.535z"
        fill="#00FF57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.446 66.536L33.29 55.688 8.619 33.971 3.446 66.536z"
        fill="#0095FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.619 33.971L33.29 55.69 33.628.097 8.618 33.97z"
        fill="#00FF57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.628.098l-.338 55.59 55.617 78.676-27.636-67.133L33.628.098z"
        fill="#DF00FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.628.098L61.271 67.23 90.39 80.74 33.628.098zM90.39 80.741L61.27 67.231l27.636 67.133 1.483-53.623z"
        fill="#0095FF"
      />
      <path
        style={{
          mixBlendMode: 'color'
        }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.628.098L8.618 33.97 3.446 66.536l10.252 29.478 50.091 25.542-50.421-12.837L.128 135.791l14.413 41.422 28.81.388 45.556-43.237 1.483-53.623L33.628.098z"
        fill="url(#butterfly_wing_left_svg__paint15_linear_7640_1278)"
      />
      <defs>
        <linearGradient
          id="butterfly_wing_left_svg__paint0_linear_7640_1278"
          x1={-1.411}
          y1={125.016}
          x2={24.636}
          y2={121.334}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint1_linear_7640_1278"
          x1={-807.115}
          y1={270.85}
          x2={24.159}
          y2={153.346}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint2_linear_7640_1278"
          x1={-808.543}
          y1={260.746}
          x2={88.826}
          y2={133.899}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint3_linear_7640_1278"
          x1={25.336}
          y1={161.673}
          x2={91.431}
          y2={152.33}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint4_linear_7640_1278"
          x1={13.401}
          y1={169.251}
          x2={41.599}
          y2={165.266}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint5_linear_7640_1278"
          x1={65.006}
          y1={474.142}
          x2={112.661}
          y2={467.406}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint6_linear_7640_1278"
          x1={26.556}
          y1={134.917}
          x2={87.747}
          y2={126.267}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint7_linear_7640_1278"
          x1={17.059}
          y1={119.948}
          x2={85.486}
          y2={110.275}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint8_linear_7640_1278"
          x1={5.571}
          y1={81.665}
          x2={34.357}
          y2={77.596}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint9_linear_7640_1278"
          x1={8.499}
          y1={102.38}
          x2={82.884}
          y2={91.865}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint10_linear_7640_1278"
          x1={1.221}
          y1={50.896}
          x2={31.977}
          y2={46.549}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint11_linear_7640_1278"
          x1={8.233}
          y1={31.4}
          x2={37.455}
          y2={27.269}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint12_linear_7640_1278"
          x1={35.408}
          y1={70.826}
          x2={79.038}
          y2={64.659}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint13_linear_7640_1278"
          x1={39.75}
          y1={43.505}
          x2={84.223}
          y2={37.219}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint14_linear_7640_1278"
          x1={66.169}
          y1={102.014}
          x2={92.849}
          y2={98.243}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="butterfly_wing_left_svg__paint15_linear_7640_1278"
          x1={-7.103}
          y1={8.106}
          x2={90.811}
          y2={208.073}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00FF57" />
          <stop offset={0.49} stopColor="#2E72FC" />
          <stop offset={1} stopColor="#DF00FE" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgButterflyWingLeft;
