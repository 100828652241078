import React from 'react';
import styled from 'styled-components';
import { Markup } from 'interweave';

const EmailNotVerifiedNotification = ({ colors, user }) => {
  return (
    <Wrapper colors={colors}>
      <Markup content={`Click the link in the email we just sent you to verify your email address, ${user?.email} and complete your registration.<br /> Can't find our email? Be sure to check your junk!`} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  animation: slideIn 750ms forwards;
  background-color: ${({ colors }) => colors.tertiary};
  bottom: -4.188rem;
  color: #fff;
  display: flex;
  font-weight: bold;
  height: auto;
  justify-content: center;
  left: 0;
  line-height: 1.325rem;
  letter-spacing: 0.05rem;
  opacity: 0;
  padding: 0.75rem 0.75rem 0.813rem;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 11;

  @keyframes slideIn {
    0% {
      bottom: -4.188rem;
      opacity: 0;
    }

    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export default EmailNotVerifiedNotification;
