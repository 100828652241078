import React from 'react';
import styled from 'styled-components';

export default ({ isPendingFilterSelected, setIsPendingFilterSelected }) => {
  return (
    <Filters
      onClick={(e) => {
        e.stopPropagation();
        setIsPendingFilterSelected(e.target.innerText === 'Pending');
      }}>
      <Filter type="button" selected={isPendingFilterSelected}>
        Pending
      </Filter>
      <Filter type="button" selected={!isPendingFilterSelected}>
        Approved
      </Filter>
    </Filters>
  );
};

const Filters = styled.div`
  align-self: flex-start;
  margin-bottom: 1.75rem;
`;

const Filter = styled.button`
  background-color: transparent;
  color: ${({ selected }) => (selected ? '#000' : '#808080')};
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: underline;

  &:first-of-type {
    margin-right: 1em;
  }

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;
