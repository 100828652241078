import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PollsAnswers as Answers, Options, Button, LoadingSpinner, ErrorMessage } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { TextInput } from 'components/Events/Livestream/Interaction/Polls';
import shortid from 'shortid';
import { ModalCloseIcon } from 'assets/svgs';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';

const initialQuestionState = () => ({
  text: '',
  error: null
});

const initialAnswersState = () => [
  {
    id: shortid.generate(),
    isCorrect: false,
    text: '',
    error: null
  },
  {
    id: shortid.generate(),
    isCorrect: false,
    text: '',
    error: null
  }
];

const CreateOrEditPollModal = ({
  colors,
  editPoll,
  setEditPoll,
  setIsCreateOrEditPollModalOpen
}) => {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [question, setQuestion] = useState(editPoll?.question || initialQuestionState());
  const [answers, setAnswers] = useState(editPoll?.answers || initialAnswersState());
  const [pollStatus, setPollStatus] = useState('');
  const [isMarkCorrectAnswersEnabled, setIsMarkCorrectAnswersEnabled] = useState(
    editPoll?.answers.some((answer) => answer.isCorrect) || false
  );
  const [allowMultipleAnswers, setAllowMultipleAnswers] = useState(
    editPoll?.multipleAnswers.allowed || false
  );
  const [numberOfMultipleAnswersAllowed, setNumberOfMultipleAnswersAllowed] = useState(
    editPoll?.multipleAnswers.allowed ? editPoll.multipleAnswers.max : 'All'
  );
  const [enableTimer, setEnableTimer] = useState(editPoll?.timer.enabled || false);
  const [timerSeconds, setTimerSeconds] = useState(
    editPoll?.timer.enabled ? editPoll.timer.seconds : 20
  );

  useEffect(() => () => editPoll && setEditPoll(null), [editPoll]);

  useEffect(() => {
    if (question.error?.code === 'missing-question' && question !== '') {
      setQuestion((currentState) => ({
        ...currentState,
        error: null
      }));
    }
  }, [question.text]);

  useEffect(() => {
    answers.forEach((answer, i) => {
      if (answer.error?.code === 'missing-answer' && answer.text) {
        setAnswers((currentState) =>
          currentState.map((_answer, j) =>
            i === j
              ? {
                  ..._answer,
                  error: null
                }
              : _answer
          )
        );
      }
    });
  }, [answers]);

  const handleQuestionTextChange = ({ target }) =>
    setQuestion((currentState) => ({
      ...currentState,
      text: target.value
    }));

  const handleAnswerTextChange = (e, answerId) => {
    const { value } = e.target;

    const allAnswerIds = answers.map((answer) => answer.id);

    const lastFieldIsFocused = answerId === allAnswerIds[allAnswerIds.length - 1];

    // Adds an extra answer field when user starts typing in the last answer field.
    if (lastFieldIsFocused && value !== '') {
      setAnswers((currentState) => [
        ...currentState,
        {
          id: shortid.generate(),
          isCorrect: false,
          text: '',
          error: null
        }
      ]);
    }

    setAnswers((currentState) =>
      currentState.map((answer) => {
        if (answer.id === answerId) {
          answer.text = value;
        }
        return answer;
      })
    );
  };

  const handleMarkCorrectAnswersOption = () => {
    setIsMarkCorrectAnswersEnabled((currentState) => !currentState);
    if (answers.filter((answer) => answer.isCorrect).length > 0) {
      setAnswers((currentState) =>
        currentState.map((answer) => ({
          ...answer,
          isCorrect: false
        }))
      );
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!question.text) {
      setQuestion((currentState) => ({
        ...currentState,
        error: {
          code: 'missing-question',
          message: 'Text is required'
        }
      }));
    }

    const emptyAnswerFields = [];

    answers
      .map(({ text }) => text)
      .forEach((answerText, i, arr) => {
        if (answerText === '' && (arr.length <= 2 || (arr.length > 2 && i !== arr.length - 1))) {
          setAnswers((currentState) =>
            currentState.map((answer, j) =>
              i === j
                ? {
                    ...answer,
                    error: {
                      code: 'missing-answer',
                      message: 'Text is required'
                    }
                  }
                : answer
            )
          );
          emptyAnswerFields.push(answerText);
        }
      });

    if (
      question.text &&
      !emptyAnswerFields.length &&
      (!isMarkCorrectAnswersEnabled ||
        (isMarkCorrectAnswersEnabled && answers.filter((answer) => answer.isCorrect).length > 0))
    ) {
      try {
        setPollStatus('saving');

        const poll = {
          type: 'general poll',
          question,
          answers: answers.filter((answer) => answer.text),
          multipleAnswers: {
            allowed: allowMultipleAnswers,
            max:
              allowMultipleAnswers && numberOfMultipleAnswersAllowed === 'All'
                ? answers.filter((answer) => answer.text).length
                : allowMultipleAnswers && numberOfMultipleAnswersAllowed !== 'All'
                ? numberOfMultipleAnswersAllowed
                : null
          },
          timer: {
            enabled: enableTimer,
            seconds: enableTimer ? timerSeconds : null
          },
          isQueued: editPoll ? editPoll.isQueued : true,
          isOpen: editPoll ? editPoll.isOpen : false,
          shareAnalytics: false
        };

        if (editPoll) {
          await firebase.editPoll({
            eid: selectedEvent.eid,
            poll,
            pid: editPoll.pid
          });
        } else {
          await firebase.saveNewPoll({ eid: selectedEvent.eid, poll });
        }

        setIsCreateOrEditPollModalOpen(false);
      } catch (error) {
        console.error(error);
        setPollStatus('error');
      }
    }
  };

  return (
    <Underlay
      colors={colors}
      data-underlay
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true') {
          setIsCreateOrEditPollModalOpen(false);
        }
      }}>
      <Modal colors={colors}>
        <div onClick={() => setIsCreateOrEditPollModalOpen(false)} role="button" tabIndex={0}>
          <ModalCloseIcon width="12" height="12" style={{ top: '20px', right: '20px' }} />
        </div>
        <form onSubmit={handleSave}>
          <div style={{ position: 'relative' }}>
            <QuestionTextInput
              error={question.error?.code === 'missing-question'}
              name="question"
              onChange={handleQuestionTextChange}
              placeholder="What would you like to ask?"
              selectedEvent={selectedEvent}
              type="text"
              value={question.text}
            />
            <ErrorMessage
              errorMessage={question.error?.code === 'missing-question' && question.error.message}
              style={{
                color: '#F55151',
                fontSize: '0.75rem',
                position: 'absolute',
                top: '2.2rem'
              }}
              variants={fadeInAndOutAndAnimateHeightVariants()}
            />
          </div>
          <Answers
            answers={answers}
            handleAnswerTextChange={handleAnswerTextChange}
            isMarkCorrectAnswersEnabled={isMarkCorrectAnswersEnabled}
            selectedEvent={selectedEvent}
            setAnswers={setAnswers}
          />
          <Options
            answers={answers}
            isMarkCorrectAnswersEnabled={isMarkCorrectAnswersEnabled}
            handleMarkCorrectAnswersOption={handleMarkCorrectAnswersOption}
            allowMultipleAnswers={allowMultipleAnswers}
            setAllowMultipleAnswers={setAllowMultipleAnswers}
            numberOfMultipleAnswersAllowed={numberOfMultipleAnswersAllowed}
            setNumberOfMultipleAnswersAllowed={setNumberOfMultipleAnswersAllowed}
            enableTimer={enableTimer}
            setEnableTimer={setEnableTimer}
            timerSeconds={timerSeconds}
            setTimerSeconds={setTimerSeconds}
          />
          <Button
            colors={selectedEvent.colors}
            type="submit"
            whileHover={{
              backgroundColor: selectedEvent.colors.secondary
            }}
            whileTap={{
              scale: 0.95
            }}
            style={{
              alignSelf: 'flex-end',
              backgroundColor: selectedEvent.colors.secondary,
              borderRadius: '0.125rem',
              height: '2.5rem',
              marginTop: '0.45rem',
              width: '5rem'
            }}>
            {pollStatus === 'saving' ? (
              <LoadingSpinner style={{ width: '1.625rem', color: '#fff' }} />
            ) : (
              'Save'
            )}
          </Button>
        </form>
      </Modal>
    </Underlay>
  );
};

const Underlay = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 3.95rem auto 0;
  max-width: 23.75rem;
  padding: 1.25rem 1.875rem;
  position: relative;

  /* Close Modal Icon */
  > div {
    align-items: center;
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -2.8rem;
    width: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
  }
`;

const QuestionTextInput = styled(TextInput)`
  height: 31px;
  margin-bottom: 28px;
`;

export default CreateOrEditPollModal;
