import styled from 'styled-components';

const TextInput = styled.input`
  border: none;
  border-bottom-color: ${({ error }) => (error ? '#F55151' : '#bdbdbd')};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4f4f4f;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  line-height: 20px;
  outline: none;
  transition: border-bottom-color 100ms ease-in-out;
  width: 100%;

  &::placeholder {
    color: #c4c4c4;
    font-style: italic;
  }

  &:focus {
    border-bottom-color: ${({ selectedEvent, error }) =>
      error ? '#F55151' : selectedEvent?.colors.secondary};
  }
`;

export default TextInput;