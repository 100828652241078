import React, { useContext } from 'react';
import styled from 'styled-components';
import { EnitiativesLogo, AgencyxLogo } from 'assets/svgs';
import { LocalContext } from 'context';
import { Link } from 'gatsby';

export default () => {
  const { contactRef } = useContext(LocalContext);
  return (
    <Footer ref={contactRef} id="contact">
      {/* <Container>
        <div>
          <EnitiativesLogo
            style={{
              width: '300px'
            }}
          />
          <p>
            Brought to you by the Pfizer HBU e-nitiatives program 20/21
            <br />
            in conjunction with Agency X.
          </p>
          <a href="https://www.agencyx.ie/" target="__blank">
            <AgencyxLogo
              style={{
                width: '200px'
              }}
            />
          </a>
        </div>
        <div />
      </Container> */}
      <Copyright>
        <div>
          <p>
            Platform Copyright © 2023 - Designed and Developed by Agency X<br />
          </p>
          <p>
            <a href="/pdfs/Terms-Of-Use-Pfizer-Virtual-Cafe.pdf" target="blank">
              Terms & Conditions
            </a>
            <a href="https://privacycenter.pfizer.com/en/ireland" target="blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </Copyright>
    </Footer>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: start;
  padding: 3rem 1rem;
  > div {
    grid-column: 1/7;
    justify-content: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    svg {
      width: 12.5rem;
      margin-bottom: 2rem;
      :last-of-type {
        width: 8rem;
      }
    }
    h5 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    p {
      margin: 0.1rem 0 2.15rem;
    }

    a {
      color: #000;
      text-decoration: underline;
    }

    @media (min-width: 1150px) {
      :first-child {
        grid-column: 2/8;
      }
      :last-child {
        grid-column: 10/12;
        justify-content: end;
      }
    }
  }

  @media (min-width: 1150px) {
    flex-direction: row;
    padding: 3rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Copyright = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  background: #c4c4c4;

  @media (min-width: 1150px) {
    padding: 1rem 0;
  }

  > div {
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    p {
      margin: 1rem 0;
      grid-column: 1/7;
      color: #3c3c3c;
      font-size: 0.875rem;

      &:first-of-type > span {
        color: #3c3c3c;
      }

      a {
        margin-right: 3rem;
        color: #3c3c3c;
      }
    }
    @media (min-width: 1150px) {
      grid-template-columns: repeat(12, 1fr);
      p {
        &:first-child {
          grid-column: 2/7;
        }
        &:last-child {
          grid-column: 8/12;
          text-align: right;
        }
      }
    }
  }
`;

const Footer = styled.footer`
  width: 100%;
  background: #ededed;
`;
