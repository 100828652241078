import * as React from 'react';
import { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Arrow } from 'assets/svgs';
import Answer from '../Answer/index.jsx';

const faqs = [
  {
    question: 'How do I register?',
    answer:
      'Simply go to the register here button  and complete the details required, you will need your email address and your full name for verification. You will then be asked to set a password for admission to the event site.'
  },
  {
    question: 'Is there a fee for registration?',
    answer: 'No this event is sponsored by Agency X as a service to entertainment and education!'
  },
  {
    question: 'How do I make a donation?',
    answer:
      'Please click on the Mark a Donation button , choose a charity of your choice and make a donation to that charity as you wish.'
  },
  {
    question: 'Is there a networking opportunity at the event?',
    answer:
      'Yes, the event includes a live chat function for registered guests, the opportunity to ask questions of the speakers and also to link in with all other attendees.'
  }
];

const Accordion = ({ i, expanded, setExpanded, question, answer, colors }) => {
  const isOpen = i === expanded;
  const { theme } = useContext(LocalContext);

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <Question
        colors={colors}
        open={isOpen}
        theme={theme}

        onClick={() => setExpanded(isOpen ? false : i)}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {question}
          <Arrow/>
        </div>
      </Question>
      <AnimatePresence initial={false}>
        {isOpen && (
          <AnswerText
          colors={colors}
            theme={theme}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}>
            <Answer answer={answer} />
          </AnswerText>
        )}
      </AnimatePresence>
    </>
  );
};

export default ({ colors }) => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(false);

  return faqs.map((faq, i) => (
    <Accordion
      colors={colors}
      i={i}
      key={i}
      expanded={expanded}
      setExpanded={setExpanded}
      question={faq.question}
      answer={faq.answer}
    />
  ));
};

const Question = styled(motion.div)`
margin:1rem 0;
width:100%;
  padding: 1.5rem;
  background-color: #f9f9f9;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
  div {
    color: ${({colors}) => colors.secondary};
    svg {
      transition:.3s;
      min-width: 1rem;
      height: 1rem;
      transform: ${({open}) => open ? 'scaleY(-1)' : 'none'};
      path {
        stroke: #BDBDBD;
      }
    }
  }
`;

const AnswerText = styled(motion.section)`
  width:100%;
  background: #E6F4FF;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top:-1rem;
  color: #3c3c3c;
  p{
    margin-bottom:0!important;
  }
`;
