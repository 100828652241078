import { useState, useEffect } from 'react';

function RenderWhenReady({ children }) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => setHasMounted(true), []);

  if (!hasMounted) {
    return null;
  }

  return children;
}

export default RenderWhenReady;