import React, { useState, useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  Navbar,
  EmailNotVerifiedNotification,
  Modal,
  AccountDeleted,
  EmailHandler
} from 'components';
import { navigate, Link } from 'gatsby';
import { LocalContext } from 'context';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { useWindowSize } from 'hooks';
import { FirebaseContext, useAuth } from 'firebase';
import { pfizer } from 'styles';
import '../assets/fonts/fonts.css';

const GlobalStyles = createGlobalStyle`
  * {
      margin:0;
      padding:0;
      box-sizing:border-box;
      font-family: noto-sans, tahoma, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html{
    font-size: ${({ font }) => font};
    scroll-behavior: smooth;
  }

  body {
    background: ${({ theme }) => (theme.className === 'contrast' ? 'black' : 'white')};
    overflow-x:hidden;
  }

  a{
    text-decoration: none;
  }

  button{
    border:none;
    outline:none;
    &:focus{
        outline:none;
        border:none;
    }
  }
  .scroll-lock {
    overflow: hidden;
  }

  .password-mask-input {
    align-items: center;
    background: transparent;
    border: 1px solid ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
    color: ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    height: 3.75rem;
    letter-spacing: 0.5px;
    padding: 0px 1.25rem;

    &:focus {
      border: 3px solid ${({ invert }) => (invert ? '#3c3c3c' : '#fff')} !important;
      border-radius: none !important;
      outline: none !important;
    }

    ::placeholder {
      color: ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
      font-size: 1rem;
      font-style: italic;
      font-weight: 600;
    }
  }

  .password-mask-button {
    align-items: center;
    color: #fff !important;
    display: flex;
    font-family: noto-sans !important;
    font-size: 1rem !important;
    font-style: italic !important;
    font-weight: bold !important;
    height: 1.750rem !important;
    justify-content: center;
    padding: 0.25rem 0.625rem 0.325rem !important;
    right: 0.938rem !important;
    top: 1.85rem !important;
    width: 3.9rem !important;
  }

  .password-mask-button-hide {
    display: none;
  }

  /* Address autocomplete dropdown style overrides */
  .pac-container {
    background: ${({ selectedEvent, invert }) => (invert ? '#fff' : selectedEvent?.colors.primary)};
    border: 0.063rem solid ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
    border-top-width: 0;
    border-radius: 0;
    box-shadow: none;

    @media screen and (min-width: 1439px) {
      max-width: 427px !important;
      width: 100% !important;
    }
  }

  .pac-target-input {
    border: 0.063rem solid ${({ invert }) => (invert ? '#3c3c3c !important' : '#fff !important')};
    color: ${({ invert }) => (invert ? '#3c3c3c !important' : '#fff !important')};
    outline: none;
    width: 100%;
  }

  .pac-target-input::placeholder {
    color: ${({ invert }) => (invert ? '#3c3c3c !important' : '#fff !important')};
  }

  .pac-target-input:focus {
    border: 0.188rem solid ${({ invert }) => (invert ? '#3c3c3c !important' : '#fff !important')};
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    color: ${({ invert }) => (invert ? '#3c3c3c !important' : '#fff !important')};
  }

  .pac-item {
    color: ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
    background-color: transparent;
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.031rem;
    padding: 0.188rem 1.25rem 0;
    border: none;
    height: 2.3rem;
  }

  .pac-item:hover {
    color: #fff;
    background-color: ${({ selectedEvent, invert }) =>
      invert ? pfizer.secondary : selectedEvent?.colors.secondary};
    cursor: pointer;
  }

  .pac-item:hover * {
    color: #fff;
  }

  .pac-logo {
    box-sizing: content-box;
    border: 0.188rem solid ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
    border-top: none;

    @media screen and (min-width: 1440px) {
      box-sizing: border-box;
    }
  }

  .pac-logo:after {
    padding: 0;
    height: 0;
  }

  .hdpi.pac-logo:after {
    padding: 0;
    height: 0;
  }

  .pac-icon,
  .hdpi .pac-icon {
    display: none;
  }

  .pac-item-query {
    color: ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
    font-size: 1rem;
  }

  .pac-item-query:after {
    content: ','
  }

  .pac-selected {
    background-color: ${({ selectedEvent, invert }) =>
      invert ? pfizer.secondary : selectedEvent?.colors.tertiary};
  }
`;

const Layout = ({ children }) => {
  const {
    theme,
    fontSizing,
    selectedEvent,
    showUserAccountDeletedModal,
    setShowUserAccountDeletedModal
  } = useContext(LocalContext);
  const { user, firebase, loading } = useAuth();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { mode } = queryParams;
  const colors = selectedEvent ? selectedEvent.colors : pfizer;
  const [eventSlugGatingEnabled, setEventSlugGatingEnabled] = useState(true);
  const { pathname } = location;

  useEffect(() => {
    let unsubscribeToEventSlugGatingEnabled;

    if (firebase && selectedEvent) {
      unsubscribeToEventSlugGatingEnabled = firebase.subscribeToEventSlugGatingEnabled({
        eid: selectedEvent.eid,
        onSnapshot: (snapshot) => {
          if (snapshot.exists() && snapshot.key) {
            const _eventSlugGatingEnabled = snapshot.val();

            if (_eventSlugGatingEnabled !== eventSlugGatingEnabled) {
              setEventSlugGatingEnabled(_eventSlugGatingEnabled);
            }
          }
        }
      });
      return () => {
        if (unsubscribeToEventSlugGatingEnabled) {
          unsubscribeToEventSlugGatingEnabled.off();
        }
      };
    }
  }, [firebase, selectedEvent]);

  useEffect(() => {
    const eventSlug = JSON.parse(window.localStorage.getItem('pfizerVirtualCafeEventSlug'))?.data;

    if (
      eventSlugGatingEnabled &&
      eventSlug &&
      !pathname.includes(eventSlug) &&
      !pathname.includes('/admin') &&
      !pathname.includes('/moderation') &&
      !pathname.includes('/event-analytics') &&
      !pathname.includes('/pdfs/Terms-Of-Use-Pfizer-Virtual-Cafe_Oct_GA.pdf') &&
      !pathname.includes('/pdfs/Terms-Of-Use-Pfizer-Virtual-Cafe.pdf')
    ) {
      navigate(`/events/${eventSlug}/`, { replace: true });
    }
  }, [pathname, selectedEvent, eventSlugGatingEnabled]);

  useEffect(() => {
    let unsubscribeToRTDBServer;

    // Sometimes the user object can initialise briefly with the user data from Firebase Auth but
    // not yet the user data from Firestore, so to prevent any errors here we check for the uid
    // property on the user object, to make sure it has the Firestore data. If we don't do this then
    // the code may try to update 'presence' on a document that doesn't exist yet, resulting in an
    // error.
    if (!loading && firebase && user?.uid) {
      unsubscribeToRTDBServer = firebase.subscribeToRTDBServer({
        onSnapshot: (snapshot) => {
          if (snapshot?.val()) {
            return firebase.updateUserPresence({
              user,
              eid: selectedEvent?.eid ?? ''
            });
          }
        }
      });
    }
    return () => {
      if (unsubscribeToRTDBServer) {
        unsubscribeToRTDBServer();
      }
    };
    // We're using user?.uid in the dependency array here - instead of just user - to avoid getting
    // into an infinite loop each time presence is updated on the user object.
  }, [loading, firebase, user?.uid, selectedEvent?.eid]);

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <Wrapper theme={theme}>
        <GlobalStyles
          theme={theme}
          font={fontSizing}
          selectedEvent={selectedEvent}
          invert={location.pathname === '/'}
        />
        <EmailHandler />
        <Navbar colors={colors} />
        {children}
        {!mode && user && !user.emailVerified && (
          <EmailNotVerifiedNotification colors={colors} user={user} />
        )}
        <Modal
          hideCloseButton
          openWithoutTrigger={showUserAccountDeletedModal}
          modalContent={
            <AccountDeleted
              setShowUserAccountDeletedModal={setShowUserAccountDeletedModal}
              onClickOutside={() => setShowUserAccountDeletedModal(false)}
            />
          }
        />
      </Wrapper>
    </FirebaseContext.Provider>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
`;

export default Layout;
