import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { Add } from 'assets/svgs';
// import ICalendarLink from 'react-icalendar-link';
import { evolve } from 'styles';

export default ({
  colors,
  contrast,
  transparent,
  calendarLink,
  eventTitle,
  calendarDescription,
  eventDescription,
  startTime,
  endTime,
  location,
  style

}) => {
  // const event = {
  //   title: eventTitle.replace('<br />', ' '),
  //   description: calendarDescription || eventDescription || eventTitle,
  //   location,
  //   startTime: `${startTime}`,
  //   endTime: `${endTime}`
  // };
  return (
    <StyledDateButton
      whileHover={{ scale: 1.025 }}
      whileTap={{ scale: 0.95 }}
      width="320px"
      colors={colors}
      transparent={transparent}
      contrast={contrast}
      style={style}>
      <AddIcon colors={colors} />
      <a href={calendarLink} target="_blank" rel="noreferrer">
        Add Date To Calendar
      </a>
    </StyledDateButton>
  );
};

const StyledDateButton = styled(motion.div)`
  align-items: center;
  background: transparent;
  border-radius: 30px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
/* left: -0.5rem;   */
  padding: 1rem 1rem 1rem 0;
  position: relative;
  width: 280px;
  z-index: 2;
  ${({ style }) => style};

  a {
    align-items: center;
    background-color: transparent;
    color: ${({ colors, contrast }) => (contrast ? colors.secondary : colors.secondary)};
    cursor: pointer;
    display: flex;
    font-family: noto-sans, tahoma, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    justify-content: flex-start;
    list-style: none;
    margin: 0.5rem 0 0.5rem 0.75rem;
    text-transform: uppercase;
    transition: 0.5s;
    width: ${(props) => (props.width ? props.width : '100%')};
  }

  svg {
    circle {
      fill: ${({ contrast, colors }) => (contrast ? colors.secondary : colors.secondary)};
    }
    path {
      stroke: #fff;
    }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const AddIcon = styled(Add)`
  width: 1.5rem;
  height: 3rem;
  margin: 0 !important;
`;
