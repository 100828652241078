import * as React from 'react';

function SvgFallenSnow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 251" {...props}>
      <linearGradient
        id="fallen-snow_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={686.744}
        y1={677.484}
        x2={719.757}
        y2={69.414}
        gradientTransform="matrix(1 0 0 -1 0 252)">
        <stop offset={0} stopColor="#00b9f2" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M0 251.7h1440V111.1C879-168.5 818 166.6 0 187.9v63.8z"
        fill="url(#fallen-snow_svg__a)"
      />
    </svg>
  );
}

export default SvgFallenSnow;
