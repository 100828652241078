import React, { useContext } from 'react';
import styled from 'styled-components';
import { AgencyxLogo, PfizerMedicalAffairsBlue, Logo, PaxlovidLogo } from 'assets/svgs';
import { LocalContext } from 'context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';

export default ({ eventJobCode, dateOfPrep, eventFooterText, eventFooterLogo, eid }) => {
  const { contactRef } = useContext(LocalContext);
  return (
    <Footer ref={contactRef} id="contact">
      <Container>
        <div>
          <div
            style={{
              width: '12.5rem'
            }}>
            {eid === 'eIj0KJ' ? (
              <PfizerMedicalAffairsBlue style={{ width: '100%', marginBottom: '1.25rem' }} />
            ) : (
              <Logo style={{ width: '100%', marginBottom: '1.25rem' }} />
            )}
          </div>
          <p>
            <Markup content={eventFooterText} />
          </p>
          <a href="https://www.agencyx.ie/" target="__blank">
            <AgencyxLogo
              style={{
                width: '200px'
              }}
            />
          </a>
        </div>
        {eid === 'eIj0KJ' && eventFooterLogo && (
          <EventLogo
            image={getImage(eventFooterLogo)}
            alt="Event Department Logo"
            style={{ maxWidth: '312px', alignSelf: 'start', marginLeft: '-1rem' }}
          />
        )}
        {eid === 'j3vd74' && (
          <PaxlovidFooterSection>
          <PaxlovidLogo />
          <a href="https://www.agencyx.ie/privacy-policy/" target="_blank" rel="noreferrer">
              Please see the Agency X Privacy Notice
            </a>
          </PaxlovidFooterSection>
        )}
      </Container>
      <Copyright>
        <div>
          <p>Platform Copyright © 2023 - Designed and Developed by Agency X</p>
          <p>
            {eventJobCode && dateOfPrep && `${eventJobCode}  ·  Date of preparation: ${dateOfPrep}`}
          </p>
          <p>
            <a
              href={
                eid === 'eIj0KJ'
                  ? '/pdfs/Terms-Of-Use-Pfizer-Virtual-Cafe_Oct_GA.pdf'
                  : '/pdfs/Terms-Of-Use-Pfizer-Virtual-Cafe.pdf'
              }
              rel="noreferrer"
              target="_blank">
              Terms & Conditions
            </a>
            <a href="https://privacycenter.pfizer.com/en/ireland" target="blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </Copyright>
    </Footer>
  );
};

const EventLogo = styled(GatsbyImage)`
  width: 12.5rem;
  height: auto;
  z-index: 1;
  margin-bottom: 1.25rem;
  img {
    object-fit: contain !important;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  padding: 3rem 1rem;
  > div:nth-of-type(1) {
    grid-column: 1/7;
    justify-content: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    svg {
      width: 12.5rem;
      margin-bottom: 2rem;
      :last-of-type {
        width: 8rem;
      }
    }
    h5 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    p {
      margin: 0.1rem 0 2.15rem;
      color: #3c3c3c;
    }

    a {
      color: #000;
      text-decoration: underline;
    }

    @media (min-width: 1150px) {
      :first-child {
        grid-column: 2/5;
        margin-right: 20px;
      }

      svg {
        margin-bottom: 0;
      }
      /* :last-child {
        grid-column: 10/12;
        justify-content: end;
      } */
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    grid-column: 1/7;
    a {
      color: #3c3c3c;
      font-size: 1rem;
      font-weight: 400;
      display: inline;
      text-decoration: underline;
      text-underline-position: under;
    }

    p {
      max-width: 324px;
    }

    @media screen and (min-width: 1150px) {
      grid-row: 1/2;
      grid-column: 6/12;
      margin-right: 32px;
      margin-left: unset;
      justify-self: end;
      text-align: right;
      p {
        align-self: flex-end;
      }
    }
  }

  @media (min-width: 1150px) {
    flex-direction: row;
    padding: 3rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Copyright = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  background: rgba(189, 189, 189, 0.3);
  font-family: 'Noto Sans', sans-serif;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  p {
    color: #3c3c3c;
    font-size: 0.75rem;
    strong {
      font-weight: 400;
    }
    &:first-of-type > span {
      color: #3c3c3c;
    }
    &:last-of-type {
      margin-top: 1rem;
    }
    a {
      color: #3c3c3c;
      &:not(last-of-type) {
        margin-right: 2rem;
      }
    }
  }
  > div {
    align-items: left;
    display: flex;
    flex-direction: column;
    grid-column: 1/7;
    @media (min-width: 1150px) {
      align-items: center;
      flex-direction: row;
      grid-column: 2/12;
      justify-content: space-between;
    }
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    padding: 1rem 0;
    p {
      &:last-of-type {
        margin-top: 0rem;
      }
    }
  }
`;

const PaxlovidFooterSection = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: space-between;
  svg {
    max-width: 300px;
  }
  @media (min-width: 1150px) {
    svg {
      align-self: end;
    }
    a {
      padding-bottom: 1rem;
    }
  }
`;

const Footer = styled.footer`
  width: 100%;
  background: #fff;
`;
