import * as React from 'react';

function SvgStar(props) {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.17 1.13L7.233 4.55c.026.073.087.128.165.128h3.423c.175 0 .244.228.105.338l-2.77 2.112a.188.188 0 00-.061.21l1.063 3.419c.052.173-.131.31-.27.21L6.1 8.846a.176.176 0 00-.209 0l-2.77 2.11c-.14.11-.322-.036-.27-.21l1.063-3.418a.197.197 0 00-.061-.21L1.074 5.015c-.14-.11-.07-.338.105-.338h3.423a.171.171 0 00.165-.128L5.83 1.13a.176.176 0 01.34 0z"
        stroke={props.stroke}
      />
    </svg>
  );
}

export default SvgStar;
