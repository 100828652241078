import React, { createContext, useState, useEffect, useRef } from 'react';
import { useWindowSize } from 'hooks';

const LocalContext = createContext();

const themes = {
  default: {
    className: 'default',
    fg: '#36D1B7',
    bg: '#004D9A',
    muted: '#007FC0',
    bgLight: '#EAF5F9',
    textColor: '#004D9A',
    textMuted: '#007FC0',
    contrastTextColor: 'white'
  },
  contrast: {
    className: 'contrast',
    fg: 'yellow',
    bg: 'black',
    muted: 'rgb(50,50,50)',
    bgLight: 'black',
    textColor: 'yellow',
    textMuted: 'yellow',
    contrastTextColor: 'yellow'
  },
  grayscale: {
    className: 'grayscale',
    fg: '#808080',
    bg: '#282828',
    muted: '#787878',
    bgLight: '#F2F2F2',
    textColor: '#282828',
    contrastTextColor: 'white'
  }
};

const fontSizes = {
  default: '16px',
  large: '18px',
  largest: '20px'
};

const LocalContextProvider = (props) => {
  const [theme, setTheme] = useState(themes.default);
  const [fontSizing, setFontSizing] = useState(fontSizes.default);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showUserAccountDeletedModal, setShowUserAccountDeletedModal] = useState(false);
  const [showGroupWatchingDialog, setShowGroupWatchingDialog] = useState(false);
  const [browserTabIsActive, setBrowserTabIsActive] = useState(true);
  const headerRef = useRef(null);
  const agendaRef = useRef(null);
  const contactRef = useRef(null);

  const dialogAnimationTime = 300;

  const scrollToRef = (ref) =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  const { windowWidth } = useWindowSize();

  const [isMobile, setIsMobile] = useState(true);

  const handleDialog = ({ dialogRef, animation, stateHandler, callback }) => {
    dialogRef.current.setAttribute('data-state', animation);
    dialogRef.current.children[0].setAttribute('data-state', animation);

    stateHandler();

    if (callback) {
      setTimeout(() => callback(), dialogAnimationTime);
    }
  };

  useEffect(() => {
    const localTheme = JSON.parse(localStorage.getItem('theme'));
    localTheme != null
      ? localTheme.className === 'default'
        ? setTheme(themes.default)
        : localTheme.className === 'contrast'
        ? setTheme(themes.contrast)
        : localTheme.className === 'grayscale'
        ? setTheme(themes.grayscale)
        : setTheme(themes.default)
      : setTheme(themes.default);

    const localFontSize = JSON.parse(localStorage.getItem('fontSizing'));
    localTheme != null
      ? localFontSize === '100%'
        ? setFontSizing(fontSizes.default)
        : localFontSize === '125%'
        ? setFontSizing(fontSizes.large)
        : localFontSize === '150%'
        ? setFontSizing(fontSizes.largest)
        : setFontSizing(fontSizes.default)
      : setFontSizing(fontSizes.default);

    windowWidth >= 1150 ? setIsMobile(false) : setIsMobile(true);
  }, [isMobile, windowWidth]);

  const toggleTheme = (value) => {
    localStorage.setItem(
      'theme',
      JSON.stringify(
        value === 'default'
          ? themes.default
          : value === 'contrast'
          ? themes.contrast
          : value === 'grayscale'
          ? themes.grayscale
          : themes.default
      )
    );
    setTheme(
      value === 'default'
        ? themes.default
        : value === 'contrast'
        ? themes.contrast
        : value === 'grayscale'
        ? themes.grayscale
        : themes.default
    );
  };

  const toggleFontSizing = (value) => {
    localStorage.setItem(
      'fontSizing',
      JSON.stringify(
        value === 'default'
          ? fontSizes.default
          : value === 'large'
          ? fontSizes.large
          : value === 'largest'
          ? fontSizes.largest
          : fontSizes.default
      )
    );
    setFontSizing(
      value === 'default'
        ? fontSizes.default
        : value === 'large'
        ? fontSizes.large
        : value === 'largest'
        ? fontSizes.largest
        : fontSizes.default
    );
  };

  const handleBrowserTabActivity = (forcedFlag) => {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setBrowserTabIsActive(true) : setBrowserTabIsActive(false);
    }
    return document.hidden ? setBrowserTabIsActive(false) : setBrowserTabIsActive(true);
  };

  useEffect(() => {
    const handleBrowserTabActivityFalse = () => handleBrowserTabActivity(false);
    const handleBrowserTabActivityTrue = () => handleBrowserTabActivity(true);

    document.addEventListener('visibilitychange', handleBrowserTabActivity);
    document.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('focus', handleBrowserTabActivityTrue);
    document.addEventListener('focus', handleBrowserTabActivityTrue);

    return () => {
      window.removeEventListener('blur', handleBrowserTabActivity);
      document.removeEventListener('blur', handleBrowserTabActivityFalse);
      window.removeEventListener('focus', handleBrowserTabActivityFalse);
      document.removeEventListener('focus', handleBrowserTabActivityTrue);
      document.removeEventListener('visibilitychange', handleBrowserTabActivityTrue);
    };
  }, []);

  return (
    <LocalContext.Provider
      value={{
        theme,
        toggle: toggleTheme,
        mobile: isMobile,
        fontSizing,
        toggleFont: toggleFontSizing,
        headerRef,
        agendaRef,
        contactRef,
        scrollTo: scrollToRef,
        selectedEvent,
        setSelectedEvent,
        selectedDepartment,
        setSelectedDepartment,
        browserTabIsActive,
        setBrowserTabIsActive,
        showUserAccountDeletedModal,
        setShowUserAccountDeletedModal,
        showGroupWatchingDialog,
        setShowGroupWatchingDialog,
        dialogAnimationTime,
        handleDialog
      }}>
      {props.children}
    </LocalContext.Provider>
  );
};

export { LocalContextProvider };
export default LocalContext;
