import * as React from 'react';

function SvgUserPlaceholder(props) {
  return (
    <svg viewBox="0 0 92 107" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M62.057 45.75c6.075-4.703 9.98-11.972 9.98-20.096C72.038 11.544 60.322 0 46 0 31.68 0 19.962 11.544 19.962 25.654c0 8.124 3.906 15.393 9.981 20.096C12.585 52.164 0 68.84 0 88.08c0 25.227 92 25.227 92 0 0-19.24-12.15-35.916-29.943-42.33z"
        fill="#C4C4C4"
      />
    </svg>
  );
}

export default SvgUserPlaceholder;
