import * as React from 'react';

function SvgPfizerLogo(props) {
  return (
    <svg viewBox="0 0 206 85" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.595 45.579c2.499-7.14 16.66-10.116 37.367-7.617l4.046-14.756c-20.588-2.618-35.463.238-37.962 9.52L.595 45.58z"
        fill="#fff"
      />
      <path
        d="M75.21 30.227c-2.5 7.14-16.66 10.115-37.248 7.616l-4.046 14.756c20.468 2.618 35.344-.357 37.843-9.639l3.45-12.733z"
        fill="#fff"
      />
      <path
        d="M13.685 14.756c30.227 2.142 61.05 17.137 58.074 28.204l2.737-9.996c.833-3.094 1.31-4.76 1.31-6.07C75.805 15.59 46.053 1.667 17.731 0l-4.046 14.756zM62.12 61.048C31.893 58.788 1.07 43.912 4.046 32.727L1.31 42.722C.476 45.816 0 47.482 0 48.792 0 60.095 29.75 74.02 58.074 75.685l4.046-14.638zM172.079 40.938c2.261 0 3.332 1.428 3.332 4.64v.953h-8.568c.952-3.332 2.975-5.593 5.236-5.593zm.119 16.66c-3.808 0-5.831-2.38-5.831-6.664V50.1h14.994c.238-1.19.476-2.38.476-3.57 0-5.236-3.213-9.282-9.877-9.282-6.784 0-12.734 6.664-12.734 13.685 0 6.664 4.403 10.71 11.663 10.71 3.213 0 6.426-1.07 8.687-2.737l-1.428-3.451c-1.666 1.19-3.808 2.023-5.95 2.142zM95.322 44.15h-2.738l3.69-13.804h2.856c3.57 0 5.593 2.38 5.593 5.712 0 5.117-4.046 8.092-9.401 8.092zm17.374-8.449c0-5.474-4.284-9.401-10.71-9.401H89.49l-9.4 34.749h7.972l3.451-12.852h6.903c7.735 0 14.28-4.999 14.28-12.496zM123.049 37.843l.833-3.213c.476-1.666 1.19-4.522 2.261-7.854 1.071-3.45 3.094-5.593 5.712-5.593 1.31 0 2.143.595 2.143 1.547 0 1.071-.952 1.785-.952 3.451 0 2.023 1.785 3.57 3.927 3.57 2.737 0 4.998-2.26 4.998-4.998 0-4.165-3.57-7.021-9.164-7.021-8.092 0-13.447 5.117-16.66 17.255L102.7 84.136h7.616l11.662-42.484h7.973l-5.236 19.397h7.616l6.308-23.206h-15.59z"
        fill="#fff"
      />
      <path
        d="M161.725 37.843h-20.23l-1.071 3.808h9.877L135.068 61.05h21.421l.952-3.809h-10.948l15.232-19.397zM201.83 37.486c-2.737 0-5.593 1.785-7.973 5.474l1.309-4.998h-7.617l-6.307 23.205h7.616l2.856-10.472c1.19-4.284 3.928-8.45 5.713-8.45 2.023 0 1.19 3.69 4.641 3.69 2.499 0 3.927-2.261 3.927-4.403.119-2.38-1.785-4.046-4.165-4.046z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgPfizerLogo;
