import React, { useContext } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Modal, EventSpeakerDetails } from 'components';
import { BgMoon1, BgMoon2 } from 'assets/svgs';
import { Markup } from 'interweave';

export default ({ data, cropped, popupType, colors, eid, carouselData }) => {
  const { mobile } = useContext(LocalContext);
  const hostImage = getImage(data.image);

  const [ref, inView] = useInView({
    rootMargin: '-96px 0px'
  });

  return (
    <>
      {data.summary ? (
        <Modal
          trigger={
            <Host colors={colors} ref={ref} style={{ opacity: inView ? 1 : 0 }}>
              <CardImgContainer style={{ filter: inView && mobile ? 'none' : null }}>
                {popupType === 'speaker' && (
                  <>
                    <SVG
                      colors={colors}
                      style={{ opacity: inView && mobile ? 1 : 0, y: inView && mobile ? 0 : -50 }}>
                      <Circle1 />
                    </SVG>
                    <SVG
                      colors={colors}
                      style={{ opacity: inView && mobile ? 1 : 0, y: inView && mobile ? 0 : 50 }}>
                      <Circle2 />
                    </SVG>
                  </>
                )}
                <CardImg image={hostImage} $cropped={cropped} alt={data.name} />
              </CardImgContainer>
              <CardTitle>{data.name}</CardTitle>
              <CardSubtitle colors={colors} eid={eid}>
                <Markup content={data.position} noWrap />
              </CardSubtitle>
            </Host>
          }
          modalContent={
            popupType === 'speaker' && (
              <EventSpeakerDetails
                startPoint={data.hostOrder - 1}
                speakers={carouselData}
                colors={colors}
                eid={eid}
              />
            )
          }
          colors={colors}
        />
      ) : (
        <Host colors={colors} ref={ref} style={{ opacity: inView ? 1 : 0 }}>
          <CardImgContainer style={{ filter: inView && mobile ? 'none' : null }}>
            {popupType === 'speaker' && (
              <>
                <SVG
                  colors={colors}
                  style={{ opacity: inView && mobile ? 1 : 0, y: inView && mobile ? 0 : -50 }}>
                  <Circle1 />
                </SVG>
                <SVG
                  colors={colors}
                  style={{ opacity: inView && mobile ? 1 : 0, y: inView && mobile ? 0 : 50 }}>
                  <Circle2 />
                </SVG>
              </>
            )}
            <CardImg image={hostImage} $cropped={cropped} alt={data.name} />
          </CardImgContainer>
          <CardTitle>{data.name}</CardTitle>
          <CardSubtitle colors={colors} eid={eid}>
            <Markup content={data.position} noWrap />
          </CardSubtitle>
        </Host>
      )}
    </>
  );
};

const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  svg {
    path {
      transition: 0.5s;
      fill: ${({ colors }) => colors.tertiary};
    }
  }
  :nth-child(1) {
    svg {
      path {
        fill: ${({ colors }) => colors.tertiary};
      }
    }
  }
  :nth-child(2) {
    svg {
      path {
        fill: ${({ colors }) => colors.primary};
      }
    }
  }
`;

const Circle1 = styled(BgMoon1)`
  bottom: -7%;
  height: 85%;
  left: -5%;
  position: absolute;
  transition-duration: 1s;
  width: 85%;
  @media (min-width: 1150px) {
    bottom: -10%;
  }
`;
const Circle2 = styled(BgMoon2)`
  height: 85%;
  position: absolute;
  right: -5%;
  top: 0%;
  transition-duration: 1s;
  width: 85%;
  path {
    fill: fill: #0000C9;
  }
  @media (min-width: 1150px) {
    top: -10%;
  }
`;

const CardImg = styled(GatsbyImage)`
  border-radius: ${({ $cropped }) => ($cropped ? '50%' : '0px')};
  cursor: pointer;
  height: 250px;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
  width: 250px;
`;

const CardImgContainer = styled(motion.div)`
  filter: none;
  left: 50%;
  max-width: 300px;
  overflow: hidden;
  overflow: visible;
  padding: 50px 20px 10px;
  position: relative;
  transform: translateX(-50%);
  transition-duration: 1s;
`;

const CardTitle = styled.h3`
  color: #333;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  margin-top: 0px;
  text-align: center;
`;

const CardSubtitle = styled.p`
  color: ${({ eid, colors }) =>
    eid === 'eIj0KJ' ? `${colors.secondary} !important` : `${colors.tertiary} !important`};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 2em !important;
  margin-top: 0px;
  text-align: center;
`;

const Host = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2rem;
  max-width: 100%;
  transition-duration: 1s;
  width: 400px;

  &:hover ${CardImg} {
    box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  }

  @media (min-width: 1150px) {
    &:hover ${SVG} {
      opacity: 1 !important;
    }
    &:hover ${Circle1} {
      bottom: -21%;
    }
    &:hover ${Circle2} {
      top: -12.25%;
    }
  }

  p,
  h3 {
    color: #3c3c3c;
  }
  @media (min-width: 1600px) {
    margin: 2rem 3rem;
  }
`;
