import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { EnitiativesLogoContrast } from 'assets/svgs';

export default (props) => {
  const { headerRef } = useContext(LocalContext);
  const { colors } = props;

  return (
    <Header
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Background>
        <BackgroundVideo
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          playsInline
          autoPlay
          muted
          loop
          id="bgvid">
          <source src="/videos/pfizer_homepage_video.mp4" type="video/mp4" />
        </BackgroundVideo>
      </Background>
      <Container>
        <Content>
          <AnimatedTitle
            initial={{ transform: 'rotateX(90deg)' }}
            animate={{ transform: 'rotateX(0deg)' }}
            transition={{ duration: 1, delay: 1 }}>
            Pfizer Virtual Cafe
          </AnimatedTitle>
          <Sponsor
            colors={colors}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <p>brought to you by</p>
            <EnitiativesLogoContrast />
          </Sponsor>
          {/* <Description
            colors={colors}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis neque vitae risus
            fringilla, at faucibus justo elementum. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Fusce semper arcu a sem sodales.
          </Description> */}
        </Content>
      </Container>
    </Header>
  );
};

const BackgroundVideo = styled(motion.video)`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Description = styled(motion.div)`
  font-size: 1.25rem;
  line-height: 1.3em;
  margin-bottom: 1.5rem;
  color: white;
  @media (min-width: 1150px) {
    margin-bottom: 4rem;
  }
`;

const Sponsor = styled(motion.div)`
  font-weight: 700;
  color: white;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  p {
    font-size: 1rem;
    color: white;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  svg {
    max-width: 100%;
    height: 5rem;
  }
  @media (min-width: 1150px) {
    margin-bottom: 2rem;
  }
`;

const Date = styled(motion.h4)`
  font-weight: 700;
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ colors }) => colors.secondary};
  margin-bottom: 1rem;
`;

const AnimatedTitle = styled(motion.div)`
  width: auto;
  height: auto;
  transform: rotateX(-90deg);
  transform-style: preserve-3d;
  color: white;
  font-size: 3rem;
  margin-bottom: 0.25em;
  font-weight: bold;
  @media (min-width: 1150px) {
    font-size: 6rem;
  }

  svg {
    width: 15rem;
    margin-bottom: 2rem;
  }
  @media (min-width: 1150px) {
    svg {
      width: 30rem;
    }
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  width: 100%;
  grid-column: 1/7;
  padding: 2rem 0.5rem;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media (min-width: 768px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/8;
    padding: 0;
    padding-right: 5rem;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  height: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  color: var(--black);
  padding: 0.625rem;
  padding-top: 5rem;
  margin-bottom: 1rem;
  padding-bottom: 2.5rem;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 4rem 0;
    padding-top: 6rem;
    padding-bottom: 12.5rem;
  }
  @media (min-width: 1150px) {
    padding: 5rem 0;
    padding-top: 12.5rem;
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Header = styled.header`
  height: auto;
  width: 100%;
  background: white;
  position: relative;
  @media (min-width: 1150px) {
    min-height: 100vh;
  }
`;
