import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  EventAgenda,
  EventAgendaITransform2023,
  EventNavbar,
  EventPublications,
  EventSpeakers,
  EventStream,
  EventSmallPrint,
  EventAPI,
  EventStreamFooter,
  EventStreamHeader,
  IndividualSpeaker,
  EventHosts,
  ProtectedRoute,
  SEO,
  Button
} from 'components';
import { Event14Arrow } from 'assets/svgs';
import { LocalContext, FirebaseContext } from 'context';

const eventLivestreamTemplate = ({ data }) => {
  const { setSelectedEvent, setSelectedDepartment, setShowGroupWatchingDialog } = useContext(LocalContext);
  const { loading, firebase, user } = useContext(FirebaseContext);
  const [isGroupWatchingEnabled, setIsGroupWatchingEnabled] = useState(false);
  const { frontmatter: event } = data.markdownRemark;

  useEffect(() => {
    setSelectedEvent({
      name: event.name,
      eid: event.eid,
      colors: event.colors,
      slug: event.slug
    });
    setSelectedDepartment(event.eventDepartment);
    return () => {
      setSelectedEvent(null);
      setSelectedDepartment(null);
    };
  }, [event.eid, event.eventDepartment]);

  console.log('Right here you go: ', user);

  useEffect(() => {
    if (!loading && firebase && user?.groupWatching) {
      if (isGroupWatchingEnabled && !Object.hasOwn(user.groupWatching, event.eid)) {
        setShowGroupWatchingDialog(true);
      }
    }
  }, [loading, firebase, user, isGroupWatchingEnabled]);
  
  return (
    <ProtectedRoute eid={event.eid} eventSlug={event.slug} eventIsHidden={event.hidden}>
      <SEO
        pageSpecificTitle={`${event.name} Livestream`}
        pageSpecificDescription={event.description}
        pageSpecificThumbnail={event.seoThumbnail.childImageSharp.original.src}
        pageSpecificThumbnailAlt={event.name}
      />
      <EventStreamHeader
        colors={event.colors}
        contrast={event.bannerContrast}
        eventTitle={event.title}
        streamPageTitle={event.streamPageTitle}
        eventDates={event.dates}
        eventDescription={event.description}
        calendarDescription={event.calendarDescription}
        calendarLink={event.calendarLink}
        eventSubtitle={event.subtitle}
        eventStartTime={event.startTime}
        eventEndTime={event.endTime}
        eventBanner={event.streamBanner}
        eventSeries={event.series}
        eventBannerVideo={event.bannerVideo}
        eventName={event.name}
        eventLogo={event.logo}
        eventLogoWithButterfly={event.logoWithButterfly}
        eventSlug={event.slug}
        eid={event.eid}
        eventBannerPromotion={event.bannerPromotion}
        footNote={event.streamFootNote}
        isChatEnabled={event.isChatEnabled}
        isQAndAEnabled={event.isQAndAEnabled}
        isPollsEnabled={event.isPollsEnabled}
        isParticipantsEnabled={event.isParticipantsEnabled}
        isGroupWatchingEnabled={isGroupWatchingEnabled}
        setIsGroupWatchingEnabled={setIsGroupWatchingEnabled}
      />
      {event.eid === 'Aje74p0' && (
        <Statement>
          <p>
            ▼ This medicinal product is subject to additional monitoring. This will allow quick
            identification of new safety information. Healthcare professionals are asked to report
            any suspected adverse reactions. See section 4.8 of the SmPC for how to report adverse
            reactions. Please refer to the Summary of Product Characteristics (SmPC) before
            prescribing Ngenla.
          </p>
        </Statement>
      )}
      {event.bannerPromotion && (
        <GatsbyImage image={getImage(event.bannerPromotion)} alt="Promotion" />
      )}
      {event.chairPerson && (
        <Container bg="rgba(196,196,196,0.2)">
          <Section>
            <h2>ChairPerson</h2>
            <IndividualSpeaker colors={event.colors} data={event.chairPerson} eid={event.eid} />
          </Section>
        </Container>
      )}
      {event.hosts?.length && (
        <Container eid={event.eid} hosts>
          <Section colors={event.colors} eid={event.eid} speakers>
            {event.eid === 'eIj0KJ' ? (<h2>Opening & Closing</h2>) : (<h2>{event.hosts.length > 1 ? 'Hosts' : 'Host'}</h2>)}
            <EventHosts colors={event.colors} eventHosts={event.hosts} eid={event.eid} />
          </Section>
        </Container>
      )}
      {event.speakers?.length && (
        <Container eid={event.eid}>
          <Section colors={event.colors} eid={event.eid} speakers>
            {event.eid !== 'j3vd74' && <h2>{event.eid === 'EUwYfa' ? 'Host & Keynote Speaker' : `Keynote Speaker${event.speakers?.length > 1 ? 's' : ''}`}</h2>}
            {event.speakers.length < 2 ? (
              event.speakers.map((speaker) => (
                <IndividualSpeaker colors={event.colors} data={speaker} eid={event.eid} />
              ))
            ) : (
              <EventSpeakers colors={event.colors} eventSpeakers={event.speakers} eid={event.eid} />
            )}
          </Section>
        </Container>
      )}
      {event.publications && (
        <Container eid={event.eid} style={{ paddingTop: 0 }}>
          <Section>
            <h2>Recent Publication</h2>
            <EventPublications
              publications={event.publications}
              tangent90EventSource={event.tangent90EventSource}
              colors={event.colors}
            />
          </Section>
        </Container>
      )}
      {event.agenda && event.eid !== 'j3vd74' && (
        <EventAgenda agenda={event.agenda} colors={event.colors} eid={event.eid} />
      )}
      {event.eid === 'j3vd74' && (
        <>
          <EventAPI />
          <EventSmallPrint colors={event.colors} smallPrint={event.smallPrint} />
        </>
      )}
      <EventStreamFooter
        eventJobCode={event.eventStreamJobCode}
        dateOfPrep={event.eventStreamDateOfPrep}
        eventFooterText={event.eventFooterText}
        eventFooterLogo={event.eventFooterLogo}
        eid={event.eid}
      />
    </ProtectedRoute>
  );
};

const Section = styled.section`
  grid-column: 1/7;
  h2 {
    color: ${({ colors }) => colors.secondary};
    font-size: 1.5rem;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }
  p {
    margin: 0 auto;
    max-width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;
    color: #3c3c3c;
    text-align: center;
    margin-bottom: 3rem;
  }
  @media (min-width: 1150px) {
    margin-top: 3rem;
    grid-column: ${({ speakers }) => (speakers ? '1/13' : '2/12')};
  }
  @media (min-width: 1600px) {
    grid-column: ${({ speakers }) => (speakers ? '2/12' : '3/11')};
  }
`;

const HCPPaxlovid = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  padding: 45px 28px;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  p {
    color: #6f6f6f;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    a {
      background-color: transparent;
      color: #6f6f6f;
      font-size: 1.25rem;
      font-weight: normal;
      border-radius: 0;
      padding: 0;
      text-decoration: underline;
      text-underline-position: under;
    }
  }
  a {
    background-color: ${({ colors }) => colors.tertiary};
    color: white;
    font-size: 1.5rem;
    font-weight: 800;
    border-radius: 100px;
    padding: 1.125rem 2.125rem;
    text-decoration: underline;
  }
`;

const HCPButtonSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;

  button {
    background: #0095ff;
    font-size: 20px;
    width: 450px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
`;

const Statement = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin: 1rem 1rem 0 1rem;
  p {
    grid-column: 1/7;
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    margin: 2rem 0 -3rem 0;
    p {
      grid-column: 3/11;
    }
  }
  @media (min-width: 1600px) {
    p {
      grid-column: 4/10;
    }
  }
`;

const Container = styled.section`
  align-items: flex-start;
  background-color: ${({ bg }) => bg || 'transparent'};
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(6, 1fr);
  padding: ${({ hosts }) => hosts ? '3rem 1rem 0 1rem' : '3rem 1rem'};
  @media (min-width: 768px) {
    padding: ${({ hosts }) => hosts ? '6rem 1.25rem 0 1.25rem' : '6rem 1.25rem'};
  }
  @media (min-width: 1150px) {
    padding: ${({ hosts }) => hosts ? '6rem 0 0 0' : '6rem 0'};
    grid-template-columns: repeat(12, 1fr);
  }
  ${({ style }) => style};

  @media (min-width: 1320px) and (max-width: 1800px) {
    ${({ eid }) =>
      eid === '10' &&
      css`
        padding: 10rem 0 0;
      `};
  }
`;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        slug
        isChatEnabled
        isQAndAEnabled
        isPollsEnabled
        isParticipantsEnabled
        colors {
          primary
          secondary
          tertiary
        }
        name
        hidden
        eid
        title
        subtitle
        seoThumbnail {
          childImageSharp {
            original {
              src
            }
          }
        }
        description
        calendarLink
        eventDepartment
        dates
        startTime
        endTime
        eventAPIStatement
        eventAPIDescription
        eventStreamJobCode
        eventStreamDateOfPrep
        eventFooterText
        smallPrint
        eventFooterLogo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        streamBanner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        bannerVideo
        bannerContrast
        agenda {
          day
          timeslots {
            title
            time
            speakers
          }
        }
        speakers {
          speakerOrder
          title
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export default eventLivestreamTemplate;
