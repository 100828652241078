import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { DefaultParticipantAvatar, ModalCloseIcon } from 'assets/svgs';

const ParticipantModal = ({ colors, showParticipantModal, handleParticipantAvatarOrNameClick }) => (
  <Wrapper
    onClick={(e) => {
      if (e.target.dataset.underlay === 'true') {
        handleParticipantAvatarOrNameClick();
      }
    }}
    data-underlay
    colors={colors}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}>
    <div>
      <div onClick={() => handleParticipantAvatarOrNameClick()} role="button" tabIndex={0}>
        <ModalCloseIcon width="12" height="12" style={{ top: '20px', right: '20px' }} />
      </div>
      {showParticipantModal.avatarUrl ? (
        <ParticipantAvatar
          src={showParticipantModal.avatarUrl}
          alt={showParticipantModal.name}
          colors={colors}
        />
      ) : (
        <DefaultParticipantAvatar />
      )}
      <h3>{showParticipantModal.name}</h3>
      <p>
        {/* {`${showParticipantModal.function}, ${showParticipantModal.region}, ${showParticipantModal.country}`} */}
      </p>
    </div>
  </Wrapper>
);

const Wrapper = styled(motion.div)`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  padding: 0 1.625rem;
  position: relative;
  z-index: 1;

  > div {
    background-color: #fff;
    margin: 6.25rem auto 0;
    max-width: 23.75rem;
    padding: 7.9rem 0 2rem;
    position: relative;
    width: 100%;

    > div {
      align-items: center;
      background-color: ${({ colors }) => colors.secondary};
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 2rem;
      justify-content: center;
      position: absolute;
      right: 0;
      top: -4rem;
      width: 2rem;
    }

    > img,
    > svg {
      border-radius: 50%;
      height: 10rem;
      left: calc(50% - 5rem);
      position: absolute;
      top: -4rem;
      width: 10rem;
    }

    > h3,
    > p {
      color: #3c3c3c;
      text-align: center;
    }

    > h3 {
      font-size: 1.25rem;
      margin-bottom: 0.28em;
    }
  }
`;

const ParticipantAvatar = styled.img`
  border: 0.2rem solid ${({ colors }) => colors.secondary};
  object-fit: cover;
`;

export default ParticipantModal;
