import React, { useState } from 'react';
import styled from 'styled-components';
import { Markup } from 'interweave';
import { Button } from 'components';
import { useWindowSize } from 'hooks';
import { hexToRGB } from 'utils';
import { Plus } from 'assets/svgs';

const Agenda = ({ agenda, colors, eid }) => {
  const { windowWidth } = useWindowSize();
  const [activeDay, setActiveDay] = useState(agenda.length > 1 ? 1 : 0);

  const handleActiveDaySelection = (day) => setActiveDay(day);

  return (
    <Wrapper id="agenda" colors={colors}>
      <div>
        <H2 colors={colors}>Agenda</H2>
        {agenda.length > 1 && (
          <DaySelection>
            {agenda.map((day, i) => (
              <Button
                onClick={() => handleActiveDaySelection(i)}
                key={i}
                style={{
                  backgroundColor: activeDay === i ? '#DB1778' : 'transparent',
                  border: activeDay === i ? '#fff' : `1px solid ${'#DB1778'}`,
                  color: activeDay === i ? '#fff' : '#DB1778'
                }}>{`Day ${i + 1}`}</Button>
            ))}
          </DaySelection>
        )}
        {agenda[activeDay]?.title && <DayTitle>{agenda[activeDay].title}</DayTitle>}
        <Table colors={colors} eid={eid}>
          <thead>
            <tr>
              <th>Time</th>
              <th>Livestream:</th>
              {agenda[activeDay].timeslots.some((timeslot) => timeslot.speakers && timeslot.speakers.length > 0) && (
                <th>Speaker:</th>
              )}
            </tr>
          </thead>
          <tbody>
            {agenda[activeDay]?.timeslots.map((timeslot, i) => (
              <BodyTrackRow colors={colors} title={timeslot.title} inset={timeslot.inset} key={i}>
                {timeslot.time ? <td>{timeslot.time}</td> : <td />}
                <td>
                  <Markup content={timeslot.title} noWrap />
                  {timeslot.calendarLink && (
                    <a
                      href={timeslot.calendarLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ display: 'block', margin: '1.438em 0 0.5em' }}>
                      <Button
                        style={{
                          backgroundColor: '#DB1778',
                          fontSize: windowWidth >= 768 ? '0.75rem' : '0.625rem',
                          height: windowWidth >= 450 ? '2.35em' : '3.25em',
                          lineHeight: '1.362em',
                          padding: '1em',
                          maxWidth: '14.5em',
                          width: '100%'
                        }}
                        whileHover={{
                          scale: 1.0225
                        }}
                        whileTap={{
                          scale: 0.975
                        }}>
                        {windowWidth >= 768 && (
                          <Plus width="0.75rem" style={{ marginRight: '0.75em' }} />
                        )}
                        Add To Calendar
                      </Button>
                    </a>
                  )}
                </td>
                {timeslot.speakers && (
                  <td style={{ paddingLeft: 0 }}>
                    <Markup content={timeslot.speakers} noWrap />
                  </td>
                )}
              </BodyTrackRow>
            ))}
          </tbody>
        </Table>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: rgba(196, 196, 196, 0.2);
  padding: 5.125rem 0 5.125rem 1rem;
  width: 100%;

  @media screen and (min-width: 75rem) {
    padding: 5.125rem 0;
  }

  > div {
    max-width: 72.875rem;
    margin: 0 auto;
  }
`;

const DaySelection = styled.div`
  display: flex;
  margin-bottom: 2rem;

  button {
    font-weight: 400;
    margin: 0 0.625rem;
    text-transform: capitalize;
    width: 120px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const DayTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 3rem;
`;

const H2 = styled.h2`
  color: ${({ colors }) => colors.primary};
  font-size: 1.5rem;
  margin-bottom: 37px;
`;

const Table = styled.table`
  color: #3c3c3c;
  width: 100%;

  &,
  th,
  td {
    border-collapse: collapse;
  }

  th {
    color: ${({ eid, colors }) => (eid === 'eIj0KJ' ? colors.secondary : '#3C3C3C')};
  }

  thead tr th {
    font-size: 0.75rem;
    font-weight: 400;
    left: -1.333em;
    line-height: 2.667em;
    padding-right: 1.333em;
    position: relative;
    text-align: left;

    &:nth-of-type(1) {
      left: 0;
      padding-right: 2.666em;
      white-space: nowrap;
    }
  }
`;

const BodyTrackRow = styled.tr`
  color: #3c3c3c;
  font-size: 1rem;
  left: -1em;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  td {
    font-size: 0.85rem;
    line-height: 1.5em;
    padding: ${({ inset }) => (inset ? '0 1em 0.1em 0' : '0.75em 1em 0.75em 0')};
    vertical-align: top;

    ul {
      margin-left: 1.25em;
    }

    @media screen and (min-width: 48rem) {
      font-size: 1rem;
    }

    &:nth-of-type(1) {
      font-size: 0.75rem;
      padding: 0.75rem 1rem;
      white-space: nowrap;

      @media screen and (min-width: 48rem) {
        font-size: 1rem;
        white-space: unset;
      }
    }

    &:nth-of-type(2) {
      max-width: 555px;
    }

    &:nth-of-type(3) {
      max-width: 450px;
    }

    &:nth-of-type(2) {
      padding-right: 2em;
    }
  }
`;

export default Agenda;
