import * as React from 'react';

function SvgLeftArrow(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.686 24.314L3.373 13 14.686 1.686" stroke="#fff" strokeWidth={4} />
    </svg>
  );
}

export default SvgLeftArrow;
