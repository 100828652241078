import React from 'react';
import queryString from 'query-string';
import Layout from './src/layouts';
import { LocalContextProvider } from './src/context/LocalContext';

export const wrapPageElement = ({ props }) => {
  const { component: PageComponent, page } = props.pageResources;
  return page.path === '/ie-not-supported/' ? (
    <PageComponent {...props} />
  ) : (
    <Layout {...props}>
      <PageComponent {...props} />
    </Layout>
  );
};

export const wrapRootElement = ({ element }) => (
  <LocalContextProvider>{element}</LocalContextProvider>
);

export const onClientEntry = () => {
  const eventSlug = window.location.pathname.split('/')[2];

  if (eventSlug) {
    window.localStorage.setItem(
      'pfizerVirtualCafeEventSlug',
      JSON.stringify({
        data: eventSlug
      })
    );
  }

  const parseUrl = (params) => {
    const queryParams = queryString.parse(params);
    /* eslint-disable camelcase */
    let { utm_source } = queryParams;
    if (utm_source) {
      utm_source = utm_source
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    return utm_source || document.referrer;
  };

  const oldReferringUrl = JSON.parse(
    window.localStorage.getItem('referringUrlPfizerVirtualCafeEvent19')
  )?.data;
  const referringUrl = JSON.parse(
    window.localStorage.getItem('referringUrlPfizerVirtualCafeEvent21')
  )?.data;

  if (oldReferringUrl) {
    window.localStorage.removeItem('referringUrlPfizerVirtualCafeEvent19');
  }

  if (!referringUrl) {
    window.localStorage.setItem(
      'referringUrlPfizerVirtualCafeEvent21',
      JSON.stringify({
        data: parseUrl(window.location.search) || 'Direct Navigation'
      })
    );
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
};
