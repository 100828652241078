import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function ReferringUrls({ urlCount }) {
  const [urlList, setUrlList] = useState([]);

  useEffect(() => {
    Object.entries(urlCount).forEach((array) => {
      const [url, count] = array;
      setUrlList((currentState) => [...currentState, { label: url, value: count }]);
    });
    return () => setUrlList([]);
  }, [urlCount]);

  return (
    <Wrapper id="referring-urls">
      <Title>Most Popular Referring URLs</Title>
      <Table multiple={urlList.length > 1}>
        <tbody>
          <tr>
            <td>Name</td>
            <td>Url</td>
            <td>#</td>
          </tr>
          {urlList
            .sort((a, b) => b.value - a.value)
            .map((element, index) => (
              <tr key={index}>
                <td>
                  {element.label.includes('facebook')
                    ? 'Facebook'
                    : element.label.includes('t.co')
                    ? 'Twitter'
                    : `${
                        element.label
                          .replace(/.+\/\/|www.|\..+/g, '')
                          .charAt(0)
                          .toUpperCase() + element.label.replace(/.+\/\/|www.|\..+/g, '').slice(1)
                      }`}
                </td>
                <td>{element.label}</td>
                <td>{element.value}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0;
`;

const Title = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
`;

const Table = styled.table`
  border: 1px solid #007fc0;
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  margin: 2rem;
  max-width: 820px;
  tr {
    &:nth-of-type(odd) {
      background: rgba(0, 149, 255, 0.1);
    }
    td {
      margin: 0;
      padding: 0.5rem;
      &:first-child {
        border-right: 1px solid #007fc0;
        width: 200px;
      }
      &:last-child {
        border-left: 1px solid #007fc0;
        text-align: center;
        width: 100px;
      }
    }
    &:not(:last-of-type) {
      td {
        border-bottom: ${({ multiple }) => (multiple ? '1px solid rgba(0, 0, 0, 0.2)' : 'none')};
      }
    }
  }
`;

export default ReferringUrls;