import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { Markup } from 'interweave';

const AgendaEntry = ({ entry }) => {
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true
  });

  return (
    <StyledAgendaEntry color={entry.colors.dark} ref={ref}>
      <Day inView={inView}>{entry.title.split(' -')[0]}</Day>
      <Title color={entry.colors.dark} inView={inView}>
        <h3>{entry.title.split('- ')[1]}</h3>
      </Title>
      <Content colors={entry.colors} inView={inView}>
        <p style={{ fontSize: '14px', fontWeight: 400 }}>Livestream:</p>
        {entry.timeslots.map((timeslot) => (
          <Markup content={timeslot.title} tagName="p" />
        ))}
      </Content>
    </StyledAgendaEntry>
  );
};

const Agenda = ({ agenda }) => (
  <Wrapper id="agenda">
    <div>
      {agenda.map((entry) => (
        <AgendaEntry entry={entry} />
      ))}
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 0rem 0.5rem 5.125rem;
  width: 100%;

  @media screen and (min-width: 75rem) {
    padding: 3.65rem 0 3.75rem;
  }

  > div {
    display: grid;
    justify-content: center;
    max-width: 72.563rem;
    margin: 0 auto;

    @media screen and (min-width: 75rem) {
      grid-template-columns: repeat(2, auto);
      grid-gap: 1.25rem;
    }
  }
`;

const StyledAgendaEntry = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 35.625rem;
`;

const dayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Day = styled.h4`
  animation: ${({ inView }) => inView ? css`${dayAnimation} 0.7s ease-in-out forwards` : ''};
  color: #3c3c3c;
  opacity: 0;
  font-size: 1.25rem;
  margin: 3rem 1rem 0.6rem 3.6rem;

  @media screen and (min-width: 75rem) {
    margin: 1rem 1rem 0.6rem 3.6rem;
  }
`;

const titleAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Title = styled.div`
  animation: ${({ inView }) => inView ? css`${titleAnimation} 0.7s ease-in-out forwards` : ''};
  background-color: ${({ color }) => color};
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  margin-left: 1.5rem;
  padding: 0.65rem 2rem;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  width: calc(100% - 3rem);
  z-index: -1;

  h3 {
    color: #fff;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 450px) {
    h3 {
      color: #fff;
      font-size: 1.938rem;
    }
  }
`;

const borderAnimation = (color) => keyframes`
  from {
    border-color: transparent;
  }
  to {
    border-color: ${color};
  }
`;

const Content = styled.div`
  animation: ${({ inView, colors }) => inView ? borderAnimation(colors.dark) : ''} 0.7s ease-in-out forwards;
  background-color: ${({ colors }) => colors.light};
  border-width: 1.5rem;
  border-color: transparent;
  border-style: solid;
  border-radius: 13px;
  border-top: none;
  border-bottom: none;
  color: #3c3c3c;
  font-size: 1rem;
  font-weight: bold;
  height: 100%;
  padding: 2.85em 2em 1.55em;
  width: 100%;

  p {
    margin-bottom: 1.45rem;
  }
`;

export default Agenda;
