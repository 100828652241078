import React, { useContext } from 'react';
import { Button } from 'components';
import { LocalContext } from 'context';
import { FormContainer } from '../FormComponents';

const AccountDeleted = ({ setShowUserAccountDeletedModal }) => {
  const { theme } = useContext(LocalContext);

  return (
    <FormContainer theme={theme}>
      <h3
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: '1.625rem',
          fontStyle: 'normal',
          fontWeight: 'bold',
          justifyContent: 'center',
          lineHeight: '1.75rem',
          textAlign: 'center'
        }}>
        Account Deleted
      </h3>
      <Button
        onClick={() => setShowUserAccountDeletedModal(false)}
        style={{
          alignSelf: 'center',
          marginTop: '1.75em',
          fontSize: '1rem',
          width: '110px'
        }}>
        Ok
      </Button>
    </FormContainer>
  );
};

export default AccountDeleted;
