import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Markup } from 'interweave';
import { useEventData } from 'hooks';

export default ({ colors }) => {
  const { edges: events } = useEventData();
  const futureEvents = events
    .filter(({ node }) => node.frontmatter.type === 'future' && !node.frontmatter.hidden)
    .sort((a, b) => b.node.frontmatter.id - a.node.frontmatter.id);

  return (
    <FutureEvents>
      {futureEvents.map((event) => {
        return (
          <Event key={event.node.frontmatter.id}>
           {event.node.frontmatter.thumb && <EventThumbnail image={event.node.frontmatter.thumb.childImageSharp.gatsbyImageData} alt={event.node.frontmatter.name} />}
            <Details colors={colors}>
              <h4>
                <Markup content={event.node.frontmatter.dates} />
              </h4>
              <h3>{event.node.frontmatter.name}</h3>
              <p><Markup content={event.node.frontmatter.description} noWrap /></p>
              <Link to={event.node.frontmatter.slug}>View Event</Link>
            </Details>
          </Event>
        );
      })}
    </FutureEvents>
  );
};

const EventThumbnail = styled(GatsbyImage)`
  height: 280px;
  max-width: 500px;
  width: 100%;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h3 {
    font-size: 2.5rem;
    font-weight: 400;
    color: #3c3c3c;
    margin: 0;
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 600 !important;
    margin-bottom: 1em !important;
    color: ${({ colors }) => colors.secondary} !important;
    text-align: left !important;
    width: 100% !important;
  }
  p {
    font-size: 1rem;
    margin-bottom: 1em !important;
    color: #3c3c3c !important;
    text-align: left !important;
    width: 100% !important;
  }
  a {
    padding: 0.75rem 1.5rem;
    border-radius: 3rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: ${({ colors }) => colors.tertiary};
    color: white;
    margin-bottom: 0.5em;
    transition: 0.3s;
    &:hover {
      background-color: ${({ colors }) => colors.secondary};
    }
  }
`;

const Event = styled.div`
  align-content: start;
  background: white;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  margin: 0 1rem;
  max-width: 500px;
  padding: 1.25rem;
  width: 100%;
`;

const FutureEvents = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 520px;

  @media screen and (min-width: 1150px) {
    align-items: start;
    flex-direction: row;
    max-width: 950px;
  }
`;
