import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  SEO,
  EventHeader,
  EventFooter,
  EventSpeakers,
  EventAPI,
  EventSmallPrint,
  EventAgenda
} from 'components';
import { graphql, navigate } from 'gatsby';
import { LocalContext, FirebaseContext } from 'context';

const eventRegistrationTemplate = ({ data }) => {
  const { setSelectedEvent, setSelectedDepartment, browserTabIsActive } = useContext(LocalContext);
  const { user, firebase } = useContext(FirebaseContext);
  const { frontmatter: event } = data.markdownRemark;

  useEffect(() => {
    setSelectedEvent({
      name: event.name,
      eid: event.eid,
      colors: event.colors,
      slug: event.slug,
      registrationFields: event.registrationFields,
      termsAndConditions: event.termsAndConditions,
      ePermissions: event.ePermissions,
      cpdPermissions: event.cpdPermissions,
      tangent90EventCollectionId: event.tangent90EventCollectionId,
      tangent90EventSource: event.tangent90EventSource
    });
    setSelectedDepartment(event.eventDepartment);
    return () => {
      setSelectedEvent(null);
      setSelectedDepartment(null);
    };
  }, [event]);

  useEffect(() => {
    let unsubscribeFromEventUpdates;
    if (firebase && user && user.emailVerified && user.eventsUserCanAccess.includes(event.eid)) {
      unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
        eid: event.eid,
        onSnapshot: (snapshot) => {
          if (snapshot.exists) {
            const { logEveryoneOutPostEvent } = snapshot.data();
            if (logEveryoneOutPostEvent) {
              firebase.logout(user);
            } else if (user?.eventsUserCanAccess.includes(event.eid) && browserTabIsActive) {
              navigate(`/events/${event.slug}/livestream/`);
            }
          } else if (!snapshot.exists) {
            /* CONOR TODO: Is there a better way of doing this? Can we automatically add the event doc somehow? Hmmmm */
            console.error(
              "You need to add a doc for this event in the 'Events' collection in Firebase."
            );
          }
        }
      });
      return () => {
        if (unsubscribeFromEventUpdates) {
          unsubscribeFromEventUpdates();
        }
      };
    }
  }, [firebase, user, event.eid, browserTabIsActive]);

  return (
    <>
      <SEO
        pageSpecificTitle={event.name}
        pageSpecificDescription={event.description}
        pageSpecificThumbnail={event.seoThumbnail.childImageSharp.original.src}
        pageSpecificThumbnailAlt={event.name}
      />
      <EventHeader id="header" event={event} />
      {event.eid === 'j3vd74' && event.speakers.length && (
        <Container eid={event.eid}>
          <Section colors={event.colors} eid={event.eid} speakers>
            <EventSpeakers colors={event.colors} eventSpeakers={event.speakers} eid={event.eid} />
          </Section>
        </Container>
      )}
      {event.eid === 'j3vd74' && <EventAPI />}
      {event.eid !== 'AvkbvcD' && (
        <EventSmallPrint colors={event.colors} smallPrint={event.smallPrint} />
      )}
      <EventFooter
        id="footer"
        eventJobCode={event.eventJobCode}
        dateOfPrep={event.eventDateOfPrep}
        eventFooterText={event.eventFooterText}
        eventFooterLogo={event.eventFooterLogo}
        eid={event.eid}
      />
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        name
        slug
        eid
        type
        colors {
          primary
          secondary
          tertiary
        }
        registrationFields
        title
        subtitle
        eventDepartment
        calendarLink
        banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
          }
        }
        eventFooterText
        eventFooterLogo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
          }
        }
        bannerContrast
        agenda {
          day
          timeslots {
            title
            time
            speakers
          }
        }
        speakers {
          speakerOrder
          title
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        seoThumbnail {
          childImageSharp {
            original {
              src
            }
          }
        }
        bannerVideo
        eventJobCode
        eventDateOfPrep
        eventAPIStatement
        eventAPIDescription
        description
        dates
        start
        end
        location
        startTime
        endTime
        termsAndConditions
        ePermissions
        smallPrint
      }
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  height: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  color: var(--black);
  padding: 5rem 0.625rem 2.5rem;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 6rem 0 12.5rem;
  }
  @media (min-width: 1150px) {
    padding: ${({ eid }) => (eid === '9' ? '18.5rem 0 5rem' : '7rem 0 5rem')};
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Section = styled.section`
  grid-column: 1/7;
  h2 {
    color: #00004e;
    font-size: 1.5rem;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }
  p {
    margin: 0 auto;
    max-width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;
    color: #3c3c3c;
    text-align: center;
    margin-bottom: 3rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    p {
      margin-bottom: 4rem;
    }
  }
  @media (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

export default eventRegistrationTemplate;
