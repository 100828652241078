import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Button, ErrorMessage, LoadingSpinner, Notification } from 'components';
import { DefaultAvatar } from 'assets/animated-svgs';
import { LocalContext, FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowSize } from 'hooks';
import { pfizer } from 'styles';
import { FormContainer, FormInput, FormInputLabel, HiddenFileUpload } from '../FormComponents';

let fileReader;

if (typeof window !== 'undefined') {
  fileReader = new FileReader();
}

const UserProfile = ({ handleLogout, setShowUserProfileModal }) => {
  const { selectedEvent, setShowUserAccountDeletedModal } = useContext(LocalContext);
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [avatar, setAvatar] = useState(user.avatarUrl);
  const [formValues, setFormValues] = useState({
    avatarFile: '',
    fullName: user.fullName,
    email: user.email
  });
  const [savingChanges, setSavingChanges] = useState({
    fullName: false,
    email: false,
    avatar: false
  });
  const [notification, setNotification] = useState('');
  const [error, setError] = useState({
    code: '',
    message: ''
  });
  const { windowWidth } = useWindowSize();
  const [generatingUserReport, setGeneratingUserReport] = useState(false);
  const [generatingTrustrackReport, setGeneratingTrustrackReport] = useState(false);
  const [requestAccountDeletionConfirmation, setRequestAccountDeletionConfirmation] =
    useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const timeToWaitBeforeSavingChanges = 750;

  useEffect(() => {
    if (!user.emailVerified) {
      setError({
        code: 'auth/email-not-verified',
        message: `Email address not verified. Please click the link in the email we just sent to ${
          user?.email || 'your email address'
        }, to complete your registration application. Can&apos;t find our email? Be sure to check your junk!`
      });
    }
  }, [user.emailVerified]);

  useEffect(() => {
    fileReader.addEventListener('load', () => {
      const avatarFile = fileReader.result;
      setFormValues((currentValues) => ({
        ...currentValues,
        avatarFile
      }));
    });
  }, []);

  useEffect(() => {
    if (formValues.avatarFile) {
      setSavingChanges((currentState) => ({
        ...currentState,
        avatar: true
      }));

      /* We wait 2.5 seconds, just to buy the backend some time uploading the image and resizing it.
      Othwerise the user might wonder why the avatar has updated instantly on their profile popup,
      but not in their navbar or any comments. */
      setTimeout(() => {
        /* We set the local file in memory as the avatar here first, if the file is still being resized and
        uploaded to the database for persistence. */
        setAvatar(formValues.avatarFile);

        setSavingChanges((currentState) => ({
          ...currentState,
          avatar: false
        }));
      }, 2500);

      firebase
        .uploadAvatarToDatabase(formValues.avatarFile)
        .then(() => firebase?.auth?.currentUser?.reload());
    }
  }, [formValues.avatarFile]);

  useEffect(() => {
    if (user.avatarUrl !== avatar) {
      setAvatar(user.avatarUrl);
    }
  }, [user.avatarUrl]);

  useEffect(() => {
    if (formValues.fullName !== user.fullName) {
      setFormValues((currentValues) => ({
        ...currentValues,
        fullName: user.fullName
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      fullName: false
    }));
  }, [user.fullName]);

  useEffect(() => {
    if (formValues.email !== user.email) {
      setFormValues((currentValues) => ({
        ...currentValues,
        email: user.email
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      email: false
    }));
  }, [user.email]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.fullName && formValues.fullName !== user.fullName) {
        setSavingChanges((currentState) => ({
          ...currentState,
          fullName: true
        }));
      }

      const changeName = async () => {
        await firebase
          .updateNameInDatabase({ uid: user.uid, fullName: formValues.fullName })
          .then(() => setNotification('Name updated'))
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSavingChanges((currentState) => ({
              ...currentState,
              fullName: false
            }));
          });
      };

      if (formValues.fullName !== '' && formValues.fullName !== user.fullName) {
        changeName();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.fullName]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.email && formValues.email !== user.email) {
        setSavingChanges((currentState) => ({
          ...currentState,
          email: true
        }));
      }

      const changeEmail = async () => {
        await firebase.auth.currentUser
          .updateEmail(formValues.email)
          .then(() => firebase.updateEmailInDatabase({ uid: user.uid, email: formValues.email }))
          .then(() => setNotification('Email address updated'))
          .catch((err) => {
            console.error(err);
            switch (err.code) {
              case 'auth/requires-recent-login':
                setError({
                  code: err.code,
                  message:
                    'Changing your email address is a sensitive operation and requires recent authentication. Please log out and then log in again before retrying.'
                });
                break;
              default:
                setError({
                  code: err.code,
                  message: err.message
                });
                break;
            }
            setSavingChanges((currentState) => ({
              ...currentState,
              email: false
            }));
          });
      };

      if (formValues.email !== '' && formValues.email !== user.email) {
        changeEmail();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((currentValues) => ({
      ...currentValues,
      [name]: value
    }));
  };

  const handleGenerateTrustrackReport = () => {
    setGeneratingTrustrackReport(true);
    firebase
      .generateTrustrackReport({ email: user.email, selectedEvent })
      .then(({ data }) => {
        setGeneratingTrustrackReport(false);
        return window.open(data, '_blank') || window.location.replace(data);
      })
      .catch(console.error);
  };

  // const handleGenerateLivestreamCommentsReport = () => {
  //   setGeneratingUserReport(true);
  //   firebase
  //     .generateLivestreamCommentsReport({ email: user.email, selectedEvent })
  //     .then(({ data }) => {
  //       setGeneratingUserReport(false);
  //       return window.open(data, '_blank') || window.location.replace(data);
  //     })
  //     .catch(console.error);
  // };

  const handleAccountDeletion = async () => {
    setDeletingAccount(true);
    try {
      await firebase?.auth?.currentUser.delete();
      setShowUserAccountDeletedModal(true);
      if (selectedEvent) {
        navigate(`/events/${selectedEvent.slug}/`);
      } else {
        navigate('/');
      }
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case 'auth/requires-recent-login':
          setError({
            code: err.code,
            message:
              'Deleting your account is a sensitive operation and requires recent authentication. Please log out and then log in again before retrying.'
          });
          break;
        default:
          setError({
            code: err.code,
            message: err.message
          });
          break;
      }
    } finally {
      setDeletingAccount(false);
    }
  };

  return (
    <CustomContainer style={{ paddingBottom: 0 }} colors={selectedEvent?.colors || pfizer}>
      {requestAccountDeletionConfirmation ? (
        <>
          <p
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              lineHeight: '1.75rem',
              marginBottom: '2.25rem',
              textAlign: 'center'
            }}>
            Are you sure you want to delete
            <br />
            your Pfizer Virtual Cafe account?
          </p>
          <ErrorMessage
            errorMessage={error.message}
            style={{
              margin: '-0.75rem auto 2rem',
              maxWidth: '360px'
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: '420px',
              width: '100%',
              margin: '0 auto',
              marginBottom: '2.25rem'
            }}>
            <Button
              onClick={() => {
                if (error.message) {
                  setError({
                    code: '',
                    message: ''
                  });
                }
                setRequestAccountDeletionConfirmation(false);
              }}
              style={{
                backgroundColor: selectedEvent?.colors.secondary || pfizer.secondary,
                width: '200px'
              }}>
              Go Back
            </Button>
            {error.code === 'auth/requires-recent-login' ? (
              <Button
                savingChanges={savingChanges}
                type="button"
                onClick={() => handleLogout()}
                width="8rem"
                style={{
                  backgroundColor: selectedEvent?.colors.secondary || pfizer.secondary,
                  width: '200px'
                }}>
                Logout
              </Button>
            ) : (
              <Button
                onClick={handleAccountDeletion}
                style={{
                  backgroundColor: selectedEvent?.colors.secondary || pfizer.secondary,
                  width: '200px'
                }}>
                {deletingAccount ? (
                  <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
                ) : (
                  'Confirm Deletion'
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <ProfileImageWrapper colors={selectedEvent?.colors || pfizer}>
            {savingChanges.avatar ? (
              <AnimatePresence>
                {savingChanges.avatar && (
                  <LoadingSpinner
                    style={{
                      width: '6rem',
                      color: selectedEvent?.colors.secondary || pfizer.secondary
                    }}
                  />
                )}
              </AnimatePresence>
            ) : (
              <AnimatePresence>
                {!savingChanges.avatar &&
                  (avatar ? (
                    <ProfileImage
                      src={avatar}
                      alt={user.fullName}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    />
                  ) : (
                    <DefaultAvatar
                      width="70"
                      fill={selectedEvent?.colors.primary || pfizer.primary}
                    />
                  ))}
              </AnimatePresence>
            )}
          </ProfileImageWrapper>
          {savingChanges.avatar ? (
            <AnimatePresence>
              {savingChanges.avatar && (
                <SavingNewAvatar
                  saving={savingChanges.avatar}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  Uploading
                </SavingNewAvatar>
              )}
            </AnimatePresence>
          ) : (
            <AnimatePresence>
              {!savingChanges.avatar && (
                <>
                  <SetOrChangeAvatarButton
                    htmlFor="avatarFile"
                    whileHover={{
                      backgroundColor: selectedEvent?.colors.secondary || pfizer.secondary,
                      borderColor: selectedEvent?.colors.secondary || pfizer.secondary
                    }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    {`${avatar ? 'Change' : 'Set'} Profile Picture`}
                  </SetOrChangeAvatarButton>
                  <HiddenFileUpload
                    type="file"
                    name="avatarFile"
                    id="avatarFile"
                    onChange={(e) =>
                      e.target.files.length && fileReader.readAsDataURL(e.target.files[0])
                    }
                  />
                </>
              )}
            </AnimatePresence>
          )}
          <FormInputLabel htmlFor="fullName" style={{ fontWeight: 400 }}>
            Full Name
          </FormInputLabel>
          <div style={{ position: 'relative' }}>
            <FormInput
              id="fullName"
              name="fullName"
              type="text"
              value={formValues.fullName}
              onChange={handleInputChange}
              style={{
                marginBottom: '1.75rem'
              }}
              autoComplete="off"
            />
            {savingChanges.fullName && (
              <LoadingSpinner
                style={{
                  position: 'absolute',
                  color: selectedEvent?.colors.secondary || pfizer.secondary,
                  top: '0.75rem',
                  left: 'calc(100% - 2.75rem)',
                  width: '2.25rem'
                }}
              />
            )}
          </div>
          <FormInputLabel htmlFor="email" style={{ fontWeight: 400 }}>
            Email
          </FormInputLabel>
          <div style={{ position: 'relative' }}>
            <FormInput
              id="email"
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
              style={{
                marginBottom: '2rem'
              }}
              autoComplete="off"
            />
            {savingChanges.email && (
              <LoadingSpinner
                style={{
                  position: 'absolute',
                  color: selectedEvent?.colors.secondary || pfizer.secondary,
                  top: '0.75rem',
                  left: 'calc(100% - 2.75rem)',
                  width: '2.25rem'
                }}
              />
            )}
          </div>
          <Notification
            notification={notification}
            callback={() => setNotification('')}
            timeout={3000}
          />
          <ErrorMessage errorMessage={error.message} />
          <Actions>
            <AnimatePresence>
              <div style={{ alignSelf: 'center' }}>
                <Button
                  savingChanges={savingChanges}
                  type="button"
                  onClick={() => handleLogout()}
                  width="8rem"
                  style={{
                    backgroundColor: selectedEvent?.colors.secondary || pfizer.secondary
                  }}>
                  Logout
                </Button>
              </div>
              {selectedEvent && (user.isModerator?.includes(selectedEvent.eid) || user.isAdmin) && (
                <div style={{ alignSelf: 'center' }}>
                  <Button
                    savingChanges={savingChanges}
                    type="button"
                    onClick={() => {
                      navigate(`/events/${selectedEvent.slug}/event-analytics`);
                      setTimeout(() => setShowUserProfileModal(false), 750);
                    }}
                    width="12rem"
                    style={{
                      backgroundColor: pfizer.tertiary
                    }}>
                    Event Analytics
                  </Button>
                </div>
              )}
            </AnimatePresence>
          </Actions>
          {!loading && user.isAdmin && (
            <div
              style={{
                display: 'flex',
                height: '1.5rem',
                justifyContent: 'center',
                fontSize: '0.825rem',
                marginBottom: '10px',
                position: 'relative',
                width: '100%'
              }}>
              <AnimatePresence>
                {(generatingUserReport || generatingTrustrackReport) && (
                  <LoadingSpinner
                    style={{
                      color: '#fff',
                      position: 'absolute',
                      top: '-0.5rem',
                      width: '2.25rem'
                    }}
                  />
                )}
              </AnimatePresence>
              <AnimatePresence>
                {!generatingTrustrackReport && (
                  <GenerateReport
                    type="button"
                    whileHover={{
                      scale: 1.05
                    }}
                    whileTap={{
                      scale: 0.95
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                      fontSize: '0.825rem',
                      marginBottom: '0'
                    }}
                    onClick={() => handleGenerateTrustrackReport()}>
                    Trustrack Report
                  </GenerateReport>
                )}
              </AnimatePresence>
            </div>
          )}
          {/* {!loading && user.isAdmin && (
        <div
          style={{
            display: 'flex',
            height: '1.5rem',
            justifyContent: 'center',
            marginBottom: '1.5rem',
            position: 'relative',
            width: '100%'
          }}>
          <AnimatePresence>
            {generatingLivestreamCommentsReport && (
              <LoadingSpinner
                style={{
                  color: '#fff',
                  position: 'absolute',
                  top: '-0.313rem',
                  width: '2.25rem'
                }}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!generatingLivestreamCommentsReport && (
              <GenerateReport
                type="button"
                whileHover={{
                  scale: 1.05
                }}
                whileTap={{
                  scale: 0.95
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => handleGenerateLivestreamCommentsReport()}>
                {`Livestream Comments Report For ${selectedEvent?.name || 'Pfizer Virtual Cafe'}`}
              </GenerateReport>
            )}
          </AnimatePresence>
        </div>
      )} */}
          <DeleteAccount
            type="button"
            whileHover={{
              scale: 1.05
            }}
            whileTap={{
              scale: 0.95
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              fontSize: '0.825rem',
              marginBottom: '1.75em'
            }}
            onClick={() => setRequestAccountDeletionConfirmation(true)}>
            Delete Account
          </DeleteAccount>
        </>
      )}
    </CustomContainer>
  );
};

const ProfileImageWrapper = styled.div`
  align-items: center;
  align-self: center;
  background: #c4c4c4;
  border-radius: 50%;
  border: 0.2rem solid ${({ colors }) => colors.primary};
  display: flex;
  height: 10rem;
  justify-content: center;
  margin-bottom: 1.75rem;
  margin-top: -7.5rem;
  overflow: hidden;
  width: 10rem;
`;

const ProfileImage = styled(motion.img)`
  background: transparent;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const SavingNewAvatar = styled(motion.p)`
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0 auto 2rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${({ saving }) =>
    saving &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.175rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 #fff;
        }
      }
    `}
`;

const SetOrChangeAvatarButton = styled(motion.label)`
  align-items: center;
  align-self: center;
  border-radius: 3rem;
  border: 1px solid #fff;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  max-width: 17rem;
  width: 100%;
`;

const CustomContainer = styled(FormContainer)`
  background-color: ${({ colors }) => colors.primary};
  max-width: 100%;
  margin-top: 4rem;
  margin-bottom: 1.25rem;
  ${({ style }) => style};
`;

const GenerateReport = styled(motion.button)`
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  text-decoration: underline;
  position: absolute;
  top: 0;
`;

const DeleteAccount = styled(motion.button)`
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  text-decoration: underline;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 1.0625rem;
  margin-bottom: 1.313rem;
`;

export default UserProfile;
