import React from 'react';
import { EventSpeaker, IndividualSpeaker } from 'components';
import styled from 'styled-components';

export default ({ eventSpeakers, colors, eid }) => {
  return (
    <Speakers eid={eid}>
      {eventSpeakers.length === 1 && <IndividualSpeaker data={eventSpeakers[0]} colors={colors} />}
      {eventSpeakers.length >= 2 && (
        <>
          {eventSpeakers.map((speaker) => (
            <EventSpeaker
              key={speaker.speakerOrder}
              data={speaker}
              buttonText="Read Bio"
              cropped
              popupType="speaker"
              colors={colors}
              carouselData={eventSpeakers}
              eid={eid}
            />
          ))}
        </>
      )}
    </Speakers>
  );
};

const Speakers = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr;
  justify-content: center;
  margin: 0 auto;
  max-width: ${({ eid }) => eid === 'j3vd74' ? '1000px' : '1400px'};
  padding: 0 1.25rem;
  position: relative;
  width: 100%;
  @media (min-width: 1200px) {
    gap: ${({ eid }) => eid === 'j3vd74' ? '6rem' : '0'};
  }
`;
