import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, gridContainer } from 'styles';
import { AnimatePresence, motion } from 'framer-motion';
import {
  BgMoon1,
  BgMoon2,
  FbIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon,
  InstaIcon,
  WebsiteIcon,
  SpeakerSvgBackground
} from 'assets/svgs';
import { Markup } from 'interweave';

export default ({ data, colors, eid }) => {
  const speakerImage = getImage(data.image);

  const speakerHasSocialLinks =
    data.websiteLink ||
    data.fbLink ||
    data.linkedinLink ||
    data.twLink ||
    data.igLink ||
    data.emailLink;

  return (
    <Slide colors={colors}>
        <SpeakerImgContainer
          eid={eid}>
          {/* <SVG colors={colors}>
              <Circle1 />
            </SVG>
            <SVG colors={colors}>
              <Circle2 />
            </SVG> */}
          <SingleSVG>
            <SpeakerSvgBackground />
          </SingleSVG>
          <SpeakerImg image={speakerImage} cropped />
        </SpeakerImgContainer>
      <SpeakerDetails>
        <SpeakerName>
          <Markup content={data.name} noWrap />
        </SpeakerName>
        {data.position && (
          <SpeakerTitle colors={colors}>
            <Markup content={data.position} />
          </SpeakerTitle>
        )}
        {speakerHasSocialLinks && (
          <SpeakerSocials>
            {data.websiteLink && (
              <SpeakerSocial href={data.websiteLink} target="_blank" colors={colors}>
                <WebsiteIcon />
              </SpeakerSocial>
            )}
            {data.linkedinLink && (
              <SpeakerSocial href={data.linkedinLink} target="_blank" colors={colors}>
                <LinkedinIcon />
              </SpeakerSocial>
            )}
            {data.twLink && (
              <SpeakerSocial href={data.twLink} target="_blank" colors={colors}>
                <TwitterIcon />
              </SpeakerSocial>
            )}
            {data.fbLink && (
              <SpeakerSocial href={data.fbLink} target="_blank" colors={colors}>
                <FbIcon />
              </SpeakerSocial>
            )}
            {data.igLink && (
              <SpeakerSocial href={data.igLink} target="_blank" colors={colors}>
                <InstaIcon />
              </SpeakerSocial>
            )}
            {data.emailLink && (
              <SpeakerSocial href={data.emailLink} target="_blank" colors={colors}>
                <EmailIcon />
              </SpeakerSocial>
            )}
          </SpeakerSocials>
        )}
        <SpeakerSummary colors={colors}>
          <Markup content={data.summary} />
        </SpeakerSummary>
      </SpeakerDetails>
    </Slide>
  );
};

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem 0 0.25rem;
`;

const SpeakerSocial = styled(motion.a).attrs({
  whileHover: {
    scale: 1.075
  },
  whileTap: {
    scale: 0.925
  }
})`
  margin-right: 0.625rem;
  svg {
    width: 1.5rem;
    circle {
      fill: ${({ colors }) => colors.tertiary};
    }
  }
`;

const SpeakerSummary = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: normal;
  margin-top: 1.15rem !important;
  max-width: 400px !important;
  text-align: center !important;
  @media (min-width: 1150px) {
    text-align: justify !important;
    max-width: 100% !important;
  }
`;

const SpeakerName = styled.h3`
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  color: #3c3c3c;
  margin: 0 auto;
  text-align: center !important;
  @media (min-width: 1150px) {
    text-align: left !important;
    margin-left: 0;
  }
`;

const SpeakerTitle = styled.p`
  color: ${({ colors }) => colors.tertiary} !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem !important;
  margin: 1rem 0 0.375rem !important;
  text-align: center !important;
  @media (min-width: 1150px) {
    margin: 0.25rem 0 0.375rem !important;
    text-align: left !important;
  }
`;

const SpeakerDetails = styled.div`
  align-items: center;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: center;
  margin-left: 0px;
  @media (min-width: ${breakpoints.lg}) {
    margin-left: -100px;
    padding: 2.5rem 2.5rem 0;
    grid-column: 5/11;
    align-items: flex-start;
  }
`;

/* const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  svg {
    path {
      fill: ${({ colors }) => colors.tertiary};
      transition: 0.5s;
    }
  }
  :nth-child(1) {
    svg {
      path {
        fill: ${({ colors }) => colors.primary};
      }
    }
  }
  :nth-child(2) {
    svg {
      path {
        fill: ${({ colors }) => colors.secondary};
      }
    }
  }
`; */

const SingleSVG = styled.div`
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 1s;
  height: 320px;
  width: 320px;
`;

/* const Circle1 = styled(BgMoon1)`
  bottom: -15%;
  height: 100%;
  left: -15%;
  position: absolute;
  transition-duration: 1s;
  width: 100%;
`;
const Circle2 = styled(BgMoon2)`
  height: 100%;
  position: absolute;
  right: -15%;
  top: -15%;
  transition-duration: 1s;
  width: 100%;
`; */

const SpeakerImg = styled(GatsbyImage)`
  border-radius: ${({ cropped }) => (cropped ? '50%' : '0px')};
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  height: 250px;
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 250px;
  z-index: 2; ;
`;

const SpeakerImgContainer = styled(motion.div)`
  grid-column: 1/7;
  justify-self: center;
  max-width: 100%;
  position: relative;
  width: auto;
  margin-bottom: 3rem;
  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    max-width: 100%;
    grid-column: 1/4;
    align-self: flex-start;
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.xl}) {
    grid-column: 1/5;
  }
`;

const Slide = styled.div`
  ${gridContainer}
  align-items: center;
  color: #3c3c3c;
  height: auto;
  max-width: 100%;
  padding: 1.25rem 1.25rem 0;
  position: relative;
  width: 1200px;
`;
