import * as React from 'react';

function SvgMutedIcon(props) {
  return (
    <svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22 22.184L1.692 1.82l.001-.001v-.001L0 3.515l4.269 4.28A12.537 12.537 0 004.05 10.2v6.6L1.657 18v2.4H16.84l3.47 3.48L22 22.183zM18.411 15.191V10.2c0-3.684-1.364-6.768-4.787-7.584V0H8.838v2.616a5.89 5.89 0 00-2.06.91L18.41 15.19z"
        fill="#fff"
      />
      <path
        d="M13.624 21.6c0 1.32-1.077 2.4-2.393 2.4a2.396 2.396 0 01-2.393-2.4h4.787z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgMutedIcon;
