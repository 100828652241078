import * as React from 'react';

function SvgQuizmasFunIcon1(props) {
  return (
    <svg viewBox="0 0 132 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M43.25 55.49L12.685 58.93a5.18 5.18 0 01-3.432-1.017 5.204 5.204 0 01-1.96-3.002L.606 24.598c-.68-3.064 1.017-5.81 3.798-6.063l34.35-3.798c2.782-.267 5.28 2.08 5.574 5.182l2.906 30.668a4.478 4.478 0 01-2.32 4.392 4.448 4.448 0 01-1.663.512z"
        fill="#FFBA38"
      />
      <path
        d="M85.376 52.716l34.928 3.806c2.134.235 4.328-1.545 4.872-4.009l6.658-30.251c.673-3.06-.761-5.802-3.218-6.063l-38.27-4.165c-2.455-.267-4.724 2.072-5.058 5.17l-3.319 30.598c-.284 2.497 1.256 4.68 3.407 4.914z"
        fill="#FA4442"
      />
      <path
        d="M66.903.844c-6.294 0-12.4 2.152-17.308 6.102a27.736 27.736 0 00-9.69 15.62A27.793 27.793 0 0042.1 40.83a27.688 27.688 0 0013.112 12.867l6.91 11.333.954-9.004c1.268.177 2.546.267 3.827.27a27.617 27.617 0 0019.554-8.12 27.764 27.764 0 008.1-19.606 27.764 27.764 0 00-8.1-19.605A27.617 27.617 0 0066.903.844z"
        fill="#30D9C8"
      />
      <path
        d="M66.903.65a27.771 27.771 0 00-17.416 6.137 27.901 27.901 0 00-9.75 15.717 27.957 27.957 0 002.21 18.376 27.852 27.852 0 0013.197 12.942l6.95 11.412.96-9.058c3.774.516 7.613.25 11.28-.78a27.792 27.792 0 0010.038-5.217 27.875 27.875 0 007.138-8.79 27.947 27.947 0 00-4.322-31.843 27.818 27.818 0 00-9.222-6.558A27.755 27.755 0 0066.903.65z"
        fill="#CB10AD"
      />
      <path d="M41.867 64.852l-1.775-9.454-8.17.492 9.945 8.962z" fill="#F5C505" />
      <path d="M86.134 64.852l9.287-11.804-7.793-1.33-1.494 13.134z" fill="#FA4442" />
      <path
        d="M22.355 36.17c.374-.168.737-.359 1.088-.571a4.77 4.77 0 00.978-.777c.279-.286.496-.626.637-.999.14-.406.151-.846.032-1.259a1.896 1.896 0 00-1.149-1.398 3.392 3.392 0 00-2.134-.046 9.748 9.748 0 00-.896.267 4.64 4.64 0 00-.712.307c-.21.114-.426.235-.64.357a9.233 9.233 0 00-.672.438 8.05 8.05 0 01-.886-1.166c-.26-.42-.468-.87-.618-1.34.38-.25.736-.475 1.067-.668.321-.19.652-.364.992-.52.32-.15.647-.28.982-.389.334-.11.693-.214 1.067-.314 1.932-.475 3.5-.403 4.705.218 1.205.62 1.999 1.704 2.383 3.252.19.675.21 1.387.06 2.072-.14.59-.38 1.15-.71 1.658-.292.458-.641.876-1.04 1.245a7.881 7.881 0 01-.931.788l.555 2.207c-.511.23-1.04.415-1.583.553a7.903 7.903 0 01-1.561.24l-1.014-4.155zm2.18 9.707c-.244-.561-.441-1.14-.59-1.733a12.272 12.272 0 01-.306-1.83 11.53 11.53 0 011.728-.592c.6-.15 1.212-.25 1.829-.303.239.56.435 1.139.586 1.73.151.601.253 1.214.306 1.832-.56.241-1.138.438-1.728.589-.599.152-1.21.255-1.825.307zM104.511 41.854c.013-.61.074-1.218.182-1.819.115-.606.277-1.202.484-1.783a11.67 11.67 0 011.817.186c.604.115 1.199.277 1.778.485a11.188 11.188 0 01-.665 3.612 11.659 11.659 0 01-1.817-.185c-.606-.116-1.2-.282-1.779-.496zm3.166-17.688a12.592 12.592 0 013.799.706l-2.465 11.971a9.89 9.89 0 01-1.672-.156 10.04 10.04 0 01-1.64-.457l1.978-12.064zM72.227 36.284c4.905 2.6 7.885-.735 6.232-3.081-1.779-2.518-5.894-1.17-7.2-5.193 1.182 1.102 4.002 1.245 4.664-.713 1.12-3.334-7.398-3.88-8.511-13.373-.257-2.193-1.836-2.546-2.113-.442-1.377 10.438-9.03 10.848-8.18 14.204.48 1.872 3.474 1.758 4.538.656-.512 2.593-3.692 2.95-5.478 4.115-3.2 2.054-.32 7.35 4.73 3.787-.632 4.251-8.108 2.568-8.425 6.851-.178 2.421 3.695 4.89 8.316.795-.356 2.172 2.404 3.142 4.147 1.694a85.068 85.068 0 00-.082 3.32h3.012a56.307 56.307 0 01-.355-2.853c1.874 1.113 4.339.542 4.744-2.375 2.323 3.335 8.746 3.335 9.056-.16.38-4.38-7.106-2.357-9.095-7.232z"
        fill="#fff"
      />
      <path
        d="M64.502 12.24l1.807-.951 1.803.952-.345-2.015L69.225 8.8l-2.016-.293-.9-1.833-.903 1.833-2.017.293 1.458 1.426-.345 2.015z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgQuizmasFunIcon1;
