import { useEffect, useState } from 'react';
import queryString from 'query-string';
import * as Sentry from '@sentry/gatsby';
import { useLocation } from '@reach/router';
import { pfizer } from 'styles';
import { useStaticQuery, graphql } from 'gatsby';
import { clearQueryParams } from 'utils';
import getFirebaseInstance from './firebase';
import loadFirebaseDependencies from './loadFirebaseDependencies';

function useAuth() {
  const [user, setUser] = useState(null);
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const { events } = useStaticQuery(graphql`
    query {
      events: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(events)/" } }) {
        edges {
          node {
            id
            frontmatter {
              eid
              name
              slug
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    let unsubscribe;
    let userUnsubscribe;
    const queryParams = queryString.parse(location.search);
    const { mode } = queryParams;

    loadFirebaseDependencies.then((app) => {
      const firebaseInstance = getFirebaseInstance(app);
      setFirebase(firebaseInstance);

      unsubscribe = firebaseInstance.auth.onAuthStateChanged((userAuthInfo) => {
        if (userAuthInfo) {
          userUnsubscribe = firebaseInstance.subscribeToUserUpdates({
            uid: userAuthInfo.uid,
            onSnapshot: (_user) => {
              firebaseInstance.auth.currentUser
                .getIdTokenResult(true)
                .then(async (token) => {
                  if (_user.exists) {
                    const userDatabaseInfo = _user.data();
                    const lastRefreshAt = new Date(
                      token.issuedAtTime
                    ).getTime();
                    if (!mode && userDatabaseInfo && !userDatabaseInfo.emailVerified) {
                      const { firstName, email } = userDatabaseInfo;

                      const actionCodeSettings = {
                        handleCodeInApp: true,
                        url: `${window.location.href.split("?")[0]}${
                          userDatabaseInfo.eventsUserWantsToAccess.length > 0
                            ? `?eventsUserWantsToAccess=${userDatabaseInfo.eventsUserWantsToAccess.join(
                                ','
                              )}`
                            : ''
                        }`
                      };

                      // We stringify the event because dataValidator() in sendVerificationEmail()
                      // checks for a string. This way we can pass in an empty string if someone
                      // registers on the homepage, or a stringified object if someone registers
                      // on an event page. If the latter, we use JSON.parse() on the event string.
                      let stringifiedEvent;

                      if (userDatabaseInfo.eventsUserWantsToAccess.length > 0) {
                        const result = events.edges.find(
                          ({ node }) =>
                            node.frontmatter.eid ===
                            [...userDatabaseInfo.eventsUserWantsToAccess].pop()
                        );

                        if (result) {
                          stringifiedEvent = JSON.stringify(result.node.frontmatter);
                        }
                      }

                      try {
                        await firebaseInstance.sendVerificationEmail({
                          firstName,
                          email,
                          actionCodeSettings,
                          colors: pfizer,
                          event: stringifiedEvent ?? ''
                        });
                      } catch (error) {
                        Sentry.captureException(error);
                        console.error(error.message);
                      }
                    }

                    setUser({
                      ...userAuthInfo,
                      ...userDatabaseInfo,
                      lastRefreshAt,
                      isAdmin: token.claims.isAdmin,
                      isModerator: token.claims.isModerator
                    });
                  }
                })
                .catch((error) => {
                  console.error(error.message);
                });
            }
          });
        } else {
          setUser(null);
        }
        setLoading(false);
      });
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      if (userUnsubscribe) {
        userUnsubscribe();
      }
    };
  }, []);

  return { user, setUser, firebase, loading };
}

export default useAuth;
