const firebaseConfig = {
  apiKey: "AIzaSyAJRvTRfLDKteqWydjU5D8-KknWQpCnZfo",
  authDomain: "pfizer-virtual-cafe.firebaseapp.com",
  projectId: "pfizer-virtual-cafe",
  databaseURL: "https://pfizer-virtual-cafe-default-rtdb.europe-west1.firebasedatabase.app/",
  storageBucket: "pfizer-virtual-cafe.appspot.com",
  messagingSenderId: "208122876774",
  appId: "1:208122876774:web:31babad17b2cda8061eea7",
  measurementId: "G-3SKVVBLKWR"
};

export default firebaseConfig;
