import * as React from 'react';

function SvgMobIcon(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={13} cy={13} r={13} fill="#E79E6D" />
      <path
        d="M9 18.963V7.037C9 6.415 9.381 6 9.952 6h6.096c.571 0 .952.415.952 1.037v11.926c0 .622-.381 1.037-.952 1.037H9.952C9.381 20 9 19.585 9 18.963zM9 16.267h8M12.333 18.03h1.429M12.905 7.763h.286"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMobIcon;
