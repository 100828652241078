import * as React from 'react';

function SvgEvolve21Logo(props) {
  return (
    <svg viewBox="0 0 572 217" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M195.82 189.652h-30.37c1.294 6.816 7.334 10.612 13.977 10.612 4.746 0 9.318-1.122 13.632-2.847a2.436 2.436 0 011.295-.345c.949 0 1.553.69 1.725 1.553.432 1.812 2.071 6.816 2.071 8.455 0 1.38-.949 1.812-2.071 2.416-5.78 2.588-13.373 3.71-19.671 3.71-17.256 0-28.214-10.612-28.214-27.955 0-16.824 11.389-27.868 28.127-27.868 6.903 0 12.77 2.589 16.825 6.903 5.435 5.78 6.471 13.2 6.471 20.793v1.38c.086 2.675-1.122 3.193-3.797 3.193zm-20.448-19.758c-5.435 0-8.973 4.314-9.749 9.404h18.118c0-4.659-2.674-9.404-8.369-9.404z"
        fill="url(#evolve-21-logo_svg__paint0_linear)"
      />
      <path
        d="M257.337 162.042l-19.671 47.368c-.777 1.984-1.639 2.243-3.71 2.243h-6.385c-1.812 0-3.02-.345-3.796-2.157l-19.327-47.971c-.086-.432-.172-.777-.172-1.122 0-.863.69-1.553 2.243-1.553h12.511c2.07 0 3.019.949 3.623 2.675l6.816 22.001c.777 2.243 1.64 5.522 2.157 7.851h.087c.517-1.984 1.38-5.176 2.157-7.592l7.247-22.26c.777-2.157 1.639-2.675 3.969-2.675h10.008c1.64 0 2.589.69 2.589 1.812 0 .431-.087.949-.346 1.38z"
        fill="url(#evolve-21-logo_svg__paint1_linear)"
      />
      <path
        d="M288.916 213.206c-18.292 0-26.919-11.82-26.919-27.954 0-15.962 10.094-27.782 27.695-27.782 18.378 0 26.919 11.82 26.919 27.954 0 15.962-10.094 27.782-27.695 27.782zm.604-42.967c-8.024 0-10.613 6.902-10.613 14.84 0 8.024 2.244 15.271 10.354 15.271 8.024 0 10.612-7.161 10.612-15.098-.086-8.024-2.329-15.013-10.353-15.013z"
        fill="url(#evolve-21-logo_svg__paint2_linear)"
      />
      <path
        d="M341.632 211.739h-10.526c-1.639 0-2.934-.863-2.934-2.934v-70.403c0-2.157.949-2.848 2.589-3.02l10.267-1.381h1.035c1.553 0 2.244.95 2.244 2.675v72.043c.086 2.071-.95 3.02-2.675 3.02z"
        fill="url(#evolve-21-logo_svg__paint3_linear)"
      />
      <path
        d="M406.6 162.043l-19.672 47.367c-.776 1.984-1.639 2.243-3.71 2.243h-6.384c-1.812 0-3.02-.345-3.797-2.157l-19.412-48.057c-.087-.432-.173-.777-.173-1.122 0-.863.69-1.553 2.243-1.553h12.511c2.071 0 3.02.949 3.624 2.675l6.816 22.001c.776 2.243 1.639 5.522 2.157 7.851h.086c.518-1.984 1.38-5.176 2.157-7.592l7.247-22.26c.777-2.157 1.64-2.675 3.969-2.675h10.008c1.64 0 2.589.69 2.589 1.812.086.518-.086 1.035-.259 1.467z"
        fill="url(#evolve-21-logo_svg__paint4_linear)"
      />
      <path
        d="M458.712 189.652h-30.37c1.294 6.816 7.42 10.612 13.977 10.612 4.746 0 9.319-1.122 13.633-2.847a2.43 2.43 0 011.294-.345c.949 0 1.553.69 1.725 1.553.432 1.812 2.071 6.816 2.071 8.455 0 1.38-.949 1.812-2.071 2.416-5.78 2.588-13.373 3.71-19.671 3.71-17.256 0-28.214-10.612-28.214-27.955 0-16.824 11.389-27.868 28.127-27.868 6.903 0 12.77 2.589 16.825 6.903 5.435 5.78 6.471 13.2 6.471 20.793v1.38c.172 2.675-1.036 3.193-3.797 3.193zm-20.361-19.758c-5.436 0-8.973 4.314-9.75 9.404h18.119c0-4.659-2.761-9.404-8.369-9.404z"
        fill="url(#evolve-21-logo_svg__paint5_linear)"
      />
      <path
        d="M536.623 172.395c0-10.44-7.506-17.256-19.24-17.256-5.954 0-11.734 1.726-17.342 5.091-1.726 1.035-2.589 2.329-2.589 3.968 0 .863.259 1.64.518 2.157l2.243 4.746c1.122 2.502 3.106 2.761 3.883 2.761.862 0 1.725-.259 2.761-.863 3.019-1.812 6.471-2.934 9.059-2.934 3.882 0 3.882 1.899 3.882 2.848 0 1.898-2.243 5.09-6.729 9.49a143.856 143.856 0 01-7.248 6.73 304.07 304.07 0 00-7.679 7.161c-1.811 1.726-2.415 3.193-2.415 5.781v4.314c0 3.537 1.811 5.349 5.349 5.349h29.248c3.193 0 5.091-1.898 5.091-5.177v-4.917c0-3.106-1.984-5.005-5.091-5.005-4.572 0-8.11 0-10.698.087l.086-.087c6.04-4.831 16.911-13.804 16.911-24.244z"
        fill="url(#evolve-21-logo_svg__paint6_linear)"
      />
      <path
        d="M567.425 156.26h-4.401c-2.07 0-3.451.345-5.176 1.294l-15.013 8.628c-.69.345-2.847 1.553-2.847 4.228 0 .777.172 1.553.517 2.33l1.985 3.968c1.035 2.071 2.502 3.193 4.314 3.193 1.121 0 2.071-.518 2.674-.777l5.781-3.106v30.888c0 4.4 2.589 5.004 5.436 5.004h6.039c4.401 0 5.263-2.761 5.263-5.09v-45.901c.087-2.933-1.639-4.659-4.572-4.659z"
        fill="url(#evolve-21-logo_svg__paint7_linear)"
      />
      <path
        d="M45.522 126.15c-1.208-3.106-4.745-4.573-7.851-3.365-3.106 1.208-4.573 4.746-3.365 7.852 1.208 3.106 4.745 4.572 7.851 3.364 3.106-1.207 4.573-4.745 3.365-7.851z"
        fill="#0095FF"
      />
      <path
        d="M31.718 93.019c.431-.173.863-.346 1.208-.518 2.674-1.38 5.867-1.035 8.282.776l12.942 9.577c2.589 1.985 3.797 5.263 2.934 8.456-.95 3.71-.863 7.679.69 11.475a17.387 17.387 0 001.898 3.537c1.898 2.761 1.985 6.299.173 9.06l-5.867 8.714c-1.467 2.243-3.883 3.623-6.558 3.71-.345 0-.776 0-1.121.086-4.918.604-8.456 5.177-7.938 10.008.604 5.263 5.522 9.06 10.871 8.024 4.228-.862 7.334-4.572 7.334-8.886a9.23 9.23 0 00-.69-3.538c-.95-2.416-.69-5.177.776-7.334l5.954-8.886c1.811-2.761 5.176-4.055 8.369-3.365 3.71.776 7.678.431 11.389-1.294 8.627-3.969 12.769-14.064 9.49-22.951-3.537-9.75-14.408-14.495-23.985-10.698-1.036.431-1.985.863-2.848 1.467-2.76 1.725-6.298 1.553-8.973-.346l-12.942-9.75c-2.415-1.811-3.537-4.83-3.02-7.764.432-2.416.173-5.004-.776-7.42v-.087c-1.121-2.847-.69-6.039 1.294-8.282l18.55-21.225c1.726-1.984 4.4-2.933 6.989-2.675.95.087 1.984.087 3.02-.172 4.055-.95 6.988-4.66 7.075-8.8.086-5.781-5.177-10.181-10.871-9.06-4.142.777-7.334 4.487-7.334 8.8 0 .863.086 1.64.259 2.416.69 2.589-.087 5.35-1.812 7.334L38.102 64.46a7.989 7.989 0 01-8.024 2.416c-3.02-.776-6.384-.518-9.49.95-6.126 3.019-9.06 10.18-6.989 16.565 2.502 7.678 10.785 11.561 18.119 8.627z"
        fill="url(#evolve-21-logo_svg__paint8_linear)"
      />
      <path
        d="M32.925 50.656c3.106-1.208 4.573-4.746 3.365-7.852-1.208-3.106-4.745-4.572-7.851-3.364-3.106 1.207-4.573 4.745-3.365 7.85 1.294 3.107 4.745 4.574 7.851 3.366zM6.87 159.971a6.084 6.084 0 00-6.817 5.091 6.084 6.084 0 005.09 6.816 6.084 6.084 0 006.817-5.091 6.083 6.083 0 00-5.09-6.816zM164.069 48.24a6.084 6.084 0 00-6.816 5.09 6.084 6.084 0 005.091 6.816 6.084 6.084 0 006.816-5.09c.517-3.279-1.812-6.385-5.091-6.816z"
        fill="#0095FF"
      />
      <path
        d="M84.175 76.367l-10.526-.69c-2.675-.173-5.09-1.554-6.47-3.883-.173-.345-.432-.604-.605-.95-3.02-3.882-8.714-4.745-12.683-1.811-4.314 3.106-5.09 9.318-1.466 13.46 2.847 3.192 7.592 4.054 11.388 1.897 1.122-.603 1.985-1.466 2.761-2.33 1.64-2.07 4.142-3.191 6.73-3.019l10.699.69c3.278.26 6.04 2.416 7.075 5.609 1.207 3.623 3.45 6.902 6.816 9.231 7.765 5.436 18.549 4.055 24.589-3.192 6.644-7.938 5.349-19.758-2.761-26.142-.863-.69-1.725-1.295-2.675-1.726-2.847-1.553-4.572-4.66-4.141-7.938l1.812-16.134c.345-3.02 2.416-5.435 5.263-6.47 2.329-.864 4.4-2.33 6.039-4.401 1.899-2.416 4.832-3.624 7.852-3.02l27.609 5.436c2.589.517 4.745 2.33 5.781 4.745.431.95.949 1.812 1.725 2.588 2.848 3.02 7.507 3.797 11.13 1.726 5.005-2.847 6.212-9.577 2.416-13.977-2.761-3.279-7.592-4.142-11.389-1.985-.776.432-1.38.95-1.984 1.467-1.898 1.898-4.659 2.589-7.334 2.07l-27.264-5.435c-2.933-.604-5.349-2.847-6.126-5.694-.863-3.02-2.761-5.78-5.608-7.765-5.608-3.797-13.373-2.847-17.86 2.243-5.349 5.953-4.486 15.099 1.726 19.93.345.26.69.518 1.122.777 2.502 1.64 3.796 4.573 3.451 7.593l-1.726 15.961c-.345 3.279-2.674 5.953-5.867 6.816-3.71.95-7.075 3.106-9.577 6.385a13.611 13.611 0 00-2.07 3.365c-1.467 2.933-4.573 4.831-7.852 4.573z"
        fill="url(#evolve-21-logo_svg__paint9_linear)"
      />
      <path
        d="M143.966 10.795c2.589 2.07 6.385 1.64 8.456-1.035 2.07-2.589 1.639-6.385-1.036-8.456-2.588-2.07-6.384-1.64-8.455 1.036-1.984 2.588-1.553 6.384 1.035 8.455zM75.461 51.95c-2.07 2.588-1.64 6.384 1.035 8.455 2.589 2.07 6.385 1.64 8.456-1.035 2.07-2.589 1.639-6.385-1.036-8.456-2.674-1.984-6.47-1.553-8.455 1.036z"
        fill="#0095FF"
      />
      <path
        d="M76.496 176.105a18.267 18.267 0 0010.354 5.177c1.38.173 2.674.259 3.968.173 3.279-.259 6.471 1.466 7.938 4.4l4.659 9.404c1.208 2.416 1.208 5.263-.086 7.507l-.518 1.035c-1.898 4.573.259 9.922 4.745 11.906 4.832 2.157 10.613-.258 12.338-5.435 1.381-4.055-.345-8.628-4.055-10.785-1.121-.604-2.243-1.035-3.451-1.208a7.762 7.762 0 01-5.953-4.314l-4.745-9.577c-1.467-2.933-.863-6.471 1.294-8.973 2.502-2.847 4.227-6.471 4.572-10.526.863-9.404-5.78-18.119-15.098-19.672-10.181-1.725-19.758 5.263-21.225 15.444-.172 1.036-.172 2.157-.172 3.193.086 3.278-1.812 6.298-4.746 7.592l-14.926 6.471c-2.761 1.208-5.953.604-8.197-1.294-1.898-1.553-4.227-2.675-6.816-3.02h-.086c-3.02-.431-5.522-2.416-6.557-5.263l-9.06-26.833c-.862-2.502-.345-5.263 1.208-7.42.604-.776 1.036-1.725 1.38-2.761 1.209-3.968-.517-8.369-4.054-10.526-5.004-2.933-11.39-.603-13.287 4.832-1.467 4.055.259 8.628 3.882 10.871.777.432 1.467.777 2.244.949 2.588.691 4.572 2.761 5.435 5.263l9.06 26.402c.949 2.847.258 6.039-1.899 8.196-2.243 2.243-3.71 5.263-3.882 8.714-.432 6.73 4.227 12.942 10.87 14.323 7.852 1.639 15.272-3.71 16.394-11.475.086-.432.086-.863.086-1.295.173-3.019 2.07-5.608 4.832-6.816l14.754-6.384c3.02-1.208 6.47-.604 8.8 1.725z"
        fill="url(#evolve-21-logo_svg__paint10_linear)"
      />
      <path
        d="M240.858 81.889a6.083 6.083 0 00-6.816 5.09 6.083 6.083 0 005.09 6.817 6.083 6.083 0 006.816-5.09c.518-3.28-1.725-6.3-5.09-6.817zM76.842 198.797a6.084 6.084 0 005.09 6.816 6.084 6.084 0 006.816-5.091 6.084 6.084 0 00-5.09-6.816c-3.279-.517-6.385 1.812-6.816 5.091z"
        fill="#0095FF"
      />
      <path
        d="M236.285 117.177c-.776-.431-1.467-.776-2.243-.949-2.589-.69-4.573-2.761-5.436-5.263l-9.059-26.401c-.949-2.847-.259-6.04 1.898-8.197 2.243-2.243 3.71-5.263 3.883-8.714.431-6.73-4.228-12.942-10.872-14.322-7.851-1.64-15.271 3.71-16.393 11.475-.086.431-.086.863-.086 1.294-.172 3.02-2.071 5.608-4.831 6.816l-14.84 6.471c-3.02 1.294-6.471.604-8.801-1.64a18.296 18.296 0 00-10.267-5.176c-1.381-.173-2.675-.259-3.969-.173-3.279.26-6.471-1.466-7.938-4.4l-4.659-9.404c-1.208-2.416-1.208-5.263.087-7.507l.517-1.035c1.898-4.573-.259-9.922-4.745-11.906-4.832-2.157-10.612.258-12.338 5.435-1.38 4.055.345 8.628 4.055 10.785 1.122.604 2.243 1.035 3.451 1.208a7.765 7.765 0 015.954 4.314l4.745 9.577c1.467 2.933.863 6.47-1.294 8.973-2.502 2.847-4.228 6.47-4.573 10.526-.863 9.404 5.781 18.119 15.099 19.672 10.181 1.725 19.758-5.263 21.224-15.444.173-1.036.173-2.157.173-3.193-.086-3.278 1.812-6.298 4.745-7.592l14.927-6.471c2.76-1.208 5.953-.604 8.196 1.294 1.898 1.553 4.228 2.675 6.816 3.02h.086c3.02.431 5.522 2.416 6.558 5.263l9.145 26.66c.863 2.502.345 5.263-1.208 7.42-.604.777-1.035 1.726-1.38 2.761-1.208 3.969.517 8.369 4.055 10.526 5.004 2.933 11.389.604 13.287-4.832 1.38-4.055-.259-8.628-3.969-10.871z"
        fill="url(#evolve-21-logo_svg__paint11_linear)"
      />
      <path
        d="M200.479 127.617c1.208 3.106 4.746 4.572 7.852 3.364 3.106-1.207 4.573-4.745 3.365-7.851-1.208-3.106-4.746-4.573-7.852-3.365-3.019 1.294-4.572 4.746-3.365 7.852z"
        fill="#0095FF"
      />
      <defs>
        <linearGradient
          id="evolve-21-logo_svg__paint0_linear"
          x1={64.205}
          y1={187.915}
          x2={736.849}
          y2={171.9}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint1_linear"
          x1={64.232}
          y1={189.065}
          x2={736.876}
          y2={173.05}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint2_linear"
          x1={64.27}
          y1={190.653}
          x2={736.914}
          y2={174.637}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint3_linear"
          x1={64.004}
          y1={179.477}
          x2={736.648}
          y2={163.462}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint4_linear"
          x1={64.317}
          y1={192.617}
          x2={736.961}
          y2={176.601}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint5_linear"
          x1={64.354}
          y1={194.172}
          x2={736.998}
          y2={178.157}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint6_linear"
          x1={64.359}
          y1={194.389}
          x2={737.003}
          y2={178.373}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint7_linear"
          x1={64.396}
          y1={195.909}
          x2={737.039}
          y2={179.894}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint8_linear"
          x1={13.388}
          y1={50.698}
          x2={137.507}
          y2={176.818}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint9_linear"
          x1={64.516}
          y1={0.381}
          x2={188.634}
          y2={126.502}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint10_linear"
          x1={-22.527}
          y1={86.043}
          x2={101.592}
          y2={212.163}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
        <linearGradient
          id="evolve-21-logo_svg__paint11_linear"
          x1={84.487}
          y1={-19.272}
          x2={208.606}
          y2={106.849}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE2DC8" />
          <stop offset={0.474} stopColor="#FD5A45" />
          <stop offset={1} stopColor="#FFC156" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgEvolve21Logo;
