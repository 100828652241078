import firebase from 'firebase/app';
import firebaseConfig from './config';

class Firebase {
  constructor(app) {
    if (!firebaseInstance) {
      app.initializeApp(firebaseConfig);

      this.auth = app.auth();
      this.rtdb = app.database();
      this.fsdb = app.firestore();
      this.storage = app.storage;
      // Need to do the below to solve region issue. Not ideal - and there seems to be an error in the Firebase docs - but it works!
      // this.functions = app.functions('europe-west2');
      this.functions = firebase.app().functions('europe-west2');

      if (process.env.NODE_ENV === 'development') {
        // this.auth.useEmulator('http://localhost:9099/');
        // this.fsdb.useEmulator('localhost', 8080);
        // this.rtdb.useEmulator('localhost', 9000);
        this.functions.useEmulator('localhost', 5001);
        // this.storage.useEmulator('localhost', 9199);
      }
    }
  }

  async createUserInAuth({ email, password }) {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email.toLowerCase().trim(),
      password
    );
    return user;
  }

  async createUserInDatabase(newUserData) {
    const createUserInDatabaseCallable = this.functions.httpsCallable('createUserInDatabase');
    return createUserInDatabaseCallable(newUserData);
  }

  async updateEmailVerifiedInDatabase({ eventsUserWantsToAccess, uid }) {
    const updateEmailVerifiedInDatabaseCallable = this.functions.httpsCallable(
      'updateEmailVerifiedInDatabase'
    );
    return updateEmailVerifiedInDatabaseCallable({ eventsUserWantsToAccess, uid });
  }

  async sendVerificationEmail({ firstName, email, actionCodeSettings, colors, event }) {
    const sendVerificationEmailCallable = this.functions.httpsCallable('sendVerificationEmail');
    return sendVerificationEmailCallable({ firstName, email, actionCodeSettings, colors, event });
  }

  async sendPasswordResetEmail({ email, actionCodeSettings, colors }) {
    const sendPasswordResetEmailCallable = this.functions.httpsCallable('sendPasswordResetEmail');
    return sendPasswordResetEmailCallable({ email, actionCodeSettings, colors });
  }

  async verifyPasswordResetCode(actionCode) {
    return this.auth.verifyPasswordResetCode(actionCode);
  }

  async confirmPasswordReset(actionCode, newPassword) {
    return this.auth.confirmPasswordReset(actionCode, newPassword);
  }

  async login(email, password) {
    return this.auth.signInWithEmailAndPassword(email.toLowerCase().trim(), password);
  }

  async logout(user) {
    const presenceRef = this.rtdb.ref(`/presence/${user.uid}`);
    await presenceRef.update({
      selectedEventId: '',
      lastChanged: firebase.database.ServerValue.TIMESTAMP,
      lastRefreshAt: user.lastRefreshAt
    });
    return this.auth.signOut();
  }

  async fetchAllUsers() {
    const fetchAllUsersCallable = this.functions.httpsCallable('fetchAllUsers');
    return fetchAllUsersCallable();
  }

  async getUserFromDatabaseWithUID({ uid }) {
    return this.fsdb.collection('users').where('uid', '==', uid).limit(1).get();
  }

  async checkIfUserAlreadyExists({ email }) {
    const checkIfUserAlreadyExistsCallable = this.functions.httpsCallable(
      'checkIfUserAlreadyExists'
    );
    return checkIfUserAlreadyExistsCallable({ email });
  }

  async addOrUpdateUsersTangent90Consent({
    title,
    fullName,
    email,
    profession,
    speciality,
    workplaceName,
    consented,
    tangent90EventSource,
    tangent90EventCollectionId
  }) {
    const addOrUpdateUsersTangent90ConsentCallable = this.functions.httpsCallable(
      'addOrUpdateUsersTangent90Consent'
    );
    return addOrUpdateUsersTangent90ConsentCallable({
      title,
      fullName,
      email,
      profession,
      speciality,
      workplaceName,
      consented,
      tangent90EventSource,
      tangent90EventCollectionId
    });
  }

  async sendEmoji({ eid, emojiType }) {
    return this.rtdb
      .ref(`/emojis/${eid}`)
      .push()
      .set({ emojiType, timestamp: firebase.database.ServerValue.TIMESTAMP });
  }

  async saveNewPoll({ eid, poll }) {
    const pid = this.rtdb.ref(`polls/${eid}`).push().key;

    const answers = {};

    poll.answers.forEach((answer) => {
      answers[`${answer.id}`] = 0;
    });

    return this.rtdb.ref().update({
      [`/polls/${eid}/${pid}`]: poll,
      [`/pollAnalytics/${eid}/${pid}`]: {
        pollType: poll.type,
        question: poll.question.text,
        correctAnswers: poll.answers.some((answer) => answer.isCorrect)
          ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
          : [],
        answers,
        totalParticipants: 0
      }
    });
  }

  async editPoll({ eid, poll, pid }) {
    const answers = {};

    poll.answers.forEach((answer) => {
      answers[`${answer.id}`] = 0;
    });

    return this.rtdb.ref().update({
      [`/polls/${eid}/${pid}`]: poll,
      [`/pollAnalytics/${eid}/${pid}`]: {
        pollType: poll.type,
        question: poll.question.text,
        correctAnswers: poll.answers.some((answer) => answer.isCorrect)
          ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
          : [],
        answers,
        totalParticipants: 0
      }
    });
  }

  async deletePoll({ eid, pid }) {
    this.rtdb.ref().update({
      [`/polls/${eid}/${pid}`]: null,
      [`/pollAnalytics/${eid}/${pid}`]: null
    });
  }

  async submitPollAnswer({ eid, poll, uid, selectedAnswerIds }) {
    const { pid } = poll;

    const answers = {};

    selectedAnswerIds.forEach((aid) => {
      answers[aid] = firebase.database.ServerValue.increment(1);
    });

    /* TODO: Finish the fsdb query */
    /* Make each answer an object, with answer.id and answer.correct on it */
    return Promise.all([
      this.rtdb.ref(`/pollAnalytics/${eid}/${pid}/answers`).update(answers),
      this.rtdb.ref(`/pollAnalytics/${eid}/${pid}`).update({
        totalParticipants: firebase.database.ServerValue.increment(1)
      }),
      this.fsdb.collection('users').doc(uid).collection('pollAnalytics').doc(`${eid}_${pid}`).set({
        uid,
        answers: selectedAnswerIds
      })
    ]);
  }

  async checkIfUserHasAlreadyAnsweredThisPoll({ eid, pid, uid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('pollAnalytics')
      .doc(`${eid}_${pid}`)
      .get();
  }

  async generateTrustrackReport({ email, selectedEvent }) {
    const generateTrustrackReportCallable = this.functions.httpsCallable('generateTrustrackReport');
    return generateTrustrackReportCallable({ email: email.toLowerCase(), selectedEvent });
  }

  async generateLoggedInOnTheDayReport({ users }) {
    const generateLoggedInOnTheDayReportCallable = this.functions.httpsCallable(
      'generateLoggedInOnTheDayReport'
    );
    return generateLoggedInOnTheDayReportCallable({ users });
  }

  async generateEventCommentsReport({ event }) {
    const generateEventCommentsReportCallable = this.functions.httpsCallable(
      'generateEventCommentsReport'
    );
    return generateEventCommentsReportCallable({ event });
  }

  async updateNameInDatabase({ uid, fullName }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .update({
        fullName,
        firstName: fullName.split(' ')[0],
        lastName: fullName.split(' ').slice(1).join(' ')
      });
  }

  async updateEmailInDatabase({ uid, email }) {
    const { fsdb } = this;

    const usersRef = fsdb.collection('users').doc(uid);
    const userEmailsRef = fsdb.collection('userEmails').doc(uid);

    const batch = fsdb.batch();

    batch.update(usersRef, {
      email: email.toLowerCase().trim()
    });

    batch.update(userEmailsRef, {
      email: email.toLowerCase().trim()
    });

    return batch.commit();
  }

  async applyActionCode(actionCode) {
    return this.auth.applyActionCode(actionCode);
  }

  async checkActionCode(actionCode) {
    return this.auth.checkActionCode(actionCode);
  }

  async reauthenticateUser(currentPassword) {
    const user = this.auth.currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email.toLowerCase(),
      currentPassword
    );
    return user.reauthenticateWithCredential(credential);
  }

  async grantUserAdminPermissions({ uid }) {
    const grantUserAdminPermissionsCallable = this.functions.httpsCallable(
      'grantUserAdminPermissions'
    );
    return grantUserAdminPermissionsCallable({ uid });
  }

  async grantUserModeratorPermissions({ uid, eid }) {
    const grantUserModeratorPermissionsCallable = this.functions.httpsCallable(
      'grantUserModeratorPermissions'
    );
    return grantUserModeratorPermissionsCallable({ uid, eid });
  }

  async removeUserModeratorPermissions({ uid }) {
    const removeUserModeratorPermissionsCallable = this.functions.httpsCallable(
      'removeUserModeratorPermissions'
    );
    return removeUserModeratorPermissionsCallable({ uid });
  }

  async uploadAvatarToDatabase(avatarFile) {
    const uploadAvatarToDatabaseCallable = this.functions.httpsCallable('uploadAvatarToDatabase');
    return uploadAvatarToDatabaseCallable({ avatarFile });
  }

  async sendEventReminderEmail({ event, templateAlias }) {
    const sendEventReminderEmailCallable = this.functions.httpsCallable('sendEventReminderEmail');
    return sendEventReminderEmailCallable({ event, templateAlias });
  }

  async sendEventCancelledEmail({ event }) {
    const sendEventCancelledEmailCallable = this.functions.httpsCallable('sendEventCancelledEmail');
    return sendEventCancelledEmailCallable({ event });
  }

  async fetchUser(uid) {
    return this.fsdb.collection('users').doc(uid).get();
  }

  async fetchPaginatedParticipants({
    eid,
    lastFetchedCurrentlyParticipatingUserDoc,
    lastFetchedNotCurrentlyParticipatingUserDoc
  }) {
    let currentlyParticipatingUsersQuery;
    let notCurrentlyParticipatingUsersQuery;

    if (lastFetchedCurrentlyParticipatingUserDoc) {
      currentlyParticipatingUsersQuery = this.fsdb
        .collection('users')
        .where('presence.selectedEventId', '==', eid)
        .orderBy('fullName')
        .startAfter(lastFetchedCurrentlyParticipatingUserDoc)
        .limit(20);
    } else {
      currentlyParticipatingUsersQuery = this.fsdb
        .collection('users')
        .where('presence.selectedEventId', '==', eid)
        .orderBy('fullName')
        .limit(20);
    }

    if (lastFetchedNotCurrentlyParticipatingUserDoc) {
      notCurrentlyParticipatingUsersQuery = this.fsdb
        .collection('users')
        .where('presence.selectedEventId', '!=', eid)
        .orderBy('presence.selectedEventId')
        .orderBy('fullName')
        .startAfter(lastFetchedNotCurrentlyParticipatingUserDoc)
        .limit(20);
    } else {
      notCurrentlyParticipatingUsersQuery = this.fsdb
        .collection('users')
        .orderBy('presence.selectedEventId')
        .where('presence.selectedEventId', '!=', eid)
        .orderBy('fullName')
        .limit(20);
    }

    const docs = [];

    docs.push(currentlyParticipatingUsersQuery.get());
    docs.push(notCurrentlyParticipatingUsersQuery.get());

    return Promise.all(docs);
  }

  async fetchEventParticipants({ eid, lastFetchedParticipantDoc }) {
    let query;

    if (lastFetchedParticipantDoc) {
      query = this.fsdb
        .collection('users')
        .where('eventsUserCanAccess', 'array-contains', eid)
        .orderBy('fullName')
        .startAfter(lastFetchedParticipantDoc)
        .limit(40);
    } else {
      query = this.fsdb
        .collection('users')
        .where('eventsUserCanAccess', 'array-contains', eid)
        .orderBy('fullName')
        .limit(40);
    }

    return query.get();
  }

  async postEventComment({ avatarUrl, eid, fullName, profession, text, uid, workplaceName }) {
    return this.fsdb
      .collection('events')
      .doc(eid)
      .collection('comments')
      .add({
        avatarUrl,
        fullName,
        text,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        profession,
        workplaceName,
        uid,
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async deleteEventComment({ eid, cid }) {
    return this.fsdb.collection('events').doc(eid).collection('comments').doc(cid).delete();
  }

  async grantRegistrantAccessToTheseEvents({ uid, eids }) {
    const usersRef = this.fsdb.collection('users').doc(uid);

    return usersRef.update({
      eventsUserWantsToAccess: firebase.firestore.FieldValue.arrayRemove(...eids),
      eventsUserCanAccess: firebase.firestore.FieldValue.arrayUnion(...eids),
      eventsUserHasBeenDeniedAccessTo: firebase.firestore.FieldValue.arrayRemove(...eids),
      isPendingSiteRegistrant: false
    });
  }

  async denyRegistrantAccessToTheseEvents({ uid, eids }) {
    const usersRef = this.fsdb.collection('users').doc(uid);

    return usersRef.update({
      eventsUserHasBeenDeniedAccessTo: firebase.firestore.FieldValue.arrayUnion(...eids),
      eventsUserCanAccess: firebase.firestore.FieldValue.arrayRemove(...eids),
      eventsUserWantsToAccess: firebase.firestore.FieldValue.arrayRemove(...eids)
    });
  }

  async denyRegistrantAccessToSite({ uid }) {
    const usersRef = this.fsdb.collection('users').doc(uid);

    return usersRef.update({
      isPendingSiteRegistrant: false
    });
  }

  async sendSiteAccessGrantedEmail({ registrantName, registrantEmail }) {
    const sendSiteAccessGrantedEmailCallable = this.functions.httpsCallable(
      'sendSiteAccessGrantedEmail'
    );
    return sendSiteAccessGrantedEmailCallable({
      registrantName,
      registrantEmail
    });
  }

  async sendEventAccessGrantedEmail({ attendeeFirstName, attendeeEmail, event }) {
    const sendEventAccessGrantedEmailCallable = this.functions.httpsCallable(
      'sendEventAccessGrantedEmail'
    );
    return sendEventAccessGrantedEmailCallable({
      attendeeFirstName,
      attendeeEmail,
      event
    });
  }

  async registerForEventIfUserAlreadyHasAnAccount({ eid, uid }) {
    const registerForEventIfUserAlreadyHasAnAccountCallable = this.functions.httpsCallable(
      'registerForEventIfUserAlreadyHasAnAccount'
    );
    return registerForEventIfUserAlreadyHasAnAccountCallable({
      eid,
      uid
    });
  }

  async forceUserEmailVerification({ uid }) {
    const forceUserEmailVerificationCallable = this.functions.httpsCallable(
      'forceUserEmailVerification'
    );
    return forceUserEmailVerificationCallable({
      uid
    });
  }

  async sendEventWelcomeEmail({ attendeeName, attendeeEmail, event }) {
    const sendEventWelcomeEmailCallable = this.functions.httpsCallable('sendEventWelcomeEmail');
    return sendEventWelcomeEmailCallable({
      attendeeName,
      attendeeEmail,
      event
    });
  }

  async updateGroupWatching({ uid, groupWatching }) {
    return this.fsdb.collection('users').doc(uid).update({
      groupWatching
    });
  }

  async addGroupWatchingField({ uid }) {
    return this.fsdb.collection('users').doc(uid).set({
      groupWatching: {}
    });
  }

  async pinEventComment({ eid, cid }) {
    return this.fsdb
      .collection('events')
      .doc(eid)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async unpinEventComment({ eid, cid }) {
    return this.fsdb
      .collection('events')
      .doc(eid)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async starQuestion({ uid, qid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        starred: {
          status: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async unstarQuestion({ uid, qid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        starred: {
          status: false,
          timestamp: 0
        }
      });
  }

  async submitNewQuestion({ uid, eid, text, name }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(`${uid}_${Date.now()}`)
      .set(
        name
          ? {
              eid: eid.toString(),
              text,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              uid,
              name,
              starred: {
                status: false,
                timestamp: 0
              }
            }
          : {
              eid: eid.toString(),
              text,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              uid,
              starred: {
                status: false,
                timestamp: 0
              }
            }
      );
  }

  async answerThisQuestionLive({ eid, text }) {
    return this.fsdb.collection('events').doc(eid).update({
      questionCurrentlyBeingAnsweredLive: text
    });
  }

  async stopShowingAnswerLiveOverlay({ eid }) {
    return this.fsdb.collection('events').doc(eid).update({
      questionCurrentlyBeingAnsweredLive: null
    });
  }

  async submitAnswer({ text, qid, uid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        answer: {
          text,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async updateVideoSessionData({ eid, uid, timeRange }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('videoSessionData')
      .doc(eid)
      .set(
        {
          timeRanges: firebase.firestore.FieldValue.arrayUnion(...timeRange)
        },
        { merge: true }
      );
  }

  async openPoll({ eid, selectedPoll, currentlyOpenPoll }) {
    if (currentlyOpenPoll?.pid) {
      const pollsRef = this.rtdb.ref(`polls/${eid}`);

      if (selectedPoll.timer?.enabled) {
        return pollsRef.update({
          [`${currentlyOpenPoll?.pid}/isOpen`]: false,
          [`${selectedPoll.pid}/isOpen`]: true,
          [`${selectedPoll.pid}/timer`]: {
            ...selectedPoll.timer,
            startedAt: firebase.database.ServerValue.TIMESTAMP
          }
        });
      }

      return pollsRef.update({
        [`${currentlyOpenPoll?.pid}/isOpen`]: false,
        [`${selectedPoll.pid}/isOpen`]: true
      });
    }

    if (selectedPoll.timer?.enabled) {
      return this.rtdb.ref(`polls/${eid}/${selectedPoll.pid}`).update({
        isOpen: true,
        timer: {
          ...selectedPoll.timer,
          startedAt: firebase.database.ServerValue.TIMESTAMP
        }
      });
    }

    return this.rtdb.ref(`polls/${eid}/${selectedPoll.pid}`).update({ isOpen: true });
  }

  async closePoll({ eid, pid }) {
    return this.rtdb.ref(`polls/${eid}/${pid}`).update({ isOpen: false, isQueued: false });
  }

  async sharePollAnalytics({ eid, pid }) {
    return this.rtdb.ref(`polls/${eid}/${pid}`).update({ shareAnalytics: true });
  }

  async stopSharingPollAnalytics({ eid, pid }) {
    return this.rtdb.ref(`polls/${eid}/${pid}`).update({ shareAnalytics: false });
  }

  async forceActiveTab({ eid, tabName }) {
    return this.fsdb.collection('events').doc(eid).update({
      forcedActiveTab: tabName
    });
  }

  async toggleZoomBanner({ eid, showZoomBanner }) {
    return this.fsdb.collection('events').doc(eid).update({
      showZoomBanner
    });
  }

  async updateUserPresence({ user, eid }) {
    const presenceRef = this.rtdb.ref(`/presence/${user.uid}`);
    presenceRef
      .onDisconnect()
      .set({
        selectedEventId: '',
        lastChanged: firebase.database.ServerValue.TIMESTAMP,
        lastRefreshAt: user.lastRefreshAt
      })
      .then(() => {
        presenceRef.update({
          selectedEventId: eid,
          lastChanged: firebase.database.ServerValue.TIMESTAMP,
          lastRefreshAt: user.lastRefreshAt
        });
      });
  }

  subscribeToEventParticipantsOnline({ eid, onSnapshot }) {
    return this.fsdb
      .collection('users')
      .where('presence.selectedEventId', '==', eid)
      .onSnapshot(onSnapshot);
  };

  subscribeToRTDBServer = ({ onSnapshot }) => {
    return this.rtdb.ref('.info/connected').on('value', onSnapshot);
  };

  subscribeToUserUpdates({ uid, onSnapshot }) {
    return this.fsdb.collection('users').doc(uid).onSnapshot(onSnapshot);
  }

  subscribeToEventUpdates({ eid, onSnapshot }) {
    return this.fsdb.collection('events').doc(eid).onSnapshot(onSnapshot);
  }

  subscribeToEventData({ eid, onSnapshot }) {
    return this.fsdb
      .collection('users')
      .where('eventsUserCanAccess', 'array-contains', eid)
      .onSnapshot(onSnapshot);
  }

  subscribeToEventComments({ eid, onSnapshot }) {
    return this.fsdb
      .collection('events')
      .doc(eid)
      .collection('comments')
      .orderBy('pinned.status', 'desc')
      .orderBy('pinned.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .limit(100)
      .onSnapshot(onSnapshot);
  }

  subscribeToServerTimeOffset({ onSnapshot }) {
    const serverTimeOffsetRef = this.rtdb.ref('.info/serverTimeOffset');

    serverTimeOffsetRef.on('value', onSnapshot);

    return serverTimeOffsetRef;
  }

  subscribeToEmojis({ eid, onSnapshot }) {
    console.log('really');
    const emojisRef = this.rtdb.ref(`/emojis/${eid}`);

    emojisRef.orderByChild('timestamp').startAfter(Date.now()).on('child_added', onSnapshot);

    return emojisRef;
  }

  subscribeToEventEmojis({ eid, onSnapshot }) {
    const emojisRef = this.rtdb.ref(`/emojis/${eid}`);
    emojisRef.on('value', onSnapshot);
    return emojisRef;
  }

  subscribeToEventSlugGatingEnabled({ onSnapshot }) {
    const eventSlugGatingEnabledRef = this.rtdb.ref(`/eventSlugGatingEnabled`);

    eventSlugGatingEnabledRef.on('value', onSnapshot);

    return eventSlugGatingEnabledRef;
  }

  subscribeModeratorToAllSubmittedQuestions({ eid, onSnapshot }) {
    return this.fsdb
      .collectionGroup('questions')
      .where('eid', '==', eid)
      .orderBy('starred.status', 'desc')
      .orderBy('starred.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeUserToTheirSubmittedQuestions({ eid, uid, onSnapshot }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .where('eid', '==', eid)
      .where('uid', '==', uid)
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeToPolls({ eid, onSnapshot }) {
    const pollsRef = this.rtdb.ref(`polls/${eid}`);

    pollsRef.on('value', onSnapshot);

    return pollsRef;
  }

  subscribeToPollAnalytics({ eid, pid, onSnapshot }) {
    const pollAnalyticsRef = this.rtdb.ref(`/pollAnalytics/${eid}/${pid}`);

    pollAnalyticsRef.on('value', onSnapshot);

    return pollAnalyticsRef;
  }

  subscribeToPendingSiteRegistrants({ lastestPendingDoc, onSnapshot }) {
    return (
      this.fsdb
        .collection('users')
        .where('eventsUserWantsToAccess', '==', [])
        .where('eventsUserCanAccess', '==', [])
        .where('isPendingSiteRegistrant', '==', true)
        .orderBy('fullName')
        // .startAfter(lastestPendingDoc || 0)
        // .limit(2)
        .onSnapshot(onSnapshot)
    );
  }

  subscribeToApprovedSiteRegistrants({ lastestPendingDoc, onSnapshot }) {
    return (
      this.fsdb
        .collection('users')
        .where('eventsUserWantsToAccess', '==', [])
        .where('isPendingSiteRegistrant', '==', false)
        .orderBy('fullName')
        // .startAfter(lastestPendingDoc || 0)
        // .limit(2)
        .onSnapshot(onSnapshot)
    );
  }

  subscribeToPendingEventRegistrants({ eid, lastestPendingDoc, onSnapshot }) {
    return (
      this.fsdb
        .collection('users')
        .where('emailVerified', '==', true)
        .where('eventsUserWantsToAccess', 'array-contains', eid)
        .orderBy('fullName')
        // .startAfter(lastestPendingDoc || 0)
        // .limit(2)
        .onSnapshot(onSnapshot)
    );
  }

  subscribeToApprovedEventRegistrants({ eid, onSnapshot }) {
    return (
      this.fsdb
        .collection('users')
        .where('emailVerified', '==', true)
        .where('eventsUserCanAccess', 'array-contains', eid)
        .orderBy('fullName')
        // .limit(1)
        .onSnapshot(onSnapshot)
    );
  }
}

let firebaseInstance;

function getFirebaseInstance(app) {
  if (!firebaseInstance && app) {
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }

  if (firebaseInstance) {
    return firebaseInstance;
  }

  return null;
}

export default getFirebaseInstance;
