import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import { GroupWatchingSettings } from 'components';
import Participant from './Participant';

const Participants = ({
  colors,
  eid,
  handleParticipantAvatarOrNameClick,
  showOnlinePresenceToUsers,
  isGroupWatchingEnabled
}) => {
  const { firebase, user } = useContext(FirebaseContext);
  const [usersCurrentlyWatchingEvent, setUsersCurrentlyWatchingEvent] = useState(0);
  const [participants, setParticpants] = useState([]);
  const [lastFetchedCurrentlyParticipatingUserDoc, setLastFetchedCurrentlyParticipatingUserDoc] =
    useState();
  const [
    lastFetchedNotCurrentlyParticipatingUserDoc,
    setLastFetchedNotCurrentlyParticipatingUserDoc
  ] = useState();
  const [groupWatchingAmount, setGroupWatchingAmount] = useState(0);

  const particpantsContainerRef = useRef();

  useEffect(() => {
    if (user.groupWatching?.[eid]) {
      setGroupWatchingAmount(user.groupWatching[eid].filter((member) => member.name).length);
    }
  }, [user?.groupWatching]);

  console.log(groupWatchingAmount);

  const populatePaginatedParticipantsList = async (snapshots) => {
    const nextParticipants = [];
    const [currentlyParticipatingUsersSnapshot, notCurrentlyParticipatingUsersSnapshot] = snapshots;

    const userHasNotBeenAddedToTheParticipantsListAlready = (uid) =>
      !participants.some((participant) => participant.uid === uid);

    if (!currentlyParticipatingUsersSnapshot?.empty) {
      currentlyParticipatingUsersSnapshot.docs.forEach((doc) => {
        const { uid } = doc.data();
        if (userHasNotBeenAddedToTheParticipantsListAlready(uid)) {
          nextParticipants.push(doc);
        }
      });
    }

    if (
      currentlyParticipatingUsersSnapshot?.empty &&
      !notCurrentlyParticipatingUsersSnapshot?.empty
    ) {
      notCurrentlyParticipatingUsersSnapshot.docs.forEach((doc) => {
        const { uid } = doc.data();
        if (userHasNotBeenAddedToTheParticipantsListAlready(uid)) {
          nextParticipants.push(doc);
        }
      });
    }

    if (nextParticipants.length) {
      setParticpants((currrentParticipants) => [
        ...currrentParticipants,
        ...nextParticipants.map((doc) => {
          const { fullName, avatarUrl, eventRole, uid, presence, groupWatching, workplaceName } = doc.data();
          return {
            fullName,
            avatarUrl,
            eventRole,
            uid,
            presence,
            workplaceName,
            groupWatching
          };
        })
      ]);

      if (!currentlyParticipatingUsersSnapshot?.empty) {
        setLastFetchedCurrentlyParticipatingUserDoc(
          currentlyParticipatingUsersSnapshot.docs[
            currentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }

      if (
        currentlyParticipatingUsersSnapshot?.empty &&
        !notCurrentlyParticipatingUsersSnapshot?.empty
      ) {
        setLastFetchedNotCurrentlyParticipatingUserDoc(
          notCurrentlyParticipatingUsersSnapshot.docs[
            notCurrentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }
    }
  };

  useEffect(() => {
    if (firebase) {
      firebase.subscribeToEventParticipantsOnline({
        eid,
        onSnapshot: (snapshot) => {
          if (!snapshot.empty) {
            setUsersCurrentlyWatchingEvent(snapshot.size);
          }
        }
      });
    }
  }, [firebase]);

  const fetchPaginatedParticipants = () =>
    firebase
      .fetchPaginatedParticipants({
        eid,
        lastFetchedCurrentlyParticipatingUserDoc,
        lastFetchedNotCurrentlyParticipatingUserDoc
      })
      .then(populatePaginatedParticipantsList);

  useEffect(() => {
    fetchPaginatedParticipants();
    return () => {
      setLastFetchedCurrentlyParticipatingUserDoc(null);
      setLastFetchedNotCurrentlyParticipatingUserDoc(null);
    };
  }, []);

  console.log(groupWatchingAmount);

  return (
    <Wrapper ref={particpantsContainerRef} colors={colors}>
      <AnimatePresence>
        {isGroupWatchingEnabled && groupWatchingAmount > 0 && (
          <GroupWatchingSettings colors={colors} amount={groupWatchingAmount} />
        )}
      </AnimatePresence>
      {(showOnlinePresenceToUsers || user.isAdmin) && (
        <Info colors={colors}>
          <p>Online Participants: {usersCurrentlyWatchingEvent}</p>
        </Info>
      )}
      {participants.length > 0 &&
        participants.map((participant, i, arr) => (
          <Participant
            key={participant.uid}
            eid={eid}
            participant={participant}
            showOnlinePresenceToUsers={showOnlinePresenceToUsers || user.isAdmin}
            fetchPaginatedParticipants={fetchPaginatedParticipants}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            isLastFetchedParticipant={arr.length === i + 1}
            colors={colors}
            particpantsContainerRef={particpantsContainerRef}
            isGroupWatchingEnabled={isGroupWatchingEnabled}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 1.25rem;
  min-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const Info = styled.div`
  padding-bottom: 0.5rem;
  p {
    color: ${({ colors }) => colors.secondary};
    font-size: 0.75rem;
    text-align: right;
    text-transform: uppercase;
  }
`;

export default Participants;
