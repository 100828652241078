import React, { useContext, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { EventButton, LivestreamOverlays, Interaction, ZoomBanner, Dialog, GroupWatching } from 'components';
import { BackgroundOverlay } from 'components/Events/EventHeader';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FirebaseContext, LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { useLocation } from '@reach/router';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';
import throttle from 'lodash.throttle';
import { breakpoints } from 'styles';
import Vimeo from '@vimeo/player';
import { useInView } from 'react-intersection-observer';
import { navigate } from 'gatsby';

export default (props) => {
  const {
    colors,
    contrast,
    eventTitle,
    eventDescription,
    calendarDescription,
    calendarLink,
    eventSubtitle,
    eventDates,
    eventStartTime,
    eventEndTime,
    eventLogo,
    eventLogoWithButterfly,
    eventBanner,
    eventSeries,
    eventBannerVideo,
    eventBannerPromotion,
    eventSlug,
    past,
    eventName,
    eid,
    eventStreamPlaceholderImg,
    footNote,
    isChatEnabled,
    isQAndAEnabled,
    isPollsEnabled,
    isParticipantsEnabled,
    isGroupWatchingEnabled,
    setIsGroupWatchingEnabled
  } = props;

  const { windowWidth } = useWindowSize();
  const { headerRef, showGroupWatchingDialog, setShowGroupWatchingDialog } = useContext(LocalContext);
  const { user, firebase } = useContext(FirebaseContext);
  const [isPipModeEnabled, setIsPipModeEnabled] = useState(false);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [isStreamMuted, setIsStreamMuted] = useState(null);
  const [livestreamIsPlaying, setLivestreamIsPlaying] = useState(false);
  const [questionCurrentlyBeingAnsweredLive, setQuestionCurrentlyBeingAnsweredLive] =
    useState(null);
  const [pipRef, vimeoPlayerIsInView] = useInView({
    initialInView: true,
    threshold: 0.75
  });
  const [activeTab, setActiveTab] = useState('Participants');
  const [forcedActiveTab, setForcedActiveTab] = useState(null);
  const [streamUrl, setStreamUrl] = useState(null);
  const [zoomUrl, setZoomUrl] = useState(null);
  const [showZoomBanner, setShowZoomBanner] = useState(null);
  const [showOnlinePresenceToUsers, setShowOnlinePresenceToUsers] = useState(null);
  const { state } = useLocation();
  const referrer = state?.referrer;

  const queryParams = `pip=1&autoplay=1&fullscreen=1&controls=1&volume=1&muted=${referrer ? 0 : 1}`;

  const livestreamRef = useRef(null);
  const groupWatchingDialogRef = useRef(null);

  useEffect(() => {
    let unsubscribeFromEventUpdates;
    if (firebase) {
      unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
        eid,
        onSnapshot: (snapshot) => {
          if (snapshot.exists) {
            const {
              forcedActiveTab: _forcedActiveTab,
              questionCurrentlyBeingAnsweredLive: _questionCurrentlyBeingAnsweredLive,
              streamUrl: _streamUrl,
              showZoomBanner: _showZoomBanner,
              zoomUrl: _zoomUrl,
              logEveryoneOutPostEvent: _logEveryoneOutPostEvent,
              groupWatchingIsEnabled: _groupWatchingIsEnabled,
              showOnlinePresenceToUsers: _showOnlinePresenceToUsers
            } = snapshot.data();

            if (_logEveryoneOutPostEvent) {
              navigate(`/events/${eventSlug}`);
            }

            if (_streamUrl !== streamUrl) {
              setStreamUrl(_streamUrl);
            }

            if (_zoomUrl !== zoomUrl) {
              setZoomUrl(_zoomUrl);
            }

            if (_showZoomBanner !== showZoomBanner) {
              setShowZoomBanner(_showZoomBanner);
            }

            if (_forcedActiveTab || _forcedActiveTab === null) {
              setForcedActiveTab(_forcedActiveTab);
            }

            if (_groupWatchingIsEnabled) {
              setIsGroupWatchingEnabled(_groupWatchingIsEnabled);
            } else {
              setIsGroupWatchingEnabled(false);
            }
            
            if(_showOnlinePresenceToUsers !== showOnlinePresenceToUsers) {
              setShowOnlinePresenceToUsers(_showOnlinePresenceToUsers);
            }

            if (_questionCurrentlyBeingAnsweredLive) {
              setQuestionCurrentlyBeingAnsweredLive(_questionCurrentlyBeingAnsweredLive);
            } else {
              setQuestionCurrentlyBeingAnsweredLive(null);
            }

          } else if (!snapshot.exists) {
            /* CONOR TODO: Is there a better way of doing this? Can we automatically add the event doc somehow? Hmmmm */
            console.error(
              "You need to add a doc for this event in the 'Events' collection in Firebase."
            );
          }
        }
      });
      return () => {
        if (unsubscribeFromEventUpdates) {
          unsubscribeFromEventUpdates();
        }
        setIsPipModeEnabled(false);
        setVimeoPlayer(null);
        setLivestreamIsPlaying(false);
        setQuestionCurrentlyBeingAnsweredLive(null);
        setForcedActiveTab(null);
        // setStreamUrl(null);
      };
    }
  }, [firebase, eid]);

  useEffect(() => {
    const livestreamIframe = document.getElementById('livestream-iframe');

    if (streamUrl && livestreamIframe && user && eid && !vimeoPlayer) {
      const _vimeoPlayer = new Vimeo(livestreamIframe);

      setVimeoPlayer(_vimeoPlayer);

      // I'm defining a video "session" as a discrete period of time someone is on the page watching
      // a video. If someone watches an hour one day, then navigates away and comes back to the same
      // page the next day, then those are two seperate sessions. Likewise, if they watch 20 mins
      // then navigate to another part of the site and then come back to the same page again and
      // watches another 20 mins, then those are two seperate sessions. But if someone watches 20
      // mins and pauses the video, then unpauses and continues watching, then that's a single
      // session. Likewise, if someone watches 20 mins and then fast-forwards an hour and continues
      // watching, then that's also a single session.
      const updateVideoSessionData = async () => {
        const data = await _vimeoPlayer.getPlayed();

        const timeRange = data.map((arr) => ({
          start: parseFloat(arr[0].toFixed(2)),
          end: parseFloat(arr[1].toFixed(2))
        }));

        return firebase.updateVideoSessionData({
          eid,
          uid: user.uid,
          timeRange
        });
      };

      _vimeoPlayer.on('timeupdate', throttle(updateVideoSessionData, 60000));
      _vimeoPlayer.on('play', (data) => {
        // Using an 'if' statement here because when the 'play' event is fired
        // after any 'seek' event 'data' is undefined.
        if (data) {
          setLivestreamIsPlaying(true);
        }
      });
      _vimeoPlayer.on('pause', (data) => {
        setLivestreamIsPlaying(false);

        // The 'pause' event is also fired when the video ends, along with the 'ended' event.
        // We want to ignore it when the video has ended, so we'll check the percent value.
        if (data.percent !== 1) {
          updateVideoSessionData();
        }
      });
      _vimeoPlayer.on('seeked', updateVideoSessionData);
      _vimeoPlayer.on('ended', updateVideoSessionData);

      _vimeoPlayer.on('enterpictureinpicture', () => {
        setIsPipModeEnabled(true);
      });
      _vimeoPlayer.on('leavepictureinpicture', () => {
        setIsPipModeEnabled(false);
      });
    }

    return () => {
      // document.removeEventListener('keydown', keyDownListener);
      vimeoPlayer?.off('timeupdate');
      vimeoPlayer?.off('play');
      vimeoPlayer?.off('pause');
      vimeoPlayer?.off('seeked');
      vimeoPlayer?.off('ended');
      vimeoPlayer?.off('enterpictureinpicture');
      vimeoPlayer?.off('leavepictureinpicture');
    };
  }, [streamUrl, user, eid, vimeoPlayer]);

  useEffect(() => {
    if (
      document.pictureInPictureEnabled &&
      vimeoPlayer &&
      !vimeoPlayer.disablePictureInPicture &&
      livestreamIsPlaying &&
      !vimeoPlayerIsInView
    ) {
      try {
        vimeoPlayer.requestPictureInPicture();
      } catch (err) {
        console.error(err);
      }
    }
  }, [vimeoPlayer, vimeoPlayerIsInView, eid]);

  const handlePipOverlayClick = () => vimeoPlayer.exitPictureInPicture();

  const handleToggleZoomBanner = (e) => {
    e.preventDefault();
    firebase.toggleZoomBanner({ eid, showZoomBanner: !showZoomBanner });
  };

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.ready().then(() => {
        vimeoPlayer.getMuted().then((muted) => {
          if (muted) setIsStreamMuted(muted);
        });
      });
    }
  }, [vimeoPlayer]);

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.on('volumechange', () => {
        if (isStreamMuted) setIsStreamMuted(false);
      });
    }
  }, [vimeoPlayer, isStreamMuted]);

  const handleUnmuteOverlayClick = () => {
    if (isStreamMuted) {
      vimeoPlayer.setMuted(false).then(() => setIsStreamMuted(false));
    }
  };

  const hideInteractionComponent =
    !isChatEnabled && !isQAndAEnabled && !isPollsEnabled && !isParticipantsEnabled;

  return (
    <>
      <Header
        colors={colors}
        ref={headerRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}>
        <Background>
          {eventBannerVideo ? (
            <BackgroundVideo
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 3 }}
              playsInline
              autoPlay
              muted
              loop
              id="bgvid">
              <source src={eventBannerVideo} type="video/mp4" />
            </BackgroundVideo>
          ) : (
            <BackgroundImage image={getImage(eventBanner)} alt="Background" />
          )}
          {eid !== '12' && <BackgroundOverlay colors={colors} eid={eid} />}
        </Background>
        {eid !== '12' && <BackgroundOverlay colors={colors} eid={eid} />}
        <HeaderText>
          <DateAndTime>
            <Markup content={eventDates} noHtml />
          </DateAndTime>
          <EventTitle>
            <Markup content={eventTitle} noWrap />
          </EventTitle>
          {eventSubtitle && (
            <EventSubtitle>
              <Markup content={eventSubtitle} noHtml={windowWidth < 640} />
            </EventSubtitle>
          )}
        </HeaderText>
        <AnimatePresence>
          {streamUrl && (
            <Container
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              colors={colors}
              hideInteractionComponent={hideInteractionComponent}>
              <LiveStream ref={livestreamRef}>
                <div
                  ref={pipRef}
                  style={{
                    border: `0.188rem solid`,
                    borderColor: colors.secondary,
                    transition: 'border-color',
                    transitionDuration: '0.5s',
                    padding: '56.25% 0 0 0',
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden'
                  }}>
                  <iframe
                    title={eventName}
                    id="livestream-iframe"
                    src={
                      streamUrl.includes('?')
                        ? `${streamUrl}&${queryParams}`
                        : `${streamUrl}?${queryParams}`
                    }
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    referrerPolicy="strict-origin"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <LivestreamOverlays
                    colors={colors}
                    eid={eid}
                    handlePipOverlayClick={handlePipOverlayClick}
                    isPipModeEnabled={isPipModeEnabled}
                    livestreamRef={livestreamRef}
                    questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
                    isStreamMuted={isStreamMuted}
                    handleUnmuteOverlayClick={handleUnmuteOverlayClick}
                  />
                </div>
              </LiveStream>
              {(isChatEnabled || isQAndAEnabled) && (
                <Interaction
                  colors={colors}
                  eid={eid}
                  forcedActiveTab={forcedActiveTab}
                  questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
                  isChatEnabled={isChatEnabled}
                  isQAndAEnabled={isQAndAEnabled}
                  isPollsEnabled={isPollsEnabled}
                  isParticipantsEnabled={isParticipantsEnabled}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  showOnlinePresenceToUsers={showOnlinePresenceToUsers}
                  isGroupWatchingEnabled={isGroupWatchingEnabled}
                />
              )}
              {footNote && (
                <EmailAddress>
                  <Markup content={footNote} />
                </EmailAddress>
              )}
              {!past && (
                <AnimatedDateButton
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1 }}>
                  <EventButton
                    colors={colors}
                    eventTitle={eventTitle}
                    eventDescription={eventDescription}
                    calendarLink={calendarLink}
                    calendarDescription={calendarDescription}
                    startTime={eventStartTime}
                    endTime={eventEndTime}
                    // contrast
                    location={`https://www.pfizervirtualcafe.ie/events/${eventSlug}`}
                  />
                </AnimatedDateButton>
              )}
            </Container>
          )}
        </AnimatePresence>
        <AnimatePresence initial={false} mode="wait">
        <Dialog ref={groupWatchingDialogRef} isVisible={showGroupWatchingDialog}>
          <GroupWatching
            colors={colors}
            question="Are you watching as a group?"
            setShowGroupWatchingDialog={setShowGroupWatchingDialog}
            groupWatchingDialogRef={groupWatchingDialogRef}
          />
        </Dialog>
      </AnimatePresence>
      </Header>
      {eid === '10' && user?.isModerator?.includes(eid) && (
        <Button
          onClick={handleToggleZoomBanner}
          whileHover={{
            scale: 1.025
          }}
          whileTap={{
            scale: 0.95
          }}
          colors={colors}
          style={{ margin: `7rem auto ${showZoomBanner ? '-5rem' : '7rem'}`, width: '16rem' }}>
          {showZoomBanner ? 'Hide Zoom Banner' : 'Show Zoom Banner'}
        </Button>
      )}
      {eid === '10' && showZoomBanner && <ZoomBanner colors={colors} zoomUrl={zoomUrl} />}
    </>
  );
};

const HeaderText = styled(motion.div)`
  align-items: center;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  left: -1rem;
  width: 100%;
  position: relative;
  z-index: 1;
  > * {
    grid-column: 2/12;

    @media (min-width: 1150px) {
      grid-column: 2/12;
    }
  }
`;

const DateAndTime = styled.h3`
  font-size: 1rem;
  @media (min-width: 640px) {
    font-size: 1.5rem;
  }
`;

const EventTitle = styled.div`
  h1 {
    font-size: 1.375rem !important;
  line-height: 1.75rem;
  max-width: 68rem;
  font-weight: 300;
  @media (min-width: 640px) {
    margin: 0.325em 0 0;
    font-size: 2.375rem !important;
    line-height: 3.25rem;
  }
  }
`;

const EventSubtitle = styled.h3`
  font-size: 1rem;
  font-weight: 800;
  width: 100%;
  @media (min-width: 640px) {
    font-size: 1.875rem;
  }
`;

const EventLogo = styled(GatsbyImage)`
  grid-column: 10/13;
  grid-row: 1/4;
  justify-self: end;
  /* min-width: 335px;
  min-height: 100px; */
  max-height: 155px;
  max-width: 333px;
  position: relative;
  left: 5%;
  @media (min-width: 1600px) {
    grid-column: 9/13;
  }
`;

const AnimatedDateButton = styled(motion.div)`
  width: auto;
  height: auto;
  /* margin-top: 1.95rem; */
`;

const EmailAddress = styled.div`
  font-size: 1.25rem;
  grid-column: 1/9;
  text-align: center;

  @media (min-width: ${breakpoints.lg}) {
    grid-column: 3/11;
  }
`;

const Button = styled(motion.a)`
  align-items: center;
  background-color: ${({ colors }) => colors.secondary};
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 5rem;
  justify-content: center;
  opacity: 1;
  padding: 1rem;
  text-transform: uppercase;
  /* transition: 0.5s; */
  width: 100%;
  ${({ style }) => style};

  &:last-child {
    background-color: ${({ colors }) => colors.primary};
  }

  @media (min-width: ${breakpoints.lg}) {
    opacity: 0.8;
  }

  &:hover {
    opacity: 1;
  }
`;

// const LiveStream = styled.div`
//   height: 100%;
//   position: relative;
//   z-index: 2;
//   overflow: hidden;
//   padding-bottom: 56.25%;
//   margin: 0 !important;
//   #livestream {
//     padding: 56.25% 0px 0px;
//     height: 100%;
//     position: relative;
//     overflow: hidden;
//   }
//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     #player {
//       max-width: 100% !important;
//       max-height: 100% !important;
//     }
//   }

//   @media (min-width: 768px) {
//     margin: 0;
//   }
//   @media (min-width: ${breakpoints.lg}) {
//     height: 100%;
//     margin: 0;
//     ${({ isChatEnabled }) => (isChatEnabled ? 'grid-column: span 1' : 'grid-column: span 2')};
//   }
// `;

const LiveStream = styled.div`
  height: auto;
  margin: 1.25rem -1.25rem;
  position: relative;
  @media (min-width: 1150px) {
    margin: 0;
    height: 100%;
  }
`;

const Container = styled(motion.div)`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  padding-bottom: 2.5rem;
  width: 100%;

  @media only screen and (min-width: 768px) {
    grid-column: 2/6;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: ${({ hideInteractionComponent }) =>
      hideInteractionComponent ? '1fr' : '2fr 1fr'};
    padding: 2.325rem 0 3rem;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundVideo = styled(motion.video)`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Header = styled.header`
  background-color: ${({ colors }) => colors.primary};
  height: auto;
  padding: 6rem 1.25rem 0;
  position: relative;
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    min-height: 100vh;
    /* padding: 0 2.5rem; */
    padding-top: 6.5rem;
  }
`;
