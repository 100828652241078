import * as React from 'react';

function SvgBauble(props) {
  return (
    <svg viewBox="0 0 114 290" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M56.595 290C25.352 290 0 264.648 0 233.405c0-31.242 25.352-56.594 56.595-56.594 31.242 0 56.594 25.352 56.594 56.594 0 31.243-25.3 56.595-56.594 56.595z"
        fill="#fff"
      />
      <path
        d="M61.666 180.959l-1.946-1.126c.205-.359.41-.769.666-1.178l-.103-.513c.103 0 .256-.051.41-.051 0-.051.051-.051.051-.102 2.612-4.866 6.505-12.139 17.158-16.492 2.663-1.076 9.219-2.202 12.958-.051 1.587.922 2.51 2.304 2.765 4.046 1.332 9.731-20.896 13.06-30.371 14.494-.41.051-.769.103-1.076.154a18.94 18.94 0 00-.512.819zm23.509-18.54c-2.459 0-5.07.563-6.454 1.127-8.963 3.687-12.702 9.423-15.262 14.084 7.836-1.178 28.886-4.558 27.862-11.882-.154-1.076-.666-1.844-1.64-2.407-1.177-.666-2.816-.922-4.506-.922zM51.627 180.959a11.76 11.76 0 01-.461-.819l-1.076-.154c-9.475-1.434-31.703-4.814-30.372-14.494.256-1.742 1.178-3.124 2.766-4.046 3.79-2.151 10.295-1.025 12.958.051 10.653 4.353 14.546 11.677 17.158 16.492 0 .051.05.051.05.102.155 0 .257.051.41.051l-.102.513c.205.409.461.819.666 1.178l-1.997 1.126zm-23.509-18.54c-1.69 0-3.33.256-4.507.922-.973.563-1.485 1.331-1.639 2.407-1.024 7.324 20.026 10.704 27.862 11.882-2.56-4.661-6.3-10.397-15.263-14.084-1.434-.564-3.995-1.127-6.453-1.127z"
        fill="#fff"
      />
      <path
        d="M62.586 169.589H50.653c-.82 0-1.537.512-1.537 1.178v7.938h15.007v-7.938c0-.666-.666-1.178-1.537-1.178z"
        fill="#fff"
      />
      <path d="M57.773 0h-2.305v170.46h2.304V0z" fill="#fff" />
    </svg>
  );
}

export default SvgBauble;
