import React from 'react';
import styled from 'styled-components';
import { hexToRGB } from 'utils';

function Registrations({ numberOfPeopleRegistered, groups, totalGroupMembers, colors }) {
  return (
    <Container>
      <Title>Registration</Title>
      <Bar colors={colors}>
        <Analytic>
          <span>{numberOfPeopleRegistered}</span>
          <p>Number of people registered</p>
        </Analytic>
        <Analytic>
          <span>{groups}</span>
          <p>Number of groups created</p>
        </Analytic>
        <Analytic>
          <span>{totalGroupMembers}</span>
          <p>Number of group participants</p>
        </Analytic>
      </Bar>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h5`
  color: #004D9A;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 0.875rem;
  margin: 5.5em auto 1.667em;
  text-transform: uppercase;
`;

const Bar = styled.div`
  background: ${({ colors }) => hexToRGB({ color: '#007fc0', alpha: 0.1 })};
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 1.5rem 1.25rem;
  row-gap: 1.75rem;
  color: #004d9a;
  span {
    color: #004d9a;
  }
  @media only screen and (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Analytic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  span {
    font-size: 3rem !important;
    font-weight: 700;
    line-height: 3rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 200px;
    text-align: center;
  }
`;

export default Registrations;