import * as React from 'react';

function SvgExLogoWhite(props) {
  return (
    <svg viewBox="0 0 165 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.996 0h2.173l7.967 19.857L41.133 0h2.173l-8.993 21.97h-2.324L22.996 0zM79.097 3.018V21.97h-1.87V0h1.9L92.89 18.71V0h1.871v21.97h-1.84L79.096 3.018zM111.72 1.72h-7.182V0h16.266v1.72h-7.183v20.25h-1.901V1.72zM14.636.03H0v1.72h14.636V.03zM10.894 9.506H0v1.72h10.14l.754-1.72zM14.636 20.28H0V22h14.636v-1.72zM67.418.03H52.782v1.72h14.636V.03zM63.676 9.506H52.782v1.72h10.14l.754-1.72zM67.418 20.28H52.782V22h14.636v-1.72z"
        fill="#fff"
      />
      <path
        d="M154.211 9.144l-9.989 12.796h2.354l7.635-9.778 7.696 9.808h2.354l-10.05-12.826z"
        fill="#EB0045"
      />
      <path
        d="M156.354 9.446L163.747 0h-2.414l-6.156 7.907 1.177 1.539zM153.276 7.907L147.089 0h-2.384l7.394 9.446 1.177-1.54z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgExLogoWhite;
