import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import {
  ModerationFilters,
  LoadingSpinner,
  LoadingSpinnerFullScreenWrapper,
  ProtectedRoute,
  SEO
} from 'components';
import { gridContainer } from 'styles';

const eventModerationTemplate = ({ pageContext }) => {
  const { loading, firebase, user } = useContext(FirebaseContext);
  const [isPendingFilterSelected, setIsPendingFilterSelected] = useState(true);
  const [pendingAttendees, setPendingAttendees] = useState(null);
  const [approvedAttendees, setApprovedAttendees] = useState(null);
  const [unsubscribeFromApprovedAttendees, setUnsubscribeFromApprovedAttendees] = useState();
  const [attendeeFetchingStates, setAttendeeFetchingStates] = useState({});
  const { event } = pageContext;

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/');
    }
  }, [loading, firebase, user]);

  useEffect(() => {
    let unsubscribeFromPendingAttendees;
    if (firebase) {
      unsubscribeFromPendingAttendees = firebase.subscribeToPendingEventRegistrants({
        eid: event.eid,
        onSnapshot: (snapshot) => {
          if (!snapshot.metadata.fromCache) {
            console.log('Listening for pending attendees');
            if (!snapshot.empty) {
              const _pendingAttendees = snapshot.docs.map((doc) => doc.data());
              setPendingAttendees(_pendingAttendees);
            } else {
              setPendingAttendees([]);
            }
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPendingAttendees) {
        console.log('Unsubscribing from pending attendees');
        unsubscribeFromPendingAttendees();
      }
    };
  }, [firebase]);

  useEffect(() => {
    if (firebase && !isPendingFilterSelected && !unsubscribeFromApprovedAttendees) {
      setUnsubscribeFromApprovedAttendees((subscribed) => {
        if (!subscribed) {
          return firebase.subscribeToApprovedEventRegistrants({
            eid: event.eid,
            onSnapshot: (snapshot) => {
              if (!snapshot.metadata.fromCache) {
                if (!snapshot.empty) {
                  console.log('Listening for approved attendees');
                  const _approvedAttendees = snapshot.docs.map((doc) => doc.data());
                  setApprovedAttendees(_approvedAttendees);
                } else {
                  setApprovedAttendees([]);
                }
              }
            }
          });
        }
      });
    }
  }, [firebase, isPendingFilterSelected, unsubscribeFromApprovedAttendees]);

  useEffect(() => {
    if (unsubscribeFromApprovedAttendees) {
      return () => {
        if (unsubscribeFromApprovedAttendees) {
          console.log('Unsubscribing from approved attendees');
          unsubscribeFromApprovedAttendees();
        }
      };
    }
  }, [unsubscribeFromApprovedAttendees]);

  const handleAccept = async (attendee) => {
    setAttendeeFetchingStates((currentState) => ({
      ...currentState,
      [attendee.uid]: {
        accepting: true,
        denying: false
      }
    }));

    try {
      /* In this case they'll just get access to this single event, but on the site-wide moderation
      page we'll be able to pass in multiple events to the 'eids' array in the first Promise
      below. */
      setTimeout(async () => {
        await Promise.all([
          firebase.grantRegistrantAccessToTheseEvents({ uid: attendee.uid, eids: [event.eid] }),
          firebase.sendEventAccessGrantedEmail({
            attendeeFirstName: attendee.firstName,
            attendeeEmail: attendee.email,
            event
          })
        ]);
        resetFetchingState(attendee);
        /* Duration of Framer Motion animation */
      }, 950);
    } catch (error) {
      console.error(error);
      resetFetchingState(attendee);
    }
  };

  const handleDeny = (attendee) => {
    setAttendeeFetchingStates((currentState) => ({
      ...currentState,
      [attendee.uid]: {
        accepting: false,
        denying: true
      }
    }));

    try {
      /* In this case they'll just be denied access to this single event, but on the site-wide moderation
      page we'll be able to pass in multiple events to the 'events' array in the Promise
      below. */
      setTimeout(async () => {
        await firebase.denyRegistrantAccessToTheseEvents({ uid: attendee.uid, eids: [event.eid] });
        resetFetchingState(attendee);
        /* Duration of Framer Motion animation */
      }, 950);
    } catch (error) {
      console.error(error);
      resetFetchingState(attendee);
    }
  };

  const resetFetchingState = (attendee) => {
    setAttendeeFetchingStates((currentState) => ({
      ...currentState,
      [attendee.uid]: {
        accepting: false,
        denying: false
      }
    }));
  };

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle={`${event.name} Moderation`} />
      <Wrapper>
        {!user ? (
          <LoadingSpinnerFullScreenWrapper>
            <LoadingSpinner style={{ width: '7.5rem', color: '#007FC0' }} />
          </LoadingSpinnerFullScreenWrapper>
        ) : (
          <>
            <h1>{`${event.name} Moderation`}</h1>
            <ModerationFilters
              isPendingFilterSelected={isPendingFilterSelected}
              setIsPendingFilterSelected={setIsPendingFilterSelected}
            />
            {isPendingFilterSelected ? (
              pendingAttendees?.length ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Workplace</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingAttendees.map((attendee) => (
                      <tr key={attendee.uid}>
                        <td>{attendee.fullName}</td>
                        <td>{attendee.email}</td>
                        <td>{attendee.workplaceName}</td>
                        <td
                          style={{
                            border: 'none',
                            padding: '0',
                            width: '13.25rem'
                          }}>
                          <ButtonWrapper>
                            <motion.button
                              onClick={() => handleAccept(attendee)}
                              disabled={attendeeFetchingStates[attendee.uid]?.accepting}
                              whileTap={{
                                scale: 0.9
                              }}
                              type="button">
                              {attendeeFetchingStates[attendee.uid]?.accepting ? (
                                <LoadingSpinner style={{ color: '#fff', width: '1.125rem' }} />
                              ) : (
                                'Accept'
                              )}
                            </motion.button>
                            <motion.button
                              onClick={() => handleDeny(attendee)}
                              disabled={attendeeFetchingStates[attendee.uid]?.denying}
                              whileTap={{
                                scale: 0.9
                              }}
                              type="button">
                              {attendeeFetchingStates[attendee.uid]?.denying ? (
                                <LoadingSpinner style={{ color: '#fff', width: '1.125rem' }} />
                              ) : (
                                'Deny'
                              )}
                            </motion.button>
                          </ButtonWrapper>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>There are currently no pending attendees</p>
              )
            ) : approvedAttendees?.length ? (
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Company/Institution Name</th>
                  </tr>
                </thead>
                <tbody>
                  {approvedAttendees.map((attendee) => (
                    <tr key={attendee.uid}>
                      <td>{attendee.firstName}</td>
                      <td>{attendee.email}</td>
                      <td>{attendee.workplaceName}</td>
                      {/* {isPendingFilterSelected && (
                    <td
                      style={{
                        border: 'none',
                        padding: '0',
                        width: '13.25rem'
                      }}>
                      <ButtonWrapper>
                        <button
                          onClick={() => handleAccept(attendee)}
                          disabled={attendeeFetchingStates[attendee.uid]?.accepting}
                          type="button">
                          {attendeeFetchingStates[attendee.uid]?.accepting ? (
                            <LoadingSpinner style={{ color: '#fff', width: '1.125rem' }} />
                          ) : (
                            'Accept'
                          )}
                        </button>
                        <button onClick={() => handleDeny(attendee)} type="button">
                          Deny
                        </button>
                      </ButtonWrapper>
                    </td>
                  )} */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>There are currently no approved attendees</p>
            )}
          </>
        )}
      </Wrapper>
    </ProtectedRoute>
  );
};

const Wrapper = styled.div`
  ${gridContainer}
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  padding: 7.5rem 1rem 7.5rem;
  max-width: 1000px;
  margin: 0 12px;

  @media (min-width: 768px) {
    padding: 7.5rem 0 3rem;
  }

  @media (min-width: 1024px) {
    margin: 0 auto;
  }

  @media (min-width: 1150px) {
    grid-gap: 1.25rem;
  }

  @media (min-width: 1600px) {
    padding: 7.5rem 0 0;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1em;
    text-align: center;
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }

  p {
    color: ${({ theme }) => theme.textColor};
  }
`;

const Table = styled.table`
  border-spacing: 0.75rem;
  left: -0.75rem;
  position: relative;
  width: 100%;

  th,
  td {
    border: 0.063rem solid black;
    height: 2rem;
    padding: 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 13.25rem;

  button {
    align-items: center;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    width: 6.25em;
  }
`;

export default eventModerationTemplate;
