import React, { useContext, useState } from 'react';
import { Button } from 'components';
import { LocalContext } from 'context';
import { FormContainer, FormLabel, FormInput, FormInputLabel, TextArea } from '../FormComponents';

const ContactForm = () => {
  const { theme } = useContext(LocalContext);
  const [formValues, setFormValues] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  return (
    <FormContainer theme={theme}>
      <FormLabel>Get In Touch</FormLabel>
      <FormInputLabel hide htmlFor="name">Your Name</FormInputLabel>
      <FormInput
        id="name"
        name="name"
        type="text"
        placeholder="Insert Name"
        onChange={handleChange}
      />
      <FormInputLabel hide htmlFor="email">Email</FormInputLabel>
      <FormInput
        id="email"
        name="email"
        type="email"
        placeholder="Insert Email"
        onChange={handleChange}
      />
      <FormInputLabel hide htmlFor="message">Your Message</FormInputLabel>
      <TextArea
        id="message"
        name="message"
        placeholder="Enter your message here..."
        onChange={handleChange}
      />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button width="7.5rem" style={{ alignSelf: 'center' }}>
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};

export default ContactForm;
