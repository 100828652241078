import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { generateNanoId } from 'utils';
import { AnimatePresence } from 'framer-motion';
import { LocalContext, FirebaseContext } from 'context';
import { Button } from 'components';
import GroupMembers from './GroupMembers';

function GroupWatching({ question, setShowGroupWatchingDialog, groupWatchingDialogRef }) {
  const { selectedEvent, handleDialog } = useContext(LocalContext);
  const { user, firebase } = useContext(FirebaseContext);

  console.log(selectedEvent);
  const initialGroupMembersState = () => [
    {
      id: generateNanoId(),
      name: ''
    },
    {
      id: generateNanoId(),
      name: ''
    }
  ];

  const [groupMembers, setGroupMembers] = useState(initialGroupMembersState());
  const [addGroupMembers, setAddGroupMembers] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const updatingGroupMembers = user?.groupWatching[selectedEvent.eid];

  useEffect(() => {
    if (updatingGroupMembers) {
      const _groupMembers = user.groupWatching[selectedEvent.eid];

      _groupMembers.push({
        id: generateNanoId(),
        name: ''
      });

      setGroupMembers(_groupMembers);
    }
  }, [updatingGroupMembers]);

  const handleGroupMemberTextChange = (e, memberId) => {
    const { value } = e.target;

    const allMembersIds = groupMembers.map((member) => member.id);

    const lastFieldIsFocused = memberId === allMembersIds[allMembersIds.length - 1];

    // Adds an extra member field when user starts typing in the last member field.
    if (lastFieldIsFocused && value !== '') {
      setGroupMembers((currentState) => [
        ...currentState,
        {
          id: generateNanoId(),
          name: ''
        }
      ]);
    }

    setGroupMembers((currentState) =>
      currentState.map((member) => {
        if (member.id === memberId) {
          member.name = value;
        }
        return member;
      })
    );
  };

  const closeGroupWatchingDialog = () => {
    setMessage('Thank You');
    setTimeout(
      () =>
        handleDialog({
          dialogRef: groupWatchingDialogRef,
          animation: 'close',
          stateHandler: () => setShowGroupWatchingDialog(false)
        }),
      1000
    );
  };

  const handleSubmitGroupMembers = async (e) => {
    e.preventDefault();
    const filteredGroupMembers = groupMembers.filter((member) => member.name);
    console.log(groupMembers);
    if (filteredGroupMembers.length) {
      await firebase.updateGroupWatching({
          uid: user.uid,
          groupWatching: Object.assign(user.groupWatching, {
            [selectedEvent.eid]: filteredGroupMembers
          })
        })
        .then(closeGroupWatchingDialog)
        .catch((err) => {
          console.error(err);
          setError({
            code: err.code,
            message: err.message
          });
        });
    }
  };

  const handleNoGroupMembers = async (e) => {
    e.preventDefault();
    await firebase.updateGroupWatching({
        uid: user.uid,
        groupWatching: Object.assign(user.groupWatching, {
          [selectedEvent.eid]: []
        })
      })
      .then(closeGroupWatchingDialog)
      .catch((err) => {
        console.error(err);
        setError({
          code: err.code,
          message: err.message
        });
      });
  };

  return (
    <Container colors={selectedEvent.colors}>
      {!addGroupMembers && !message ? (
        <>
          <Message colors={selectedEvent.colors}>
            <p>{question}</p>
          </Message>
          <Options>
            <Button
              colors={selectedEvent.colors}
              type="button"
              onClick={() => setAddGroupMembers(true)}
              whileHover={{
                scale: 1.025
              }}
              whileTap={{
                scale: 0.95
              }}
              style={{
                alignSelf: 'flex-end',
                backgroundColor: selectedEvent.colors.secondary,
                borderRadius: '0.125rem',
                height: '2.5rem',
                marginTop: '0.45rem',
                width: '6rem'
              }}>
              Yes
            </Button>
            <Button
              colors={selectedEvent.colors}
              type="button"
              whileHover={{
                scale: 1.025
              }}
              whileTap={{
                scale: 0.95
              }}
              style={{
                alignSelf: 'flex-end',
                backgroundColor: selectedEvent.colors.secondary,
                borderRadius: '0.125rem',
                height: '2.5rem',
                marginTop: '0.45rem',
                width: '6rem'
              }}
              onClick={handleNoGroupMembers}>
              No
            </Button>
          </Options>
        </>
      ) : (
        <AnimatePresence>
          {message ? (
            <Message
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              colors={selectedEvent.colors}>
              {message}
            </Message>
          ) : (
            <>
              <h4>If watching with multiple people, please add their names below:</h4>
              <GroupMembers
                groupMembers={groupMembers}
                setGroupMembers={setGroupMembers}
                handleGroupMemberTextChange={handleGroupMemberTextChange}
                colors={selectedEvent.colors}
              />
              {error && <p>{error.message}</p>}
              <Options>
                <Button
                  colors={selectedEvent.colors}
                  type="button"
                  onClick={() => setAddGroupMembers(false)}
                  whileHover={{
                    scale: 1.025
                  }}
                  whileTap={{
                    scale: 0.95
                  }}
                  style={{
                    alignSelf: 'flex-end',
                    backgroundColor: 'transparent',
                    border: `1px solid ${selectedEvent.colors.secondary}`,
                    borderRadius: '0.125rem',
                    color: selectedEvent.colors.secondary,
                    height: '2.5rem',
                    marginTop: '0.45rem',
                    width: '8rem'
                  }}>
                  Go Back
                </Button>
                <Button
                  colors={selectedEvent.colors}
                  disabled={groupMembers.length === 0}
                  type="button"
                  onClick={handleSubmitGroupMembers}
                  whileHover={{
                    scale: 1.025
                  }}
                  whileTap={{
                    scale: 0.95
                  }}
                  style={{
                    alignSelf: 'flex-end',
                    backgroundColor: selectedEvent.colors.secondary,
                    borderRadius: '0.125rem',
                    height: '2.5rem',
                    marginTop: '0.45rem',
                    width: '8rem'
                  }}>
                  {user?.groupWatching?.[selectedEvent.eid] ? 'Update' : 'Add'}
                </Button>
              </Options>
            </>
          )}
        </AnimatePresence>
      )}
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  position: relative;
  width: min-content;
  min-width: 23.75rem;
  h4 {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
  }
  button {
    cursor: pointer;
    margin: 0;
  }
  @media screen and (min-width: 1150px) {
    padding: 2rem 3rem;
  }
`;

const Options = styled.div`
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 1rem 0;
`;

const Message = styled.div`
  color: ${({ colors }) => colors.secondary};
  font-weight: 700;
  padding: 0.75rem 0;
  text-align: center;
  width: 100%;
`;

export default GroupWatching;