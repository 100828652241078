import * as React from 'react';
import { motion } from 'framer-motion';

function SvgSpeakerSvgBackground(props) {
  return (
    <motion.svg
      viewBox="0 0 392 392"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <motion.path
        d="M308.173 196C354.47 196 393.548 156.831 374.346 114.705V114.705C362.711 89.1822 345.735 66.4537 324.563 48.0552C303.39 29.6567 278.516 16.0169 251.619 8.05724C224.723 0.0975935 196.432 -1.99642 168.657 1.91668C140.881 5.82978 114.27 15.6588 90.6195 30.7397C66.9693 45.8207 46.8314 65.8021 31.5665 89.334C16.3016 112.866 6.26537 139.4 2.1358 167.144V167.144C-4.68015 212.936 43.6988 239.783 88.1929 226.992L171.796 202.958C175.8 201.807 177.939 197.524 178.552 193.403V193.403C178.924 190.906 179.827 188.518 181.201 186.4C182.575 184.282 184.387 182.484 186.516 181.127C188.644 179.769 191.039 178.885 193.539 178.533C196.039 178.18 198.585 178.369 201.006 179.085C203.426 179.802 205.665 181.029 207.571 182.685C209.476 184.341 211.004 186.386 212.051 188.683V188.683C213.779 192.475 217.018 196 221.184 196H308.173Z"
        fill="#CCFF00"
      />
      <motion.path
        d="M90.907 240.192C49.1636 257.745 28.771 307.808 61.7684 338.821V338.821C81.7572 357.608 105.467 371.989 131.364 381.036C157.261 390.082 184.768 393.591 212.107 391.337V391.337C257.238 387.616 272.453 335.744 250.719 296.017L208.008 217.949C206.052 214.374 201.511 213.245 197.45 213.58V213.58C194.989 213.783 192.513 213.467 190.183 212.653C187.852 211.839 185.718 210.545 183.919 208.854V208.854C180.949 206.063 176.694 204.118 172.937 205.698L90.907 240.192Z"
        fill="#CCFF00"
      />
      <circle cx="198" cy="195" r="183" fill="#E50060" />
      <circle cx="198" cy="195" r="169" fill="#0004D9" />
    </motion.svg>
  );
}

export default SvgSpeakerSvgBackground;
