import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';

export default (props) => {
  const { theme } = useContext(LocalContext);
  return (
    <StyledAltButton
      theme={theme}
      onClick={props.onClick ? props.onClick : null}
      width={props.width}
      disabled={props.disabled}
      hover={props.hover}>
      {props.children}
    </StyledAltButton>
  );
};

const StyledAltButton = styled.button`
  height: 3rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: noto-sans, tahoma, sans-serif;
  text-transform: uppercase;
  color: ${({ theme, hover }) =>
    theme.className === 'contrast'
      ? 'yellow'
      : theme.className !== 'contrast' && hover
      ? theme.bg
      : theme.fg};
  background-color: transparent;
  cursor: pointer;
  transition: 0.5s;
  width: ${(props) => (props.width ? props.width : '100%')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :disabled {
    color: #ccc;
  }
  :hover {
    color: ${({ theme }) => theme.fg};
  }
`;
