import React, { useContext, useState, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Modal, LoginModal } from 'components';
import * as Sentry from '@sentry/gatsby';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import Autocomplete from 'react-google-autocomplete';
import PasswordMask from 'react-password-mask';
import BaseSelect from 'react-select';
import hexRgb from 'hex-rgb';
import { checkIfNameIncludesOnlyStandardChars, formatName, validateEmailFormat } from 'utils';
import { motion, AnimatePresence } from 'framer-motion';
import { fadeInAndOutVariants } from 'styles';
import { Markup } from 'interweave';
import FixRequiredSelect from '../FixRequiredSelect';
import {
  FormContainer,
  FormInput,
  FormInputLabel,
  FormChecks,
  FormCheckboxText
} from '../FormComponents';

const FormSelect = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />
);

const resetForm = () => ({
  title: '',
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  profession: '',
  speciality: '',
  workplaceName: '',
  termsAndConditions: false,
  ePermissionsConsent: false,
  cpdPermissionsConsent: false,
  referringUrl:
    typeof window !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('referringUrlPfizerVirtualCafeEvent21'))?.data
});

const includesSurname = (name) => /\s/.test(name.trim());

const RegistrationForm = ({ event, colors }) => {
  const { firebase, user } = useContext(FirebaseContext);
  const { selectedEvent } = useContext(LocalContext);
  const { pathname } = useLocation();
  const invert = pathname === '/';
  const registrationFields = event?.registrationFields;
  const [formValues, setFormValues] = useState(resetForm());
  const [errorMessage, setErrorMessage] = useState('');
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [checkingIfUserAlreadyExists, setCheckingIfUserAlreadyExists] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [disableSubmitButtonWhileCheckingInputs, setDisableSubmitButtonWhileCheckingInputs] =
    useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 750;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!checkIfNameIncludesOnlyStandardChars(name)) {
          setErrorMessage('Please include only standard characters in your name');
        } else if (!includesSurname(name)) {
          setErrorMessage('Please include a surname');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  useEffect(() => {
    const { email } = formValues;
    if (validateEmailFormat(email)) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(async () => {
        setCheckingIfUserAlreadyExists(true);
        try {
          const { data: _userAlreadyExists } = await firebase.checkIfUserAlreadyExists({ email });
          if (_userAlreadyExists) {
            setUserAlreadyExists(true);
            throw new Error(
              "This email address already belongs to a Pfizer Virtual Cafe account. Click 'Login' above if it belongs to you."
            );
          } else if (userAlreadyExists) {
            setUserAlreadyExists(false);
            setErrorMessage('');
          }
        } catch (error) {
          setErrorMessage(error.message);
        } finally {
          setCheckingIfUserAlreadyExists(false);
          setDisableSubmitButtonWhileCheckingInputs(false);
        }
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (errorMessage && !userAlreadyExists) {
      setErrorMessage('');
    }
    if (!submissionStatus.submitted) {
      setFormValues((currentValues) => ({
        ...currentValues,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  // const handleOtherInputChange = (e) => {
  //   e.persist();
  //   const { name, value } = e.target;
  //   if (!submissionStatus.submitted) {
  //     setOtherFormValues((currentValues) => ({
  //       ...currentValues,
  //       [name]: value
  //     }));
  //   }
  // };

  const handleWorkplaceAutocompleteSelection = (res) => {
    const { name } = res;
    if (errorMessage && !userAlreadyExists) {
      setErrorMessage('');
    }
    setFormValues((currentValues) => ({
      ...currentValues,
      workplaceName: name.split(',')[0]
    }));
  };

  /* useEffect(() => {
    if (formValues.profession !== 'Other') {
      setOpenOtherField((currentValues) => ({
        ...currentValues,
        profession: false
      }));
    }
    if (formValues.speciality !== 'Other') {
      setOpenOtherField((currentValues) => ({
        ...currentValues,
        speciality: false
      }));
    }
  }, [formValues.profession, formValues.speciality]); */

  async function handleSubmit(e) {
    e.preventDefault();

    if (errorMessage || submissionStatus.submitting) {
      return;
    }

    const {
      title,
      name,
      email,
      password,
      confirmPassword,
      profession,
      speciality,
      workplaceName,
      termsAndConditions,
      ePermissionsConsent,
      cpdPermissionsConsent,
      referringUrl
    } = formValues;

    if (!title && (!event || registrationFields?.includes('title'))) {
      setErrorMessage('Please select a title');
      return;
    }

    if (!name) {
      setErrorMessage('Please include your full name');
      return;
    }

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!email) {
      setErrorMessage('Please include an email address');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("'Choose Password' and 'Confirm Password' fields must match");
      return;
    }

    if (userAlreadyExists) {
      setErrorMessage(
        "This email address already belongs to a Pfizer Virtual Cafe account. Click 'Login' above if it belongs to you."
      );
      return;
    }

    if (!profession && (!event || registrationFields?.includes('profession'))) {
      setErrorMessage('Please include your profession');
      return;
    }

    if (!speciality && (!event || registrationFields?.includes('speciality'))) {
      setErrorMessage('Please include your speciality');
      return;
    }

    if (!workplaceName && (!event || registrationFields?.includes('workplaceName') && event.eid !== 'eIj0KJ')) {
      setErrorMessage('Please include your workplace name');
      return;
    }

    if (event?.termsAndConditions && !termsAndConditions) {
      setErrorMessage('You must agree to the Terms & Conditions to register');
      return;
    }

    try {
      setSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const _user = await firebase.createUserInAuth({
        email,
        password
      });

      const _title = title.trim();
      const formattedName = formatName(name);
      const fullName = formattedName.trim();
      const _email = email.toLowerCase().trim();
      const _workplaceName = workplaceName.trim() || '';
      const _profession = profession.trim() || '';
      const _speciality = speciality.trim() || '';

      await firebase.createUserInDatabase({
        title: _title,
        fullName,
        email: _email,
        workplaceName: _workplaceName,
        profession: _profession,
        speciality: _speciality,
        ePermissionsConsent,
        cpdPermissionsConsent,
        uid: _user.uid,
        eid: selectedEvent?.eid || '',
        referringUrl
      });

      if (selectedEvent?.tangent90EventSource && selectedEvent?.tangent90EventCollectionId) {
        await firebase.addOrUpdateUsersTangent90Consent({
          title: _title,
          fullName,
          email: _email,
          workplaceName: _workplaceName,
          profession: _profession,
          speciality: _speciality,
          consented: ePermissionsConsent,
          tangent90EventSource: selectedEvent?.tangent90EventSource,
          tangent90EventCollectionId: selectedEvent?.tangent90EventCollectionId
        });
      }

      setSubmissionStatus({
        submitting: false,
        submitted: true
      });

      setFormValues(resetForm());
    } catch (error) {
      console.error(error);

      Sentry.captureException(error);

      const userFromAuth = firebase?.auth?.currentUser;
      let userFromDatabase;

      if (userFromAuth) {
        userFromDatabase = await firebase.fetchUser(userFromAuth.uid);
      }

      if (userFromAuth && !userFromDatabase.exists) {
        console.error(
          "User exists in auth but not in database. There's been an error, so deleting from Auth."
        );
        await userFromAuth.delete();
        console.error('User from Auth deleted.');
      }

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <StyledFormContainer
      autoComplete="new-password"
      onSubmit={handleSubmit}
      colors={colors}
      invert={invert}
      eid={event?.eid}>
      <div
        style={{
          color: invert ? '#3c3c3c' : '#fff',
          fontSize: '1.75rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
          marginBottom: '0.55rem',
          textAlign: 'center'
        }}>
        Register Here
      </div>
      <div
        style={{
          color: invert ? '#3c3c3c' : '#fff',
          display: 'flex',
          fontSize: '0.875rem',
          justifyContent: 'center',
          marginBottom: '2.625em'
        }}>
        Already registered?&nbsp;
        <Modal
          hideCloseButton
          trigger={
            <span
              style={{
                color: invert ? '#3c3c3c' : '#fff',
                fontSize: '0.875rem',
                textAlign: 'center',
                marginBottom: '1.5em',
                textDecoration: 'underline'
              }}>
              Click here!
            </span>
          }
          modalContent={<LoginModal colors={colors} />}
        />
      </div>
      <div
        style={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr'
        }}>
        <AnimatePresence>
          <motion.div
            style={{
              gridRow: 1,
              gridColumn: 1
            }}
            variants={fadeInAndOutVariants}
            initial="initial"
            animate="animate"
            exit="exit">
            <Fields>
              {(!event || registrationFields?.includes('title')) && (
                <FormSelect
                  id="title"
                  name="title"
                  required
                  defaultValue={formValues.title}
                  value={titleOptions.filter(({ value }) => value === formValues.title)}
                  controlShouldRenderValue
                  onChange={({ value }) => {
                    setFormValues((currentValues) => ({
                      ...currentValues,
                      title: value
                    }));
                  }}
                  placeholder="Title*"
                  styles={customDropdownMenuStyles({ invert, colors })}
                  options={titleOptions}
                />
              )}
              <FormInput
                id="name"
                name="name"
                onChange={handleInputChange}
                placeholder="Full Name*"
                type="text"
                value={formValues.name}
                invert={invert}
                required
              />
              <div style={{ position: 'relative' }}>
                <FormInput
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  onBlur={() => setCheckingIfUserAlreadyExists(false)}
                  placeholder="Email*"
                  type="email"
                  value={formValues.email}
                  invert={invert}
                  required
                  autoComplete="off"
                />
                {checkingIfUserAlreadyExists && disableSubmitButtonWhileCheckingInputs && (
                  <LoadingSpinner
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      width: '2rem',
                      top: '14px',
                      right: '14px'
                    }}
                  />
                )}
              </div>
              <PasswordMask
                id="password"
                minLength={6}
                name="password"
                onChange={handleInputChange}
                placeholder="Choose Password*"
                required
                type="password"
                value={formValues.password}
                inputClassName="password-mask-input"
                buttonStyles={{
                  background: colors.secondary
                }}
                buttonClassName={
                  formValues.password ? 'password-mask-button' : 'password-mask-button-hide'
                }
              />
              <PasswordMask
                id="confirmPassword"
                minLength={6}
                name="confirmPassword"
                onChange={handleInputChange}
                placeholder="Confirm Password*"
                required
                type="password"
                value={formValues.confirmPassword}
                inputClassName="password-mask-input"
                buttonClassName={
                  formValues.confirmPassword ? 'password-mask-button' : 'password-mask-button-hide'
                }
                buttonStyles={{
                  background: colors.secondary
                }}
              />
              {(!event || registrationFields?.includes('profession')) && (
                <FormSelect
                  id="profession"
                  name="profession"
                  required
                  defaultValue={formValues.profession}
                  value={professionOptions.filter(({ value }) => value === formValues.profession)}
                  controlShouldRenderValue
                  onChange={({ value }) => {
                    setFormValues((currentValues) => ({
                      ...currentValues,
                      profession: value
                    }));
                  }}
                  placeholder="Profession*"
                  styles={customDropdownMenuStyles({ invert, colors })}
                  options={professionOptions}
                />
              )}
              {(!event || registrationFields?.includes('speciality')) && (
                <FormSelect
                  id="speciality"
                  name="speciality"
                  required
                  defaultValue={formValues.speciality}
                  value={specialityOptions.filter(({ value }) => value === formValues.speciality)}
                  controlShouldRenderValue
                  onChange={({ value }) => {
                    setFormValues((currentValues) => ({
                      ...currentValues,
                      speciality: value
                    }));
                  }}
                  placeholder="Speciality*"
                  styles={customDropdownMenuStyles({ invert, colors })}
                  options={specialityOptions}
                />
              )}
              {(!event || registrationFields?.includes('workplaceName')) && (
                <FormInput
                  id="workplaceName"
                  name="workplaceName"
                  onChange={handleInputChange}
                  placeholder={event.eid !== 'eIj0KJ' ? "Workplace Name*" : "Workplace Name"}
                  type="text"
                  value={formValues.workplaceName}
                  invert={invert}
                  required={event.eid !== 'eIj0KJ'}
                />
              )}
            </Fields>
            {event?.termsAndConditions && (
              <FormChecks>
                <FormInputLabel hide htmlFor="termsAndConditions">
                  Terms And Conditions
                </FormInputLabel>
                <FormCheckbox
                  checked={formValues.termsAndConditions}
                  id="termsAndConditions"
                  name="termsAndConditions"
                  onChange={handleInputChange}
                  required
                  colors={colors}
                  type="checkbox"
                />
                <FormCheckboxText>{event.termsAndConditions}</FormCheckboxText>
              </FormChecks>
            )}
            {event?.ePermissions && (
              <FormChecks>
                <FormInputLabel hide htmlFor="ePermissionsConsent">
                  ePermissions
                </FormInputLabel>
                <FormCheckbox
                  checked={formValues.ePermissionsConsent}
                  id="ePermissionsConsent"
                  name="ePermissionsConsent"
                  onChange={handleInputChange}
                  colors={colors}
                  type="checkbox"
                />
                <FormCheckboxText colors={colors}><Markup content={event.ePermissions} noWrap /></FormCheckboxText>
              </FormChecks>
            )}
            {event?.cpdPermissions && (
              <FormChecks>
                <FormInputLabel hide htmlFor="cpdPermissionsConsent">
                  CPD Permissions
                </FormInputLabel>
                <FormCheckbox
                  checked={formValues.cpdPermissionsConsent}
                  id="cpdPermissionsConsent"
                  name="cpdPermissionsConsent"
                  onChange={handleInputChange}
                  colors={colors}
                  type="checkbox"
                />
                <FormCheckboxText colors={colors}><Markup content={event.cpdPermissions} noWrap /></FormCheckboxText>
              </FormChecks>
            )}
            <ErrorMessage errorMessage={errorMessage} style={{ color: colors.tertiary }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1.25rem'
              }}>
              {user?.emailVerified === false || submissionStatus.submitted ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginBottom: '0.5rem',
                    color: colors?.secondary || '#fff',
                    width: '100%'
                  }}>
                  Click the link in the email we just sent you to verify your email address and
                  complete your registration. Can&apos;t find our email? Be sure to check your junk!
                </div>
              ) : (
                <Button
                  type="submit"
                  width="7.5rem"
                  disabled={
                    user?.emailVerified === false ||
                    userAlreadyExists ||
                    disableSubmitButtonWhileCheckingInputs
                  }
                  userAlreadyExists={userAlreadyExists}
                  tabIndex={0}
                  whileHover={{
                    scale: 1.05
                  }}
                  whileTap={{
                    scale: 0.95
                  }}
                  style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    cursor: disableSubmitButtonWhileCheckingInputs
                      ? 'progress'
                      : userAlreadyExists || user?.emailVerified === false
                      ? 'not-allowed'
                      : 'pointer',
                    backgroundColor: colors.secondary,
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative'
                  }}>
                  {
                    <AnimatePresence>
                      {submissionStatus.submitting && (
                        <LoadingSpinner
                          style={{ width: '2rem', color: '#fff', position: 'absolute' }}
                        />
                      )}
                    </AnimatePresence>
                  }
                  {
                    <AnimatePresence>
                      {!submissionStatus.submitting && (
                        <motion.span
                          variants={fadeInAndOutVariants()}
                          initial="initial"
                          animate="animate"
                          exit="exit">
                          Submit
                        </motion.span>
                      )}
                    </AnimatePresence>
                  }
                </Button>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};

  @media screen and (min-width: 1150px) {
    background: ${({ colors, invert }) =>
      `${hexRgb(invert ? '#fff' : colors.primary, { format: 'css', alpha: 0.7 })}`};
  }

  input {
    margin-bottom: 0;
  }

  button {
    background: ${({ colors }) => colors.tertiary};
  }
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.625rem;
  margin-bottom: 1.625rem;
`;

const FormWorkplaceAutoComplete = styled(Autocomplete)`
  align-items: center;
  background: transparent;
  border: 0.063rem solid ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
  color: ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  height: 3.75rem;
  letter-spacing: 0.031rem;
  outline: none;
  padding: 0 1.25rem;

  ::placeholder {
    font-style: italic;
    font-weight: bold;
    font-size: 1rem;
    color: ${({ invert }) => (invert ? '#3c3c3c' : '#fff')};
  }
`;

const FormCheckbox = styled.input`
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0.75rem;
  margin-top: 0.3rem;
  outline: none;
  padding: 0.625rem;
  position: relative;
  transition: all 0.2s;
  width: 1.5rem;

  &:focus {
    border: 0.188rem solid #fff;
    margin-right: calc(0.75rem - 0.188rem);
  }

  &:checked {
    background-color: ${({ colors }) => `${colors.secondary}`};
    border: 0.188rem solid #fff;
    margin-right: calc(0.75rem - 0.188rem);
  }

  &:checked:before {
    color: ${({ invert }) => (invert ? `#000` : `#fff`)};
    content: '✓';
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37.5%, -67.5%);
    width: 1rem;
  }
`;

const customDropdownMenuStyles = ({ colors, invert }) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused
      ? `0.188em solid ${invert ? '#3c3c3c' : '#fff'}`
      : `0.063em solid ${invert ? '#3c3c3c' : '#fff'}`,
    display: 'flex',
    fontSize: '1rem',
    fontWeight: 'bold',
    height: '3.75rem',
    letterSpacing: '0.031rem',
    padding: '0'
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: '0 0 0 1.25rem',
    cursor: 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: `${invert ? '#3c3c3c' : '#fff'}`,
    '&:hover': {
      color: `${invert ? '#3c3c3c' : '#fff'}`
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: `${invert ? '#3c3c3c' : '#fff'}`
  }),
  menu: (provided) => ({
    ...provided,
    background: invert ? '#fff' : colors.primary,
    border: `0.214em solid ${invert ? '#3c3c3c' : '#fff'}`,
    borderRadius: '0',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.214em',
    letterSpacing: '0.071em',
    margin: '0',
    width: 'calc(100% + 0.429em)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: `${invert ? '#3c3c3c' : '#fff'}`
  }),
  option: (provided, state) => ({
    ...provided,
    color: invert ? '#3c3c3c' : '#fff',
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '1.25rem',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `${invert ? '#3c3c3c' : '#fff'}`,
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1rem'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: `${invert ? '#3c3c3c' : '#fff'}`
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0'
  })
});

const titleOptions = [
  {
    value: 'Doctor',
    label: 'Doctor'
  },
  {
    value: 'Mr',
    label: 'Mr'
  },
  {
    value: 'Mrs',
    label: 'Mrs'
  },
  {
    value: 'Ms',
    label: 'Ms'
  },
  {
    value: 'Miss',
    label: 'Miss'
  },
  {
    value: 'Professor',
    label: 'Professor'
  },
  {
    value: 'Nurse',
    label: 'Nurse'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

const professionOptions = [
  {
    value: 'Community Pharmacist',
    label: 'Community Pharmacist'
  },
  {
    value: 'Consultant',
    label: 'Consultant'
  },
  {
    value: 'Hospital Doctor - Consultant',
    label: 'Hospital Doctor - Consultant'
  },
  {
    value: 'Hospital Doctor - NCHD - Registrar',
    label: 'Hospital Doctor - NCHD - Registrar'
  },
  {
    value: 'Hospital Doctor - NCHD - Specialist Registrar',
    label: 'Hospital Doctor - NCHD - Specialist Registrar'
  },
  {
    value: 'Intern',
    label: 'Intern'
  },
  {
    value: 'Practice Nurse',
    label: 'Practice Nurse'
  },
  {
    value: 'Hospital Nurse',
    label: 'Hospital Nurse'
  },
  {
    value: 'Hospital Pharmacist',
    label: 'Hospital Pharmacist'
  },
  {
    value: 'Hospital Pharmacist - Basic Pharmacist',
    label: 'Hospital Pharmacist - Basic Pharmacist'
  },
  {
    value: 'Hospital Pharmacist - Senior Pharmacist',
    label: 'Hospital Pharmacist - Senior Pharmacist'
  },
  {
    value: 'Primary Care Physician',
    label: 'Primary Care Physician'
  },
  {
    value: 'Registrar',
    label: 'Registrar'
  },
  {
    value: 'SpR',
    label: 'SpR'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

const specialityOptions = [
  {
    value: 'Antimicrobial',
    label: 'Antimicrobial'
  },
  {
    value: 'Chief Pharmacist',
    label: 'Chief Pharmacist'
  },
  {
    value: 'Critical Care',
    label: 'Critical Care'
  },
  {
    value: 'Emergency Medicine',
    label: 'Emergency Medicine'
  },
  {
    value: 'Endocrinology',
    label: 'Endocrinology'
  },
  {
    value: 'General Medicine',
    label: 'General Medicine'
  },
  {
    value: 'Haematology',
    label: 'Haematology'
  },
  {
    value: 'Head of Pharmacy Services',
    label: 'Head of Pharmacy Services'
  },
  {
    value: 'Hospital Nurse',
    label: 'Hospital Nurse'
  },
  {
    value: 'Hospital Pharmacist',
    label: 'Hospital Pharmacist'
  },
  {
    value: 'Hospital Pharmacy',
    label: 'Hospital Pharmacy'
  },
  {
    value: 'ICU',
    label: 'ICU'
  },
  {
    value: 'Immunology',
    label: 'Immunology'
  },
  {
    value: 'Infectious Diseases',
    label: 'Infectious Diseases'
  },
  {
    value: 'Intensive Care Medicine',
    label: 'Intensive Care Medicine'
  },
  {
    value: 'Intensivist',
    label: 'Intensivist'
  },
  {
    value: 'Microbiology',
    label: 'Microbiology'
  },
  {
    value: 'Paediatric Endocrinology',
    label: 'Paediatric Endocrinology'
  },
  {
    value: 'Paediatrics',
    label: 'Paediatrics'
  },
  {
    value: 'Pharmacy',
    label: 'Pharmacy'
  },
  {
    value: 'Primary Care',
    label: 'Primary Care'
  },
  {
    value: 'Respiratory',
    label: 'Respiratory'
  },
  {
    value: 'Respiratory / Oncology',
    label: 'Respiratory / Oncology'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export default RegistrationForm;
