import React from 'react';
import styled, { keyframes } from 'styled-components';
import leftWing from 'assets/images/CnmzM0d/left-wing.png';
import rightWing from 'assets/images/CnmzM0d/right-wing.png';

const ButterflyContainer = () => {
  return (
    <Container>
      <Butterfly />
    </Container>
  );
};

const leftWingAnimation = keyframes`
  from {
    transform: translateZ(80px) rotateY(-10deg);
  }
  to {
    transform: translateZ(0px) rotateY(50deg);
  }
`;

const rightWingAnimation = keyframes`
  from {
    transform: translateZ(80px) rotateY(10deg);
  }
  to {
    transform: translateZ(0px) rotateY(-50deg);
  }
`;

const fadeInButterfly = keyframes`
  from {
    opacity: 0;
    transform: rotate(21deg) scale3d(0.01, 0.01, 0.01) translate3d(-400px, -400px, 0px);
  }
  to {
    opacity: 1;
    transform: rotate(21deg) scale3d(0.4, 0.4, 0.4) translate3d(0px, 0px, 0px);
  }
`;

const Container = styled.div`
  animation: ${fadeInButterfly} 2.75s 1s ease-in-out forwards;
  height: 342px;
  bottom: -200px;
  opacity: 0;
  position: absolute;
  right: -15.15rem;
  top: -6.7rem;
  transform-origin: center;
  transform: rotate(21deg) scale3d(0.4, 0.4, 0.4) translate3d(-400px, -400px, 0px);
  transform-style: preserve-3d;
  width: 450px;
`;

const Butterfly = styled.div`
  display: flex;
  height: 342px;
  margin-right: 100px;
  perspective: 450px;
  width: 450px;

  &::before,
  &::after {
    background-size: 100%;
    content: '';
    flex: 1;
    padding-top: 56%;
  }

  &::before {
    animation: ${leftWingAnimation} 1.7s ease-in-out infinite alternate;
    background-image: url(${leftWing});
    height: 342px;
    transform-origin: right 60%;
    width: 225px;
  }

  &::after {
    animation: ${rightWingAnimation} 1.7s ease-in-out infinite alternate;
    background-image: url(${rightWing});
    background-position: right;
    height: 342px;
    transform-origin: left 60%;
    width: 225px;
  }
`;

export default ButterflyContainer;
