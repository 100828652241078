import React, { useState, useEffect, useContext } from 'react';
import {
  ActionCodeError,
  EnterNewPassword,
  LoginModal,
  Modal,
  NewUserEmailVerification,
  RecoverEmail,
  Unsubscribe
} from 'components';
import { FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import queryString from 'query-string';
import { pfizer } from 'styles';

export default () => {
  const [event, setEvent] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [emailRecovered, setEmailRecovered] = useState(false);
  const [newUserEmailVerified, setNewUserEmailVerified] = useState(false);
  const [showNewUserEmailVerificationModal, setShowNewUserEmailVerificationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showEnterNewPasswordModal, setShowEnterNewPasswordModal] = useState(false);
  const [showRecoverEmailModal, setShowRecoverEmailModal] = useState(false);
  const [actionCodeError, setActionCodeError] = useState({ mode: null, message: null });
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const { loading, firebase, user } = useContext(FirebaseContext);
  const {
    mode,
    oobCode: actionCode,
    continueUrl,
    unsubscribe
  } = queryString.parse(useLocation().search);
  const {
    eventsUserWantsToAccess,
    eventSlug: eventSlugIfUserRegisteredUsingAnotherDeviceOrBrowser
  } = queryString.parse(continueUrl?.slice(continueUrl?.indexOf('?')));
  const colors = pfizer;

  const eventsUserWantsToAccessArray = eventsUserWantsToAccess?.split(',');

  const { events } = useStaticQuery(graphql`
    query {
      events: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(events)/" } }) {
        edges {
          node {
            id
            frontmatter {
              eid
              name
              slug
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (unsubscribe) {
      setShowUnsubscribeModal(true);
    }
  }, [unsubscribe]);

  const verifyEmail = (currentUser) => {
    if (!showNewUserEmailVerificationModal) {
      setShowNewUserEmailVerificationModal(true);
    }

    firebase
      .applyActionCode(actionCode)
      .then(() =>
        firebase.updateEmailVerifiedInDatabase({
          eventsUserWantsToAccess: eventsUserWantsToAccessArray || [],
          uid: currentUser.uid
        })
      )
      .then(async () => {
        if (eventsUserWantsToAccessArray?.length) {
          const _event = events.edges.find(
            ({ node }) =>
              node.frontmatter.eid ===
              [...eventsUserWantsToAccessArray].pop()
          );

          if (_event) setEvent(_event);

          // if (_event) {
          //   const { name, slug, start, end, location } = _event.node.frontmatter;
          //   await firebase.sendEventWelcomeEmail({
          //     attendeeName: data.user.name,
          //     attendeeEmail: data.user.email,
          //     event: {
          //       name,
          //       slug,
          //       start,
          //       end,
          //       location
          //     }
          //   });
          // }
        }

        await currentUser.reload();

        setNewUserEmailVerified(true);
      })
      .catch((error) => {
        console.error(error);
        setShowNewUserEmailVerificationModal(false);
        switch (error.code) {
          case 'auth/invalid-action-code':
            firebase.logout(user);
            setActionCodeError({
              mode,
              message:
                'Invalid or expired link. If you haven&apos;t already verified your account, please log in to your account again to trigger a new verification email.'
            });
            break;
          default:
            setActionCodeError({
              mode,
              message: error.message
            });
            break;
        }
      });
  };

  useEffect(() => {
    if (!loading && firebase) {
      const currentUser = firebase?.auth?.currentUser;
      switch (mode) {
        case 'verifyEmail':
          if (currentUser) {
            verifyEmail(currentUser);
          } else {
            setShowLoginModal(true);
          }
          break;
        case 'resetPassword':
          setShowEnterNewPasswordModal(true);
          firebase
            .verifyPasswordResetCode(actionCode)
            .then((email) => setUserEmail(email))
            .catch((error) => {
              console.error(error);
              setShowEnterNewPasswordModal(false);
              setActionCodeError({
                mode,
                message: 'Invalid or expired reset code.<br />Please try again.'
              });
            });
          break;
        case 'recoverEmail':
          setShowRecoverEmailModal(true);
          firebase
            .checkActionCode(actionCode)
            .then(({ data }) => {
              const recoveredEmail = data.email;
              return firebase
                .applyActionCode(actionCode)
                .then(() => firebase.updateEmailInDatabase(recoveredEmail))
                .then(() => {
                  setEmailRecovered(true);
                  setUserEmail(recoveredEmail);
                });
            })
            .catch((error) => {
              console.error(error);
              setShowRecoverEmailModal(false);
              switch (error.code) {
                case 'auth/invalid-action-code':
                  setActionCodeError({
                    mode,
                    message: 'Invalid or expired link.<br />Please try again.'
                  });
                  break;
                default:
                  setActionCodeError({
                    mode,
                    message: error.message
                  });
                  break;
              }
            });
          break;
        default:
          break;
      }
    }
  }, [loading, firebase]);

  return (
    <>
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showNewUserEmailVerificationModal}
        modalContent={
          <NewUserEmailVerification
            colors={colors}
            setShowNewUserEmailVerificationModal={setShowNewUserEmailVerificationModal}
            newUserEmailVerified={newUserEmailVerified}
            event={event}
          />
        }
      />
      <Modal
        hideCloseButton
        openWithoutTrigger={showLoginModal}
        redirectIfNotAuthorised
        modalContent={
          <LoginModal
            verifyEmail={verifyEmail}
            colors={colors}
            setShowLoginModal={setShowLoginModal}
            userRegisteredUsingAnotherDeviceOrBrowser
            eventSlug={eventSlugIfUserRegisteredUsingAnotherDeviceOrBrowser}
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showEnterNewPasswordModal}
        modalContent={
          <EnterNewPassword
            colors={colors}
            actionCode={actionCode}
            setShowEnterNewPasswordModal={setShowEnterNewPasswordModal}
            userEmail={userEmail}
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showRecoverEmailModal}
        modalContent={
          <RecoverEmail
            colors={colors}
            emailRecovered={emailRecovered}
            setShowRecoverEmailModal={setShowRecoverEmailModal}
            userEmail={userEmail}
          />
        }
      />
      <Modal
        hideCloseButton
        openWithoutTrigger={actionCodeError.message}
        modalContent={
          <ActionCodeError
            colors={colors}
            actionCodeError={actionCodeError}
            setActionCodeError={setActionCodeError}
          />
        }
      />
      <Modal
        hideCloseButton
        openWithoutTrigger={showUnsubscribeModal}
        modalContent={<Unsubscribe />}
      />
    </>
  );
};
