import React, { useContext, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  RegistrationForm,
  Button,
  EventButton,
  Modal,
  LoadingSpinner,
  Baubles
} from 'components';
import { FormLabel } from 'components/Forms/FormComponents';
import { FirebaseContext, LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AnimatePresence, motion } from 'framer-motion';
import { hexToRGB, scrollToAnchor } from 'utils';
import { pfizer } from 'styles';
import { Markup } from 'interweave';
import { PfizerMedicalAffairsLogo, PfizerLogo } from 'assets/svgs';


export default (props) => {
  const { event } = props;
  const { loading, firebase, user } = useContext(FirebaseContext);
  const { headerRef } = useContext(LocalContext);
  const { windowWidth } = useWindowSize();
  const [isRegistering, setIsRegistering] = useState(false);
  const showComponent = !loading && firebase && (!user || user?.emailVerified === false) && event.type !== 'past';

  const videoRef = useRef();

  /* https://stackoverflow.com/questions/58731760/how-to-use-playbackrate-parameter-for-video-element-in-reactjs */
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.85;
    // videoRef.current.playbackRate = 0.65;
  };

  const userAlreadyHasAnAccount =
    !loading &&
    firebase &&
    user?.emailVerified &&
    !user.eventsUserWantsToAccess.includes(event.eid) &&
    !user.eventsUserHasBeenDeniedAccessTo.includes(event.eid);

  const handleRegisterForEventIfUserAlreadyHasAnAccount = async () => {
    setIsRegistering(true);
    const { name, slug, start, end, location } = event;

    firebase
      .registerForEventIfUserAlreadyHasAnAccount({
        uid: user.uid,
        eid: event.eid
      })
      .then(({ data: _user }) => {
        const { eventsUserCanAccess } = _user;
        if (eventsUserCanAccess.includes(event.eid)) {
          // firebase.sendEventWelcomeEmail({
          //   attendeeName: user.fullName,
          //   attendeeEmail: user.email,
          //   event: {
          //     name,
          //     slug,
          //     start,
          //     end,
          //     location
          //   }
          // });
        } else {
          // firebase.sendAwaitingAccessApprovalEmail({
          //   fullName: _user.fullName,
          //   email: _user.email,
          //   uid: _user.uid,
          //   eventName: event.name
          // })
        }
        setIsRegistering(false);
      })
      .catch((error) => {
        console.error(error);
        setIsRegistering(false);
      });
  };

  return (
    <Header
      colors={event.colors}
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Background
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.25 }} eid={event.eid}>
        {event.bannerVideo ? (
          <BackgroundVideo
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
            playsInline
            autoPlay
            muted
            loop
            id="bgvid"
            ref={videoRef}
            onCanPlay={() => setPlayBack()}
            key={event.bannerVideo}>
            <source src={event.bannerVideo} type="video/mp4" />
          </BackgroundVideo>
        ) : (
          <BackgroundImage eid={event.eid} image={getImage(event.banner)} alt="Background" objectPosition="right center" />
        )}
        <BackgroundOverlay colors={event.colors} eid={event.eid} />
      </Background>
      <Container eid={event.eid} userAlreadyHasAnAccount={userAlreadyHasAnAccount}>
        <Content>
          <Date
            colors={event.colors}
            contrast={event.bannerContrast}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <Markup content={event.dates} />
          </Date>
          {event.title && (
            <Title
              colors={event.colors}
              contrast={event.bannerContrast}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              {<Markup content={event.title} />}
              {event.subtitle && (
                <Subtitle colors={event.colors} contrast={event.bannerContrast}>
                  <Markup content={event.subtitle} />
                </Subtitle>
              )}
            </Title>
          )}
          {event.description && (
            <Description
              colors={event.colors}
              contrast={event.bannerContrast}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <Markup content={event.description} noWrap />
            </Description>
          )}
            <Sponsor
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <p>brought to you by</p>
              <div>
              {event.eid === 'eIj0KJ' ? <PfizerMedicalAffairsLogo style={{ maxWidth: '192px' }} /> : <PfizerLogo style={{ maxWidth: '192px' }} /> }
              </div>
            </Sponsor>
          {userAlreadyHasAnAccount && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}
              style={{ margin: '2.5rem 0 1.75rem' }}>
              <Button
                shine
                onClick={() => handleRegisterForEventIfUserAlreadyHasAnAccount()}
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.95 }}
                disabled={user?.emailVerified === false}
                style={{
                  alignItems: 'center',
                  backgroundColor: event.colors.secondary,
                  cursor: user?.emailVerified ? 'pointer' : 'not-allowed',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '180px'
                }}>
                {isRegistering ? (
                  <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
                ) : (
                  'Register'
                )}
              </Button>
            </motion.div>
          )}
          {windowWidth < 1150 && showComponent && (
            <RegisterButton
              colors={event.colors}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                width="200px"
                type="button"
                style={{
                  backgroundColor: event.colors.secondary
                }}>
                <Modal
                  hideCloseButton
                  trigger={<p>Register Here</p>}
                  modalContent={
                    <RegistrationForm
                      colors={event.colors}
                      event={event}
                      contrast={event.bannerContrast}
                    />
                  }
                />
              </Button>
            </RegisterButton>
          )}
          <motion.div
            style={{ display: 'flex', alignItems: 'center' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            {event.type === 'future' && windowWidth >= 550 && (
              <EventButton
                colors={event.colors}
                eventTitle={event.title}
                eventDescription={event.description}
                calendarDescription={event.calendarDescription}
                startTime={event.startTime}
                endTime={event.endTime}
                calendarLink={event.calendarLink}
                location={`https://www.pfizervirtualcafe.ie/events/${event.slug}`}
              />
            )}
          </motion.div>
          {event.type === 'future' && windowWidth < 550 && (
            <EventButton
              colors={event.colors}
              eventTitle={event.title}
              eventDescription={event.description}
              calendarDescription={event.calendarDescription}
              startTime={event.startTime}
              endTime={event.endTime}
              calendarLink={event.calendarLink}
              location={`https://www.pfizervirtualcafe.ie/events/${event.slug}`}
              style={{
                padding: '2.5rem 1rem 1rem 0'
              }}
            />
          )}
        </Content>
        {windowWidth >= 1150 && showComponent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <RegistrationForm colors={event.colors} event={event} contrast={event.bannerContrast} />
          </motion.div>
        )}
        {!loading &&
          firebase &&
          user?.emailVerified &&
          !user.emailAddressIsWhitelisted &&
          (user.eventsUserWantsToAccess.includes(event.eid) ||
            user.eventsUserHasBeenDeniedAccessTo.includes(event.eid)) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <AwaitingApprovalMessage colors={pfizer}>
                <FormLabel>Approval Pending</FormLabel>
                <p>
                  {`Our moderators will review your registration application and grant you access to
                  the ${
                    user.eventsUserWantsToAccess.includes(event.eid) ? 'event' : 'site'
                  } as soon as possible.`}
                </p>
                <p>Keep an eye on that inbox!</p>
              </AwaitingApprovalMessage>
            </motion.div>
          )}
      </Container>
    </Header>
  );
};

const AwaitingApprovalMessage = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  color: #3c3c3c;
  display: flex;
  font-size: 1.25rem;
  flex-direction: column;
  padding: 0.5em 1em;
  text-align: center;
  padding-top: 1.25em;
  margin-top: 6.625em;

  h3 {
    font-size: 1.75rem;
    margin-bottom: 0.625em;
  }

  p {
    margin-bottom: 0.75em;
  }
`;

const RegisterButton = styled(motion.div)`
  /* margin-left: 0.5rem; */
  margin-top: 1rem;
  button {
    background-color: ${({ colors }) => colors.tertiary};
  }
`;

const Sponsor = styled(motion.div)`
  font-weight: 400;
  color: white;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  max-width: 360px;
  p {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }
  svg {
    width: 100%;
    height: auto;
  }
  div {
    display: flex;
    gap: 2rem;
  }
`;

const Description = styled(motion.div)`
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 1.5rem;
  color: white;
  /* width: 90%; */
  span {
    font-style: italic;
    font-family: Noto Sans;
  }
  @media (min-width: 1150px) {
    margin-bottom: 1rem;
  }
`;

const Title = styled(motion.div)`
  color: ${({ contrast, colors }) => (contrast ? 'white' : colors.secondary)};
  margin-bottom: 2rem;
  h1 {
    font-weight: 300;
    font-size: 2.625rem;
    line-height: 1.364em;
    span {
      white-space: nowrap;
    }
  }
  @media (min-width: 1150px) {
    h1 {
      font-size: 3.75rem;
    }
  }
`;

const Subtitle = styled.div`
  font-weight: 700;
  font-size: 1.625rem;
  color: ${({ contrast, colors }) => (contrast ? 'white' : colors.tertiary)};
`;

const Date = styled(motion.h4)`
  font-weight: 700;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ contrast, colors }) => (contrast ? 'white' : colors.tertiary)};
  margin-bottom: 1rem;
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 710px;
  padding: 2rem 0.5rem;
  width: 100%;

  @media (min-width: 768px) {
    padding: 4rem;
  }

  @media (min-width: 1150px) {
    padding: 2rem 0 0 0;
    max-width: 680px;
  }
`;

const BackgroundVideo = styled(motion.video)`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${({ colors }) =>
    `linear-gradient(to right, ${hexToRGB({
      color: colors.primary,
      alpha: 1
    })}, rgba(0,0,0,0))`};
`;

const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation:  ${({ eid }) => (eid === 'EUwYfa' ? 'zoom-in-zoom-out 10s ease-in-out infinite' : 'none')};
  @keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1) translateX(0px);
  }
  50% {
    transform: scale(1.1, 1.1) translateX(-20px);
  }
  75% {
    transform: scale(1.05, 1.05) translateX(10px);
  }
  100% {
    transform: scale(1, 1) translateX(0px);
  }
}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1184px;
  padding: 5rem 0.625rem 2.5rem;
  position: relative;
  @media (min-width: 1150px) {
    flex-direction: row;
    padding: ${({ userAlreadyHasAnAccount }) =>
      `${userAlreadyHasAnAccount ? '7.75rem' : '9.55rem'} 0.625rem 1.25rem`};
  }
  @media (min-width: 1220px) {
    padding: ${({ userAlreadyHasAnAccount }) =>
      `${userAlreadyHasAnAccount ? '7.75rem' : '9.55rem'} 0 1.25rem`};
  }
`;

const Header = styled.header`
  background-color: ${({ colors }) => colors.primary};
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (min-width: 1150px) {
    min-height: 100vh;
  }
`;
