import React, { useContext, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { Button, ErrorMessage, LoadingSpinner } from 'components';
import PasswordMask from 'react-password-mask';
import { LocalContext, FirebaseContext } from 'context';
import { clearQueryParams } from 'utils';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { FormContainer, FormLabel, FormInput } from '../FormComponents';

const LoginModal = ({
  verifyEmail,
  setShowLoginModal,
  userRegisteredUsingAnotherDeviceOrBrowser,
  eventSlug,
  colors
}) => {
  const { theme } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState({
    sending: false,
    sent: false
  });
  const [error, setError] = useState({
    code: '',
    message: userRegisteredUsingAnotherDeviceOrBrowser
      ? 'It seems your last website interaction was from another device or browser. Please enter your email address to be sent a new link, which you can click to continue with this browser.'
      : ''
  });

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        if (eventSlug) {
          navigate(`/events/${eventSlug}/livestream`);
        }
      } else if (!user.emailVerified) {
        navigate('/');
      }
    }
  }, [user]);

  const handleInputChange = (e) => {
    if (!userRegisteredUsingAnotherDeviceOrBrowser) {
      setError({
        code: '',
        message: ''
      });
    }

    const { name, value } = e.target;
    setFormValues((currentValues) => ({
      ...currentValues,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userRegisteredUsingAnotherDeviceOrBrowser && error.code === 'auth/user-not-found') {
      clearQueryParams();
      if (eventSlug) {
        navigate(`/events/${eventSlug}`);
      } else {
        setShowLoginModal(false);
      }
      return;
    }

    if (!userRegisteredUsingAnotherDeviceOrBrowser) {
      setError({
        code: '',
        message: ''
      });
    }

    const { email, password } = formValues;

    if (forgotPassword) {
      setPasswordResetEmail({
        sending: true,
        sent: false
      });
      firebase
        .sendPasswordResetEmail({
          email,
          actionCodeSettings: {
            handleCodeInApp: true,
            url: window.location.href
          },
          colors
        })
        .then(() => {
          setPasswordResetEmail({
            sending: false,
            sent: true
          });
          setFormValues({
            email: '',
            password: ''
          });
        })
        .catch((_error) => {
          console.error(_error);
          setPasswordResetEmail({
            sending: false,
            sent: false
          });
          switch (_error.code) {
            case 'auth/user-not-found':
              setError({
                code: _error.code,
                message: 'There is no user account associated<br />with this email address.'
              });
              break;
            default:
              setError({ code: _error.code, message: _error.message });
              break;
          }
        });
    } else {
      setLoggingIn(true);
      firebase
        .login(email, password)
        .then(({ user: userFromAuth }) => {
          setLoggingIn(false);
          document.querySelector('html').classList.remove('scroll-lock');
          if (verifyEmail) {
            setShowLoginModal(false);
            verifyEmail(userFromAuth);
          }
        })
        .catch((_error) => {
          console.error(_error);
          setLoggingIn(false);
          switch (_error.code) {
            case 'auth/user-disabled':
              setError({
                code: _error.code,
                message:
                  '<div style="text-transform: initial;">Error logging in.<br />Please contact support@pfizervirtualcafe.ie</div>'
              });
              break;
            case 'auth/user-not-found':
              setError({
                code: _error.code,
                message: 'There is no user account associated<br />with this email address.'
              });
              break;
            case 'auth/wrong-password':
              setError({
                code: _error.code,
                message: 'Password invalid.<br />Please double check and try again.'
              });
              break;
            default:
              setError({ code: _error.code, message: _error.message });
              break;
          }
        });
    }
  };

  return (
    <StyledFormContainer onSubmit={handleSubmit} theme={theme} colors={colors}>
      <FormLabel>{forgotPassword ? 'Reset Password' : 'Login'}</FormLabel>
      {forgotPassword ? (
        <>
          <FormLabel hide htmlFor="email">
            Email
          </FormLabel>
          <FormInput
            id="email"
            name="email"
            onChange={(e) => handleInputChange(e)}
            placeholder="Email"
            type="email"
            value={formValues.email}
            required
            style={{
              marginBottom: '1.1rem'
            }}
          />
        </>
      ) : (
        <>
          <FormLabel hide htmlFor="email">
            Email
          </FormLabel>
          <FormInput
            id="email"
            name="email"
            onChange={(e) => handleInputChange(e)}
            placeholder="Email"
            type="email"
            value={formValues.email}
            required
            style={{
              marginBottom: '1.5rem'
            }}
          />
          <FormLabel hide htmlFor="password">
            Password
          </FormLabel>
          <PasswordMask
            id="password"
            minLength={6}
            name="password"
            onChange={(e) => handleInputChange(e)}
            placeholder="Password"
            required
            type="password"
            value={formValues.password}
            inputClassName="password-mask-input"
            inputStyles={{
              marginBottom: '1rem'
            }}
            buttonStyles={{
              background: colors.secondary
            }}
            buttonClassName={
              formValues.password ? 'password-mask-button' : 'password-mask-button-hide'
            }
          />
        </>
      )}
      <ErrorMessage
        errorMessage={error.message}
        style={{
          color: '#fff',
          marginBottom: '0.275em'
        }}
        variants={fadeInAndOutAndAnimateHeightVariants()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: forgotPassword ? 'column' : 'row',
          alignItems: forgotPassword ? 'center' : null
        }}>
        {passwordResetEmail.sent ? (
          <p
            style={{
              marginBottom: '0.7rem',
              textAlign: 'center'
            }}>
            Please check your email to reset your password.
          </p>
        ) : (
          <Button
            whileTap={{
              scale: 0.9
            }}
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            type="submit"
            width={forgotPassword ? '300px' : '120px'}>
            {loggingIn || passwordResetEmail.sending ? (
              <LoadingSpinner style={{ color: '#fff', width: '1.5rem' }} />
            ) : forgotPassword ? (
              'Send Password Reset Email'
            ) : (
              'Login'
            )}
          </Button>
        )}
        {forgotPassword && (
          <Button
            width="300px"
            whileTap={{
              scale: 0.9
            }}
            onClick={() => {
              setForgotPassword(false);
              setPasswordResetEmail(false);
              setError({ code: '', message: '' });
            }}
            style={{ marginTop: '1rem' }}>
            {passwordResetEmail.sent ? 'Return To Login' : 'Never Mind, Go Back'}
          </Button>
        )}
      </div>
      {!forgotPassword && (
        <ForgotPassword onClick={() => setForgotPassword(true)}>Forgot password?</ForgotPassword>
      )}
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};

  button {
    background: ${({ colors }) => colors.secondary};
  }

  ${({ userRegisteredUsingAnotherDeviceOrBrowser }) =>
    userRegisteredUsingAnotherDeviceOrBrowser &&
    css`
      @media (min-width: 1440px) {
        padding: 2.5rem 1.25rem;
        max-width: 510px;
        width: 100%;
      }
    `}
`;

const ForgotPassword = styled.p`
  cursor: pointer;
  margin-top: 1.4rem;
  text-align: center;
  text-decoration: underline;
`;

export default LoginModal;
