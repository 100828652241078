import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const FormContainer = styled.form`
  background: ${({ theme }) => theme.muted};
  width: 500px;
  max-width: 100%;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 0.625rem;
  height: auto;
  justify-content: center;
  h3,
  label,
  input,
  span,
  p,
  textarea {
    color: ${({ theme }) => theme.contrastTextColor};
  }
  input,
  span textarea {
    border-color: ${({ theme }) => theme.contrastTextColor};
  }
  input,
  textarea {
    ::placeholder {
      color: ${({ theme }) => theme.contrastTextColor};
    }
  }

  @media (min-width: 1440px) {
    padding: 2.5rem 1.25rem;
    max-width: 466.625px;
  }
`;

export const FormLabel = styled.h3`

  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ hide }) =>
    hide
      ? css`
          width: 1px;
          height: 1px;
          opacity: 0;
        `
      : css`
          margin-bottom: 2rem;
        `}
`;

export const FormInputLabel = styled.label`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  justify-content: flex-start;
  line-height: 19px;
  overflow: hidden;
  width:100%;
  ${({ style }) => style};

  ${({ hide }) =>
    hide
      ? css`
          width: 1px;
          height: 1px;
          opacity: 0;
        `
      : css`
          margin-bottom: 0.375rem;
        `}
`;

export const FormInput = styled(motion.input)`
  align-items: center;
  background: transparent;
  border: 1px solid ${({ invert }) => invert ? '#3c3c3c' : '#fff'};
  color: ${({ invert }) => invert ? '#3c3c3c' : '#fff'};
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3.75rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.6em;
  outline: none;
  padding: 0px 1.25rem;
  width: 100%;

  :focus {
    border: 0.188em solid ${({ invert }) => invert ? '#3c3c3c' : '#fff'};
  }

  ::placeholder {
    font-style: italic;
    font-weight: 600;
    font-size: 1rem;
    color: ${({ invert }) => invert ? '#3c3c3c' : '#fff'}
  }
`;

export const FormChecks = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  margin-bottom: 1.25rem;
`;

export const FormCheckboxText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.25rem;

  a {
    color: ${({ colors }) => colors ? colors.secondary : '#fff'};
    text-decoration: underline;
  }
`;

export const FileUpload = styled.label`
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
  cursor: pointer;
`;

export const HiddenFileUpload = styled.input`
  display: none;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 10rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 0px;
  background-color: transparent;
  color: ${({ theme }) => theme.contrastTextColor};
  resize: none;

  font-weight: 600;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  ::placeholder {
    color: white !important;
    font-style: italic;
  }
  :focus {
    outline: none;
  }
`;
