import React, { useState, useEffect, useContext } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import { LoadingSpinner, LoadingSpinnerFullScreenWrapper, ProtectedRoute, SEO } from 'components';
import BaseSelect from 'react-select';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';
import { pfizer } from 'styles';
import { useEventData } from 'hooks';

const reminderTypes = [
  {
    label: '72 Hours Before',
    value: 'reminder-email-72-hours-before'
  },
  {
    label: '48 Hours Before',
    value: 'reminder-email-48-hours-before'
  },
  {
    label: '24 Hours Before',
    value: 'reminder-email-24-hours-before'
  },
  {
    label: 'Same Day',
    value: 'reminder-email-same-day'
  }
];

const FormSelect = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />
);

const customDropdownMenuStyles = (colors) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'transparent',
    border: state.isFocused ? `0.25em solid ${colors.primary}` : `1px solid ${colors.primary}`,
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    height: '2.5rem',
    letterSpacing: '0.036em',
    padding: '0',
    width: '300px',
    marginBottom: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    // width: '100%',
    padding: state.isFocused ? '0 0 0 0.85rem' : '0 0 0 1rem',
    transition: 'none',
    cursor: 'pointer',
    height: '100%',
    width: '300px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.primary,
    '&:hover': {
      color: colors.primary
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0,
    width: '100%'
  }),
  menu: (provided) => ({
    ...provided,
    background: colors.primary,
    border: `0.25em solid ${colors.primary}`,
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.25em',
    letterSpacing: '0.036em',
    margin: '0',
    width: 'calc(100% + 0.5em)'
  }),
  menuList: (provided) => ({
    ...provided,
    '::-webkit-scrollbar': {
      width: '11px'
    },
    '::-webkit-scrollbar-track': {
      background: colors.primary
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.secondary,
      height: '1.25rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.tertiary
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: colors.primary
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '0.85rem',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Noto Sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: colors.secondary,
    margin: 0
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
});

const Admin = () => {
  const { theme } = useContext(LocalContext);
  const { loading, firebase, user } = useContext(FirebaseContext);
  const [uidOfUserToGrantAdminPermissionsTo, setUidOfUserToGrantAdminPermissionsTo] = useState('');
  const [grantingUserAdminPermissions, setGrantingUserAdminPermissions] = useState(false);
  const [generatingEventCommentsReport, setGeneratingEventCommentsReport] = useState(false);
  const [uidOfUserToGrantModeratorPermissionsTo, setUidOfUserToGrantModeratorPermissionsTo] =
    useState('');
  const [uidOfUserToRemoveModeratorPermissionsOf, setUidOfUserToRemoveModeratorPermissionsOf] =
    useState('');
  const [grantingUserModeratorPermissions, setGrantingUserModeratorPermissions] = useState('');
  const [removingUserModeratorPermissions, setRemovingUserModeratorPermissions] = useState('');
  const [makeUserAModeratorSelectedEvent, setMakeUserAModeratorSelectedEvent] = useState('');
  const [sendReminderEmailSelectedEvent, setSendReminderEmailSelectedEvent] = useState('');
  const [sendEventCancelledEmailSelectedEvent, setSendEventCancelledEmailSelectedEvent] =
    useState('');
  const [sendingEventReminderEmail, setSendingEventReminderEmail] = useState(false);
  const [sendingEventCancelledEmail, setSendingEventCancelledEmail] = useState(false);
  const [eventReminderType, setEventReminderType] = useState('');

  const { edges: events } = useEventData();

  const sortedEvents = events
    .map(({ node }) => ({
      ...node.frontmatter,
      value: node.frontmatter.eid,
      label: node.frontmatter.eid
    }))
    .sort((a, b) => a.eid - b.eid);

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/');
    }
  }, [loading, firebase, user]);

  const uidArray = [];

  const handleGrantUserAdminPermissions = (e) => {
    e.persist();
    setUidOfUserToGrantAdminPermissionsTo(e.target.value);
  };

  const handleGrantUserModeratorPermissions = (e) => {
    e.persist();
    setUidOfUserToGrantModeratorPermissionsTo(e.target.value);
  };

  const handleRemoveUserModeratorPermissions = (e) => {
    e.persist();
    setUidOfUserToRemoveModeratorPermissionsOf(e.target.value);
  };

  const handleGenerateEventCommentsReport = async () => {
    try {
      setGeneratingEventCommentsReport(true);
      const { data } = await firebase.generateEventCommentsReport({
        event: {
          eid: makeUserAModeratorSelectedEvent.eid,
          name: makeUserAModeratorSelectedEvent.name
        }
      });
      return window.open(data, '_blank') || window.location.replace(data);
    } catch (error) {
      console.error(error);
    } finally {
      setGeneratingEventCommentsReport(false);
    }
  };

  const sendEventReminderEmail = async () => {
    try {
      setSendingEventReminderEmail(true);
      await firebase.sendEventReminderEmail({
        event: sendReminderEmailSelectedEvent,
        templateAlias: eventReminderType.value
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSendingEventReminderEmail(false);
    }
  };

  const sendEventCancelledEmail = async () => {
    try {
      setSendingEventCancelledEmail(true);
      await firebase.sendEventCancelledEmail({ event: sendEventCancelledEmailSelectedEvent });
    } catch (error) {
      console.error(error);
    } finally {
      setSendingEventCancelledEmail(false);
    }
  };

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle="Admin" />
      <Wrapper>
        {!user ? (
          <LoadingSpinnerFullScreenWrapper>
            <LoadingSpinner style={{ width: '7.5rem', color: theme.contrast }} />
          </LoadingSpinnerFullScreenWrapper>
        ) : (
          <>
            <Button
              onClick={async () => {
                const uidArray = [];

                await firebase.fetchAllUsers().then(({ data: allUsers }) => {
                  allUsers.forEach((_user) => {
                    // Go here to convert the event date and time to a timestamp - www.timestampconvert.com
                    // and then add three zeros onto the end to make it compatible with the Firebase timestamp. //
                    if (
                      new Date(_user.metadata.lastRefreshTime).getTime() >= 1700586000000 &&
                      new Date(_user.metadata.lastRefreshTime).getTime() <= 1700600400000
                    ) {
                      uidArray.push(_user.uid);
                    }
                  });
                });
                const userNamesAndEmailsArray = [];

                const run = async () => {
                  const promises = [];

                  uidArray.forEach((uid) => {
                    promises.push(firebase.getUserFromDatabaseWithUID({ uid }));
                  });

                  const promiseResults = await Promise.all(promises);

                  promiseResults.forEach((res) => {
                    if (!res.empty) {
                      const {
                        title,
                        fullName,
                        firstName,
                        lastName,
                        email,
                        profession,
                        speciality,
                        workplaceName,
                        eventsUserCanAccess,
                        cpdPermissionsConsent
                      } = res.docs[0].data();
                      if (eventsUserCanAccess.includes('eIj0KJ') && cpdPermissionsConsent) {
                        userNamesAndEmailsArray.push({
                          title,
                          fullName,
                          firstName,
                          lastName,
                          email,
                          profession,
                          speciality,
                          workplaceName
                        });
                      }
                    }
                  });
                };

                await run();

                const sortedUsers = userNamesAndEmailsArray.sort((a, b) =>
                  a.fullName.toLowerCase() !== b.fullName.toLowerCase()
                    ? a.fullName.toLowerCase() < b.fullName.toLowerCase()
                      ? -1
                      : 1
                    : 0
                );

                const sortedUsersWithRowNumbers = sortedUsers.map((entry, i) => ({
                  rowNumber: i + 1,
                  ...entry
                }));

                firebase
                  .generateLoggedInOnTheDayReport({ users: sortedUsersWithRowNumbers })
                  .then(
                    ({ data: _data }) =>
                      window.open(_data, '_blank') || window.location.replace(_data)
                  )
                  .catch(console.error);
              }}>
              Generate Logged In On The Day Report
            </Button>
            <br />
            <br />
            <Button
              type="button"
              onClick={() => {
                firebase.fetchAllUsers().then(({ data: allUsers }) => {
                  allUsers.forEach((_user) => {
                    firebase.getUserFromDatabaseWithUID({ uid: _user.uid }).then((res) => {
                      if (res.empty) {
                        console.log(_user.uid);
                      } else {
                        console.log('OK');
                      }
                    });
                  });
                });
              }}>
              Get Invalid UIDs
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.55rem'
              }}>
              <input
                style={{
                  outlineColor: '#2d9cdb',
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToGrantAdminPermissionsTo}
                placeholder="UID"
                onChange={handleGrantUserAdminPermissions}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setGrantingUserAdminPermissions(true);
                    await firebase.grantUserAdminPermissions({
                      uid: uidOfUserToGrantAdminPermissionsTo
                    });
                    setUidOfUserToGrantAdminPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setGrantingUserAdminPermissions(false);
                  }
                }}>
                {grantingUserAdminPermissions ? <LoadingSpinner /> : `Make User An Admin`}
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.55rem',
                width: '100%'
              }}>
              <input
                style={{
                  outlineColor: '#2d9cdb',
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToGrantModeratorPermissionsTo}
                placeholder="UID"
                onChange={handleGrantUserModeratorPermissions}
              />
              <FormSelect
                required
                defaultValue={makeUserAModeratorSelectedEvent.eid}
                value={sortedEvents.filter(
                  ({ value }) => value === makeUserAModeratorSelectedEvent.eid
                )}
                controlShouldRenderValue
                onChange={setMakeUserAModeratorSelectedEvent}
                placeholder="Event Id*"
                styles={customDropdownMenuStyles(pfizer)}
                options={sortedEvents}
                style={{
                  width: '100%'
                }}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setGrantingUserModeratorPermissions(true);
                    await firebase.grantUserModeratorPermissions({
                      uid: uidOfUserToGrantModeratorPermissionsTo,
                      eid: makeUserAModeratorSelectedEvent.eid
                    });
                    // setSelectedEvent('');
                    // setUidOfUserToGrantModeratorPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setGrantingUserModeratorPermissions(false);
                  }
                }}>
                {grantingUserModeratorPermissions ? <LoadingSpinner /> : `Make User A Moderator`}
              </Button>
              <input
                style={{
                  outlineColor: '#2d9cdb',
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToRemoveModeratorPermissionsOf}
                placeholder="UID"
                onChange={handleRemoveUserModeratorPermissions}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setRemovingUserModeratorPermissions(true);
                    await firebase.removeUserModeratorPermissions({
                      uid: uidOfUserToRemoveModeratorPermissionsOf
                    });
                    // setUidOfUserToGrantModeratorPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setRemovingUserModeratorPermissions(false);
                  }
                }}>
                {removingUserModeratorPermissions ? (
                  <LoadingSpinner />
                ) : (
                  `Remove Moderator Permissions`
                )}
              </Button>
              {/* <Button
                $theme={theme}
                type="button"
                whileHover={{
                  scale: 1.05
                }}
                whileTap={{
                  scale: 0.95
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={handleGenerateEventCommentsReport}>
                {generatingEventCommentsReport ? (
                  <LoadingSpinner />
                ) : (
                  'Generate Event Comments Report'
                )}
              </Button> */}
              <br />
              <br />
              <FormSelect
                required
                defaultValue={sendReminderEmailSelectedEvent.eid}
                value={sortedEvents.filter(
                  ({ value }) => value === sendReminderEmailSelectedEvent.eid
                )}
                controlShouldRenderValue
                onChange={setSendReminderEmailSelectedEvent}
                placeholder="Event Id*"
                styles={customDropdownMenuStyles(pfizer)}
                options={sortedEvents}
                style={{
                  width: '100%'
                }}
              />
              <FormSelect
                id="reminder-types"
                name="reminder-types"
                required
                value={eventReminderType}
                controlShouldRenderValue
                onChange={setEventReminderType}
                placeholder="Reminder Type*"
                styles={customDropdownMenuStyles(pfizer)}
                options={reminderTypes}
                style={{
                  width: '100%'
                }}
              />
              <Button
                $theme={theme}
                type="button"
                whileHover={{
                  scale: 1.05
                }}
                whileTap={{
                  scale: 0.95
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={sendEventReminderEmail}>
                {sendingEventReminderEmail ? <LoadingSpinner /> : 'Send Reminder Email'}
              </Button>
              {/* <br />
              <br />
              <FormSelect
                required
                defaultValue={sendEventCancelledEmailSelectedEvent.eid}
                value={events.filter(({ value }) => value === sendEventCancelledEmailSelectedEvent.eid)}
                controlShouldRenderValue
                onChange={setSendEventCancelledEmailSelectedEvent}
                placeholder="Event Id*"
                styles={customDropdownMenuStyles(pfizer)}
                options={events}
                style={{
                  width: '100%'
                }}
              />
              <Button
                $theme={theme}
                type="button"
                whileHover={{
                  scale: 1.05
                }}
                whileTap={{
                  scale: 0.95
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={sendEventCancelledEmail}>
                {sendingEventCancelledEmail ? <LoadingSpinner /> : 'Send Event Cancelled Email'}
              </Button> */}
              <br />
              <br />
            </div>
          </>
        )}
      </Wrapper>
    </ProtectedRoute>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  margin: 2.55rem 1rem 0;
  padding-top: 10rem;
  width: 100%;
`;

const ButtonStyles = css`
  align-items: center;
  background: #2d9cdb;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 700;
  height: 3.5em;
  justify-content: center;
  margin: 0 6px 12px;
  padding: 1em 0;
  text-align: center;
  text-transform: uppercase;
  width: 200px;

  &:hover {
    filter: brightness(112%);
    transition: filter 150ms;
  }
`;

const Button = styled(motion.button).attrs({
  whileHover: {
    scale: 1.025
  },
  whileTap: {
    scale: 0.95
  }
})`
  ${ButtonStyles}
`;

export default Admin;
